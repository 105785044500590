.GeneralView {
  height: 100%;
  position: sticky;
  @media (max-width: 1096px) {
    width: 100%; }

  &__title {
    margin: 3.9rem 0px 2.1rem;
    @media (max-width: 1096px) {
      margin: 2.5rem 0px 1.5rem; } }

  &__topbar {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 5px;

    @media (max-width: 1096px) {
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 0; } }

  &__filterValue {
    margin: 0;
    font-size: 15px;
    color: #4B4B4B;
    line-height: 15px;

    &--planValue {
      margin-top: 5px;
      font-size: 12px;
      color: #1F35B5;
      font-weight: bold;
      line-height: 12px; } }

  &__filterIcon {
    cursor: pointer;
    height: 24px; }

  &__content {
    width: 100%;

    &__column {
      display: flex;
      flex-direction: column;
      width: 100%;

      &:nth-child(odd) {
        margin-right: 20px; }

      > div {
        margin-top: 20px; } }

    &--cards {
      padding: 0;
      display: flex;
      justify-content: space-between; }

    &--charts {
      padding: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: 1096px) {
        flex-direction: column; } } } }
