.ProfessionalChip {
  @include card(1);
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 100px;
  background-color: white;
  min-width: 100%;

  &__info {
    display: inline-block;
    flex: 1;
    padding-left: 15px;
    width: calc(100% - 50px);

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #4B4B4B; } }

  &__image {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center center;
    background-color: #CCCCCC;
    border-radius: 100%;
    margin-left: 10px; }

  &:hover {
    background-color: #ebebeb; } }
