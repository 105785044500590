.PaymentsList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
  border-top: 0.2rem solid $grey-one;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center; }
  &__data {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &__paymentStatus {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 1.6rem;
      align-items: flex-start;
      color: $grey-eleven;
      @include desktop-media {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row; }


      &__price {
        display: flex;
        width: 22rem;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        @include desktop-media {
         margin: 0; }
        &__width {
          display: flex;
          width: 12rem; }
        &__typography {
          // flex-basis: 5rem
 }          // margin-right: 4.5rem
        @include desktop-media {
         display: flex;
         justify-content: flex-start;
         align-items: flex-start;
         margin-right: 4.5rem; } } }






    &__paymentFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $grey-eleven;
      @include desktop-media {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row; }
      &__tags {
        display: flex;
        margin-right: 1rem;
        @include desktop-media {
          margin-right: 6rem; } } } } }
