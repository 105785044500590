.ProfessionalHome {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__Card {
    margin-bottom: 20px;
    max-width: 100% !important;

    &__ReviewNotification {
      color: #fff;
      position: absolute;
      background: rgb(255, 41, 41);
      border-radius: 15px;
      text-align: center;
      border: 4px solid #fff;
      right: 0;
      top: 0;
      padding: 2px 7px;
      animation: pulse 2.5s infinite; } }

  &__Schedules {
    margin: 20px 0; }

  &__ScheduleItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0; }

    &__Grid {
      display: flex;
      align-items: center;
      margin-top: 5px;

      p {
        margin-left: 5px; } } }

  &__EmptySchedules {
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #C1C1C1; } }


@keyframes pulse {
  0% {
    border: 4px solid rgb(255, 41, 41);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7); }

  70% {
    border: 4px solid rgba(255, 82, 82, 0);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0); }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0); } }
