.SlotCard {
  color: $grey-eleven;
  align-items: center;
  border: 0.1rem dashed $grey-eleven;
  border-radius: $radius-s;
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
  padding: 1.4rem 1.7rem;
  width: 100%;

  &__icon {
    cursor: pointer;
    height: 2.4rem; } }
