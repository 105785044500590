.NotificationStep {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  &__iconHeader {
    margin: 3rem 0 1.5rem 0; }

  h1 {
    margin-top: 2.5rem; }

  &-icon {
    width: 7rem;
    &--isSuccess {
      @extend .NotificationStep-icon;
      color: $green;
      display: inherit;
      font-size: 6.6rem; } } }
