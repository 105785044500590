.ConsultationList {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: 1.8rem;
  padding-left: 1.8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $white;
  border-color: $grey-ten;
  border-radius: $radius-m !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include card(1);
  @include desktop-media {
   padding-right: 1.8rem;
   padding-left: 1.8rem;
   padding-top: 2.5rem;
   padding-bottom: 2.5rem; }
  &__icon {
    display: flex;
    width: 20%;
    justify-content: flex-start;
    align-items: flex-start;
    Button {
     height: 3.5rem;
     width: 100%;
     padding: 0 auto;
     @include desktop-media {
      width: 100%; } } }
  &__data {
    display: flex;
    width: 80% !important;
    justify-content: space-between;
    flex-direction: column;
    @include desktop-media {
     width: 100% !important; }

    &__name {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      line-height: 1.9rem;
      width: 100%; }
    &__paymentStatus {
      display: flex;
      width: 70%;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 1.6rem;
      align-items: flex-start;
      color: $grey-eleven;
      @include desktop-media {
        display: flex;
        width: 70%;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row; }


      &__price {
        display: flex;
        width: 23rem;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        @include desktop-media {
         width: 29rem;
         margin: 0 !important; }
        &__width {
          display: flex;
          width: 32%;
          @include desktop-media {
           width: 100%; } } } }


    &__paymentFooter {
      display: flex;
      width: 100%;
      // flex-direction: row
      // justify-content: space-between
      color: $grey-eleven;
      @include desktop-media {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-left: 3rem; }
      &__tags {
        display: flex;
        margin-right: 1rem;
        @include desktop-media {
         width: 100%;
         margin: 0; } }
      &__id {
        display: flex;
        margin-left: 1rem;
        @include desktop-media {
         width: 100%;
         margin: 0; } } } }
  // &__buttons
  //   display: flex
  //   align-items: center
  //   justify-content: center
  //   flex-direction: column
  //   +desktop-media
  //     flex-direction: row
  //     margin: 0
 }  //
