.HourFilter {
  margin: 10px 40px 0;

  &__group {
    display: flex;
    align-items: center; }

  &__text {
    font-size: 15px;
    color: #898989;
    margin: 0 10px; }

  &__errorMessage {
    font-size: 1.2rem;
    color: #eb384d;
    text-align: left;
    margin-top: 5px;

    &--hidden {
      visibility: hidden;
      height: 0; } }

  &__field {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      padding: 0 5px; }

    .TextInput {
      margin-bottom: 0;

      input {
        text-align: center; }

      .messageError {
        display: none; } } } }
