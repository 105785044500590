.profile-list-item {
  background-color: $white;
  border-top: 1px solid $grey;

  &:first-child {
    border-top: none; }

  &--isOpen {
    background-color: $grey-one;
    border-top: 1px solid $grey; }

  &--avatar {
    align-self: center;
    height: 5rem;
    width: 5rem; }

  &--info {
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin-left: 1.5rem;

    &--status, &--id {
      margin-top: 0.7rem; }

    &--status {
      align-items: center;
      display: flex;
      margin-left: 1.5rem;
      width: auto;

      svg {
        margin-right: 0.5rem;
        width: 1.3rem; }

      &--active {
        @extend .profile-list-item--info--status;
        color: $action-online;
        svg {
          fill: $action-online; } }

      &--inactive {
        @extend .profile-list-item--info--status;
        color: $red;
        svg {
          fill: $red; } }

      &--uncomplete {
        @extend .profile-list-item--info--status;
        color: $status-pending;
        svg {
          fill: $status-pending; } } } }

  &--menu {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative; }

  &--float-menu {
    align-self: flex-start;
    display: block;
    position: absolute;
    left: -14.5rem;
    min-width: 17.8rem;
    top: 2.3rem;
    z-index: 1; } }
