.Modal {
    display: flex;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 98;
    padding: 0;
    overflow: auto;
    align-items: flex-start;
    justify-content: center;

    &-isFixed {
        display: flex;
        height: 100vh;
        place-content: center center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99; }

    &-align-center {
        @extend .Modal;
        align-items: center; }

    &__backdrop {
        animation: openModal 0.5s ease;
        background-color: $grey;
        height: 100%;
        opacity: 0.9;
        position: fixed;
        width: 100%;
        z-index: 100;
        top: 0;
        left: 0; }

    &__container {
        background-color: $white;
        display: flex;
        flex-direction: column;
        max-width: $desktop / 2;
        padding: 2rem 0 3rem;
        position: relative;
        width: 100%;
        z-index: 101;

        @include desktop-media {
            margin-top: 6rem; }

        &--scrollable {
            height: calc(90% - 5rem); }

        &__close {
            align-self: flex-end;
            animation: closeModal 0.5s ease;
            cursor: pointer;
            display: flex;
            min-height: 1.6px;
            padding: 0 2rem 0;
            position: absolute;
            top: 14px;
            z-index: 500; }

        &__content {
            padding: 1.4rem 1.4rem 0;
            width: 100%;

            @include modal-media {
                padding: 1.4rem 9.4rem 0; }

            &--scrollable {
                padding: 1.4rem 0rem 0;
                width: 100%;
                height: 100%;

                @include modal-media {
                    padding: 1.4rem 0rem 0; } } }

        &--scrollable {
            @extend .Modal__container;
            padding: 2rem 0 0rem; } } }
