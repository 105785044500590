.Dashboard {
  min-height: calc(100vh - 6rem);

  &__Menu {
    margin-top: 40px; }

  &__Content {
    margin-top: 20px;

    @include desktop-media {
      margin-top: 40px;
      padding-left: 30px; }

    @include mobile-media {
      padding-bottom: 90px; } }

  &__Header {
    align-items: center;
    margin-bottom: 20px !important;
    min-height: 48px;
    position: relative;

    @include mobile-media {
      justify-content: center !important;
      position: static; } }


  &-filter {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
    @include desktop-media {
      max-width: 33.7rem; }

    .filter-input {
      width: 100%;
      @include desktop-media {
        width: 34rem; } }

    .filter-btn {
      cursor: pointer;
      height: 6rem;
      margin-left: 1rem;
      width: 3.5rem; } } }

.editUserPaci {
  &__email {
    position: relative; }
  &__edit-icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-16px, 50%);
    cursor: pointer;
    color: #1F35B5;
    fontWeight: bold; }
  &__phone-number {
    margin-top: 10px; }
  &__edit-button {
    border: 0;
    background: initial;
    color: #1F35B5;
    font-weight: bold; } }

.dashboard-person-list {
  align-content: center;
  justify-content: center;
  width: 100%;
  &--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 37.3rem;
    color: $color-text;
    &.animation-shake {
      animation: shake 0.1s;
      animation-iteration-count: 3; } }

  &--icon {
    fill: $color-text;
    max-height: 6rem;
    max-width: 6rem; } }

.onScroll {
  position: fixed;
  top: 0;
  width: 100%; }

.MyConsultations {
  width: 100%; }

.AppointmentsList__heading {
  margin-bottom: 1.5rem; }

.PaymentList__heading {
  margin-bottom: 1.5rem; }

.AppointmentsList {
  margin-bottom: 10.5rem;

  &__topMessage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 15px; }

  &__message {
    max-width: 188px;
    text-align: right;
    line-height: 16px;
    word-break: break-word; }

  &__icon {
    margin: 0 10px; }

  &__list-empty {
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: center;

    .ListPlaceholder {
      margin: 0 auto;
      width: 100%;

      &__description {
        max-width: 14rem;

        @include desktop-media {
          max-width: 100%; }

        p {
          line-height: 1.2; } } }

    .btn--test {
      margin: 0 auto;
      margin-top: 1rem;
      width: 7.261rem;

      @include desktop-media {
        margin-top: 2rem; }

      button {
        font-size: $font-xs;
        height: 3.5rem; } } }

  &__footer {
    &--test-device {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      p {
        margin-right: 1.5rem; }

      .btn--test {
        margin: 0;
        margin-top: 1rem;
        width: 7.261rem;

        button {
          font-size: $font-xs;
          height: 3.5rem;
          margin: 0; } } } } }

.report {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  &--text {
    display: flex;
    justify-content: center;
    align-items: center; }
  &--input {
    width: 87%;
    margin-bottom: 0;
    .TextInput {
      margin-bottom: 0; } }
  &--button {
    margin-top: 20px; } }
.Permissions {
  &__title {
    margin-bottom: 1.4rem; } }

.Plans {
  margin-bottom: 10.5rem;

  &__List {
    margin: 3rem 0 2.5rem; }

  &__Add {
    @media (min-width: $desktop) {
      max-width: 50%; }

    &__Label {
      margin-bottom: 1rem; }

    &__Error {
      align-items: center;
      display: flex;
      margin: 2.5rem 0;
      width: 100%;

      &__Icon {
        align-self: flex-start;
        transform: rotate(180deg); }

      &__Description {
        margin-left: 1rem; } } } }

.MedicalRequest {
  &__List {
    width: 100%;

    &__CardMedicalRequest {
      width: 100%;

      &:last-of-type {
        margin-bottom: 2rem; } } } }

.LinkPlanModal {
  overflow-y: inherit !important; }

.DisableNewPatientsModal {
  max-width: 870px !important;
  .ModalGeneric__Modal__Button {
    max-width: 332px;
    margin: 0 auto; }
  .ModalGeneric__Modal__Container {
    padding-left: 60px;
    padding-right: 60px; }
  .ModalGeneric__Modal__Topbar {
    max-width: 870px; } }

@keyframes shake {
  0% {
    margin-left: 0; }
  25% {
    margin-left: 5px; }
  50% {
    margin-left: 0; }
  75% {
    margin-left: -5px; }
  100% {
    margin-left: 0; } }
