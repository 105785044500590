.CartItem {
  align-items: center;
  border-bottom: 0.1rem solid $grey-four;
  color: $grey-eleven;
  display: flex;
  padding: 1.45rem 0;

  &__body {
    align-items: center;
    border-left: 0.2rem solid $color-primary;
    color: $grey-eleven;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%; }

  &__delete {
    cursor: pointer; }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0; }

  &:first-child {
    padding-top: 0; } }
