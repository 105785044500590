.FilterHeader {
  align-content: center;
  align-items: center;
  background-color: $white;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
  border-radius: 50px;
  z-index: 1;
  &--justifyCenter {
    justify-content: center; }
  @include mobile-media {
    scroll-behavior: smooth;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    padding-left: 14px;
    gap: 5px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-right: 14px;
    &::-webkit-scrollbar {
      display: none; } }
  &__Sticky {
    z-index: 2;
    background: linear-gradient(to right, #1F35B5, #556EFF 100%);
    width: 100%;
    height: 80px;
    @include mobile-media {
      height: 70px; } }
  &__Wrapper {
    width: 100%;
    background: linear-gradient(to right, #1F35B5, #556EFF 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile-media {
      height: 70px; } }
  &__Divider {
    width: 1px;
    height: 40px;
    background: #E2E2E2;
    @include mobile-media {
      display: none; }
    &--hidden {
      background: #fff; } }
  &__FilterPanelBackdrop {
    position: absolute;
    width: 303px;
    z-index: 1;
    top: 80px;
    @include mobile-media {
      width: 100%;
      padding: 0 14px;
      top: 70px; } }
  &__FilterPanel {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #1F35B5;
    box-shadow: 0px 1px 20px #00000029;
    overflow:auto {}
    padding: 25px;
    @include mobile-media {
      width: 100% !important; } }
  &__FilterButton {
    width: 303px;
    height: 60px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    z-index: 1;
    padding: 14px 17px 14px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border: 1px solid #fff;
    @include mobile-media {
      padding: 9.5px 20px;
      height: 50px; }
    &:hover {
      @include desktop-media {
        background: #EBEBEB; } }
    &__icon {
      width: 12px;
      color: #4B4B4B;
      transform: rotate(180deg);
      &--down {
        transform: rotate(360deg); } }
    &--active {
      @include mobile-media {
        background: #C7D0FF !important;
        border: 1px solid #C7D0FF; }
      @include desktop-media {
        border: 1px solid #1F35B5; }
      background: #fff; }
    &--fullWidth {
      @include mobile-media {
        width: 100% !important; } } }
  &__ExperiencesFilter {
    @include mobile-media {
      min-width: 240px;
      margin: 0;
      order: 2; }
    &__InputWrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex: 2; }
    &__Title {
      color: #ACACAC !important;
      &--active {
        color: #4B4B4B !important; } }
    &__Input {
      color: #4B4B4B !important;
      background: none;
      border: none;
      width: 100%;
      outline: none;
      padding: 0;
      &:focus::placeholder {
        color: transparent; } }
    &__ClearButton {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      z-index: 2;
      background: none;
      cursor: pointer;
      padding: 0; }
    &__Panel {
      width: 100%;
      padding: 0;
      position: relative;
      overflow: hidden; }
    &__NotFound {
      height: 145px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      &__Title {
        color: #898989 !important; }
      &__Subtitle {
        color: #1F35B5 !important;
        cursor: pointer; } }
    &__List {
      overflow-y: scroll;
      max-height: 265px;
      &__Item {
        min-height: 47px;
        padding: 14px 30px;
        cursor: pointer;
        &:hover {
          color: #1F35B5;
          background: #E8EAF8; } } } }
  &__DayFilter {
    justify-content: center;
    @include mobile-media {
      min-width: 164px;
      order: 1; }
    &__icon {
      width: 12px;
      color: #4B4B4B;
      transform: rotate(180deg);
      &--down {
        transform: rotate(360deg); } }
    &__Container {
      display: flex;
      align-items: center;
      justify-content: center;
      &--fullWidth {
        width: 100%; }
      @include mobile-media {
        justify-content: space-between;
        width: 120px; }
      &__DateWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 25px;
        margin-left: 10px;
        gap: 10px;
        &__TextContainer {
          display: flex;
          flex-direction: column; }
        @include mobile-media {
          margin-left: 0;
          margin-right: 5px; } } }
    &__Title {
      color: #1F35B5 !important;
      text-transform: uppercase; }
    &__Subtitle {
      color: #1F35B5 !important; }
    &__Panel {
      width: 340px;
      padding: 10px 25px; } }
  &__MoreFilter {
    justify-content: center;
    @include mobile-media {
      order: 3;
      min-width: 136px; }
    &__Container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      &__Text {
        color: #1F35B5 !important;
        font-weight: bold; } }
    &__Panel {
      width: 340px;
      &__GenderContainer {
        border-bottom: 1px solid #E2E2E2;
        padding-bottom: 20px;
        &__Title {
          color: #4B4B4B !important;
          font-weight: bold; }
        &__RadioButtons {
          display: flex;
          margin-top: 10px;
          gap: 21px; } }
      &__ApproachContainer {
        margin-top: 20px;
        &__Wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px; }
        &__Title {
          color: #4B4B4B !important;
          font-weight: bold; } } } } }
