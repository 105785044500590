.ModalUploadMedicalRequest {
    &--image-wrapper {
        cursor: pointer;
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;

        .TextInput {
            .inputContent {
                span {
                    margin-left: -3rem; } } }

        input[type=file] {
            cursor: pointer;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%; } }

    &__Data {
        width: 100%;

        .col {
            width: 100% !important; } } }
