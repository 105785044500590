.ConsultationLists {
  align-items: flex-start;
  border-top: 0.1rem solid $grey-four;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height:  1;
  padding: 1.4rem 0;
  width: 100%;
  @include desktop-media {
    align-items: center;
    padding: 1.4rem; }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
    width:  100%;
    @include desktop-media {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }

    &__participants {
      .content__psychologist {
        margin-bottom: 1rem; } } }

  &__info {
    display: grid;
    grid-template-rows: auto auto;
    margin-top: 10px;
    @include desktop-media {
     width: 25rem;
     justify-content: space-between;
     align-items: center;
     margin-right: 6rem;
     margin-top: 0; }

    &__id {
      display: flex;
      line-height: 1.2;
      min-width: 45px;
      width: auto; }

    &__session {
      display: flex;
      line-height: 1.2;
      margin-left: 1rem;
      width: auto; } }

  &__subInfo {
    display: flex;
    align-items: flex-start;
    &--spaced {
      margin-top: 5px; } }

  &__BadgeWrapper {
    margin-bottom: 10px; }
  &__DateDisplayerWrapper {
    width: 75px;
    height: 100%; }
  &__DateDisplayer {
    height: 93px;
    @include mobile-media {
      height: 100% !important; } } }
.menuButton {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  max-height: 10rem;
  cursor: pointer;

  &__NoIcon {
    min-width: 7.3rem; }

  &__Link {
    cursor: pointer; } }
