.RegisterBlock {
  &__BiographyData,
  &__DocumentsData,
  &__EducationData,
  &__MediaData,
  &__ProductsData,
  &__SkillsData {
    margin-top: 3rem; }
  &__EducationData {
    display: flex;
    &--Education {
      display: flex;
      margin-top: 3rem !important;

      &--EducationList {
        border-top: 0.1rem solid $grey-one;
        padding-bottom: 2rem !important;
        padding-top: 2rem !important;
        &--data {
          width: 90%; }
        &--icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem; } } }
    &__year {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 1.5rem;
      &--field {
        display: flex;
        width: 50%;
        @include desktop-media {
          width: 10rem; } }


      &--start {
        display: flex;
        justify-content: space-between;
        align-items: center !important;
        margin-right: 2rem;
        @include desktop-media {
         margin-right: 3.4rem; }
        &--text {
          display: flex;
          justify-content: flex-start;
          margin-right: 2rem;
          padding-bottom: 1rem; } }
      &--end {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--text {
          display: flex;
          justify-content: flex-start;
          margin-right: 2rem;
          padding-bottom: 1rem; } } } }

  &__MediaData {
    &--image-wrapper {
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      position: relative;
      width: 100%;

      .TextInput {
        .inputContent {
          span {
            margin-left: -3rem; } } }

      input[type=file] {
        cursor: pointer;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%; } } }

  &__SkillsData {
    &__title {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
      @include desktop-media {
        justify-content: flex-start; }

      h2 {
        max-width: 40% !important; }

      &__toggle-btn {
        cursor: pointer;
        height: 3.5rem; } }

    .RegisterSkillsModal {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 0;
      max-width: 100%;
      opacity: 0;
      overflow: hidden;
      padding: 0;

      &__header {
        margin-bottom: 2.5rem; }

      .Button {
        max-width: 33.2rem; }

      &--show {
        height: auto;
        max-width: 100%;
        opacity: 1;
        transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;

        .SkillsSelection {
          margin-bottom: 4rem;
          max-width: 100%;

          &__options {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 1rem;
            max-width: 100%;
            @include desktop-media {
              justify-content: flex-start; }

            .RadioToggle {
              flex-shrink: 1;
              margin-right: 0.5rem;
              margin-top: 1rem;
              max-width: 100%;

              &__text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap; } } } } } }

    &--btn {
      text-align: center;
      @include desktop-media {
        text-align: left; }
      button {
        font-size: $font-xs;
        height: 3.5rem;
        margin: 0;
        max-width: 18rem;
        padding: 0; } } }

  &__ProductsData {
    &__SelectPlansModal {
      max-width: 969px;
      position: relative;
      padding: 0;
      height: 100vh;
      &__Title {
        background: #fff;
        position: fixed;
        display: flex;
        align-items: center;
        padding: 30px 0 40px 40px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        top: 60px;
        width: 950px; }
      &__Container {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        &__ModalContentWrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0 0 0 40px;
          gap: 50px;
          margin-top: 30px; }
        &__SidebarWrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          overflow-y: scroll;
          overflow-x: hidden;
          max-height: 500px;
          max-width: 300px;
          padding-right: 20px;
          position: fixed;
          &__SidebarTitle {
            font-size: 10px !important;
            color: #898989 !important; }
          &__TierSelecionWrapper {
            margin-top: 35px;
            display: flex;
            flex-direction: column;
            svg {
              fill: #4B4B4B; }
            &__TierSelectionSection {
              border-bottom: 1px solid #E2E2E2;
              padding: 15px 0;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              &:first-of-type {
                padding-top: 10px; }
              &:last-of-type {
                border: none; } } }

          &__FiltersWrapper {
            display: flex;
            flex-direction: column;
            gap: 10px; } }
        &__MainContent {
          overflow-y: scroll;
          overflow-x: hidden;
          flex: 2;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          // max-height: 500px
          padding: 0 40px 70px 300px;
          &__PlansSection {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #E2E2E2;
            padding: 30px 0;
            width: 100%;
            &:nth-child(1) {
              border-top: 1px solid #E2E2E2; }
            &__GroupedPlansHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              margin-bottom: 20px;
              > button {
                height: auto; } }
            &__GroupedPlans {
              display: flex;
              flex-wrap: wrap;
              gap: 10px; } }
          &__PlanSelection {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px 10px 20px;
            background: #FFFFFF00 0% 0% no-repeat padding-box;
            border: 1px solid #C3C3C3;
            border-radius: 40px;
            gap: 15px;
            cursor: pointer;
            &--active {
              background: #E8EAF8 0% 0% no-repeat padding-box;
              border: 1px solid #1F35B5; }
            &__TextWrapper {
              color: #898989;
              &--active {
                color: #1F35B5; } } } } }



      &__ModalFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        width: 969px;
        height: 70px;
        box-shadow: 0px -5px 20px #00000029;
        padding: 0 40px;
        position: fixed;
        bottom: 60px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        &__ActionsWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 40px;
          > button {
            width: 182px; } } }
      > .ModalGeneric__Modal__Container {
        padding: 0; }
      > .ModalGeneric__Modal__CloseButton {
        margin: 18px 18px 0 0; } }
    &__PlanContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 25px;
      gap: 20px; }
    &__AddPlansContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #F5F5F5;
      border-radius: 5px;
      padding: 30px 0;
      flex: 1;
      gap: 13px;
      height: 185px;
      &__AddPlansButton {
        margin-top: 6px;
        width: 230px; } }
    &__ParticularPlanContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      background: #F5F5F5;
      padding: 30px 0;
      border-radius: 5px;
      flex: 1;
      height: 185px;
      &__InputPrice {
        width: 230px;
        margin: 20px 0 0 0; }
      &__RadioButtonsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 15px; } }
    &__product {
      display: flex;
      border-bottom: 1px solid #E2E2E2;
      padding: 30px 0 !important;
      &:nth-child(1) {
        border-top: 1px solid #E2E2E2; }
      &__heading {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        @include desktop-media {
          flex-direction: row; }

        &__fixed,
        &__price {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
          @include desktop-media {
            width: 45%; } }


        &__fixed {
          &__title {
            line-height: 1.1; } }


        &__price {
          .TextInput {
            max-width: 17.5rem !important;
            @include desktop-media {
              margin-left: 1rem; } }

          @include desktop-media {
            justify-content: flex-end;
            margin-right: 1rem; } }

        &__plans {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          &__title {
            display: block;
            width: 100%;
            margin-bottom: 1rem; }

          .RadioToggle {
            margin-right: 10px;
            margin-top: 10px; } } } } } }


