.CartList {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  &__populated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__name {
      margin-bottom: 0.5rem; } }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__text {
      justify-content: center !important; }

    &__button {
      justify-content: flex-end !important;
      margin: 15px auto 0 !important;
      max-width: 18.5rem; } } }
