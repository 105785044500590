.UnavailabilityCard {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #00000033;

  &__timeBox {
    min-width: 8.2rem;
    min-height: 11rem;
    padding: 10px 5px;
    margin-right: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    border-radius: 10px;
    background-color: #898989; }

  &__cardBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
    cursor: pointer; }

  &__info {
    height: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; } }
