.PaymentStatus {
  @include ubuntu($font-bold);
  border: none;
  border-radius: $radius-s;
  height: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  color: $white;
  justify-content: center;
  padding: 1rem 0.6rem;
  letter-spacing: -0.06rem;


  &--cancelled {
    @extend .PaymentStatus;
    @include ubuntu($font-regular);
    background-color: $red;
    width: 9rem; }

  &--paid {
    @extend .PaymentStatus;
    @include ubuntu($font-regular);
    background-color: $action-online;
 }    // width: 4.8rem

  &--notPaid {
    @extend .PaymentStatus;
    @include ubuntu($font-regular);
    background-color: $red;
    // width: 8rem
    overflow: hidden;
    white-space: nowrap; }

  &--pending {
    @extend .PaymentStatus;
    @include ubuntu($font-regular);
    background-color: #F9A435; }

  &--notApproved {
    @extend .PaymentStatus;
    @include ubuntu($font-regular);
    background-color: $red; } }





