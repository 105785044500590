.CardPlan {
    align-items: center;
    border-radius: $radius-m;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    height: 9.5rem;
    margin: 1rem 0;
    padding: 0 1.5rem 0 1rem;
    width: 100%;

    &:first-of-type {
        margin-top: 0; }

    &:last-of-type {
        margin-bottom: 0; }

    &__Logo {
        align-items: center;
        border-radius: $radius-s;
        border: 0.1rem solid $grey-seven;
        display: flex;
        height: 7.5rem !important;
        justify-content: center;
        width: 7.5rem !important;

        &__Image {
            max-width: 100%;
            max-height: 100%; } }

    &__Center {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        @include desktop-media {
            align-items: center;
            flex-direction: row;
            flex-grow: 1;
            justify-content: space-between; }

        &__PlanData {
            line-height: 1.7rem;
            margin-bottom: 0.5rem;

            &__Status {
                align-items: center;
                display: flex;

                &--positive {
                    @extend .CardPlan__Center__PlanData__Status;
                    color: $action-online;

                    svg {
                        margin-right: 0.5rem;
                        fill: $action-online; } }

                &--neutral {
                    @extend .CardPlan__Center__PlanData__Status;
                    color: $orange;

                    svg {
                        margin-right: 0.5rem;
                        fill: $orange; } }

                &--negative {
                    @extend .CardPlan__Center__PlanData__Status;
                    color: $red;

                    svg {
                        margin-right: 0.5rem;
                        fill: $red; } } } }

        &__Professionals {
            cursor: pointer; } }

    &__Menu {
        @include desktop-media {
            width: 8.4rem; } } }
