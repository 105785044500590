.DashboardFilters {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;

    @include desktop-media {
        justify-content: flex-end; }

    &__Input {
        width: 100%;

        @include desktop-media {
            justify-content: auto; } }


    &__Button {
        cursor: pointer;
        margin-bottom: 1rem;
        width: 3.5rem; } }
