
.ProfessionalAgenda {
  &__Header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 4px 20px 0;
    @include mobile-media {
      margin-top: 5px;
      padding: 0 4px 20px; }
    &__CustomWrapper {
      padding: 15px 0 20px 0 !important;
      box-shadow: 0 4px 2px -2px #00000029;
      @include mobile-media {
        padding: 15px 14px !important; } }
    &__Wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%; }
    &__ClearFilters {
      margin-top: 20px;
      &__Text {
        text-transform: uppercase !important;
        font-size: 12px !important; } }
    &__Title {
      font-size: 30px !important;
      @include mobile-media {
        font-size: 21px !important;
        font-weight: 500;
        margin-bottom: 20px; } }
    &__Filters {
      display: flex;
      align-items: center;
      gap: 10px;
      @include mobile-media {
        width: 100%; }
      &__Button {
        background: none;
        border: 0;
        height: 35px;
        margin: 0;
        padding: 0;
        svg {
          margin: 0;
          padding: 0; } }
      &__Input {
        width: 250px !important;
        &__Custom {
          margin: 0 !important; }
        @include mobile-media {
          width: 100% !important; } } }
    &--floating {
      position: fixed;
      top: 0; } }
  &__Title {
    display: flex;

    @include mobile-media {
      margin-top: 10px;
      justify-content: center; }

    @include desktop-media {
      border-right: 2px solid $grey-six; }

    &__Avatar {
      margin-right: 10px; }

    &__Text {
      padding-top: 2px;
      padding-left: 5px; }

    p {
      line-height: 15px; } }

  &__Calendar {
    @include mobile-media {
      margin-top: 20px; }

    &__float {
      position: absolute;
      width: 100%;
      margin-top: -7.3rem;
      z-index: 205;

      @include desktop-media {
        position: absolute;
        width: 100%;
        margin-top: -7.3rem;
        margin-left: -17.4rem;
        z-index: 205; } }

    &__link {
      color: initial;
      cursor: pointer; } }

  &__DateSelector {
    &__date {
      align-items: center;
      display: flex;
      height: 6rem;
      flex-grow: 1;
      justify-content: space-between; }

    &__dateButton {
      cursor: pointer; }

    &__dateValue {
      margin-bottom: 0.5rem; } }

  &__appointmentList {
    height: 100%;

    &__text {
      margin-bottom: 1rem; }

    &__button {
      width: 16.7rem; }

    &__Slots {
      display: flex;
      flex-direction: column;
      width: 100%; }

    &__Schedules {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__item {
        margin: 1rem 0; } } }

  &__List {
    &--isFilterActive {
 }      // margin-top: 20px
    &__Row {
      @include mobile-media {
        margin: 0 -10px !important;
        width: auto !important; } }
    &__EmptyWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #898989;
      svg {
        margin: 164px 0 10px 0; } }
    &__Item {
      &__ActionButton {
        background: none;
        border: none;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0; }
      &__Ripple {
        padding: 14px 0 14px 15px;
        &__DateDisplayer {
          background-color: #898989 !important; } }
      &__Badge {
        margin: 0 40px 18px 0;
        display: flex;
        justify-content: flex-end;
        @include mobile-media {
          margin: 15px 0;
          justify-content: flex-start; } }
      &__ContentWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: {}
        width: 100%;
        padding: 14px 15px 14px 0; }
      &__SlotData {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 15px;
        &__RecurrentRange {
          line-height: 16px !important; }
        &__Separator {
          color: #bebebe; }
        @include mobile-media {
          flex-direction: column;
          gap: 5px;
          align-items: flex-start; } }
      &__Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 85px;
        width: 100%;
        @include mobile-media;
        &__MedicalReturn {
          justify-content: normal;
          @include mobile-media {
            height: 100%; } } }
      &__PlanData {
        display: flex;
        &__Reservation {
          margin-left: 10px !important;
          @include mobile-media {
            margin: 0 0 10px 0 !important; } }
        @include mobile-media {
          flex-direction: column-reverse; }
        &__Wrapper {
          display: flex;
          gap: 10px; } } }
    &__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 20px;
      max-height: 35px; }
    &__SkeletonWrapper {
      &__Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #F5F5F5;
        height: 35px;
        padding: 10px; }
      &__Item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px;
        border-top: 1px solid #E2E2E2;
        &__Content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 5px 0; } } } }
  &__RemoveSlotModal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__Title {
      font-size: 21px;
      font-weight: 500;
      margin-bottom: 30px;
      text-align: center; }
    &__SuccessTitle {
      @extend .ProfessionalAgenda__RemoveSlotModal__Title;
      margin: 10px 0 25px 0; }
    &__SlotData {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 20px; }
    &__ConfirmationMessage {
      display: flex;
      color: #FF4949;
      gap: 10px;
      justify-content: space-between;
      margin: 30px 0 20px 0; }
    &__Button {
      width: 100%; } }
  &__FiltersModal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 332px;
    position: relative;
    @include mobile-media {
      width: 100%; }
    &__Header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
    &__InputWrapper {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 15px;
      margin: 10px 0; }
    &__Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      &__FiltersWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 15px; } }
    &__ClearFilter {
      display: flex;
      background: none;
      border: none;
      align-items: center;
      gap: 10px;
      margin: 30px 0 27px 0; }
    &__Button {
      width: 100%; } }
  .FloatActionButtonDropdown__Button {
    @include mobile-media {
      padding: 0; } }

  .FloatActionButtonDropdown__Icon {
    @include mobile-media {
      margin-right: 0; } }

  .FloatActionButtonDropdown__Title {
    @include mobile-media {
      display: none; } } }
