.ModalAdminSchedule {
  &__Content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    &__DropdownWrapper {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 10px; }
    &__TreatmentNotStartedMessage {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 25px; }

    &__LoadingContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      div {
        width: 30px;
        height: 30px; } } } }
