.PlanGroupItem {
    &__Container {
        width: 100%;
        padding: 20px 10px 20px 14px;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        min-height: 110px; }

    &__LogoContainer {
        width: 130px;
        height: 70px;
        border-radius: 5px;
        border: 1px solid #E2E2E2;
        padding: 3px;
        > img {
            height: 100%;
            width: 100%; } }

    &__NameContainer {
        margin-left : 20px;
        font-size: 15px;
        color: #4b4b4b;
        font-weight: bold; }

    &__ListItemMenu {
        align-items: center;
        cursor: pointer;
        display: flex;
        position: relative;
        &__FloatMenu {
            align-self: flex-start;
            display: block;
            position: absolute;
            left: -11.5rem;
            min-width: 14.8rem;
            top: 1.3rem;
            z-index: 1;
            margin-right: 0; } } }

