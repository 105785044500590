.Survey {
  width: 98%;
  margin: 0 auto;
  min-height: calc(100vh -12vh) !important;

  &__Button {
    width: 33.2rem;
    margin: 30px auto;

    @include desktop-media {
      width: 59.5rem; }

    button {
      width: 100%;
      font-weight: bold; } }

  &__body {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__subheading {
    margin: 0.5rem auto 2rem auto;
    color: $grey-eight !important;
    @include desktop-media {
      width: 51rem; } }

  &__link {
    font-weight: $font-bold; }

  &__message {
    margin-top: 30px; }

  &__loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    div {
      width: 33px;
      height: 33px; } } }
