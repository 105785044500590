$photo-sizes-desktop: 160px;
$photo-sizes-mobile: 140px;

.ProfessionalInfo {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  font-family: "Ubuntu";
  @media (max-width: 1096px) {
    margin-bottom: 30px;
    margin-top: -10px; }

  &__professional {
    display: grid;
    grid-template-columns: 160px auto;
    column-gap: 25px;
    @media (max-width: 1096px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: center;
      background: #fff; } }

  &__containerPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    @media (max-width: 1096px) {
      padding-bottom: 100px;
      background: linear-gradient(to right, #1F35B5, #556EFF 100%); }

    &__photo {
      width: $photo-sizes-desktop;
      height: $photo-sizes-desktop;
      border-radius: 50%;
      background-color: #2D2D2D;
      @media (max-width: 1096px) {
        width: $photo-sizes-mobile;
        height: $photo-sizes-mobile;
        margin: 0;
        top: 30px;
        border: 8px solid #fff;
        position: absolute; }

      &--empty {
        color: #FFFFFF; } } }

  &__info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 1096px) {
      justify-content: normal;
      margin-top: 80px; } }

  &__personal {
    margin-bottom: 25px;
    @media (max-width: 1096px) {
      margin-bottom: 20px; } }

  &__text {
    font-size: 15px;
    line-height: 15px;
    color: #2D2D2D;
    @media (max-width: 1096px) {
      text-align: center; }

    &--main {
      font-size: 30px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 4px;
      @media (max-width: 1096px) {
        font-size: 24px; } } }

  &__jobInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1096px) {
      justify-content: space-around;
      width: 100%; } }

  &__group {
    display: flex;
    align-items: center;
    @media (max-width: 1096px) {
      justify-content: space-around;
      width: 100%; } }

  &__icon {
    width: 20px;
    color: #4B4B4B;
    margin-right: 5px; }

  &__rating {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    @media (max-width: 1096px) {
      justify-content: center; }

    span {
      display: flex;
      gap: 5px;
      cursor: pointer;
      transition: filter 500ms;

      &:hover {
        filter: brightness(120%); } } } }
