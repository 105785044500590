.ToggleButton {
  align-content: center;
  align-items: center;
  border: dashed 1px $grey-eight;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 0.6rem;
  width: 102px;
  margin: 4px;
  max-height: 90px;

  &:hover {
    opacity: 0.8; }
  &--active {
    @extend .ToggleButton;
    background-color: $color-secondary;
    border-color: $color-secondary;

    p {
      color: $red;
      transition: 250ms; } }

  &__icon {
    display: flex;
    height: 3.75rem;
    width: 3.75rem;
    justify-content: center;
    justify-items: center;
    position: relative;
    img {
      display: inline;
      height: 100%;
      margin: 0 auto;
      width: auto; } }

  &__labelValue {
    font-size: 12px !important; }

  .text {
    display: flex;
    line-height: 0.8;
    justify-content: center;
    justify-items: center;
    width: 100%;
    margin-top: 6px;
    letter-spacing: 0px;
    line-height: 1.3rem;
    @include ubuntu($font-bold); } }
