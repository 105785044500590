.FloatActionButtonMobile {
  align-items: flex-start;
  bottom: 24px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 18px;
  z-index: 3;

  &-hasTitle {
    @extend .FloatActionButtonMobile;
    right: 0;
    width: 100%;
    justify-content: center; }

  &__info {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    max-width: 16rem;

    &__icon {
      margin-right: -2.5rem;
      width: 4.9rem; }
    &__text {
      width: 11.6rem; } }

  &__button {
    @include card(3);
    align-items: center;
    border: none;
    border-radius: 10rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 5.6rem;
    justify-content: center;
    margin-top: 40px;
    padding: 1.2rem;
    min-width: 5.6rem;
    &:hover {
      @include card(2.5);
      filter: brightness(85%);
      border-radius: 10rem;
      transition: 0.5s; }

    svg {
      margin-right: 0; }

    &--primary {
      @extend .FloatActionButtonMobile__button;
      background-color: $color-primary; }

    &__text {
      color: $white;
      margin-left: 1rem;
      margin-right: 1rem; } } }
