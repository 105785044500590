.ListPlaceholder {
  align-items: center;
  color: $grey-ten;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 24rem;

  &__icon {
    @include svg-colors($grey-ten); }

  &__title {
    margin-top: 1rem; }

  &__description {
    margin-top: 0.9rem; } }
