.BoxDateDisplay {
  @include ubuntu($font-bold);
  border: none;
  border-radius: $radius-m;
  height: 11rem;
  text-align: center;
  width: 8.2rem;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0.6rem;

  &--default {
    @extend .BoxDateDisplay; }

  &--cancelled {
    @extend .BoxDateDisplay;
    @include ubuntu($font-regular);
    background-color: $grey-three; }

  &--scheduled {
    @extend .BoxDateDisplay;
    @include ubuntu($font-regular);
    background-color: $action-online; }

  &--attending {
    @extend .BoxDateDisplay;
    @include ubuntu($font-regular);
    background-color: $orange; }

  &--attended {
    @extend .BoxDateDisplay;
    @include ubuntu($font-regular);
    background-color: $grey-three; }

  &--not-attended {
    @extend .BoxDateDisplay;
    @include ubuntu($font-regular);
    background-color: $grey-three; }

  .footer {
    display: flex;
    flex-direction: column;
    letter-spacing: -0.1rem;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    &__icon {
      display: flex;
      justify-content: space-around; }

    &__text {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      color: $white;
      letter-spacing: -0.1rem;
      line-height: 1.3rem; } }


  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    &__time {
      display: flex;
      justify-content: center; } } }
