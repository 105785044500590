.TextContainer {
  font-family: "Ubuntu";

  &__text {
    font-size: 15px;
    color: #4B4B4B;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    line-height: 1.5;
    text-align: justify;

    &--empty {
      font-size: 15px;
      font-family: "Ubuntu";
      color: #4b4b4b;
      font-style: italic; }

    &--open {
      overflow: visible; } }

  &__button {
    font-size: 15px;
    color: #1F35B5;
    cursor: pointer;
    text-align: right;
    margin-top: 15px; } }
