.ProfessionalsNetwork {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background: #1F35B5;
  height: 60px;
  width: 311px;
  border-radius: 50px;
  padding: 14px 25px;
  &__Text {
    color: #fff !important; }
  @include mobile-media {
    width: 100%;
    border-radius: 5px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0px 1px 3px #00000029;
    &__Text {
      color: #4B4B4B !important; } } }
