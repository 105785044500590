.ProfessionalReservations {

    &__spinner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 82px; }

    &__empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 82px;
        padding: 0 20%;

        @include mobile-media {
            padding: 0 20px; } }

    &__emptyTitle {
        margin: 10px 0 20px 0 !important; }

    &__emptyText {
        color: #898989 !important;
        text-align: center;
        margin-bottom: 20px !important; }

    &__dayHeader {
        width: 100%;
        height: 35px;
        background-color: #FFFFB5;
        display: flex;
        align-items: center;
        padding-left: 15px; }

    &__listItem {
        display: flex;
        padding: 15px;
        border-top: 1px solid #E2E2E2; }

    &__grow {
        flex-grow: 1; }

    &__displayer {
        justify-content: center !important;
        align-items: center !important;
        margin-right: 15px;
        height: auto !important; }

    &__listItemPlan {
        margin-top: 40px;
        display: flex; }

    &__listItemPlanName {
        margin-right: 10px !important; }

    &__listItemActions {
        display: flex;
        align-items: center;
        justify-content: center; }

    &__listItem--hideBorderTop {
      border-top: none; } }
