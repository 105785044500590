/** General **/
// body
//   min-height: 100vh

.cursor-pointer {
  cursor: pointer !important; }

.overflow-hidden {
  overflow: hidden; }

.hasMarginBottom {
  margin-bottom: 2rem !important; }

.hasLeftMarginMobile {
  margin-left: 1.5rem !important;
  @include desktop-media {
    margin-left: 0 !important; } }

/** Third Party Components **/
// PinCode
.pincode-input-container {
  display: flex !important;
  width: 100% !important;
  .pincode-input-text {
    border: none !important;
    border-bottom: 0.1rem solid !important;
    color: $color-text !important;
    font-family: $font-ubuntu !important;
    font-size: $font-s !important;
    margin: 0.5rem !important;
    outline: none!important;
    padding: $size-xl !important;
    width: 100% !important; } }

//PhoneInput
.react-phone-number-input {
  height: 4.8rem !important; }

.react-phone-number-input__icon {
	border: none !important; }
.react-phone-number-input__icon svg {
  height: 15px;
  width: 20px; }

.react-phone-number-input__input {
	background: transparent !important;
	border: none !important;
	color: $color-title; }

.react-phone-number-input__row {
	align-items: center;
	border: 0.1rem solid $grey-four;
	border-radius: 0.5rem;
	display: flex;
	padding: 1.5rem 1rem;

	input {
		&::placeholder {
			color: $grey-six; } } }

.react-phone-number-input__error {
	margin-left: 0 !important; }
