.CardMedicalRequest {
    border-bottom: 0.1rem solid $grey-four;
    display: flex;
    justify-content: space-between;
    padding: 2rem 1.6rem 2rem 1.8rem;
    transition: 0.3s;

    &--invalid {
        text-decoration: line-through; }

    &:first-of-type {
        border-top: 0.1rem solid $grey-four; }

    &--open {
        background-color: $grey-one;
        @extend .CardMedicalRequest; }

    &__insideGroup {
        margin-top: 10px; }

    &--bold {
        font-weight: bold; }

    &__Data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (min-width: $desktop) {
            display: flex;
            flex-direction: row; }

        &__Block {
            line-height: 1.6rem;
            margin: 0.75rem 0 0;

            &:first-of-type {
                margin-top: 0; }
            &:last-of-type {
                margin-bottom: 0;

                @include desktop-media;
                margin: 0; }

            &__Name {
                p {
                    padding-bottom: 3px; }
                @include desktop-media;
                width: 32.5rem;
                margin-right: 1rem; }

            &__Tag {
                margin-top: 1rem;
                margin-bottom: 1.5rem;

                @media (min-width: $desktop) {
                    margin-bottom: 0; }

                &__Border {
                    border-radius: $radius-s;
                    padding: 0.3rem 0.7rem;
                    width: fit-content;

                    &--negative {
                        background-color: $red;
                        @extend .CardMedicalRequest__Data__Block__Tag__Border; }

                    &--neutral {
                        background-color: $orange;
                        @extend .CardMedicalRequest__Data__Block__Tag__Border; }

                    &--positive {
                        background-color: $status-online;
                        @extend .CardMedicalRequest__Data__Block__Tag__Border; }

                    &--done {
                        background-color: $grey-eleven;
                        @extend .CardMedicalRequest__Data__Block__Tag__Border; } } }

            &__Role {
                padding-bottom: 3px; }

            &__Data {
                display: flex;

                &--spaced {
                    margin-top: 1.3rem;
                    @extend .CardMedicalRequest__Data__Block__Data; }

                &__Label {
                    margin-right: 1rem;
                    width: 9rem;
                    padding-bottom: 3px; }

                &__Actions {
                    display: flex;
                    gap: 20px; } } } }

    &__Menu {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        position: relative;

        &__FloatMenu {
            align-self: flex-start;
            display: block;
            position: absolute;
            left: -13.5rem;
            min-width: 14.8rem;
            top: 50%;
            z-index: 1; }

        &__Icon {
            height: 45px;
            width: 45px;
            cursor: pointer; } } }
