.toast {
    align-items: center;
    border-radius: $radius-s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0rem;
    text-align: start;
    width: 100%;
    padding: 2rem;

    &-icon {
        color: $white;
        height: 2.2rem;
        width: 2.2rem;
        text-align: center;
        min-width: 50px; }

    &-message {
        color: $white;
        font-size: 1.6rem;
        @media (min-width: 768px) {
            max-width: 100%; } }
    &-info {
        @extend .toast;
        background: #d1ecf1;
        border: 1px solid #bee5eb;
        span {
            color: #0c5460; } }

    &-error {
        @extend .toast;
        background: rgba(239, 107, 123,95%);
        span {
            color: $white; } }

    &-error-inverted {
        @extend .toast;
        color: rgba(239, 107, 123,95%);
        span {
            color: rgba(239, 107, 123,95%); } }

    &-warn {
        @extend .toast;
        background: #fff3cd;
        border: 1px solid #ffeeba;
        span {
            color: #856404; } }

    &-primary {
        @extend .toast;
        background: $primary-lighter;
        border: 1px solid $color-primary;
        span {
            color: $white; } }

    &-success {
        @extend .toast;
        background: $green;
        border: 1px solid $green;
        span {
            color: $white; } } }
