.TagChip {
  display: inline-flex;
  padding: 7px 14px;
  border: 1px solid #898989;
  border-radius: 40px;
  // text
  font-size: 15px;
  color: #4B4B4B;
  font-weight: 500;
  font-family: "Ubuntu";
  text-align: center;
  line-height: 16px;

  &--main {
    color: #FFFFFF;
    background-color: #22CBD3;
    border: 1px solid #22CBD3; } }
