.Card {
  background-color: $white;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  height: auto;
  padding: 15px;
  position: relative;
  width: 100%;
  z-index: 1;

  &--default {
    @extend .Card; }

  &--darkenBackground {
    @extend .Card;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

  &--yellowBackground {
    @extend .Card;
    background-color: $yellow-light;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    padding: 1.5rem 2rem; }

  &--scrollable {
    @extend .Card;
    max-height: 100vh;
    overflow-y: auto; } }
