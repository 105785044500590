.profile-list-items {
  background-color: transparent;
  &--isOpen {
    background-color: transparent; }
  &--menu {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;
    svg {
      fill: #4b4b4b; } }

  &--float-menu {
    align-self: flex-start;
    display: block;
    position: absolute;
    left: -135px;
    top: 2.4rem;
    z-index: 1; } }
