.StickyIndex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  width: 100%;
  height: 3.5rem;
  &__day {
   display: flex;
   justify-content: flex-start;
   width: 50%; }
  &__date {
   display: flex;
   justify-content: flex-end;
   width: 50%; } }
.test {
  background-color: #000; }
