*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  color:  $color-text;
  font-family: Helvetica, Arial, sans-serif;
  font-size: $font-s;
  font-weight: $font-regular;
  line-height: 2.2rem; }

button {
  cursor: pointer; }

button:disabled {
  cursor: default; }

a {
  color: inherit;
  text-decoration: none;
  width: inherit; }

h1,
h2,
h4,
h5,
h6,
ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none; } }
