.title {
  @include font-size($text-sizes);
  font-family: $font-ubuntu;
  font-weight: $font-bold; }

.btn-teste {
  @include complex-border(1.5em, 50%);
  @include card(2);
  background-color: $yellow;
  max-width: 10%;
  padding: 0.25rem; }

.div-test {
  background-color: $red;
  height: 50px; }
