.PageTop {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__Wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    @media (max-width: 1096px) {
      flex-direction: column;
      justify-content: center; } }
  &--hidden {
    display: none; }

  &__floating {
    width: inherit;
    max-width: 787px;
    position: fixed;
    top: 0;
    margin: 0;
    padding: 15px 14px 5px;
    display: none;
    // box-shadow: 0 4px 6px -6px #C4C4C4
    // box-shadow: 0 3px 6px rgba(0,0,0,.16)
    background-color: #FFFFFF;
    z-index: 5;
    @media (max-width: 1096px) {
      width: 100%;
      left: 0;
      padding: 15px 14px 5px; }

    &--visible {
      display: flex;
      flex-direction: column;
      width: 100%;
      animation: fadein 0.3s;

      @keyframes fadein {
        0% {
          opacity: 0; }
        100% {
          opacity: 1; } } } }

  &__title {
    &--hidden {
      @media (max-width: 1096px) {
        display: none; } }

    &--mobile {
      flex-direction: column; } }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1096px) {
      width: 100%; } } }
