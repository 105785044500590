.LineBreaker {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  &__text {
    font-size: 15px;
    color: #ACACAC;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0 10px;
    text-align: center;

    &--left {
      text-align: left;
      margin: 0;
      margin-right: 10px; }

    &--right {
      text-align: right;
      margin: 0;
      margin-left: 10px; } }

  &__line {
    width: 100%;
    height: 1px;
    background-color: #E2E2E2; } }
