.Container {
  padding-top: 2rem;

  &__data {
    width: 100%;
    margin-bottom: 20px; }

  &__dropdown {
    width: 100%;

    .dropdown {
      margin-bottom: 20px !important; } } }
