.CheckoutPlans {
  min-height: calc(100vh - 12rem) !important;
  width: 100%;

  &__header {
    text-align: center;
    width: 100%; }

  &__content {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    .FirstTime {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      max-width: 53.8rem;
      width: 100%;

      @include desktop-media {
        margin-top: 3rem; }

      .Card {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2.5rem 1.5rem 3rem;
        white-space: pre-wrap;

        &__buttons {
          align-items: center;
          direction: row;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          max-width: 27.1rem;
          width: 100%;

          .Button {
            margin: 0;
            width: 12.8rem; } } } }

    .MedicalRequest {
      align-items: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      max-width: 35.2rem;
      padding-top: 1rem;
      width: 100%;

      @include desktop-media {
        padding-top: 0; }

      &--image-wrapper {
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: 100%;

        .TextInput {
          .inputContent {
            span {
              margin-left: -3rem; } } }

        input[type=file] {
          cursor: pointer;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          width: 100%; } }

      .Button {
        margin: 0; } }

    .Details {
      max-width: 91rem;
      width: 100%;

      .Button {
        margin: 0 0 2rem; }

      .CartList__populated__name {
        margin: 1rem 0; } }

    .Confirmation {
      align-items: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin: 4.8rem 0 0;
      max-width: 35.2rem;
      text-align: center;
      white-space: pre-wrap;
      width: 100%;

      span {
        font-weight: $font-bold; } }

    .YellowBox {
      align-items: center;
      background-color: $yellow-light;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      padding: 1.1rem 0.1rem 1.4rem;
      width: 100%;
      white-space: pre-wrap;

      p {
        line-height: 1.4; } }

    .Button {
      margin: 1rem 0 0; } } }

