.typography {
  white-space: pre-line;
  // *
  // * Font patterns
  // *
  &.title {
    @include font-size($title-sizes);
    @include ubuntu($font-medium);
    line-height: 2.1rem; }

  &.regular-title {
    @include font-size($title-sizes);
    @include ubuntu($font-regular); }

  &.subtitle {
    @include font-size($subtitle-sizes);
    @include ubuntu($font-medium); }

  &.paragraph {
    @include font-size($text-sizes);
    @include ubuntu($font-regular);
    line-height: 1.2; }

  &.paragraph-medium {
    @include font-size($text-sizes);
    @include ubuntu($font-medium); }

  &.heading1 {
    @include font-size($heading-sizes);
    @include ubuntu($font-medium); }

  &.heading2 {
    @include font-size($heading-sizes);
    @include ubuntu($font-regular);
    line-height: 1.1; }

  &.heading4 {
    @include font-size($medium-sizes);
    @include ubuntu($font-medium); }

  &.heading5 {
    @include font-size($subtitle-sizes);
    @include ubuntu($font-d); }

  &.heading6 {
    @include font-size($text-sizes);
    @include ubuntu($font-regular); }

  &.subheading1 {
    @include font-size($small-sizes);
    @include ubuntu($font-light); }

  &.subheading2 {
    @include font-size($small-sizes);
    @include ubuntu($font-regular); }

  &.menu-item {
    @include font-size($menu-sizes);
    @include ubuntu($font-medium); }


  &.custom {}

  // *
  // * Font styling
  // *

  &.italic {
    font-style: italic; }

  &.bold {
    @include ubuntu($font-bold); }

  &.uppercase {
    text-transform: uppercase; }

  &.underline {
    text-decoration: underline; }

  &.gutter-bottom {
    margin-bottom: 2.0rem; }

  // *
  // * Font align
  // *

  &.left {
    text-align: left; }

  &.mcenter-dleft {
    text-align: center;
    width: 100%;
    @include desktop-media {
      width: auto;
      text-align: left; } }

  &.mcenter-dright {
    text-align: center;
    width: 100%;
    @include desktop-media {
      width: auto;
      text-align: right; } }

  &.center {
    text-align: center; }

  &.right {
    text-align: right; }

  &.inherit {
    text-align: inherit; }

  &.justify {
    text-align: justify; }

  // *
  // * Color Modifier
  // *

  &.action-online {
    color: $action-online; }

  &.orange {
    color: $orange; }

  &.color-title {
    color: $color-title; }

  &.grey {
    color: $grey-five; }

  &.grey-dark {
    color: $grey-six; }

  &.grey-darker {
    color: $grey-eight; }

  &.grey-light {
    color: $grey-four; }

  &.grey-ten {
    color: $grey-ten; }

  &.grey-eleven {
    color: $grey-eleven; }

  &.primary {
    color: $color-primary; }

  &.white {
    color: $white; }

  &.black {
    color: $black; }

  &.false {
    color: $red-lighter; }

  &.red-lighter {
    color: $red-lighter; }

  &.aqua {
    color: $aqua; }

  // *
  // * Action Modifier
  // *

  &.clickable {
    @include ubuntu($font-bold);
    cursor: pointer;
    color: $color-primary;

    &:hover {
      opacity: 0.5; } } }
