.ClearSearch {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  &__SideLine {
    background-color: $grey-four;
    height: 0.1rem;
    width: 100%;

    &:first-child {
      margin-right: 10px; }
    &:last-child {
      margin-left: 10px; } }

  &__TextArea {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    white-space: nowrap;

    p {
      margin-left: 1rem; } } }
