.profile-swap {
	background-color: #f5f5f5;
	padding: 10px;
	border: 1px solid #e2e2e2;
	width: 100%;

	@include desktop-media {
		margin-bottom: 15px; }

	&--option {
		border-radius: $radius-s;
		box-shadow: 0.1rem 0.1rem 0.5rem $color-title;
		overflow: hidden;
		position: absolute;
		width: calc(100% - 3.2rem);
		z-index: 103; }

	&--option--item {
		background-color: $white;
		color: $grey-six;
		padding: 1rem;
		height: 3.5rem;
		width: auto;

		p {
			line-height: 1; }

		&:hover {
			cursor: pointer;
			background-color: $grey-four; } }

	&--select {
		background-color: $white;
		border: 0.1rem solid $grey-six;
		border-radius: $radius-s;
		color: $grey-six;
		padding: 1rem;
		height: 3.5rem;
		width: auto;

		.profile-swap {
			&--text {
				padding-bottom: 1rem; }

			&--arrow {
				cursor: pointer;
				margin-top: -0.6rem !important; } }

		p {
			line-height: 1; } } }
