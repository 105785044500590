.FileCard {
  width: 100%;
  height: auto;
  padding: 20px;
  font-family: "Ubuntu";
  background: #ffffff;
  box-shadow: 0 1px 3px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__Title {
    width: 100%;
    font-size: 18px;
    color: #4B4B4B;
    margin-bottom: 5px; }

  &__Subtitle {
    width: 100%;
    line-height: 12px;
    font-size: 12px;
    color: #898989;
    text-align: left;
    margin-bottom: 20px; }

  &__UploadArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 130px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 1px dashed #A7A7A7;
    border-radius: 10px;

    &--row {
      @extend .FileCard__UploadArea;
      flex-direction: row; }

    &__Col {
      min-width: 40%;
      text-align: left;
      color: #4B4B4B;
      line-height: 18px;

      &:nth-child(odd) {
        min-width: 30%;
        text-align: center;

        span {
          display: block; } }

      &__LastUpdate {
        font-size: 12px; }

      &__UpdateInfo {
        color: #898989;
        font-size: 12px;
        line-height: 15px;
        margin-top: 10px; } }

    &__EmptyMessage {
      color: #898989;
      font-size: 15px;
      margin-bottom: 10px; }

    &__Button {
      padding: 10px;
      width: auto;
      font-size: 12px; } }

  &__Downloadlink {
    display: none; } }
