.Textarea__inputContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 24.8rem;
  border: 0.1rem solid $grey-seven;
  border-radius: $radius-s;
  color: $color-text;
  flex-shrink: 0;
  font-family: $font-ubuntu;
  font-size: $font-s;
  height: 100%;
  padding: 1.5rem;
  resize: none;

  &:focus {
    border-color: $color-primary;
    color: $grey-eleven;
    opacity: 1;
    outline: none; }

  &::placeholder {
    color: $grey-six; }

  &:disabled {
    background-color: $grey-one;
    color: $grey-eight;
    border: 0.1rem solid $grey-five;

    &::placeholder {
      color: $grey-eight; } }

  .inputContent {

    textarea {
      resize: none;
      &::-webkit-resizer {
        display: none; }
      &::placeholder {
        color: $grey-six; }

      &:disabled {} } } }


