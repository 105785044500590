.TimerContainer {
  color: $color-primary;
  font-family: $font-ubuntu;
  font-size: $font-s;
  font-weight: $font-bold;
  text-align: center;
  width: 4rem;

  &--negative {
    @extend .TimerContainer;
    color: $red-lighter; } }
