.Skeleton {
  background: linear-gradient(90deg,#f5f5f5 30%,#fcfcfc 28%,#fcfcfc 40%,#f5f5f5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: skeleton-loading 2s infinite;
  &--avatar {
    min-width: 92px;
    min-height: 92px;
    border-radius: 50%; }
  &--text {
    width: 100%;
    border-radius: 5px; } }

@keyframes skeleton-loading {
  100% {
    background-position: -100% 0; } }
