//Relative path from root folder (src) ../../../
.BoxBgRounded {
  border-radius: 0.5rem;
  padding: 3rem;
  background-color: $grey-one;

  &.secondary-lighter {
    background-color: $secondary-lighter; }

  &__row {
    margin-bottom: 2rem;
    line-height: 2rem;

    &:last-of-type {
      margin: 0; } } }
