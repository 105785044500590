.Register {
    &__Header {
        margin: 40px 0 30px !important;
        @include desktop-media {
            margin: 80px 0 0 !important; } }

    &__Link {
        font-weight: bold;
        color: $color-primary;
        cursor: pointer;

        &:hover {
            color: #8E99D9; } }

    &__InfoGroup {
        width: 100%;

        &__Head {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;

            &__Image {
                align-items: center;
                border-radius: $radius-s;
                box-shadow: 0rem 0rem 0.6rem transparentize($black, 0.85);
                display: flex;
                justify-content: center;
                justify-self: self-start;
                overflow: hidden;
                width: auto;
                min-width: 115px;
                height: auto;
                min-height: 62px;

                img {
                    width: 100%;
                    height: auto; } }

            &__Text {
                width: 100%;
                text-align: left;
                padding-left: 15px; } }

        &__Forms {
            margin: 20px 0 20px 0;

            &__Item {
                margin-bottom: 10px;
                &:nth-child(4) {
                    margin-bottom: 5px; }
                &.inputMedicalRequest {
                    width: 100%; }

                &--radio {
                    display: flex;
                    margin: 2rem 0;

                    &__Item {
                        margin: 0 1rem;
                        &:first-of-type {
                            margin-left: 0; }
                        &:last-of-type {
                            margin-right: 0; } } }

                .dropdown {
                    margin-bottom: 0.5rem; }

                &__HelperText {
                    line-height: 15px !important;
                    font-size: 12px;
                    font-style: italic;
                    &.helperTextMedicalRequest {
                        text-align: left; } } }

            &--image-wrapper {
                cursor: pointer;
                overflow: hidden;
                position: relative;
                width: 100%;

                input[type=file] {
                    cursor: pointer;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    width: 100%; } } }

        &__Error {
            align-items: flex-start;
            display: flex;
            margin: 20px 0px;

            &__Icon {
                margin-right: 10px;
                margin-top: -5px;
                transform: rotate(180deg); } } }

    &__Professionals {
        margin-top: 30px;
        & > div {
            content: "' '";
            height: 1px;
            width: 100%;
            background-color: #e2e2e2;
            margin-bottom: 30px; }

        &__Text {
            font-size: 12px;
            color: #898989;
            text-align: center; } }
    &__no-medical-order {
        margin-bottom: 20px; }
    &__boxMedicalRequest {
        padding-bottom: 85px !important;
        &--authorization {
            background: #FFFEB5;
            border-radius: 5px;
            line-height: 1.8rem !important;
            padding: 20px; }
        &--info {
            max-width: 33rem;
            font-size: 2.4rem;
            line-height: 2.6rem; }
        &--iconButton {
            -webkit-animation: refresh 1s linear infinite;
            -moz-animation: refresh 1s linear infinite;
            animation: refresh 1s linear infinite;
            position: absolute;
            right: calc(50% - 110px);
            top: 10px; }
        &--alignItems {
            position: relative; }
        .TextInput {
            margin-bottom: 5px; } } }

.GroupPlanSelection {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    &__Title {
        margin-bottom: 11px; }

    &__Selectors {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        width: 22rem;

        .FilterToggleBox {
            width: 100% !important; } }

    &__Dropdown {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        .dropdown {
            margin-bottom: 10px;
            width: 100%; } }

    &__Button {
        width: 100%; } }

.Modal {
    .Modal__container__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        > p {
            margin-bottom: 15px; } } }


@-moz-keyframes refresh {
    0% {
        -moz-transform: rotate(0deg); }
    100% {
        -moz-transform: rotate(-360deg); } }
@-webkit-keyframes refresh {
    0% {
        -moz-transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(-360deg); } }
@keyframes refresh {
    0% {
        -moz-transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg); } }
