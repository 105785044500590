.dropdown {
  height: auto;
  position: relative;
  min-width: 19.5rem;

  &__toggle {
    &:focus {
      outline: none;
      .dropdown--box {
        border-color: $color-primary; } } }

  &-error {
    @extend .dropdown;
    .dropdown--box {
      border-color: $red; } }

  &--box {
    align-items: center;
    background-color: $white;
    background-position: calc(100% - 15px) center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    border: 0.1rem solid $grey-seven;
    border-radius: $radius-s;
    cursor: pointer;
    color: $grey-six;
    display: flex;
    font-size: $font-s;
    justify-content: space-between;
    padding: 1.2rem 1.4rem;
    text-align: left;

    &--disabled {
      background-color: #F5F5F5;
      color: $grey-eight;
      border: 0.1rem solid #dedede;
      cursor: not-allowed; }

    &-open {
      @extend .dropdown--box;
      border-color: #dedede; } }

  &-disabled {
    @extend .dropdown--box;

    background-color: #F5F5F5;
    border: 0.1rem solid $grey-eight;
    color: $grey-eight;
    cursor: not-allowed; }

  &--option-list {
    background-color: $white;
    border-radius: $radius-s;
    box-shadow: 0.2rem 0.2rem 0.5rem $grey, -0.1rem -0.1rem 0.1rem $grey;
    color: $color-primary;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    display: none;
    left: 0;
    right: 0;
    min-height: 48px;
    max-height: 160px;

    &.open {
      display: block; } }

  &--overflow {
    overflow: auto;

    .dropdown--option-item {
      padding: 1.5rem;
      text-align: left; } }

  &--option-item {
    color: $color-text;
    cursor: pointer;
    padding: 1.5rem;

    &:hover, &:active {
      @include ubuntu($font-regular);
      color: #1F35B5;
      background-color: #E8EAF8;
      outline: none;
      transition: 0.2s; } }

  &--option-item-selected {
    cursor: pointer;
    padding: 1.5rem;
    @include ubuntu($font-regular);
    color: #1F35B5;
    background-color: #E8EAF8;
    transition: 0.2s;

    &:focus, &:active, &:hover {
      outline: none; } }

  &--placeholder {
    &-selected {
      color: $color-text;
      white-space: nowrap;
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis; } } }

.messageError {
  margin-top: 0 !important; }
