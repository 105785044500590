.ScheduleModal {
  &__warn {
    border-radius: $radius-s;
    background-color: $yellow-light;
    margin-bottom: 2rem;
    padding: 15px; }

  &__error {
    display: flex;
    z-index: 999;
    margin: 10px 0;
    position: relative;

    &__text {
      margin-left: 15px; }

    &__actions {
      z-index: 999;
      position: relative;
      margin: 10px 0 20px 0;
      display: flex;
      width: 100%;
      margin-left: 35px;

      &__button {
        height: 35px !important;
        width: 100px !important;
        font-size: 12px !important;
        font-weight: 500 !important; }

      &__buttonRight {
        margin-left: 10px;
        height: 35px !important;
        width: 100px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        border-color: #4b4b4b !important;
        color: #4b4b4b !important;
        &:hover {
          background-color: !important; } } } }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .1);
    z-index: 400; }

  &__tabs {
    margin-bottom: 2.25rem;
    z-index: 1002; }

  &__single__time {
    padding: 0 40px; }

  &__single__error p {
    padding-left: 40px; }

  &__weekday, &__single {
    padding: 2rem 1rem;

    &__title {
      display: flex;
      flex-direction: column; }

    &__time {
      display: flex;
      justify-content: flex-start;
      margin-top: 15px;
      align-items: flex-start; }

    &__input {
      max-width: 90px; }

    &__button {
      flex-grow: 1;
      button {
        width: 100%; } }

    &__date {
      margin-top: 15px;
      width: 100%; }

    &__child {
      display: flex;
      align-items: center;

      padding: 0 0.5rem;

      &:first-child {
        padding-left: 0; }

      &:last-child {
        padding-right: 0; }

      .TextInput {
        margin-bottom: 0 !important;

        .messageError {
          height: 0; }

        input {
          text-align: center;
          padding: 0.5rem;

          &:disabled {
            border-color: initial; } } }

      p {
        height: 48px;
        line-height: 48px !important; }

      span {
        padding: 0 5px; } }

    &__slots {
      display: flex;
      flex-wrap: wrap;
      margin: 1.6rem 0;

      &__slot {
        padding-right: 5px;
        padding-bottom: 5px;
        width: 50%;

        &__error {
          position: relative;
          z-index: 999; } } }

    &__hourError {
      font-size: $font-xs;
      color: $red-lighter;
      padding-top: 10px; } } }

