.SocialLinks {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  justify-content: center;
  align-items: center;

  @media (min-width: 1096px) {
    margin-top: 0; }

  &--hideOnDesktop {
    @media (min-width: 1096px) {
      display: none; } }

  &--hideOnMobile {
    @media (max-width: 1096px) {
      display: none; } }

  &__label {
    font-size: 12px;
    color: #ACACAC;
    margin-right: 10px; }

  &__links {
    display: flex;
    list-style: none; }

  &__link {
    margin-right: 10px;
    height: 30px;
    width: 30px;

    &--last {
      margin: 0; } }

  &__icon {
    height: 30px;
    width: 30px; } }
