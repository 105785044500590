.AdminReservations {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    &__spinnerContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px; }

    &__header {
        display: flex;
        &__mobile {
            width: 100%;
            flex-direction: column; } }

    &__professionalContainer {
        margin-left: 20px;
        &__mobile {
            margin-left: 0;
            margin-top: 20px; } }

    &__professional {
        display: flex;

        &__description {
            margin-left: 10px; } }

    &__content {
        margin-top: 25px; } }
