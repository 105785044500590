.EditPlanModal {

  &__card-number-wrapper {
    width: 100%;
    margin-top: 10px; }

  &__wrapper-confirmation-data {
    width: 100%;

    table {
      line-height: 1.1;

      & + table {
        margin-top: 20px; }

      th {
        text-align: left; }

      tr > td {
        padding-right: 20px; } } }

  &__wrapper-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 30px;

    button {
      width: 100%; }

    button:disabled {
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        border-color: #fff;
        border-top-color: transparent;
        width: 40px;
        height: 40px; } } }

  .Textarea {
    width: 100%;
    margin-top: 10px; }

  .dropdown {
    width: 100%; }

  .dropdown + .dropdown {
    margin-top: 10px; } }
