.NewPlanGroup {
    &__Container {
        border-bottom: 1px solid #E2E2E2; }

    &__InnerContainer {
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 5px;
        margin-bottom: 40px; }

    &__CreditsRow {
        display: flex;
        padding: 0px 20px 20px 20px;
        border-bottom: 1px solid #E2E2E2;
        justify-content: flex-end;
        @media (max-width: 1096px) {
            flex-direction: column; } }



    &__CreditsOptions {
        display: flex;
        width: 60%;
        @media (max-width: 1096px) {
            flex-direction: column;
            width: 100%;
            align-items: flex-end; } }


    &__addPlanButton {
        height: 35px;
        width: 172px;
        font-size: 12px;
        border: 1px solid #1F35B5;
        color: #1F35B5;
        background: #fff;
        border-radius: 5px;
        @media (max-width: 1096px) {
          margin-top: 20px; } }

    &__PlanContainer {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      border-bottom: 1px solid #E2E2E2;
      margin-bottom: 20px;
      @media (max-width: 1096px) {
        flex-direction: column;
        gap: 0;
        margin-bottom: 0; }

      &__PlanName {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        &__Fields {
          display: flex;
          gap: 10px;
          margin-bottom: 0;
          > div {
            max-width: 200px; }
          @media (max-width: 1096px) {
            flex-direction: column;
            gap: 0; } } }
      &__Psychiatry {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px; }
      &__MedicalReturn {
        gap: 10px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        &__Fields {
          display: flex;
          align-items: center;
          > input {
            height: 48px;
            width: 60px;
            margin-right: 15px;
            border: 1px solid #C3C3C3;
            border-radius: 5px;
            text-align: center;
            color: #4b4b4b;
            font-size: 15px; }
          @media (max-width: 1096px) {
            justify-content: end; } } } }

    &__InputContainer {
        display: flex;
        align-items: center;
        > input {
            height: 48px;
            width: 60px;
            margin-right: 15px;
            border: 1px solid #C3C3C3;
            border-radius: 5px;
            text-align: center;
            color: #4b4b4b;
            font-size: 15px; }

        @media (max-width: 1096px) {
            &:last-child {
                margin-top: 20px; } } }

    &__DateContainer {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #C1C1C1;
        border-radius: 4px;
        padding: 12px 16px;
        width: 100%; }

    &__RenewRow {
        display: flex;
        padding: 20px;
        border-bottom: 1px solid #E2E2E2; }

    &__RenewCreditsRow {
        display: flex;
        padding: 20px;
        border-bottom: 1px; }


    &__RenewTitle {
        display: flex;
        flex-direction: column;
        flex: 1;
        > span {
            width: 100%;
            line-height: 15px;
            font-size: 12px;
            color: #898989;
            margin-right: 44px; } }

    &__RenewRadios {
        flex: 1.5;
        display: flex;
        @media (max-width: 1096px) {
            flex: 1;
            justify-content: flex-end; } }

    &__BottomContainer {
        margin-top: 30px; }

    &__Button {
        margin-top: 26px;
        margin-bottom: 40px;
        width: 384px;
        height: 48px;
        text-align: center;
        @media (max-width: 1096px) {
            width: 100%; } } }
.row {
    &__addPlanRow {
        flex-flow: row !important;
        gap: 10px; }

    &__addPlanX {
        cursor: pointer;
        display: block;
        width: 20px;
        height: 2px;
        background: #868585;
        position: relative;
        transform: rotate(45deg);
        margin: 24px 0 0 10px;

        &::before {
            display: block;
            content: '';
            width: 20px;
            height: 2px;
            background: #868585;
            position: relative;
            transform: rotate(-90deg); } } }
