
@keyframes toggleButtonBlink {
    0% {
      background-color: $grey-three; }
    50.0% {
      background-color: transparentize($green, 0.5); }
    100.0% {
      background-color: $grey-three; } }

.Toggle {
  align-items: center;
  background-color: $grey-three;
  cursor: pointer;
  display: flex;
  height: 28px;
  width: 48px;
  border-radius: 20px;
  position: relative;

  &--active {
    background-color: $green; }

  &--loading {
    background-color: $grey-one;
    animation: toggleButtonBlink 1s ease-in-out infinite; }

  &__icon {
    height: 24px;
    width: 24px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: $white;
    border-radius: 24px;
    transition: all 0.1s ease-out;
    margin-left: 2px;

    &--active {
      margin-left: 22px; }

    &--loading {
      margin-left: 12px; } }

  &__label {
    position: absolute;
    color: #fff !important;
    right: 10px;
    &--active {
      left: 10px; } } }

