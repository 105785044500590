.NotificationInline {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 7.261rem;

  p {
    line-height: 1rem; }

  .Button {
    font-size: 12px;
    padding: 10px 13px 12px;
    margin-top: 0.5rem;
    font-weight: 400; } }
