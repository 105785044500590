.Login {

    &__Header {
        margin: 40px 0 30px !important;
        @include desktop-media {
            margin: 80px 0 30px !important; } }

    &__Content {

        &__Column {
            width: 50%; }

        &__Link {
            &:hover {
                color: #8E99D9; } } } }
