.SearchPlaceholder {
    display: flex;
    justify-content: center;
    align-self: center;
    align-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    &__icon {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-bottom: 1rem; }
    &__title {
        display: flex;
        align-content: center;
        color: #898989;
        font-size: 1.5rem; }
    &__text {
        display: flex;
        align-content: center;
        color: #898989;
        font-size: 1.2rem;
        font-style: italic; } }
