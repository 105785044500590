.Footer {
    background: linear-gradient(to right, #1F35B5, #556EFF 100%);
    margin-top: 5rem;
    position: relative;
    width: 100%;

    &__Image {
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      opacity: 0.4;
      height: 100%;
      width: 100%; }

    &__Layer {
        padding: 4rem 0 5rem;
        position: relative;
        width: 100%;

        @include desktop-media {
            padding: 7rem 0 8rem; } }

    &__Upper {
        align-items: center;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        margin-bottom: 3.5rem;
        width: 100%;

        @include desktop-media {
            align-items: flex-start;
            flex-direction: row;
            margin-bottom: 10rem; }

        &__Left {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;

            @include desktop-media {
                align-items: flex-start;
                flex-direction: row;
                justify-content: flex-start; } }

        &__Right {
            align-items: center;
            display: flex;
            height: 6.2rem;
            flex-direction: column;

            @include desktop-media {
                flex-direction: row;
                height: 4.5rem;
                margin-left: auto;
                margin-right: 0; }

            &__Label {
                margin-bottom: 1rem;

                @include desktop-media {
                    margin-right: 1.5rem;
                    margin-top: 0.5rem; } }

            &__Social {
                display: flex;
                flex-direction: row;

                &__Item {
                    margin: 0 0.75rem;

                    @include hover-opacity;

                    &:first-of-type {
                        margin-left: 0; }
                    &:last-of-type {
                        margin-right: 0; } } } } }

    &__Under {
        align-items: center;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        margin-top: 7rem;
        width: 100%;

        @include desktop-media {
            flex-direction: row;
            margin-top: 0; } }


    &__Copyright {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include desktop-media {
            flex-direction: row;
            margin-right: 4.6rem; }

        &__Text {
            opacity: 0.4; }

        &__Logo {
            @include desktop-media {
                margin-right: 1.8rem; } } }

    &__Contact {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 6rem;

        @include desktop-media {
            margin-bottom: 0; }

        &__Email {
            @include hover-opacity; }

        &__Label {
            margin-right: 0.3rem; } }

    // &__Under
    //     align-items: center
    //     display: flex
    //     flex-direction: row
    //     justify-content: flex-start
    //     width: 100%



    &__Topic {
        align-items: center;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: fit-content;

        @include desktop-media {
            align-items: flex-start;
            max-width: 18rem;
            margin: 0 3.5rem;

            &:first-of-type {
                margin-left: 0; }
            &:last-of-type {
                margin-right: 0; } }

        &__Title {
            margin-bottom: 2.15rem;

            @include desktop-media {
                margin-bottom: 0rem; } }



        &__Item {
            cursor: pointer;
            margin: 2.15rem 0;

            p {
                line-height: 1.7rem; }
            .Tag {
                margin-left: 1rem; }

            &:first-of-type {
                margin: 0 0 2.15rem; }
            &:last-of-type {
                margin: 2.15rem 0 0; }

            @include hover-opacity;
            @include desktop-media {
                display: flex;
                margin: 1.2rem 0;
                width: 100%;

                &:first-of-type {
                    margin: 0 0 1.2rem; }
                &:last-of-type {
                    margin: 1.2rem 0 0; } } } } }
