.CheckBox {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  &__icon {
    align-items: center;
    display: flex;
    margin-right: 1rem;

    svg {
      fill: $color-primary; } }

  &__text {
    cursor: initial;
    &.disabled {
      color: $grey-six; } } }
