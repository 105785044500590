.PatientHome {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__Card {
        margin-bottom: 20px;
        max-width: 100% !important; }

    &__NextScheduleCard {
        margin: 20px 0;

        & > div {
            align-items: inherit; } }

    &__NextScheduleEmpty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        fill: #c1c1c1;
        color: #c1c1c1;
        margin-top: 60px;
        margin-bottom: 50px; }

    &__EmptyReservation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        color: #898989;
        overflow: auto;

        &__title {
            margin: 10px 0 !important; }

        &__subtitle {
            max-width: 224px;
            margin-bottom: 20px !important;
            text-align: center; } }

    &__InfoDialog {
        &__icon {
            margin-top: 20px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: center; }

        &__title {
            margin-bottom: 20px !important; }

        &__container {}

        &__content {
            margin-bottom: 20px !important; }

        &__paper {
            margin: 10px 0 20px 0;
            align-self: center;
            max-width: 100% !important; } }

    &__Reservation {
        display: flex;
        margin-top: 20px;
        z-index: 1;

        &__exceptions {
            justify-content: flex-start !important;
            margin: 20px 0; }

        &__avatar {
            display: flex;
            justify-content: center;
            margin-right: 15px; }

        &__schedule {
            flex: 1; }

        &__actions {
            display: flex;
            align-items: center;
            justify-content: flex-end; }

        &__footer {
            background-color: #E7FFE5;
            display: flex;
            align-items: center;
            border-radius: 0 0 5px 5px;
            justify-content: center;
            padding: 17px 15px 20px 20px;
            z-index: 2;
            margin: 15px -20px -20px -20px; }

        &__footerIcon {
            margin-top: 5px;
            margin-right: 10px; }

        &__scheduleProfessional {
            margin-top: 10px; } }

    &__CancelModal {
        &__warning {
            text-align: center;
            max-width: 100%; }

        &__reason {
            margin-top: 30px;
            display: flex;
            flex-direction: column; }

        &__textbox {
            margin-top: 10px;
            margin-bottom: 20px; }

        &__required {
            color: #898989 !important; }

        &__button {
            margin-top: 20px;
            margin-bottom: 40px;
            width: 100%; }

        &__spinner {
            display: flex;
            justify-content: center;
            height: 50vh;
            padding-top: calc(25vh - 42px); }

        &__success {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-self: center;
            &__subTitle {
                margin-top: 5px !important; }
            &__warning {
                margin-top: 30px;
                padding: 20px !important; } }

        &__successIcon {
            margin-bottom: 10px; }

        &__successButton {
            width: 332px;
            margin-top: 30px; }

        &__professionalWarning {
            margin-bottom: 20px;
            justify-content: center;
            max-width: 100% !important; } }

    &__FabItem {
        max-width: 60%;
        cursor: pointer;

        @include desktop-media {
            max-width: initial;
            width: 250px; } }

    .PatientEligibility {
        &__title {
            text-align: left; }

        &__info {
            justify-content: flex-start; }

        &__info p,
        &__message {
            text-align: left; } } }
