.ModalGeneric {

    &__Backdrop {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999999999999 !important;
        background-color: rgba(196, 196, 196, 0.9);
        padding-top: 0px;
        @include desktop-media {
            padding-top: 60px; }

        .--large {
            max-width: 729px;

            .ModalGeneric__Modal__Topbar, .ModalGeneric__Modal__Topbar--active {
                max-width: 729px; }

            .ModalGeneric__Modal__Container {
                @include desktop-media {
                    padding: 0px 35px 22px; } } } }


    &__Modal {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        max-width: 536px;
        overflow-y: overlay !important;
        padding: 10px;
        position: relative;
        scroll-behavior: smooth;
        border-radius: 20px;
        max-height: calc(100% - 60px) !important;
        background: #fff;
        @include mobile-media {
            height: calc(100% - 60px) !important;
            top: 60px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; }

        &__error {
            &::after {
                position: fixed;
                content: " ";
                background-color: rgba(255,255,255,.7);
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow-y: hidden;
                z-index: 600; } }

        @include desktop-media {
            height: auto;
            max-height: 100%; }

        &__Title {
            text-align: center;
            width: 70%;
            margin-left: 15%;
            height: auto;
            padding: 6px 0px 20px 0px;
            @include desktop-media {
                width: 100%;
                margin-left: 0%; }

            &__Subtitle {
                color: $grey-ten;
                margin-top: 5px !important;
                text-align: center; } }

        &__Button {}

        &__CloseButton {
            position: fixed;
            width: 35px;
            height: 35px;
            cursor: pointer;
            background-color: white;
            right: initial;
            top: initial;
            z-index: 500;
            border-radius: 100px;
            &:hover {
                background: #F5F5F5; } }

        &__Topbar,
        &__Downbar {
            display: none;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            z-index: 1000;
            width: 100%;
            max-width: 536px;
            height: 72px;
            padding: 18px;
            opacity: 1.0;
            background-color: transparent;
            box-shadow: none;
            margin: -18px auto 0 auto !important;
            left: 0;
            right: 0;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            &--active {
                @extend .ModalGeneric__Modal__Topbar;
                background-color: white;
                box-shadow: 0px 3px 6px #00000029;
                -webkit-box-shadow: 0px 3px 6px #00000029;
                -moz-box-shadow: 0px 3px 6px #00000029;
                -ms-box-shadow: 0px 3px 6px #00000029;
                animation: fadeInAnimation 0.5s ease;
                display: flex;

                &__Title {
                    width: 63%;
                    margin-left: 20%;
                    text-align: center;
                    line-height: 15px; } } }
        &__Downbar {}
        &__Container {
            width: 100%;
            padding: 0px 0px 28px 0px;
            @include desktop-media {
                padding: 0px 82px 22px 82px; }

            &__Group {
                margin: 20px 0;

                &__Item {
                    margin-bottom: 10px; } }

            &__Feedback {
                padding: 20px 0px;
                text-align: center;

                &__Icon {
                    margin-bottom: 5px; } } } } }

@keyframes fadeInAnimation {
    0% {
        opacity: 0.0; }

    100% {
        opacity: 1.0; } }
