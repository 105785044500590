@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i);
.GeneralViewFilters {
  margin-bottom: 20px; }
  .GeneralViewFilters__sectionTitle {
    font-size: 15px;
    color: #4B4B4B;
    font-weight: bold;
    font-family: "Ubuntu";
    margin-top: 20px;
    margin-bottom: 17px; }
  .GeneralViewFilters__radio {
    margin-bottom: 15px; }
  .GeneralViewFilters__dateFilter {
    display: flex;
    align-items: center;
    margin-top: 11px; }
  .GeneralViewFilters__text {
    font-size: 15px;
    color: #898989;
    margin: 0 10px 10px; }
  .GeneralViewFilters__errorMessage {
    font-size: 1.2rem;
    color: #eb384d;
    text-align: center; }

.ProfUnavailabilityModal {
  color: #4B4B4B; }
  .ProfUnavailabilityModal__title {
    width: 100%;
    text-align: center;
    font-size: 21px; }
  .ProfUnavailabilityModal__text {
    text-align: center;
    margin-bottom: 10px !important; }
    .ProfUnavailabilityModal__text--toast {
      margin-bottom: 20px;
      color: #ff4949;
      line-height: 1;
      text-align: left; }
  .ProfUnavailabilityModal__alertText {
    padding: 13px 15px !important;
    font-size: 12px !important;
    line-height: 14px !important;
    border-radius: 5px;
    background-color: #FFFFB5; }
  .ProfUnavailabilityModal__inputItem {
    margin-top: 20px; }
  .ProfUnavailabilityModal__subInput {
    margin-top: -10px;
    font-size: 12px;
    color: #898989; }
  .ProfUnavailabilityModal__doubleInput {
    margin-top: 10px; }
    .ProfUnavailabilityModal__doubleInput--shortSpaced {
      margin-top: 20px; }
  .ProfUnavailabilityModal__toast {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    padding: 0 20px; }
    .ProfUnavailabilityModal__toast > svg {
      width: 22px;
      height: 22px;
      margin-right: 10px; }
  .ProfUnavailabilityModal__button {
    margin-top: 20px; }

.TimestampTag {
  margin: 20px 0; }

.RemoveUnavailabilityModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .RemoveUnavailabilityModal__topInfo {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .RemoveUnavailabilityModal__toptext {
    max-width: 212px;
    margin-bottom: 20px; }
  .RemoveUnavailabilityModal__toast {
    margin: 20px 0 30px;
    display: grid;
    grid-template-columns: 22px auto;
    grid-column-gap: 10px; }
    .RemoveUnavailabilityModal__toast > svg {
      width: 22px;
      height: 22px;
      margin-right: 10px; }
  .RemoveUnavailabilityModal__text {
    font-size: 15px;
    text-align: center; }
    .RemoveUnavailabilityModal__text--toast {
      color: #ff4949;
      line-height: 1;
      text-align: left; }

.SuccessModal {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .SuccessModal__top {
    max-width: 264px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .SuccessModal__top > svg {
      margin-bottom: 10px; }

.PlanDetail {
  background-color: #f5f5f5;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 5px;
  width: 100%; }
  .PlanDetail__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    .PlanDetail__header__resume {
      width: 100%; }
      .PlanDetail__header__resume p {
        line-height: 15px; }
    .PlanDetail__header__button {
      display: flex;
      justify-content: flex-end; }
      .PlanDetail__header__button__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: 7px;
        gap: 7px;
        cursor: pointer; }
        .PlanDetail__header__button__wrapper p {
          color: #1f35b5; }
  .PlanDetail__details--close {
    display: none; }
  .PlanDetail__details--open {
    margin-top: 20px; }
  .PlanDetail__details__item {
    margin-bottom: 20px; }
    .PlanDetail__details__item:last-child {
      margin-bottom: 0; }
    .PlanDetail__details__item p {
      line-height: 15px; }

.ChartCard {
  width: 100%;
  padding: 20px;
  font-family: "Ubuntu";
  background: #ffffff;
  box-shadow: 0 1px 3px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .ChartCard--spaced {
    margin-right: 20px; }
    @media (max-width: 1096px) {
      .ChartCard--spaced {
        margin-bottom: 20px; } }
  .ChartCard__title {
    width: 100%;
    font-size: 18px;
    color: #4B4B4B;
    margin-bottom: 25px; }
    .ChartCard__title--correction {
      margin-bottom: -25px; }
  .ChartCard__chart {
    margin-bottom: 30px; }
    .ChartCard__chart--solo {
      margin: 30px 0; }
  .ChartCard__labels {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 36px;
    -webkit-column-gap: 36px;
            column-gap: 36px;
    margin-bottom: 25px; }
    @media (max-width: 1096px) {
      .ChartCard__labels {
        width: auto; } }
  .ChartCard__link {
    color: #1F35B5;
    cursor: pointer; }
  .ChartCard__bottom {
    width: 100%; }
  .ChartCard__text {
    line-height: 12px;
    font-size: 12px;
    color: #4B4B4B;
    margin-bottom: 5px; }
    .ChartCard__text--sub {
      font-style: italic;
      color: #ACACAC; }
  .ChartCard__doughnut__container {
    margin-top: 40px; }

.ChartLabel {
  display: flex;
  margin-bottom: 15px;
  font-family: "Ubuntu"; }
  .ChartLabel__group {
    display: flex;
    flex-direction: column; }
  .ChartLabel__color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px; }
  .ChartLabel__text {
    font-size: 15px;
    color: #4B4B4B;
    line-height: 15px; }
    .ChartLabel__text--highlight {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 3px 5px;
      font-size: 12px;
      margin-top: 4px;
      border-radius: 3px; }

.DateFilter {
  margin-top: 11px; }
  .DateFilter__group {
    display: flex;
    align-items: center; }
  .DateFilter__text {
    font-size: 15px;
    color: #898989;
    margin: 0 10px 20px; }
  .DateFilter__errorMessage {
    font-size: 1.2rem;
    color: #eb384d;
    text-align: center;
    margin-top: -1rem; }
    .DateFilter__errorMessage--hidden {
      visibility: hidden; }

.GeneralViewCard {
  width: 100%;
  max-height: 113px;
  padding: 10px 0 15px;
  background: #ffffff;
  box-shadow: 0 1px 3px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .GeneralViewCard--spaced {
    margin-right: 20px; }
  .GeneralViewCard__text {
    width: 70%;
    margin: 0;
    font-size: 12px;
    font-family: "Ubuntu";
    color: #4B4B4B;
    text-align: center;
    line-height: 1;
    word-break: break-word; }
    .GeneralViewCard__text--value {
      font-size: 35px;
      font-weight: bold;
      color: #1F35B5;
      margin-bottom: 5px; }
    .GeneralViewCard__text--chips {
      width: auto;
      padding: 3px 5px;
      color: #1F35B5;
      background: #EBEEFD;
      border-radius: 3px;
      margin-top: 10px; }

.HourFilter {
  margin: 10px 40px 0; }
  .HourFilter__group {
    display: flex;
    align-items: center; }
  .HourFilter__text {
    font-size: 15px;
    color: #898989;
    margin: 0 10px; }
  .HourFilter__errorMessage {
    font-size: 1.2rem;
    color: #eb384d;
    text-align: left;
    margin-top: 5px; }
    .HourFilter__errorMessage--hidden {
      visibility: hidden;
      height: 0; }
  .HourFilter__field {
    display: flex;
    justify-content: center;
    align-items: center; }
    .HourFilter__field span {
      padding: 0 5px; }
    .HourFilter__field .TextInput {
      margin-bottom: 0; }
      .HourFilter__field .TextInput input {
        text-align: center; }
      .HourFilter__field .TextInput .messageError {
        display: none; }

.LineBreaker {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center; }
  .LineBreaker__text {
    font-size: 15px;
    color: #ACACAC;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0 10px;
    text-align: center; }
    .LineBreaker__text--left {
      text-align: left;
      margin: 0;
      margin-right: 10px; }
    .LineBreaker__text--right {
      text-align: right;
      margin: 0;
      margin-left: 10px; }
  .LineBreaker__line {
    width: 100%;
    height: 1px;
    background-color: #E2E2E2; }

.ProfessionalInfo {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  font-family: "Ubuntu"; }
  @media (max-width: 1096px) {
    .ProfessionalInfo {
      margin-bottom: 30px;
      margin-top: -10px; } }
  .ProfessionalInfo__professional {
    display: grid;
    grid-template-columns: 160px auto;
    grid-column-gap: 25px;
    -webkit-column-gap: 25px;
            column-gap: 25px; }
    @media (max-width: 1096px) {
      .ProfessionalInfo__professional {
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: center;
        background: #fff; } }
  .ProfessionalInfo__containerPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative; }
    @media (max-width: 1096px) {
      .ProfessionalInfo__containerPhoto {
        padding-bottom: 100px;
        background: linear-gradient(to right, #1F35B5, #556EFF 100%); } }
    .ProfessionalInfo__containerPhoto__photo {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      background-color: #2D2D2D; }
      @media (max-width: 1096px) {
        .ProfessionalInfo__containerPhoto__photo {
          width: 140px;
          height: 140px;
          margin: 0;
          top: 30px;
          border: 8px solid #fff;
          position: absolute; } }
      .ProfessionalInfo__containerPhoto__photo--empty {
        color: #FFFFFF; }
  .ProfessionalInfo__info {
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 1096px) {
      .ProfessionalInfo__info {
        justify-content: normal;
        margin-top: 80px; } }
  .ProfessionalInfo__personal {
    margin-bottom: 25px; }
    @media (max-width: 1096px) {
      .ProfessionalInfo__personal {
        margin-bottom: 20px; } }
  .ProfessionalInfo__text {
    font-size: 15px;
    line-height: 15px;
    color: #2D2D2D; }
    @media (max-width: 1096px) {
      .ProfessionalInfo__text {
        text-align: center; } }
    .ProfessionalInfo__text--main {
      font-size: 30px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 4px; }
      @media (max-width: 1096px) {
        .ProfessionalInfo__text--main {
          font-size: 24px; } }
  .ProfessionalInfo__jobInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1096px) {
      .ProfessionalInfo__jobInfo {
        justify-content: space-around;
        width: 100%; } }
  .ProfessionalInfo__group {
    display: flex;
    align-items: center; }
    @media (max-width: 1096px) {
      .ProfessionalInfo__group {
        justify-content: space-around;
        width: 100%; } }
  .ProfessionalInfo__icon {
    width: 20px;
    color: #4B4B4B;
    margin-right: 5px; }
  .ProfessionalInfo__rating {
    display: flex;
    align-items: center;
    padding-bottom: 5px; }
    @media (max-width: 1096px) {
      .ProfessionalInfo__rating {
        justify-content: center; } }
    .ProfessionalInfo__rating span {
      display: flex;
      grid-gap: 5px;
      gap: 5px;
      cursor: pointer;
      transition: -webkit-filter 500ms;
      transition: filter 500ms;
      transition: filter 500ms, -webkit-filter 500ms; }
      .ProfessionalInfo__rating span:hover {
        -webkit-filter: brightness(120%);
                filter: brightness(120%); }

.ProfessionalData {
  font-family: "Ubuntu"; }
  .ProfessionalData--spaced {
    margin-right: 30px; }
  .ProfessionalData__content {
    font-size: 21px;
    font-weight: 500;
    color: #4B4B4B;
    display: flex;
    align-items: center; }
    @media (max-width: 1096px) {
      .ProfessionalData__content {
        justify-content: center; } }
  .ProfessionalData__label {
    font-size: 12px;
    color: #ACACAC;
    line-height: 12px;
    margin-top: 2px; }
    @media (max-width: 1096px) {
      .ProfessionalData__label {
        text-align: center; } }

.PatientCard {
  width: 100%;
  display: grid;
  grid-template-columns: 50px auto;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  background-color: #FFFFFF;
  padding: 25px 14px; }
  .PatientCard__photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #C4C4C4;
    color: #EBEBEB; }
  .PatientCard__row {
    display: flex;
    align-items: center; }
    @media (max-width: 1096px) {
      .PatientCard__row {
        align-items: flex-start;
        flex-direction: column; } }
    .PatientCard__row--spaced {
      margin-bottom: 10px; }
    @media (max-width: 1096px) {
      .PatientCard__row--column {
        flex-direction: row !important; } }
  .PatientCard__text {
    font-size: 15px;
    line-height: 15px;
    color: #898989;
    font-family: "Ubuntu";
    margin-right: 15px;
    margin-bottom: 2px;
    white-space: normal; }
    .PatientCard__text:last-child {
      margin: 0; }
    .PatientCard__text--name {
      color: #4B4B4B;
      font-weight: bold; }
    .PatientCard__text--appointments {
      font-weight: bold;
      color: #4AC326; }
    .PatientCard__text--normal {
      color: #4B4B4B; }

.PatientsList {
  width: 100%;
  height: 100%; }
  .PatientsList__top {
    background: #F5F5F5;
    padding: 8px 14px;
    font-size: 15px;
    color: #2D2D2D; }
    .PatientsList__top--fixed {
      width: 100%;
      max-width: 787px;
      position: fixed;
      z-index: 2; }
  .PatientsList__list {
    width: 100%;
    list-style: none;
    padding: 0; }
  .PatientsList__item {
    border-bottom: 2px solid #F5F5F5; }
    .PatientsList__item:last-child {
      border: none; }

.PageTop {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .PageTop__Wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3; }
    @media (max-width: 1096px) {
      .PageTop__Wrapper {
        flex-direction: column;
        justify-content: center; } }
  .PageTop--hidden {
    display: none; }
  .PageTop__floating {
    width: inherit;
    max-width: 787px;
    position: fixed;
    top: 0;
    margin: 0;
    padding: 15px 14px 5px;
    display: none;
    background-color: #FFFFFF;
    z-index: 5; }
    @media (max-width: 1096px) {
      .PageTop__floating {
        width: 100%;
        left: 0;
        padding: 15px 14px 5px; } }
    .PageTop__floating--visible {
      display: flex;
      flex-direction: column;
      width: 100%;
      -webkit-animation: fadein 0.3s;
              animation: fadein 0.3s; }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  @media (max-width: 1096px) {
    .PageTop__title--hidden {
      display: none; } }
  .PageTop__title--mobile {
    flex-direction: column; }
  .PageTop__content {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1096px) {
      .PageTop__content {
        width: 100%; } }

.SocialLinks {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1096px) {
    .SocialLinks {
      margin-top: 0; } }
  @media (min-width: 1096px) {
    .SocialLinks--hideOnDesktop {
      display: none; } }
  @media (max-width: 1096px) {
    .SocialLinks--hideOnMobile {
      display: none; } }
  .SocialLinks__label {
    font-size: 12px;
    color: #ACACAC;
    margin-right: 10px; }
  .SocialLinks__links {
    display: flex;
    list-style: none; }
  .SocialLinks__link {
    margin-right: 10px;
    height: 30px;
    width: 30px; }
    .SocialLinks__link--last {
      margin: 0; }
  .SocialLinks__icon {
    height: 30px;
    width: 30px; }

.ShareButton {
  height: 30px;
  display: inline-block;
  cursor: pointer; }
  .ShareButton:visited, .ShareButton:active {
    color: none;
    outline: none;
    border: none; }

.TextContainer {
  font-family: "Ubuntu"; }
  .TextContainer__text {
    font-size: 15px;
    color: #4B4B4B;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    line-height: 1.5;
    text-align: justify; }
    .TextContainer__text--empty {
      font-size: 15px;
      font-family: "Ubuntu";
      color: #4b4b4b;
      font-style: italic; }
    .TextContainer__text--open {
      overflow: visible; }
  .TextContainer__button {
    font-size: 15px;
    color: #1F35B5;
    cursor: pointer;
    text-align: right;
    margin-top: 15px; }

.TagChip {
  display: inline-flex;
  padding: 7px 14px;
  border: 1px solid #898989;
  border-radius: 40px;
  font-size: 15px;
  color: #4B4B4B;
  font-weight: 500;
  font-family: "Ubuntu";
  text-align: center;
  line-height: 16px; }
  .TagChip--main {
    color: #FFFFFF;
    background-color: #22CBD3;
    border: 1px solid #22CBD3; }

.UnavailabilityCard {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #00000033; }
  .UnavailabilityCard__timeBox {
    min-width: 8.2rem;
    min-height: 11rem;
    padding: 10px 5px;
    margin-right: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    border-radius: 10px;
    background-color: #898989; }
  .UnavailabilityCard__cardBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .UnavailabilityCard__icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
    cursor: pointer; }
  .UnavailabilityCard__info {
    height: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.GeneralView {
  height: 100%;
  position: -webkit-sticky;
  position: sticky; }
  @media (max-width: 1096px) {
    .GeneralView {
      width: 100%; } }
  .GeneralView__title {
    margin: 3.9rem 0px 2.1rem; }
    @media (max-width: 1096px) {
      .GeneralView__title {
        margin: 2.5rem 0px 1.5rem; } }
  .GeneralView__topbar {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 5px; }
    @media (max-width: 1096px) {
      .GeneralView__topbar {
        margin-left: -15px;
        margin-right: -15px;
        border-radius: 0; } }
  .GeneralView__filterValue {
    margin: 0;
    font-size: 15px;
    color: #4B4B4B;
    line-height: 15px; }
    .GeneralView__filterValue--planValue {
      margin-top: 5px;
      font-size: 12px;
      color: #1F35B5;
      font-weight: bold;
      line-height: 12px; }
  .GeneralView__filterIcon {
    cursor: pointer;
    height: 24px; }
  .GeneralView__content {
    width: 100%; }
    .GeneralView__content__column {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .GeneralView__content__column:nth-child(odd) {
        margin-right: 20px; }
      .GeneralView__content__column > div {
        margin-top: 20px; }
    .GeneralView__content--cards {
      padding: 0;
      display: flex;
      justify-content: space-between; }
    .GeneralView__content--charts {
      padding: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center; }
      @media (max-width: 1096px) {
        .GeneralView__content--charts {
          flex-direction: column; } }

.FileCard {
  width: 100%;
  height: auto;
  padding: 20px;
  font-family: "Ubuntu";
  background: #ffffff;
  box-shadow: 0 1px 3px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .FileCard__Title {
    width: 100%;
    font-size: 18px;
    color: #4B4B4B;
    margin-bottom: 5px; }
  .FileCard__Subtitle {
    width: 100%;
    line-height: 12px;
    font-size: 12px;
    color: #898989;
    text-align: left;
    margin-bottom: 20px; }
  .FileCard__UploadArea, .FileCard__UploadArea--row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 130px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 1px dashed #A7A7A7;
    border-radius: 10px; }
    .FileCard__UploadArea--row {
      flex-direction: row; }
    .FileCard__UploadArea__Col {
      min-width: 40%;
      text-align: left;
      color: #4B4B4B;
      line-height: 18px; }
      .FileCard__UploadArea__Col:nth-child(odd) {
        min-width: 30%;
        text-align: center; }
        .FileCard__UploadArea__Col:nth-child(odd) span {
          display: block; }
      .FileCard__UploadArea__Col__LastUpdate {
        font-size: 12px; }
      .FileCard__UploadArea__Col__UpdateInfo {
        color: #898989;
        font-size: 12px;
        line-height: 15px;
        margin-top: 10px; }
    .FileCard__UploadArea__EmptyMessage {
      color: #898989;
      font-size: 15px;
      margin-bottom: 10px; }
    .FileCard__UploadArea__Button {
      padding: 10px;
      width: auto;
      font-size: 12px; }
  .FileCard__Downloadlink {
    display: none; }

.MyPatients {
  width: 100%;
  height: 100%; }
  .MyPatients__top {
    width: 100%;
    position: relative; }
  .MyPatients__title {
    margin-bottom: 1rem; }
    @media (max-width: 1096px) {
      .MyPatients__title {
        margin-bottom: 20px; } }
  .MyPatients__searchField {
    width: 332px;
    display: flex;
    align-items: center; }
    @media (max-width: 1096px) {
      .MyPatients__searchField {
        width: 100%;
        padding: 0 14px; } }
  .MyPatients__group {
    max-width: 244px;
    word-wrap: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .MyPatients__icon {
    color: #898989;
    margin-bottom: 17px; }
  .MyPatients__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start; }
    .MyPatients__content--centered {
      align-items: center;
      justify-content: center; }
  .MyPatients__list {
    width: 100%;
    display: grid;
    grid-template-columns: auto; }

.PlanGroupItem__Container {
  width: 100%;
  padding: 20px 10px 20px 14px;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  min-height: 110px; }

.PlanGroupItem__LogoContainer {
  width: 130px;
  height: 70px;
  border-radius: 5px;
  border: 1px solid #E2E2E2;
  padding: 3px; }
  .PlanGroupItem__LogoContainer > img {
    height: 100%;
    width: 100%; }

.PlanGroupItem__NameContainer {
  margin-left: 20px;
  font-size: 15px;
  color: #4b4b4b;
  font-weight: bold; }

.PlanGroupItem__ListItemMenu {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative; }
  .PlanGroupItem__ListItemMenu__FloatMenu {
    align-self: flex-start;
    display: block;
    position: absolute;
    left: -11.5rem;
    min-width: 14.8rem;
    top: 1.3rem;
    z-index: 1;
    margin-right: 0; }

.PlanGroupsList__header {
  width: 100%; }

.PlanGroupsList__filters {
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto; }
  @media (max-width: 1096px) {
    .PlanGroupsList__filters {
      width: 100%;
      display: flex;
      justify-content: center; } }

.PlanGroupsList__inputText {
  width: 220px; }
  @media (max-width: 1096px) {
    .PlanGroupsList__inputText {
      width: 100%; } }

.PlanGroupsList__headerButton {
  margin-bottom: 10px;
  margin-left: 17px; }
  .PlanGroupsList__headerButton button {
    display: flex;
    grid-gap: 12px;
    gap: 12px; }
  @media (max-width: 1096px) {
    .PlanGroupsList__headerButton {
      display: none; } }

.PlanGroupsList__buttonLabel {
  display: flex;
  align-items: center;
  padding: 0 5px; }
  .PlanGroupsList__buttonLabel > svg {
    margin-right: 10px; }

.PlanGroupsList__CompaniesContainer {
  width: 100%;
  background-color: #F5F5F5;
  height: 35px;
  display: flex;
  align-items: center; }

.PlanGroupsList__Companies {
  margin-left: 14px;
  color: #2d2d2d;
  font-size: 15px;
  font-weight: normal; }

.NewPlanGroup__Container {
  border-bottom: 1px solid #E2E2E2; }

.NewPlanGroup__InnerContainer {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 40px; }

.NewPlanGroup__CreditsRow {
  display: flex;
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid #E2E2E2;
  justify-content: flex-end; }
  @media (max-width: 1096px) {
    .NewPlanGroup__CreditsRow {
      flex-direction: column; } }

.NewPlanGroup__CreditsOptions {
  display: flex;
  width: 60%; }
  @media (max-width: 1096px) {
    .NewPlanGroup__CreditsOptions {
      flex-direction: column;
      width: 100%;
      align-items: flex-end; } }

.NewPlanGroup__addPlanButton {
  height: 35px;
  width: 172px;
  font-size: 12px;
  border: 1px solid #1F35B5;
  color: #1F35B5;
  background: #fff;
  border-radius: 5px; }
  @media (max-width: 1096px) {
    .NewPlanGroup__addPlanButton {
      margin-top: 20px; } }

.NewPlanGroup__PlanContainer {
  margin-top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  border-bottom: 1px solid #E2E2E2;
  margin-bottom: 20px; }
  @media (max-width: 1096px) {
    .NewPlanGroup__PlanContainer {
      flex-direction: column;
      grid-gap: 0;
      gap: 0;
      margin-bottom: 0; } }
  .NewPlanGroup__PlanContainer__PlanName {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 20px; }
    .NewPlanGroup__PlanContainer__PlanName__Fields {
      display: flex;
      grid-gap: 10px;
      gap: 10px;
      margin-bottom: 0; }
      .NewPlanGroup__PlanContainer__PlanName__Fields > div {
        max-width: 200px; }
      @media (max-width: 1096px) {
        .NewPlanGroup__PlanContainer__PlanName__Fields {
          flex-direction: column;
          grid-gap: 0;
          gap: 0; } }
  .NewPlanGroup__PlanContainer__Psychiatry {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }
  .NewPlanGroup__PlanContainer__MedicalReturn {
    grid-gap: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; }
    .NewPlanGroup__PlanContainer__MedicalReturn__Fields {
      display: flex;
      align-items: center; }
      .NewPlanGroup__PlanContainer__MedicalReturn__Fields > input {
        height: 48px;
        width: 60px;
        margin-right: 15px;
        border: 1px solid #C3C3C3;
        border-radius: 5px;
        text-align: center;
        color: #4b4b4b;
        font-size: 15px; }
      @media (max-width: 1096px) {
        .NewPlanGroup__PlanContainer__MedicalReturn__Fields {
          justify-content: end; } }

.NewPlanGroup__InputContainer {
  display: flex;
  align-items: center; }
  .NewPlanGroup__InputContainer > input {
    height: 48px;
    width: 60px;
    margin-right: 15px;
    border: 1px solid #C3C3C3;
    border-radius: 5px;
    text-align: center;
    color: #4b4b4b;
    font-size: 15px; }
  @media (max-width: 1096px) {
    .NewPlanGroup__InputContainer:last-child {
      margin-top: 20px; } }

.NewPlanGroup__DateContainer {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #C1C1C1;
  border-radius: 4px;
  padding: 12px 16px;
  width: 100%; }

.NewPlanGroup__RenewRow {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #E2E2E2; }

.NewPlanGroup__RenewCreditsRow {
  display: flex;
  padding: 20px;
  border-bottom: 1px; }

.NewPlanGroup__RenewTitle {
  display: flex;
  flex-direction: column;
  flex: 1 1; }
  .NewPlanGroup__RenewTitle > span {
    width: 100%;
    line-height: 15px;
    font-size: 12px;
    color: #898989;
    margin-right: 44px; }

.NewPlanGroup__RenewRadios {
  flex: 1.5 1;
  display: flex; }
  @media (max-width: 1096px) {
    .NewPlanGroup__RenewRadios {
      flex: 1 1;
      justify-content: flex-end; } }

.NewPlanGroup__BottomContainer {
  margin-top: 30px; }

.NewPlanGroup__Button {
  margin-top: 26px;
  margin-bottom: 40px;
  width: 384px;
  height: 48px;
  text-align: center; }
  @media (max-width: 1096px) {
    .NewPlanGroup__Button {
      width: 100%; } }

.row__addPlanRow {
  flex-flow: row !important;
  grid-gap: 10px;
  gap: 10px; }

.row__addPlanX {
  cursor: pointer;
  display: block;
  width: 20px;
  height: 2px;
  background: #868585;
  position: relative;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin: 24px 0 0 10px; }
  .row__addPlanX::before {
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    background: #868585;
    position: relative;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

.AdminReservations {
  margin-top: 40px;
  display: flex;
  flex-direction: column; }
  .AdminReservations__spinnerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px; }
  .AdminReservations__header {
    display: flex; }
    .AdminReservations__header__mobile {
      width: 100%;
      flex-direction: column; }
  .AdminReservations__professionalContainer {
    margin-left: 20px; }
    .AdminReservations__professionalContainer__mobile {
      margin-left: 0;
      margin-top: 20px; }
  .AdminReservations__professional {
    display: flex; }
    .AdminReservations__professional__description {
      margin-left: 10px; }
  .AdminReservations__content {
    margin-top: 25px; }

.app {
  background: #FFFFFF; }
  .app--gradient {
    background: linear-gradient(#F5F5F5 10%, #FFFFFF 20%); }
    @media (max-width: 1096px) {
      .app--gradient {
        background: #FFFFFF; } }
  .app--grey {
    background: #F5F5F5; }

a:hover {
  color: #8E99D9; }

@charset "UTF-8";
/* Google Fonts*/
/* React Day Picker */
/* DayPicker styles */
.DayPicker, .DayPicker-wrapper, .DayPicker-Month {
  display: inline-block;
  font-size: 1rem; }

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  -webkit-user-select: none;
  user-select: none; }

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  user-select: none; }

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer; }

.DayPicker-NavButton:hover {
  opacity: 0.8; }

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC"); }

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg=="); }

.DayPicker-NavButton--interactionDisabled {
  display: none; }

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left; }

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em; }

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em; }

.DayPicker-WeekdaysRow {
  display: table-row; }

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em; }

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none; }

.DayPicker-Body {
  display: table-row-group; }

.DayPicker-Week {
  display: table-row; }

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer; }

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer; }

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default; }

.DayPicker-Footer {
  padding-top: 0.5em; }

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer; }

/* Default modifiers */
.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700; }

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default; }

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */ }

/* Example modifiers */
.DayPicker-Day--sunday {
  background-color: #F7F8F8; }

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #4A90E2;
  color: #F0F8FF; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA; }

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover, .DayPicker-wrapper:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover, .DayPicker-Month:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF; }

/* DayPickerInput */
.DayPickerInput {
  display: inline-block; }

.DayPickerInput-OverlayWrapper {
  position: relative; }

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); }

/* colors */
/* viewports */
/* grid
 * $gutter is relative to 1 column width */
/* Font Size */
/* Spacing */
/* Radius */
*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  color: #4b4b4b;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.2rem; }

button {
  cursor: pointer; }

button:disabled {
  cursor: default; }

a {
  color: inherit;
  text-decoration: none;
  width: inherit; }

h1,
h2,
h4,
h5,
h6,
ul {
  padding: 0;
  margin: 0; }
  h1 li,
  h2 li,
  h4 li,
  h5 li,
  h6 li,
  ul li {
    list-style: none; }

/* Fonts */
/* Borders */
/* Media Query */
/* Shadows */
/* Grids and Columns */
/* Colors */
.Accordion {
  width: 100%; }
  .Accordion__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between; }
    .Accordion__header__text {
      max-width: 92%; }
  .Accordion__body {
    margin-top: 2rem; }

.AgendaListItem, .AgendaListItem--default, .AgendaListItem--cancelled, .AgendaListItem--scheduled, .AgendaListItem--attending, .AgendaListItem--attended, .AgendaListItem--not-attended, .AgendaListItem--personal {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem 1.7rem 1rem 1rem;
  border-radius: 1rem !important;
  border-radius: 0.5rem;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12); }
  .AgendaListItem--cancelled {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #ebebeb;
    box-shadow: none; }
  .AgendaListItem--scheduled {
    font-family: "Ubuntu";
    font-weight: 400; }
  .AgendaListItem--attending {
    font-family: "Ubuntu";
    font-weight: 400; }
  .AgendaListItem--attended {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #ebebeb;
    box-shadow: none; }
  .AgendaListItem--not-attended {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #ebebeb;
    box-shadow: none; }
  .AgendaListItem--personal {
    font-family: "Ubuntu";
    font-weight: 400; }
  .AgendaListItem__TimeBox {
    display: flex;
    margin-right: 1.4rem; }
  .AgendaListItem__Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 10rem; }
    .AgendaListItem__Content__extra-info {
      display: flex;
      flex-direction: column; }
      @media (min-width: 1096px) {
        .AgendaListItem__Content__extra-info {
          display: flex;
          margin-top: 1rem;
          line-height: 1.8rem; } }
    .AgendaListItem__Content__info {
      display: flex;
      justify-content: space-between;
      flex-direction: column; }
      .AgendaListItem__Content__info__patient .paragraph {
        font-size: 5rem !important; }
      @media (min-width: 1096px) {
        .AgendaListItem__Content__info {
          display: flex;
          line-height: 1.8rem; } }
    .AgendaListItem__Content__message {
      display: flex;
      justify-content: flex-start;
      flex-direction: row; }
    .AgendaListItem__Content__Status {
      display: flex;
      justify-content: flex-start;
      flex-direction: row; }
    .AgendaListItem__Content__data {
      display: flex;
      flex-direction: row; }
      .AgendaListItem__Content__data__plan-full-name {
        margin-left: 2rem; }
  .AgendaListItem__menuButton {
    cursor: pointer;
    display: flex;
    flex: 2 1;
    align-items: flex-end;
    justify-content: flex-end; }
  .AgendaListItem__emptyCard {
    padding: 15px;
    border: 1px dashed #000;
    border-radius: 5px;
    background-color: #e3e3e3; }

.AlertIcon {
  align-items: center;
  display: flex;
  justify-content: center; }
  .AlertIcon__Icon {
    padding: 0 0.5rem 0 1rem; }
  .AlertIcon__Text {
    padding: 0 1rem 0 0.5rem; }

.Avatar {
  display: block;
  height: 100%;
  height: 9.2rem;
  width: 9.2rem;
  position: relative;
  width: 100%; }
  .Avatar__image {
    background-image: url("/static/avatar.png");
    background-size: cover;
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    justify-items: center;
    overflow: hidden;
    position: relative;
    width: auto; }
    .Avatar__image img {
      display: inline;
      height: 100%;
      margin: 0 auto;
      width: auto; }
  .Avatar__status, .Avatar__status--busy, .Avatar__status--online {
    border: 3px solid #ffffff;
    border-radius: 50%;
    display: block;
    height: 25px;
    position: absolute;
    right: -3px;
    top: -3px;
    width: 25px;
    z-index: 1; }
    .Avatar__status--busy {
      background-color: red; }
    .Avatar__status--offline {
      display: none; }
    .Avatar__status--online {
      background-color: #55d86b; }

.ApproachCard {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  flex-direction: column;
  margin-bottom: 30px; }
  .ApproachCard__Description {
    line-height: 22px !important; }

.BodyContent {
  align-content: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%; }
  .BodyContent__Container {
    height: 100%;
    width: 30rem; }
    @media (min-width: 1096px) {
      .BodyContent__Container {
        width: 108.7rem; } }

.BoxBgRounded {
  border-radius: 0.5rem;
  padding: 3rem;
  background-color: #f5f5f5; }
  .BoxBgRounded.secondary-lighter {
    background-color: #d3f5f6; }
  .BoxBgRounded__row {
    margin-bottom: 2rem;
    line-height: 2rem; }
    .BoxBgRounded__row:last-of-type {
      margin: 0; }

.BoxDateDisplay, .BoxDateDisplay--default, .BoxDateDisplay--cancelled, .BoxDateDisplay--scheduled, .BoxDateDisplay--attending, .BoxDateDisplay--attended, .BoxDateDisplay--not-attended {
  font-family: "Ubuntu";
  font-weight: 700;
  border: none;
  border-radius: 1rem;
  height: 11rem;
  text-align: center;
  width: 8.2rem;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0.6rem; }
  .BoxDateDisplay--cancelled {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #e0e0e0; }
  .BoxDateDisplay--scheduled {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #4ac326; }
  .BoxDateDisplay--attending {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #F9A435; }
  .BoxDateDisplay--attended {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #e0e0e0; }
  .BoxDateDisplay--not-attended {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #e0e0e0; }
  .BoxDateDisplay .footer, .BoxDateDisplay--default .footer, .BoxDateDisplay--cancelled .footer, .BoxDateDisplay--scheduled .footer, .BoxDateDisplay--attending .footer, .BoxDateDisplay--attended .footer, .BoxDateDisplay--not-attended .footer {
    display: flex;
    flex-direction: column;
    letter-spacing: -0.1rem;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    .BoxDateDisplay .footer__icon, .BoxDateDisplay--default .footer__icon, .BoxDateDisplay--cancelled .footer__icon, .BoxDateDisplay--scheduled .footer__icon, .BoxDateDisplay--attending .footer__icon, .BoxDateDisplay--attended .footer__icon, .BoxDateDisplay--not-attended .footer__icon {
      display: flex;
      justify-content: space-around; }
    .BoxDateDisplay .footer__text, .BoxDateDisplay--default .footer__text, .BoxDateDisplay--cancelled .footer__text, .BoxDateDisplay--scheduled .footer__text, .BoxDateDisplay--attending .footer__text, .BoxDateDisplay--attended .footer__text, .BoxDateDisplay--not-attended .footer__text {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      color: #ffffff;
      letter-spacing: -0.1rem;
      line-height: 1.3rem; }
  .BoxDateDisplay .header, .BoxDateDisplay--default .header, .BoxDateDisplay--cancelled .header, .BoxDateDisplay--scheduled .header, .BoxDateDisplay--attending .header, .BoxDateDisplay--attended .header, .BoxDateDisplay--not-attended .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center; }
    .BoxDateDisplay .header__time, .BoxDateDisplay--default .header__time, .BoxDateDisplay--cancelled .header__time, .BoxDateDisplay--scheduled .header__time, .BoxDateDisplay--attending .header__time, .BoxDateDisplay--attended .header__time, .BoxDateDisplay--not-attended .header__time {
      display: flex;
      justify-content: center; }

.Breadcrumb {
  font-family: "Ubuntu";
  font-weight: 400;
  margin: 2.0rem 0 0;
  width: 100%; }
  .Breadcrumb__item {
    color: #acacac;
    cursor: pointer;
    font-family: "Ubuntu";
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }
    .Breadcrumb__item::before {
      color: #acacac;
      content: '>';
      margin: 0 0.3rem 0.2rem; }
    .Breadcrumb__item:first-child::before {
      content: '';
      margin: 0; }
    .Breadcrumb__item:last-child {
      font-family: "Ubuntu";
      font-weight: 700; }
      .Breadcrumb__item:last-child::before {
        font-family: "Ubuntu";
        font-weight: 400; }
    .Breadcrumb__item:hover {
      color: #c4c4c4; }
      .Breadcrumb__item:hover::before {
        color: #c4c4c4; }

.Button, .Button--default, .Button--reverse, .Button--reverse--grey, .Button--reverse--grey--lighter, .Button--reverse--grey--padded, .Button--online, .Button--false {
  font-family: "Ubuntu";
  font-weight: 700;
  border: none;
  border-radius: 0.5rem;
  background-color: #1f35b5;
  color: #ffffff;
  font-size: 1.5rem;
  padding: 15px 20px 16px;
  text-align: center;
  transition: 0.4s;
  width: 100%; }
  .Button span, .Button--default span, .Button--reverse span, .Button--reverse--grey span, .Button--reverse--grey--lighter span, .Button--reverse--grey--padded span, .Button--online span, .Button--false span {
    font-family: "Ubuntu";
    font-weight: 400;
    padding-left: 1rem; }
  .Button:focus, .Button--default:focus, .Button--reverse:focus, .Button--reverse--grey:focus, .Button--reverse--grey--lighter:focus, .Button--reverse--grey--padded:focus, .Button--online:focus, .Button--false:focus {
    outline: none; }
  .Button:hover, .Button--default:hover, .Button--reverse:hover, .Button--reverse--grey:hover, .Button--reverse--grey--lighter:hover, .Button--reverse--grey--padded:hover, .Button--online:hover, .Button--false:hover, .Button:active, .Button--default:active, .Button--reverse:active, .Button--reverse--grey:active, .Button--reverse--grey--lighter:active, .Button--reverse--grey--padded:active, .Button--online:active, .Button--false:active {
    background-color: #172888; }
  .Button:disabled, .Button--default:disabled, .Button--reverse:disabled, .Button--reverse--grey:disabled, .Button--reverse--grey--lighter:disabled, .Button--reverse--grey--padded:disabled, .Button--online:disabled, .Button--false:disabled {
    background-color: #ebebeb;
    border: none;
    color: #c1c1c1; }
  .Button--reverse, .Button--reverse--grey, .Button--reverse--grey--lighter, .Button--reverse--grey--padded {
    font-family: "Ubuntu";
    font-weight: 700;
    background-color: #ffffff;
    border: 0.1rem solid #1f35b5;
    color: #1f35b5;
    padding: 15px 20px 14px; }
    .Button--reverse:hover, .Button--reverse--grey:hover, .Button--reverse--grey--lighter:hover, .Button--reverse--grey--padded:hover {
      background-color: #f6f6f6; }
    .Button--reverse--grey, .Button--reverse--grey--lighter, .Button--reverse--grey--padded {
      background-color: #ffffff;
      border: 0.1rem solid #4b4b4b;
      color: #4b4b4b; }
      .Button--reverse--grey--lighter {
        border: 0.1rem solid #c4c4c4;
        color: #c4c4c4; }
      .Button--reverse--grey--padded {
        padding: 1rem 1.7rem 1.2rem;
        height: auto; }
  .Button--online {
    background-color: #4ac326;
    color: #ffffff; }
    .Button--online:hover {
      background-color: #3a981e; }
  .Button--false {
    background-color: #eb384d;
    color: #ffffff; }
    .Button--false:hover:not(:disabled) {
      background-color: #ac1123; }

.DayPickerInput {
  width: 100%; }
  .DayPickerInput input {
    background-color: #ffffff;
    background-image: url("/static/icon-calendar.svg");
    background-position: 96% 50%;
    background-repeat: no-repeat;
    background-size: 2.3rem;
    border: 0.1rem solid #dedede;
    border-radius: 0.5rem;
    color: #4b4b4b;
    cursor: pointer;
    font-family: "Ubuntu";
    font-size: 1.5rem;
    height: 100%;
    padding: 1.5rem;
    text-align: left;
    width: 100%; }
  .DayPickerInput input::-webkit-input-placeholder {
    color: #4b4b4b;
    opacity: 1; }
  .DayPickerInput input::placeholder {
    color: #4b4b4b;
    opacity: 1; }
  .DayPickerInput-Overlay {
    min-height: 35rem;
    text-align: center;
    width: 100%; }

.Card, .Card--default, .Card--darkenBackground, .Card--yellowBackground, .Card--scrollable {
  background-color: #ffffff;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  height: auto;
  padding: 15px;
  position: relative;
  width: 100%;
  z-index: 1; }
  .Card--darkenBackground {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }
  .Card--yellowBackground {
    background-color: #ffffb5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    padding: 1.5rem 2rem; }
  .Card--scrollable {
    max-height: 100vh;
    overflow-y: auto; }

.CardEmptyPlan {
  align-items: center;
  border: 0.1rem dashed #c1c1c1;
  border-radius: 1rem;
  display: flex;
  height: 9.5rem;
  justify-content: center;
  width: 100%; }

.CardMedicalRequest, .CardMedicalRequest--open {
  border-bottom: 0.1rem solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  padding: 2rem 1.6rem 2rem 1.8rem;
  transition: 0.3s; }
  .CardMedicalRequest--invalid {
    text-decoration: line-through; }
  .CardMedicalRequest:first-of-type, .CardMedicalRequest--open:first-of-type {
    border-top: 0.1rem solid #e2e2e2; }
  .CardMedicalRequest--open {
    background-color: #f5f5f5; }
  .CardMedicalRequest__insideGroup {
    margin-top: 10px; }
  .CardMedicalRequest--bold {
    font-weight: bold; }
  .CardMedicalRequest__Data {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 1096px) {
      .CardMedicalRequest__Data {
        display: flex;
        flex-direction: row; } }
    .CardMedicalRequest__Data__Block {
      line-height: 1.6rem;
      margin: 0.75rem 0 0; }
      .CardMedicalRequest__Data__Block:first-of-type {
        margin-top: 0; }
      .CardMedicalRequest__Data__Block:last-of-type {
        margin-bottom: 0;
        margin: 0; }
      .CardMedicalRequest__Data__Block__Name {
        width: 32.5rem;
        margin-right: 1rem; }
        .CardMedicalRequest__Data__Block__Name p {
          padding-bottom: 3px; }
      .CardMedicalRequest__Data__Block__Tag {
        margin-top: 1rem;
        margin-bottom: 1.5rem; }
        @media (min-width: 1096px) {
          .CardMedicalRequest__Data__Block__Tag {
            margin-bottom: 0; } }
        .CardMedicalRequest__Data__Block__Tag__Border, .CardMedicalRequest__Data__Block__Tag__Border--negative, .CardMedicalRequest__Data__Block__Tag__Border--neutral, .CardMedicalRequest__Data__Block__Tag__Border--positive, .CardMedicalRequest__Data__Block__Tag__Border--done {
          border-radius: 0.5rem;
          padding: 0.3rem 0.7rem;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; }
          .CardMedicalRequest__Data__Block__Tag__Border--negative {
            background-color: #eb384d; }
          .CardMedicalRequest__Data__Block__Tag__Border--neutral {
            background-color: #F9A435; }
          .CardMedicalRequest__Data__Block__Tag__Border--positive {
            background-color: #55d86b; }
          .CardMedicalRequest__Data__Block__Tag__Border--done {
            background-color: #4b4b4b; }
      .CardMedicalRequest__Data__Block__Role {
        padding-bottom: 3px; }
      .CardMedicalRequest__Data__Block__Data, .CardMedicalRequest__Data__Block__Data--spaced {
        display: flex; }
        .CardMedicalRequest__Data__Block__Data--spaced {
          margin-top: 1.3rem; }
        .CardMedicalRequest__Data__Block__Data__Label {
          margin-right: 1rem;
          width: 9rem;
          padding-bottom: 3px; }
        .CardMedicalRequest__Data__Block__Data__Actions {
          display: flex;
          grid-gap: 20px;
          gap: 20px; }
  .CardMedicalRequest__Menu {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: relative; }
    .CardMedicalRequest__Menu__FloatMenu {
      align-self: flex-start;
      display: block;
      position: absolute;
      left: -13.5rem;
      min-width: 14.8rem;
      top: 50%;
      z-index: 1; }
    .CardMedicalRequest__Menu__Icon {
      height: 45px;
      width: 45px;
      cursor: pointer; }

.CardPlan {
  align-items: center;
  border-radius: 1rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 9.5rem;
  margin: 1rem 0;
  padding: 0 1.5rem 0 1rem;
  width: 100%; }
  .CardPlan:first-of-type {
    margin-top: 0; }
  .CardPlan:last-of-type {
    margin-bottom: 0; }
  .CardPlan__Logo {
    align-items: center;
    border-radius: 0.5rem;
    border: 0.1rem solid #c1c1c1;
    display: flex;
    height: 7.5rem !important;
    justify-content: center;
    width: 7.5rem !important; }
    .CardPlan__Logo__Image {
      max-width: 100%;
      max-height: 100%; }
  .CardPlan__Center {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 1rem; }
    @media (min-width: 1096px) {
      .CardPlan__Center {
        align-items: center;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between; } }
    .CardPlan__Center__PlanData {
      line-height: 1.7rem;
      margin-bottom: 0.5rem; }
      .CardPlan__Center__PlanData__Status, .CardPlan__Center__PlanData__Status--positive, .CardPlan__Center__PlanData__Status--neutral, .CardPlan__Center__PlanData__Status--negative {
        align-items: center;
        display: flex; }
        .CardPlan__Center__PlanData__Status--positive {
          color: #4ac326; }
          .CardPlan__Center__PlanData__Status--positive svg {
            margin-right: 0.5rem;
            fill: #4ac326; }
        .CardPlan__Center__PlanData__Status--neutral {
          color: #F9A435; }
          .CardPlan__Center__PlanData__Status--neutral svg {
            margin-right: 0.5rem;
            fill: #F9A435; }
        .CardPlan__Center__PlanData__Status--negative {
          color: #eb384d; }
          .CardPlan__Center__PlanData__Status--negative svg {
            margin-right: 0.5rem;
            fill: #eb384d; }
    .CardPlan__Center__Professionals {
      cursor: pointer; }
  @media (min-width: 1096px) {
    .CardPlan__Menu {
      width: 8.4rem; } }

.CartItem {
  align-items: center;
  border-bottom: 0.1rem solid #e2e2e2;
  color: #4b4b4b;
  display: flex;
  padding: 1.45rem 0; }
  .CartItem__body {
    align-items: center;
    border-left: 0.2rem solid #1f35b5;
    color: #4b4b4b;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%; }
  .CartItem__delete {
    cursor: pointer; }
  .CartItem:last-child {
    border-bottom: none;
    padding-bottom: 0; }
  .CartItem:first-child {
    padding-top: 0; }

.CartPlansCredit {
  background-color: #ffffb5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 2rem;
  position: relative;
  width: 100%;
  z-index: 1; }
  .CartPlansCredit__credit--header {
    width: 80%; }
  .CartPlansCredit__credit--info {
    color: #4ac326;
    width: 20%; }

.CartSummary {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .CartSummary__header {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .CartSummary__items {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 100%; }
  .CartSummary__totals {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    width: 100%; }
    .CartSummary__totals__header__value {
      display: flex;
      justify-content: space-between;
      width: 100%; }
    .CartSummary__totals__final__value {
      display: flex;
      justify-content: space-between;
      width: 100%; }
    .CartSummary__totals__separator {
      background-color: #c4c4c4;
      height: 0.2rem;
      margin: 1rem 0;
      width: 100%; }

.CheckBox {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-direction: row; }
  .CheckBox__icon {
    align-items: center;
    display: flex;
    margin-right: 1rem; }
    .CheckBox__icon svg {
      fill: #1f35b5; }
  .CheckBox__text {
    cursor: initial; }
    .CheckBox__text.disabled {
      color: #c4c4c4; }

.ClearFilters {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%; }
  .ClearFilters__SideLine {
    background-color: #e2e2e2;
    height: 0.1rem;
    width: 100%; }
    .ClearFilters__SideLine:first-child {
      margin-right: 2rem; }
    .ClearFilters__SideLine:last-child {
      margin-left: 2rem; }
  .ClearFilters__TextArea {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    white-space: nowrap; }
    .ClearFilters__TextArea p {
      margin-left: 1rem; }

.ClearSearch {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%; }
  .ClearSearch__SideLine {
    background-color: #e2e2e2;
    height: 0.1rem;
    width: 100%; }
    .ClearSearch__SideLine:first-child {
      margin-right: 10px; }
    .ClearSearch__SideLine:last-child {
      margin-left: 10px; }
  .ClearSearch__TextArea {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    white-space: nowrap; }
    .ClearSearch__TextArea p {
      margin-left: 1rem; }

.col {
  width: 100%;
  box-sizing: border-box; }

.right-offset {
  margin-right: 10px !important; }

@media (min-width: 1096px) {
  .col:first-child {
    margin-left: 0; }
  .one.col {
    width: 6.80556%; }
    .one.col.left-offset {
      margin-left: 1.66667%; }
    .one.col.right-offset {
      margin-right: 1.66667%; }
    .one.col:first-child {
      margin-left: 0; }
      .one.col:first-child:last-child {
        width: 8.33333%; }
  .two.col {
    width: 15.27778%; }
    .two.col.left-offset {
      margin-left: 1.66667%; }
    .two.col.right-offset {
      margin-right: 1.66667%; }
    .two.col:first-child {
      margin-left: 0; }
      .two.col:first-child:last-child {
        width: 16.66667%; }
  .three.col {
    width: 23.75%; }
    .three.col.left-offset {
      margin-left: 1.66667%; }
    .three.col.right-offset {
      margin-right: 1.66667%; }
    .three.col:first-child {
      margin-left: 0; }
      .three.col:first-child:last-child {
        width: 25%; }
  .four.col {
    width: 32.22222%; }
    .four.col.left-offset {
      margin-left: 1.66667%; }
    .four.col.right-offset {
      margin-right: 1.66667%; }
    .four.col:first-child {
      margin-left: 0; }
      .four.col:first-child:last-child {
        width: 33.33333%; }
  .five.col {
    width: 40.69444%; }
    .five.col.left-offset {
      margin-left: 1.66667%; }
    .five.col.right-offset {
      margin-right: 1.66667%; }
    .five.col:first-child {
      margin-left: 0; }
      .five.col:first-child:last-child {
        width: 41.66667%; }
  .six.col {
    width: 49.16667%; }
    .six.col.left-offset {
      margin-left: 1.66667%; }
    .six.col.right-offset {
      margin-right: 1.66667%; }
    .six.col:first-child {
      margin-left: 0; }
      .six.col:first-child:last-child {
        width: 50%; }
  .seven.col {
    width: 57.63889%; }
    .seven.col.left-offset {
      margin-left: 1.66667%; }
    .seven.col.right-offset {
      margin-right: 1.66667%; }
    .seven.col:first-child {
      margin-left: 0; }
      .seven.col:first-child:last-child {
        width: 58.33333%; }
  .eight.col {
    width: 66.11111%; }
    .eight.col.left-offset {
      margin-left: 1.66667%; }
    .eight.col.right-offset {
      margin-right: 1.66667%; }
    .eight.col:first-child {
      margin-left: 0; }
      .eight.col:first-child:last-child {
        width: 66.66667%; }
  .nine.col {
    width: 74.58333%; }
    .nine.col.left-offset {
      margin-left: 1.66667%; }
    .nine.col.right-offset {
      margin-right: 1.66667%; }
    .nine.col:first-child {
      margin-left: 0; }
      .nine.col:first-child:last-child {
        width: 75%; }
  .ten.col {
    width: 83.05556%; }
    .ten.col.left-offset {
      margin-left: 1.66667%; }
    .ten.col.right-offset {
      margin-right: 1.66667%; }
    .ten.col:first-child {
      margin-left: 0; }
      .ten.col:first-child:last-child {
        width: 83.33333%; }
  .eleven.col {
    width: 91.52778%; }
    .eleven.col.left-offset {
      margin-left: 1.66667%; }
    .eleven.col.right-offset {
      margin-right: 1.66667%; }
    .eleven.col:first-child {
      margin-left: 0; }
      .eleven.col:first-child:last-child {
        width: 91.66667%; }
  .twelve.col {
    width: 100%; }
    .twelve.col.left-offset {
      margin-left: 1.66667%; }
    .twelve.col.right-offset {
      margin-right: 1.66667%; }
    .twelve.col:first-child {
      margin-left: 0; }
      .twelve.col:first-child:last-child {
        width: 100%; }
  .mansory-col {
    break-inside: avoid-region !important; } }

.ConsultationCard, .ConsultationCard--cardColor {
  background-color: #ffffff;
  border-color: #898989;
  border-radius: 1rem !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 13rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12); }
  @media (min-width: 1096px) {
    .ConsultationCard, .ConsultationCard--cardColor {
      height: 13rem; } }
  @media (min-width: 1096px) {
    .ConsultationCard__proDocument {
      display: flex; } }
  .ConsultationCard--cardColor {
    background-color: #ffffb5; }
  .ConsultationCard__Cardcenter {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 1.5rem;
    margin-right: 2rem; }
    @media (min-width: 1096px) {
      .ConsultationCard__Cardcenter {
        flex-direction: row;
        justify-content: space-around; } }
    .ConsultationCard__Cardcenter .Cardcenter__Psycologist {
      display: flex;
      flex-direction: column;
      line-height: 1.6rem;
      text-align: left;
      width: 14.5rem;
      height: 3.3rem; }
      @media (min-width: 1096px) {
        .ConsultationCard__Cardcenter .Cardcenter__Psycologist {
          flex-direction: row;
          align-items: center;
          width: 20.5rem;
          height: auto;
          text-align: center;
          flex: 2 1; } }
      .ConsultationCard__Cardcenter .Cardcenter__Psycologist .avatar {
        width: 40px;
        height: 40px;
        display: none; }
        @media (min-width: 1096px) {
          .ConsultationCard__Cardcenter .Cardcenter__Psycologist .avatar {
            width: 65px;
            height: 65px;
            display: block; } }
      @media (min-width: 1096px) {
        .ConsultationCard__Cardcenter .Cardcenter__Psycologist .pinfo {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          padding: 2rem; } }
      .ConsultationCard__Cardcenter .Cardcenter__Psycologist__Name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden; }
    .ConsultationCard__Cardcenter .info {
      margin-top: 2rem; }
      @media (min-width: 1096px) {
        .ConsultationCard__Cardcenter .info {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 4rem;
          padding-right: 1rem; } }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ConsultationCard__Cardcenter .info__plan {
          margin-top: 2rem;
          margin-right: 15px;
          line-height: 1rem; } }
      @media (min-width: 1096px) {
        .ConsultationCard__Cardcenter .info__plan {
          margin-right: 30px; } }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ConsultationCard__Cardcenter .info__id {
          line-height: 2rem;
          margin-right: 0.8rem; } }
      @media (min-width: 1096px) {
        .ConsultationCard__Cardcenter .info__id {
          margin-right: 30px; } }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ConsultationCard__Cardcenter .info__price {
          line-height: 0.8rem; } }
      .ConsultationCard__Cardcenter .info__price span {
        margin-left: 0.08rem; }
  .ConsultationCard__Icon {
    align-items: center;
    display: flex;
    justify-content: center; }
  .ConsultationCard__NoIcon {
    margin-right: 24px; }
  .ConsultationCard__Notification {
    align-items: center;
    display: flex;
    justify-content: center; }

.ConsultationLists {
  align-items: flex-start;
  border-top: 0.1rem solid #e2e2e2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1;
  padding: 1.4rem 0;
  width: 100%; }
  @media (min-width: 1096px) {
    .ConsultationLists {
      align-items: center;
      padding: 1.4rem; } }
  .ConsultationLists__content {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
    width: 100%; }
    @media (min-width: 1096px) {
      .ConsultationLists__content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center; } }
    .ConsultationLists__content__participants .content__psychologist {
      margin-bottom: 1rem; }
  .ConsultationLists__info {
    display: grid;
    grid-template-rows: auto auto;
    margin-top: 10px; }
    @media (min-width: 1096px) {
      .ConsultationLists__info {
        width: 25rem;
        justify-content: space-between;
        align-items: center;
        margin-right: 6rem;
        margin-top: 0; } }
    .ConsultationLists__info__id {
      display: flex;
      line-height: 1.2;
      min-width: 45px;
      width: auto; }
    .ConsultationLists__info__session {
      display: flex;
      line-height: 1.2;
      margin-left: 1rem;
      width: auto; }
  .ConsultationLists__subInfo {
    display: flex;
    align-items: flex-start; }
    .ConsultationLists__subInfo--spaced {
      margin-top: 5px; }
  .ConsultationLists__BadgeWrapper {
    margin-bottom: 10px; }
  .ConsultationLists__DateDisplayerWrapper {
    width: 75px;
    height: 100%; }
  .ConsultationLists__DateDisplayer {
    height: 93px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ConsultationLists__DateDisplayer {
        height: 100% !important; } }

.menuButton {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  max-height: 10rem;
  cursor: pointer; }
  .menuButton__NoIcon {
    min-width: 7.3rem; }
  .menuButton__Link {
    cursor: pointer; }

.ConsultationList {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: 1.8rem;
  padding-left: 1.8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #ffffff;
  border-color: #898989;
  border-radius: 1rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12); }
  @media (min-width: 1096px) {
    .ConsultationList {
      padding-right: 1.8rem;
      padding-left: 1.8rem;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  .ConsultationList__icon {
    display: flex;
    width: 20%;
    justify-content: flex-start;
    align-items: flex-start; }
    .ConsultationList__icon Button {
      height: 3.5rem;
      width: 100%;
      padding: 0 auto; }
      @media (min-width: 1096px) {
        .ConsultationList__icon Button {
          width: 100%; } }
  .ConsultationList__data {
    display: flex;
    width: 80% !important;
    justify-content: space-between;
    flex-direction: column; }
    @media (min-width: 1096px) {
      .ConsultationList__data {
        width: 100% !important; } }
    .ConsultationList__data__name {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      line-height: 1.9rem;
      width: 100%; }
    .ConsultationList__data__paymentStatus {
      display: flex;
      width: 70%;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 1.6rem;
      align-items: flex-start;
      color: #4b4b4b; }
      @media (min-width: 1096px) {
        .ConsultationList__data__paymentStatus {
          display: flex;
          width: 70%;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row; } }
      .ConsultationList__data__paymentStatus__price {
        display: flex;
        width: 23rem;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0.5rem; }
        @media (min-width: 1096px) {
          .ConsultationList__data__paymentStatus__price {
            width: 29rem;
            margin: 0 !important; } }
        .ConsultationList__data__paymentStatus__price__width {
          display: flex;
          width: 32%; }
          @media (min-width: 1096px) {
            .ConsultationList__data__paymentStatus__price__width {
              width: 100%; } }
    .ConsultationList__data__paymentFooter {
      display: flex;
      width: 100%;
      color: #4b4b4b; }
      @media (min-width: 1096px) {
        .ConsultationList__data__paymentFooter {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          margin-left: 3rem; } }
      .ConsultationList__data__paymentFooter__tags {
        display: flex;
        margin-right: 1rem; }
        @media (min-width: 1096px) {
          .ConsultationList__data__paymentFooter__tags {
            width: 100%;
            margin: 0; } }
      .ConsultationList__data__paymentFooter__id {
        display: flex;
        margin-left: 1rem; }
        @media (min-width: 1096px) {
          .ConsultationList__data__paymentFooter__id {
            width: 100%;
            margin: 0; } }

.container, .container--mobile-full, .container--min-height {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  width: 100%; }
  .container.view, .view.container--mobile-full, .view.container--min-height {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .container--mobile-full {
    padding: 0; }
  .container--min-height {
    min-height: calc(100vh - 450px); }

@media (min-width: 1096px) {
  .container, .container--mobile-full, .container--min-height {
    padding: 0;
    width: 1096px; } }

path {
  transition: 250ms; }

.DashboardFilters {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center; }
  @media (min-width: 1096px) {
    .DashboardFilters {
      justify-content: flex-end; } }
  .DashboardFilters__Input {
    width: 100%; }
    @media (min-width: 1096px) {
      .DashboardFilters__Input {
        justify-content: auto; } }
  .DashboardFilters__Button {
    cursor: pointer;
    margin-bottom: 1rem;
    width: 3.5rem; }

.dropdown, .dropdown-error {
  height: auto;
  position: relative;
  min-width: 19.5rem; }
  .dropdown__toggle:focus {
    outline: none; }
    .dropdown__toggle:focus .dropdown--box, .dropdown__toggle:focus .dropdown--box-open, .dropdown__toggle:focus .dropdown-disabled {
      border-color: #1f35b5; }
  .dropdown-error .dropdown--box, .dropdown-error .dropdown--box-open, .dropdown-error .dropdown-disabled {
    border-color: #eb384d; }
  .dropdown--box, .dropdown--box-open, .dropdown-disabled {
    align-items: center;
    background-color: #ffffff;
    background-position: calc(100% - 15px) center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    border: 0.1rem solid #c1c1c1;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #c4c4c4;
    display: flex;
    font-size: 1.5rem;
    justify-content: space-between;
    padding: 1.2rem 1.4rem;
    text-align: left; }
    .dropdown--box--disabled {
      background-color: #F5F5F5;
      color: #acacac;
      border: 0.1rem solid #dedede;
      cursor: not-allowed; }
    .dropdown--box-open {
      border-color: #dedede; }
  .dropdown-disabled {
    background-color: #F5F5F5;
    border: 0.1rem solid #acacac;
    color: #acacac;
    cursor: not-allowed; }
  .dropdown--option-list {
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.5rem #dedede, -0.1rem -0.1rem 0.1rem #dedede;
    color: #1f35b5;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    display: none;
    left: 0;
    right: 0;
    min-height: 48px;
    max-height: 160px; }
    .dropdown--option-list.open {
      display: block; }
  .dropdown--overflow {
    overflow: auto; }
    .dropdown--overflow .dropdown--option-item {
      padding: 1.5rem;
      text-align: left; }
  .dropdown--option-item {
    color: #4b4b4b;
    cursor: pointer;
    padding: 1.5rem; }
    .dropdown--option-item:hover, .dropdown--option-item:active {
      font-family: "Ubuntu";
      font-weight: 400;
      color: #1F35B5;
      background-color: #E8EAF8;
      outline: none;
      transition: 0.2s; }
  .dropdown--option-item-selected {
    cursor: pointer;
    padding: 1.5rem;
    font-family: "Ubuntu";
    font-weight: 400;
    color: #1F35B5;
    background-color: #E8EAF8;
    transition: 0.2s; }
    .dropdown--option-item-selected:focus, .dropdown--option-item-selected:active, .dropdown--option-item-selected:hover {
      outline: none; }
  .dropdown--placeholder-selected {
    color: #4b4b4b;
    white-space: nowrap;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis; }

.messageError {
  margin-top: 0 !important; }

.FilterHeader {
  align-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
  border-radius: 50px;
  z-index: 1; }
  .FilterHeader--justifyCenter {
    justify-content: center; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .FilterHeader {
      scroll-behavior: smooth;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      padding-left: 14px;
      grid-gap: 5px;
      gap: 5px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding-right: 14px; }
      .FilterHeader::-webkit-scrollbar {
        display: none; } }
  .FilterHeader__Sticky {
    z-index: 2;
    background: linear-gradient(to right, #1F35B5, #556EFF 100%);
    width: 100%;
    height: 80px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__Sticky {
        height: 70px; } }
  .FilterHeader__Wrapper {
    width: 100%;
    background: linear-gradient(to right, #1F35B5, #556EFF 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__Wrapper {
        height: 70px; } }
  .FilterHeader__Divider {
    width: 1px;
    height: 40px;
    background: #E2E2E2; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__Divider {
        display: none; } }
    .FilterHeader__Divider--hidden {
      background: #fff; }
  .FilterHeader__FilterPanelBackdrop {
    position: absolute;
    width: 303px;
    z-index: 1;
    top: 80px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__FilterPanelBackdrop {
        width: 100%;
        padding: 0 14px;
        top: 70px; } }
  .FilterHeader__FilterPanel {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #1F35B5;
    box-shadow: 0px 1px 20px #00000029;
    padding: 25px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__FilterPanel {
        width: 100% !important; } }
  .FilterHeader__FilterButton {
    width: 303px;
    height: 60px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    z-index: 1;
    padding: 14px 17px 14px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border: 1px solid #fff; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__FilterButton {
        padding: 9.5px 20px;
        height: 50px; } }
    @media (min-width: 1096px) {
      .FilterHeader__FilterButton:hover {
        background: #EBEBEB; } }
    .FilterHeader__FilterButton__icon {
      width: 12px;
      color: #4B4B4B;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
      .FilterHeader__FilterButton__icon--down {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg); }
    .FilterHeader__FilterButton--active {
      background: #fff; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .FilterHeader__FilterButton--active {
          background: #C7D0FF !important;
          border: 1px solid #C7D0FF; } }
      @media (min-width: 1096px) {
        .FilterHeader__FilterButton--active {
          border: 1px solid #1F35B5; } }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__FilterButton--fullWidth {
        width: 100% !important; } }
  @media (min-width: 0px) and (max-width: 1096px) {
    .FilterHeader__ExperiencesFilter {
      min-width: 240px;
      margin: 0;
      order: 2; } }
  .FilterHeader__ExperiencesFilter__InputWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 2 1; }
  .FilterHeader__ExperiencesFilter__Title {
    color: #ACACAC !important; }
    .FilterHeader__ExperiencesFilter__Title--active {
      color: #4B4B4B !important; }
  .FilterHeader__ExperiencesFilter__Input {
    color: #4B4B4B !important;
    background: none;
    border: none;
    width: 100%;
    outline: none;
    padding: 0; }
    .FilterHeader__ExperiencesFilter__Input:focus::-webkit-input-placeholder {
      color: transparent; }
    .FilterHeader__ExperiencesFilter__Input:focus::placeholder {
      color: transparent; }
  .FilterHeader__ExperiencesFilter__ClearButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    z-index: 2;
    background: none;
    cursor: pointer;
    padding: 0; }
  .FilterHeader__ExperiencesFilter__Panel {
    width: 100%;
    padding: 0;
    position: relative;
    overflow: hidden; }
  .FilterHeader__ExperiencesFilter__NotFound {
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; }
    .FilterHeader__ExperiencesFilter__NotFound__Title {
      color: #898989 !important; }
    .FilterHeader__ExperiencesFilter__NotFound__Subtitle {
      color: #1F35B5 !important;
      cursor: pointer; }
  .FilterHeader__ExperiencesFilter__List {
    overflow-y: scroll;
    max-height: 265px; }
    .FilterHeader__ExperiencesFilter__List__Item {
      min-height: 47px;
      padding: 14px 30px;
      cursor: pointer; }
      .FilterHeader__ExperiencesFilter__List__Item:hover {
        color: #1F35B5;
        background: #E8EAF8; }
  .FilterHeader__DayFilter {
    justify-content: center; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__DayFilter {
        min-width: 164px;
        order: 1; } }
    .FilterHeader__DayFilter__icon {
      width: 12px;
      color: #4B4B4B;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
      .FilterHeader__DayFilter__icon--down {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg); }
    .FilterHeader__DayFilter__Container {
      display: flex;
      align-items: center;
      justify-content: center; }
      .FilterHeader__DayFilter__Container--fullWidth {
        width: 100%; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .FilterHeader__DayFilter__Container {
          justify-content: space-between;
          width: 120px; } }
      .FilterHeader__DayFilter__Container__DateWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 25px;
        margin-left: 10px;
        grid-gap: 10px;
        gap: 10px; }
        .FilterHeader__DayFilter__Container__DateWrapper__TextContainer {
          display: flex;
          flex-direction: column; }
        @media (min-width: 0px) and (max-width: 1096px) {
          .FilterHeader__DayFilter__Container__DateWrapper {
            margin-left: 0;
            margin-right: 5px; } }
    .FilterHeader__DayFilter__Title {
      color: #1F35B5 !important;
      text-transform: uppercase; }
    .FilterHeader__DayFilter__Subtitle {
      color: #1F35B5 !important; }
    .FilterHeader__DayFilter__Panel {
      width: 340px;
      padding: 10px 25px; }
  .FilterHeader__MoreFilter {
    justify-content: center; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FilterHeader__MoreFilter {
        order: 3;
        min-width: 136px; } }
    .FilterHeader__MoreFilter__Container {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
      gap: 10px; }
      .FilterHeader__MoreFilter__Container__Text {
        color: #1F35B5 !important;
        font-weight: bold; }
    .FilterHeader__MoreFilter__Panel {
      width: 340px; }
      .FilterHeader__MoreFilter__Panel__GenderContainer {
        border-bottom: 1px solid #E2E2E2;
        padding-bottom: 20px; }
        .FilterHeader__MoreFilter__Panel__GenderContainer__Title {
          color: #4B4B4B !important;
          font-weight: bold; }
        .FilterHeader__MoreFilter__Panel__GenderContainer__RadioButtons {
          display: flex;
          margin-top: 10px;
          grid-gap: 21px;
          gap: 21px; }
      .FilterHeader__MoreFilter__Panel__ApproachContainer {
        margin-top: 20px; }
        .FilterHeader__MoreFilter__Panel__ApproachContainer__Wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px; }
        .FilterHeader__MoreFilter__Panel__ApproachContainer__Title {
          color: #4B4B4B !important;
          font-weight: bold; }

.Skeleton {
  background: linear-gradient(90deg, #f5f5f5 30%, #fcfcfc 28%, #fcfcfc 40%, #f5f5f5 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  -webkit-animation: skeleton-loading 2s infinite;
          animation: skeleton-loading 2s infinite; }
  .Skeleton--avatar {
    min-width: 92px;
    min-height: 92px;
    border-radius: 50%; }
  .Skeleton--text {
    width: 100%;
    border-radius: 5px; }

@-webkit-keyframes skeleton-loading {
  100% {
    background-position: -100% 0; } }

@keyframes skeleton-loading {
  100% {
    background-position: -100% 0; } }

.SignInBarBackdrop {
  display: flex;
  align-items: flex-end;
  background: rgba(196, 196, 196, 0.9);
  z-index: 10;
  height: 100%;
  position: fixed;
  bottom: 0;
  width: 100%; }

.SignInBar__Collapsed {
  background: #fff;
  height: 76px;
  box-shadow: 0px 1px 20px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  width: 100%; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .SignInBar__Collapsed {
      background: none;
      box-shadow: none; } }

.SignInBar__Expanded {
  height: 550px;
  grid-gap: 90px;
  gap: 90px;
  background: #fff;
  box-shadow: 0px 1px 20px #00000029;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .SignInBar__Expanded {
      height: 350px;
      grid-gap: 0;
      gap: 0;
      flex-direction: column-reverse; } }

.SignInBar__CloseButton {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  margin: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.SignInBar__LoginData {
  display: flex;
  align-items: center; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .SignInBar__LoginData {
      padding: 0 60px;
      flex-direction: column;
      margin: 20px 0; } }
  .SignInBar__LoginData__Label {
    text-align: center; }

.SignInBar__ButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .SignInBar__ButtonsWrapper {
      margin-top: 20px; } }

.SignInBar__SignInButton {
  height: 36px !important;
  width: 96px;
  padding: 9px 17px;
  font-size: 15px !important;
  margin-left: 17px; }

.SignInBar__SignUpButton {
  height: 36px !important;
  width: 135px;
  padding: 9px 17px;
  font-size: 15px !important;
  margin-left: 10px; }
  .SignInBar__SignUpButton__Expanded {
    width: 163px;
    margin-bottom: 30px; }

.SignInBar__LoginWrapper {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.SignInBar__LinkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.SignInBar__DividerWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin: 30px 0; }
  .SignInBar__DividerWrapper__Divider {
    width: 100%;
    height: 1px;
    background: #C1C1C1; }

.SignInBar__Link {
  color: #1F35B5 !important;
  cursor: pointer;
  font-weight: 500 !important; }

.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .Login__Title {
    text-align: center;
    margin-bottom: 20px !important; }
  .Login__Form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }
    .Login__Form__Error {
      margin-bottom: 10px; }
  .Login__SubmitButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Login__Spinner {
    height: 24px !important;
    width: 24px !important;
    border-width: 4px !important;
    border-color: #fff !important;
    border-top-color: transparent !important; }

.Header, .Header--succinct {
  background: linear-gradient(to right, #1F35B5, #556EFF 100%);
  display: flex;
  justify-content: center;
  max-height: 6rem;
  padding: 20px !important;
  width: 100%;
  z-index: 1; }
  .Header__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 1096px;
    width: 100%; }
    .Header__container .header--logo {
      cursor: pointer;
      margin-top: 0.4rem;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      grid-gap: 10px;
      gap: 10px; }
  .Header--center {
    width: 68%;
    display: flex;
    justify-content: space-between; }
    .Header--center--item p:hover {
      font-weight: 700;
      text-decoration: underline; }
  .Header--succinct {
    background: #f5f5f5;
    border-bottom: 0.1rem solid #c4c4c4; }
  .Header--nav {
    align-items: flex-start;
    display: flex;
    justify-content: center; }
    .Header--nav--text, .Header--nav--avatar {
      color: #ffffff;
      height: 100%; }
    .Header--nav--avatar {
      margin-left: 1rem;
      margin-top: 5px;
      height: 30px; }
  .Header--protector {
    width: 100%;
    height: 0;
    padding-bottom: 6rem; }

@media (min-width: 1096px) {
  .Header, .Header--succinct {
    position: relative; }
    .Header--nav {
      align-items: center;
      display: flex; }
    .Header--protector {
      display: none; } }

.HeaderButton, .HeaderButton:disabled, .HeaderButton--default, .HeaderButton--secondary, .HeaderButton--outlined, .HeaderButton--mobile, .HeaderButton--text {
  font-family: "Ubuntu";
  font-weight: 500;
  border: 0.1rem solid #ffffff;
  border-radius: 0.5rem;
  background-color: #ffffff;
  color: #1f35b5;
  font-size: 1.5rem;
  margin-left: 0.8rem;
  min-height: 3.6rem;
  padding: 1rem 1.6rem;
  text-transform: uppercase;
  transition: 0.4s; }
  .HeaderButton:focus, .HeaderButton--default:focus, .HeaderButton--secondary:focus, .HeaderButton--outlined:focus, .HeaderButton--mobile:focus, .HeaderButton--text:focus {
    outline: none; }
  .HeaderButton:hover, .HeaderButton--default:hover, .HeaderButton--secondary:hover, .HeaderButton--outlined:hover, .HeaderButton--mobile:hover, .HeaderButton--text:hover {
    background-color: #172888;
    border-color: #172888;
    color: #fff; }
  .HeaderButton:disabled, .HeaderButton--default:disabled, .HeaderButton--secondary:disabled, .HeaderButton--outlined:disabled, .HeaderButton--mobile:disabled, .HeaderButton--text:disabled {
    background-color: #dedede;
    border-color: #dedede;
    color: #acacac; }
  .HeaderButton--secondary {
    background-color: #1f35b5;
    border: 0.1rem solid #ffffff;
    color: #ffffff; }
  .HeaderButton--outlined {
    background: transparent;
    color: #fff; }
  .HeaderButton--mobile {
    background-color: #1f35b5;
    border-color: #1f35b5;
    color: #ffffff;
    padding: 0; }
  .HeaderButton--text {
    background: none;
    border: none;
    padding: 0; }

@-webkit-keyframes toggleButtonBlink {
  0% {
    background-color: #e0e0e0; }
  50.0% {
    background-color: rgba(74, 195, 38, 0.5); }
  100.0% {
    background-color: #e0e0e0; } }

@keyframes toggleButtonBlink {
  0% {
    background-color: #e0e0e0; }
  50.0% {
    background-color: rgba(74, 195, 38, 0.5); }
  100.0% {
    background-color: #e0e0e0; } }

.Toggle {
  align-items: center;
  background-color: #e0e0e0;
  cursor: pointer;
  display: flex;
  height: 28px;
  width: 48px;
  border-radius: 20px;
  position: relative; }
  .Toggle--active {
    background-color: #4AC326; }
  .Toggle--loading {
    background-color: #f5f5f5;
    -webkit-animation: toggleButtonBlink 1s ease-in-out infinite;
            animation: toggleButtonBlink 1s ease-in-out infinite; }
  .Toggle__icon {
    height: 24px;
    width: 24px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff;
    border-radius: 24px;
    transition: all 0.1s ease-out;
    margin-left: 2px; }
    .Toggle__icon--active {
      margin-left: 22px; }
    .Toggle__icon--loading {
      margin-left: 12px; }
  .Toggle__label {
    position: absolute;
    color: #fff !important;
    right: 10px; }
    .Toggle__label--active {
      left: 10px; }

.FloatActionButtonDesktop {
  align-items: center;
  display: flex;
  margin-top: -6rem; }
  .FloatActionButtonDesktop__button, .FloatActionButtonDesktop__button--primary {
    border-radius: 0.5rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
    align-items: center;
    border: none;
    border-radius: 10rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 5.6rem;
    justify-content: center;
    margin-top: 4rem;
    padding: 1.5rem 2rem;
    min-width: 5.6rem;
    transition: 0.1s; }
    .FloatActionButtonDesktop__button:focus, .FloatActionButtonDesktop__button--primary:focus {
      outline: none; }
    .FloatActionButtonDesktop__button--primary {
      background-color: #1f35b5;
      color: #ffffff; }
      .FloatActionButtonDesktop__button--primary:hover {
        background-color: #182889; }
  .FloatActionButtonDesktop__icon {
    margin-right: 1rem;
    height: 2.4rem; }
  .FloatActionButtonDesktop__info {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    max-width: 16rem; }
    .FloatActionButtonDesktop__info__icon {
      margin-right: -2.5rem;
      width: 4.9rem; }
    .FloatActionButtonDesktop__info__text {
      width: 11.6rem; }

.FloatActionButtonDropdown {
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 98;
  width: auto;
  justify-content: space-between; }
  @media (min-width: 1096px) {
    .FloatActionButtonDropdown {
      position: relative; } }
  .FloatActionButtonDropdown__SpinnerContainer {
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .FloatActionButtonDropdown__Spinner {
    height: 34px !important;
    width: 34px !important;
    border-width: 4px !important;
    border-color: #fff !important;
    border-top-color: transparent !important; }
  .FloatActionButtonDropdown__Modal {
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    overflow: auto;
    align-items: flex-start;
    justify-content: center;
    place-content: center center;
    left: 0;
    z-index: 99; }
    .FloatActionButtonDropdown__Modal--open {
      display: flex;
      visibility: visible; }
    .FloatActionButtonDropdown__Modal__Backdrop {
      background-color: #dedede;
      height: 100%;
      opacity: 0.9;
      position: fixed;
      width: 100%;
      z-index: 100;
      top: 0;
      left: 0; }
    .FloatActionButtonDropdown__Modal__Wrapper {
      position: relative;
      height: 100%;
      width: 100%; }
    .FloatActionButtonDropdown__Modal__OptionsList {
      position: fixed;
      z-index: 105;
      max-width: 60%; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .FloatActionButtonDropdown__Modal__OptionsList {
          width: 100%;
          max-width: 100%; } }
      .FloatActionButtonDropdown__Modal__OptionsList__Option {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-bottom: 1rem;
        justify-content: flex-end; }
        .FloatActionButtonDropdown__Modal__OptionsList__Option:hover .FloatActionButtonDropdown__Modal__OptionsList__Option__Background {
          background-color: #f5f5f5; }
        .FloatActionButtonDropdown__Modal__OptionsList__Option:last-child {
          margin-bottom: 0; }
        @media (min-width: 0px) and (max-width: 1096px) {
          .FloatActionButtonDropdown__Modal__OptionsList__Option--right {
            padding-left: 30%; } }
        @media (min-width: 0px) and (max-width: 1096px) {
          .FloatActionButtonDropdown__Modal__OptionsList__Option--left {
            padding-right: 30%;
            justify-content: flex-start; } }
        .FloatActionButtonDropdown__Modal__OptionsList__Option__Background {
          border-radius: 0.5rem;
          box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
          align-items: center;
          background-color: #ffffff;
          color: #4b4b4b;
          display: flex;
          padding: 0.6rem 1.4rem 0.9rem; }
        .FloatActionButtonDropdown__Modal__OptionsList__Option__Text {
          margin: 0 8px;
          padding: 8px 16px; }
        .FloatActionButtonDropdown__Modal__OptionsList__Option__Icon {
          border-radius: 30px;
          padding: 8px;
          margin: 0 3px; }
  .FloatActionButtonDropdown--open {
    z-index: 300; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .FloatActionButtonDropdown--left {
      bottom: 20px;
      left: 20px;
      right: auto;
      top: auto; } }
  @media (min-width: 0px) and (max-width: 1096px) {
    .FloatActionButtonDropdown--right {
      bottom: 20px;
      right: 20px;
      left: auto;
      top: auto; } }
  .FloatActionButtonDropdown__Button {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: center;
    padding: 0 2rem;
    transition: 0.1s;
    background-color: #1f35b5;
    color: #ffffff;
    min-width: 48px; }
    @media (min-width: 1096px) {
      .FloatActionButtonDropdown__Button {
        border-radius: 5px; } }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FloatActionButtonDropdown__Button {
        border-radius: 0.5rem;
        box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
        border-radius: 48px !important;
        padding: 0;
        height: 56px;
        width: 56px;
        box-shadow: 0px 6px 10px #0000004D; } }
    .FloatActionButtonDropdown__Button:hover {
      background-color: #182889; }
    .FloatActionButtonDropdown__Button:focus {
      outline: none; }
    .FloatActionButtonDropdown__Button--open {
      padding: 0;
      border-radius: 48px; }
  .FloatActionButtonDropdown__Icon {
    margin-right: 1rem;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .FloatActionButtonDropdown__Icon {
        margin-right: 0; } }
    .FloatActionButtonDropdown__Icon--open {
      margin-right: 0; }

.FloatActionButtonMobile, .FloatActionButtonMobile-hasTitle {
  align-items: flex-start;
  bottom: 24px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 18px;
  z-index: 3; }
  .FloatActionButtonMobile-hasTitle {
    right: 0;
    width: 100%;
    justify-content: center; }
  .FloatActionButtonMobile__info {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    max-width: 16rem; }
    .FloatActionButtonMobile__info__icon {
      margin-right: -2.5rem;
      width: 4.9rem; }
    .FloatActionButtonMobile__info__text {
      width: 11.6rem; }
  .FloatActionButtonMobile__button, .FloatActionButtonMobile__button--primary {
    border-radius: 0.5rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
    align-items: center;
    border: none;
    border-radius: 10rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 5.6rem;
    justify-content: center;
    margin-top: 40px;
    padding: 1.2rem;
    min-width: 5.6rem; }
    .FloatActionButtonMobile__button:hover, .FloatActionButtonMobile__button--primary:hover {
      border-radius: 0.5rem;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
      -webkit-filter: brightness(85%);
              filter: brightness(85%);
      border-radius: 10rem;
      transition: 0.5s; }
    .FloatActionButtonMobile__button svg, .FloatActionButtonMobile__button--primary svg {
      margin-right: 0; }
    .FloatActionButtonMobile__button--primary {
      background-color: #1f35b5; }
    .FloatActionButtonMobile__button__text {
      color: #ffffff;
      margin-left: 1rem;
      margin-right: 1rem; }

.FloatMenu {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 1rem #dedede, 0.3rem 0.3rem 1rem #dedede;
  max-width: 16rem;
  overflow: hidden; }
  .FloatMenu__item {
    padding: 1rem 1.5rem; }
    .FloatMenu__item:hover {
      background-color: #c5ccf5;
      color: #1f35b5;
      cursor: pointer; }

.FloatmenuCard {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 1rem #dedede, 0.3rem 0.3rem 1rem #dedede;
  width: 185px;
  overflow: hidden; }
  .FloatmenuCard__item {
    padding: 1rem 1.5rem; }
    .FloatmenuCard__item:hover {
      background-color: #c5ccf5;
      color: #1f35b5;
      cursor: pointer; }

.Footer {
  background: linear-gradient(to right, #1F35B5, #556EFF 100%);
  margin-top: 5rem;
  position: relative;
  width: 100%; }
  .Footer__Image {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    opacity: 0.4;
    height: 100%;
    width: 100%; }
  .Footer__Layer {
    padding: 4rem 0 5rem;
    position: relative;
    width: 100%; }
    @media (min-width: 1096px) {
      .Footer__Layer {
        padding: 7rem 0 8rem; } }
  .Footer__Upper {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    width: 100%; }
    @media (min-width: 1096px) {
      .Footer__Upper {
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 10rem; } }
    .Footer__Upper__Left {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%; }
      @media (min-width: 1096px) {
        .Footer__Upper__Left {
          align-items: flex-start;
          flex-direction: row;
          justify-content: flex-start; } }
    .Footer__Upper__Right {
      align-items: center;
      display: flex;
      height: 6.2rem;
      flex-direction: column; }
      @media (min-width: 1096px) {
        .Footer__Upper__Right {
          flex-direction: row;
          height: 4.5rem;
          margin-left: auto;
          margin-right: 0; } }
      .Footer__Upper__Right__Label {
        margin-bottom: 1rem; }
        @media (min-width: 1096px) {
          .Footer__Upper__Right__Label {
            margin-right: 1.5rem;
            margin-top: 0.5rem; } }
      .Footer__Upper__Right__Social {
        display: flex;
        flex-direction: row; }
        .Footer__Upper__Right__Social__Item {
          margin: 0 0.75rem; }
          .Footer__Upper__Right__Social__Item:hover {
            transition: 0.2s;
            opacity: 0.4; }
          .Footer__Upper__Right__Social__Item:first-of-type {
            margin-left: 0; }
          .Footer__Upper__Right__Social__Item:last-of-type {
            margin-right: 0; }
  .Footer__Under {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin-top: 7rem;
    width: 100%; }
    @media (min-width: 1096px) {
      .Footer__Under {
        flex-direction: row;
        margin-top: 0; } }
  .Footer__Copyright {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    @media (min-width: 1096px) {
      .Footer__Copyright {
        flex-direction: row;
        margin-right: 4.6rem; } }
    .Footer__Copyright__Text {
      opacity: 0.4; }
    @media (min-width: 1096px) {
      .Footer__Copyright__Logo {
        margin-right: 1.8rem; } }
  .Footer__Contact {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 6rem; }
    @media (min-width: 1096px) {
      .Footer__Contact {
        margin-bottom: 0; } }
    .Footer__Contact__Email:hover {
      transition: 0.2s;
      opacity: 0.4; }
    .Footer__Contact__Label {
      margin-right: 0.3rem; }
  .Footer__Topic {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    @media (min-width: 1096px) {
      .Footer__Topic {
        align-items: flex-start;
        max-width: 18rem;
        margin: 0 3.5rem; }
        .Footer__Topic:first-of-type {
          margin-left: 0; }
        .Footer__Topic:last-of-type {
          margin-right: 0; } }
    .Footer__Topic__Title {
      margin-bottom: 2.15rem; }
      @media (min-width: 1096px) {
        .Footer__Topic__Title {
          margin-bottom: 0rem; } }
    .Footer__Topic__Item {
      cursor: pointer;
      margin: 2.15rem 0; }
      .Footer__Topic__Item p {
        line-height: 1.7rem; }
      .Footer__Topic__Item .Tag {
        margin-left: 1rem; }
      .Footer__Topic__Item:first-of-type {
        margin: 0 0 2.15rem; }
      .Footer__Topic__Item:last-of-type {
        margin: 2.15rem 0 0; }
      .Footer__Topic__Item:hover {
        transition: 0.2s;
        opacity: 0.4; }
      @media (min-width: 1096px) {
        .Footer__Topic__Item {
          display: flex;
          margin: 1.2rem 0;
          width: 100%; }
          .Footer__Topic__Item:first-of-type {
            margin: 0 0 1.2rem; }
          .Footer__Topic__Item:last-of-type {
            margin: 1.2rem 0 0; } }

.InputList__Input {
  border: 0.1rem solid #c1c1c1;
  border-radius: 0.5rem;
  color: #4b4b4b;
  flex-shrink: 0;
  font-family: inherit;
  font-size: 1.5rem;
  height: auto;
  padding: 1.5rem 40px 1.5rem 1.5rem;
  width: 100%; }
  .InputList__Input:hover, .InputList__Input:active, .InputList__Input:focus {
    outline: none;
    border: 0.1rem solid #1f35b5; }
  .InputList__Input::-webkit-input-placeholder {
    color: #C1C1C1; }
  .InputList__Input::placeholder {
    color: #C1C1C1; }

.InputList__Button {
  position: absolute;
  margin-left: -40px;
  margin-top: 10px;
  cursor: pointer; }

.InputList__List, .InputList__List--show {
  width: 100%;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0.2rem 0.2rem 0.5rem #dedede, -0.1rem -0.1rem 0.1rem #dedede;
  height: auto;
  max-height: 175px;
  overflow-y: scroll;
  position: absolute;
  display: none; }
  .InputList__List--show {
    display: block; }
  .InputList__List__Item {
    width: 100%;
    padding: 15px;
    height: 50px; }
    .InputList__List__Item:hover {
      color: #1F35B5;
      background-color: #E8EAF8;
      cursor: pointer;
      transition: 0.2s; }

.ListPlaceholder {
  align-items: center;
  color: #898989;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 24rem; }
  .ListPlaceholder__icon svg {
    fill: #898989 !important; }
    .ListPlaceholder__icon svg path {
      fill: #898989 !important; }
    .ListPlaceholder__icon svg circle {
      stroke: #898989 !important; }
  .ListPlaceholder__title {
    margin-top: 1rem; }
  .ListPlaceholder__description {
    margin-top: 0.9rem; }

.Logo {
  cursor: pointer;
  max-width: 100px; }

.MenuLogout:hover {
  opacity: 0.9; }

@-webkit-keyframes openMenu {
  0% {
    right: -30rem; }
  100% {
    right: 0rem; } }

@keyframes openMenu {
  0% {
    right: -30rem; }
  100% {
    right: 0rem; } }

@-webkit-keyframes closeMenu {
  0% {
    right: 0rem; }
  100% {
    right: -30rem; } }

@keyframes closeMenu {
  0% {
    right: 0rem; }
  100% {
    right: -30rem; } }

.main-menu--container, .main-menu--container-undefined, .main-menu--container-closed {
  -webkit-animation: openMenu;
          animation: openMenu;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  background-color: #ffffff;
  box-shadow: -0.2rem 0.1rem 1rem #4b4b4b;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  width: 27.8rem;
  z-index: 500; }
  .main-menu--container-undefined {
    right: -30rem;
    -webkit-animation: none;
            animation: none; }
  .main-menu--container-closed {
    -webkit-animation: closeMenu;
            animation: closeMenu;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }

.main-menu--header, .main-menu {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  position: relative;
  width: 27.8rem; }

.main-menu {
  background-color: #1f35b5;
  display: flex;
  padding-bottom: 0.5rem;
  height: 100vh;
  width: 27.8rem;
  z-index: 100; }
  .main-menu--header {
    background-color: #1f35b5;
    color: #ffffff;
    display: block;
    height: auto;
    padding-bottom: 2rem;
    padding-top: 2rem; }
  .main-menu--separator {
    display: block !important;
    background-color: #ffffff;
    height: 2px;
    width: 100%; }
  .main-menu--top {
    align-items: center;
    color: #ffffff;
    display: flex;
    height: 3.5rem;
    justify-content: flex-end;
    margin: 1.3rem 1.4rem 0 0;
    width: 3.5rem; }
    .main-menu--top-row {
      right: 0;
      padding-right: 1.4rem !important;
      position: absolute !important;
      width: 3.8rem !important;
      z-index: 101; }
  .main-menu--body {
    display: block !important; }
    .main-menu--body--text {
      font-size: 1.5rem !important;
      color: #ffffff !important; }
      @media screen and (min-width: 1096px) {
        .main-menu--body--text {
          font-size: 1.5rem !important; } }
      .main-menu--body--text:hover {
        color: #e2e2e2 !important; }
  .main-menu--user {
    display: block !important; }
    .main-menu--user--text {
      font-size: 1.8rem !important;
      color: #ffffff !important; }
      @media screen and (min-width: 1096px) {
        .main-menu--user--text {
          font-size: 1.8rem !important; } }
      .main-menu--user--text:hover {
        color: #e2e2e2 !important; }

.main-menu.isLogged {
  background-color: #ffffff; }
  .main-menu.isLogged .main-menu {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .main-menu.isLogged .main-menu--separator {
      background-color: #e2e2e2; }
    .main-menu.isLogged .main-menu--body {
      display: block !important; }
      .main-menu.isLogged .main-menu--body--text {
        color: #4b4b4b !important; }
        .main-menu.isLogged .main-menu--body--text:hover {
          color: #acacac !important; }

.black-screen {
  background-color: #00000088;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 99; }

.menu-dashboard-item {
  cursor: pointer;
  margin-bottom: 20px !important; }
  .menu-dashboard-item--icon {
    margin-right: 10px;
    height: 24px; }
  .menu-dashboard-item--badge {
    margin-left: 8px; }
  .menu-dashboard-item:hover .menu-dashboard-item--icon {
    color: #172888;
    fill: #172888; }
  .menu-dashboard-item:hover .menu-dashboard-item--text {
    color: #172888; }

.profile-swap {
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #e2e2e2;
  width: 100%; }
  @media (min-width: 1096px) {
    .profile-swap {
      margin-bottom: 15px; } }
  .profile-swap--option {
    border-radius: 0.5rem;
    box-shadow: 0.1rem 0.1rem 0.5rem #4b4b4b;
    overflow: hidden;
    position: absolute;
    width: calc(100% - 3.2rem);
    z-index: 103; }
  .profile-swap--option--item {
    background-color: #ffffff;
    color: #c4c4c4;
    padding: 1rem;
    height: 3.5rem;
    width: auto; }
    .profile-swap--option--item p {
      line-height: 1; }
    .profile-swap--option--item:hover {
      cursor: pointer;
      background-color: #e2e2e2; }
  .profile-swap--select {
    background-color: #ffffff;
    border: 0.1rem solid #c4c4c4;
    border-radius: 0.5rem;
    color: #c4c4c4;
    padding: 1rem;
    height: 3.5rem;
    width: auto; }
    .profile-swap--select .profile-swap--text {
      padding-bottom: 1rem; }
    .profile-swap--select .profile-swap--arrow {
      cursor: pointer;
      margin-top: -0.6rem !important; }
    .profile-swap--select p {
      line-height: 1; }

@media (min-width: 1096px) {
  .MenuUserInfo {
    margin-bottom: 25px; } }

.MenuUserInfo__name {
  font-family: "Ubuntu";
  font-weight: 700;
  font-size: 1.8rem;
  margin-top: 0.4rem; }

.MenuUserInfo__email {
  font-family: "Ubuntu";
  font-weight: 400;
  color: #ffffff;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.5; }
  @media (min-width: 1096px) {
    .MenuUserInfo__email {
      color: #898989; } }

.NotificationInline {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 7.261rem; }
  .NotificationInline p {
    line-height: 1rem; }
  .NotificationInline .Button, .NotificationInline .Button--default, .NotificationInline .Button--reverse, .NotificationInline .Button--reverse--grey, .NotificationInline .Button--reverse--grey--lighter, .NotificationInline .Button--reverse--grey--padded, .NotificationInline .Button--online, .NotificationInline .Button--false {
    font-size: 12px;
    padding: 10px 13px 12px;
    margin-top: 0.5rem;
    font-weight: 400; }

.NpsNumber {
  display: flex;
  border: 1px solid #ffffff;
  justify-content: center;
  border-radius: 2.5rem;
  width: 5.5rem;
  height: 8rem;
  flex-direction: column;
  justify-items: center; }
  .NpsNumber .NpsNumber__circle, .NpsNumber .NpsNumber__circle--0, .NpsNumber .NpsNumber__circle--1, .NpsNumber .NpsNumber__circle--2, .NpsNumber .NpsNumber__circle--3, .NpsNumber .NpsNumber__circle--4, .NpsNumber .NpsNumber__circle--5, .NpsNumber .NpsNumber__circle--6, .NpsNumber .NpsNumber__circle--7, .NpsNumber .NpsNumber__circle--8, .NpsNumber .NpsNumber__circle--9, .NpsNumber .NpsNumber__circle--10 {
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
    justify-content: center;
    align-items: center;
    background-color: #eb384d;
    border-radius: 2.5rem;
    margin-bottom: 0.3rem;
    margin-left: 0.4rem;
    cursor: pointer; }
    .NpsNumber .NpsNumber__circle--0 {
      background-color: #EB2D2D; }
    .NpsNumber .NpsNumber__circle--1 {
      background-color: #FF4949; }
    .NpsNumber .NpsNumber__circle--2 {
      background-color: #FF6549; }
    .NpsNumber .NpsNumber__circle--3 {
      background-color: #FF7349; }
    .NpsNumber .NpsNumber__circle--4 {
      background-color: #FF9649; }
    .NpsNumber .NpsNumber__circle--5 {
      background-color: #FFB249; }
    .NpsNumber .NpsNumber__circle--6 {
      background-color: #FFCE49; }
    .NpsNumber .NpsNumber__circle--7 {
      background-color: #C5DB1B; }
    .NpsNumber .NpsNumber__circle--8 {
      background-color: #47DB1B; }
    .NpsNumber .NpsNumber__circle--9 {
      background-color: #3AAD17; }
    .NpsNumber .NpsNumber__circle--10 {
      background-color: #268909; }
    .NpsNumber .NpsNumber__circle:hover, .NpsNumber .NpsNumber__circle--0:hover, .NpsNumber .NpsNumber__circle--1:hover, .NpsNumber .NpsNumber__circle--2:hover, .NpsNumber .NpsNumber__circle--3:hover, .NpsNumber .NpsNumber__circle--4:hover, .NpsNumber .NpsNumber__circle--5:hover, .NpsNumber .NpsNumber__circle--6:hover, .NpsNumber .NpsNumber__circle--7:hover, .NpsNumber .NpsNumber__circle--8:hover, .NpsNumber .NpsNumber__circle--9:hover, .NpsNumber .NpsNumber__circle--10:hover {
      opacity: 0.5; }
  .NpsNumber__icon {
    display: flex;
    margin-left: 1rem;
    margin-top: 0.1rem; }
  .NpsNumber.active {
    display: flex;
    border: 1px solid #4B4B4B;
    border-radius: 2.5rem;
    width: 5.5rem;
    height: 8rem; }

.NpsTextArea {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  flex-wrap: wrap; }
  @media (min-width: 1096px) {
    .NpsTextArea {
      flex: 1 1;
      flex-direction: column;
      align-items: center; } }
  .NpsTextArea__text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 29rem; }
    @media (min-width: 1096px) {
      .NpsTextArea__text {
        width: 52.4rem; } }
  .NpsTextArea__textarea {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%; }
    @media (min-width: 1096px) {
      .NpsTextArea__textarea {
        width: 59rem; } }
  .NpsTextArea__subtext {
    max-width: 557px; }
    .NpsTextArea__subtext p {
      text-align: center; }
  .NpsTextArea__text ~ .NpsTextArea__subtext {
    margin-top: 0.5rem; }
  .NpsTextArea__text ~ .NpsTextArea__textarea {
    margin-top: 2rem; }

.NotificationStep {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%; }
  .NotificationStep__iconHeader {
    margin: 3rem 0 1.5rem 0; }
  .NotificationStep h1 {
    margin-top: 2.5rem; }
  .NotificationStep-icon, .NotificationStep-icon--isSuccess {
    width: 7rem; }
    .NotificationStep-icon--isSuccess {
      color: #4AC326;
      display: inherit;
      font-size: 6.6rem; }

.PaymentFooter {
  min-height: 60px;
  width: 100%; }
  .PaymentFooter__container {
    border-top: 0.1rem solid #c4c4c4;
    height: 100%; }
  .PaymentFooter__row {
    flex-direction: column !important;
    height: 100%;
    padding: 2rem 0 1.7rem !important; }
  .PaymentFooter__payment-methods {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2.1rem;
    margin-right: 0;
    width: auto; }
    .PaymentFooter__payment-methods p {
      text-align: center; }
    .PaymentFooter__payment-methods__text {
      margin-right: 0;
      width: auto; }
    .PaymentFooter__payment-methods__cards {
      height: 2.2rem;
      width: auto; }
  .PaymentFooter__copyright p {
    text-align: center !important; }

@media (min-width: 1096px) {
  .PaymentFooter {
    height: 6rem; }
    .PaymentFooter__payment-methods {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 0;
      margin-right: 1.25rem;
      width: calc(50% - 1.25rem); }
      .PaymentFooter__payment-methods__text {
        margin-right: 1.5rem;
        width: auto; }
      .PaymentFooter__payment-methods__cards {
        height: 2.2rem;
        width: auto; }
    .PaymentFooter__row {
      flex-direction: row !important; }
    .PaymentFooter__copyright {
      display: flex;
      justify-content: flex-start;
      margin-left: 1.25rem;
      margin-top: 0;
      width: calc(50% - 1.25rem); } }

.PaymentStatus, .PaymentStatus--cancelled, .PaymentStatus--paid, .PaymentStatus--notPaid, .PaymentStatus--pending, .PaymentStatus--notApproved {
  font-family: "Ubuntu";
  font-weight: 700;
  border: none;
  border-radius: 0.5rem;
  height: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  padding: 1rem 0.6rem;
  letter-spacing: -0.06rem; }
  .PaymentStatus--cancelled {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #eb384d;
    width: 9rem; }
  .PaymentStatus--paid {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #4ac326; }
  .PaymentStatus--notPaid {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #eb384d;
    overflow: hidden;
    white-space: nowrap; }
  .PaymentStatus--pending {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #F9A435; }
  .PaymentStatus--notApproved {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #eb384d; }

.PaymentDetails {
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2rem solid #f5f5f5;
  padding: 0 2rem 2rem 2rem;
  width: 100%; }
  @media (min-width: 1096px) {
    .PaymentDetails {
      padding: 0; } }
  .PaymentDetails__data {
    display: flex;
    flex-direction: column; }
  .PaymentDetails__content {
    display: flex;
    flex-direction: column; }
    @media (min-width: 1096px) {
      .PaymentDetails__content {
        flex-direction: row;
        justify-content: space-between; } }
  .PaymentDetails__name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 2rem;
    margin-top: 3rem; }
    @media (min-width: 1096px) {
      .PaymentDetails__name {
        align-items: flex-start; } }
  .PaymentDetails__paymentStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    margin-top: 2rem; }
    .PaymentDetails__paymentStatus__typography {
      margin-right: 1rem; }
    @media (min-width: 1096px) {
      .PaymentDetails__paymentStatus {
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 1rem; } }
  .PaymentDetails__paymentFooter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2rem; }
    .PaymentDetails__paymentFooter__tags {
      margin-right: 1.5rem; }
  .PaymentDetails__buttons {
    display: flex;
    margin: 0 auto;
    width: 70%;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media (min-width: 1096px) {
      .PaymentDetails__buttons {
        flex-direction: row;
        margin: 0;
        width: 60%; }
        .PaymentDetails__buttons button {
          margin-left: 1rem; } }

.PaymentsList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
  border-top: 0.2rem solid #f5f5f5; }
  .PaymentsList__icon {
    display: flex;
    justify-content: center;
    align-items: center; }
  .PaymentsList__data {
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    .PaymentsList__data__paymentStatus {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 1.6rem;
      align-items: flex-start;
      color: #4b4b4b; }
      @media (min-width: 1096px) {
        .PaymentsList__data__paymentStatus {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row; } }
      .PaymentsList__data__paymentStatus__price {
        display: flex;
        width: 22rem;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem; }
        @media (min-width: 1096px) {
          .PaymentsList__data__paymentStatus__price {
            margin: 0; } }
        .PaymentsList__data__paymentStatus__price__width {
          display: flex;
          width: 12rem; }
        @media (min-width: 1096px) {
          .PaymentsList__data__paymentStatus__price {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-right: 4.5rem; } }
    .PaymentsList__data__paymentFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #4b4b4b; }
      @media (min-width: 1096px) {
        .PaymentsList__data__paymentFooter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row; } }
      .PaymentsList__data__paymentFooter__tags {
        display: flex;
        margin-right: 1rem; }
        @media (min-width: 1096px) {
          .PaymentsList__data__paymentFooter__tags {
            margin-right: 6rem; } }

.ProfessionalListItem {
  display: flex;
  justify-content: space-between;
  border-top: 0.2rem solid #ebebeb;
  width: 100%;
  padding: 2rem 0 2rem 0; }
  .ProfessionalListItem__Avatar {
    display: flex;
    justify-content: center;
    align-items: center; }
  .ProfessionalListItem--icon {
    display: flex;
    align-items: center; }
  .ProfessionalListItem__Content {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    justify-content: flex-start;
    align-items: flex-start;
    letter-spacing: -0.1rem;
    line-height: 1.8rem;
    width: 100%; }
    @media (min-width: 1096px) {
      .ProfessionalListItem__Content {
        justify-content: center;
        align-items: flex-start;
        flex: 2 1;
        line-height: 1.8rem;
        margin-left: 2rem; } }
    .ProfessionalListItem__Content__name {
      display: flex;
      flex-flow: wrap;
      font-weight: bold;
      flex-direction: column;
      margin-bottom: 10px; }
    .ProfessionalListItem__Content__info {
      display: flex;
      flex-direction: column; }
      @media (min-width: 1096px) {
        .ProfessionalListItem__Content__info {
          flex-direction: row;
          margin-top: 0.2rem; } }
      .ProfessionalListItem__Content__info__document {
        display: flex;
        color: #4B4B4B; }
        @media (min-width: 1096px) {
          .ProfessionalListItem__Content__info__document {
            margin-right: 15px; } }
        .ProfessionalListItem__Content__info__document--text {
          display: flex;
          margin-right: 0.5rem; }
      .ProfessionalListItem__Content__info__id {
        display: flex;
        flex-direction: column;
        flex-basis: 8rem;
        flex-direction: column; }
      .ProfessionalListItem__Content__info__status {
        display: flex;
        flex-basis: 22rem; }
  .ProfessionalListItem--footer {
    display: flex;
    flex-direction: row;
    width: 19rem; }
    @media (min-width: 1096px) {
      .ProfessionalListItem--footer {
        flex-grow: 6; } }

.statusIcon, .statusIcon--active, .statusIcon--inactive, .statusIcon--incomplete {
  display: flex;
  flex-direction: row;
  color: #4ac326;
  flex-basis: 23rem; }
  .statusIcon--active {
    font-family: "Ubuntu";
    font-weight: 400;
    color: #4ac326; }
  .statusIcon--inactive {
    font-family: "Ubuntu";
    font-weight: 400;
    color: #eb384d; }
  .statusIcon--incomplete {
    font-family: "Ubuntu";
    font-weight: 400;
    color: #f9a435; }

.ProfessionalCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 392px;
  padding: 30px !important;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalCard {
      padding: 0 !important;
      height: auto !important;
      max-width: calc(100vw - 14px) !important; } }
  .ProfessionalCard__loading {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 43px;
    grid-gap: 30px;
    gap: 30px;
    height: 358px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalCard__loading {
        padding: 20px 0 !important;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px; } }
  .ProfessionalCard__sectionLoading {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalCard__sectionLoading {
        border-top: 1px solid #eee;
        padding: 20px 0 0 20px; } }
    .ProfessionalCard__sectionLoading__info {
      flex: 1.5 1; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__sectionLoading__info {
          padding: 0 20px;
          width: 100%; } }
  .ProfessionalCard__availabilitiesLoading {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 35px);
    margin: 25px 0;
    grid-gap: 10px;
    overflow-x: hidden; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalCard__availabilitiesLoading {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: auto;
        white-space: nowrap;
        padding: 0 20px 0 0;
        width: 100%;
        height: auto;
        scrollbar-width: none;
        -ms-overflow-style: none; }
        .ProfessionalCard__availabilitiesLoading::-webkit-scrollbar {
          display: none; }
        .ProfessionalCard__availabilitiesLoading::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 0; } }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalCard__submitLoadingButton {
      padding-right: 20px; } }
  .ProfessionalCard__informationWrapperLoading {
    display: flex;
    width: 100%;
    align-items: center;
    grid-gap: 15px;
    gap: 15px; }
    .ProfessionalCard__informationWrapperLoading__data {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      gap: 10px;
      width: 55%; }
  .ProfessionalCard__tabLoading {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 30px;
    width: 70%; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalCard__tabLoading {
        width: 100%; } }
  .ProfessionalCard__descriptionLoading {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 30px; }
    .ProfessionalCard__descriptionLoading__wrapper {
      display: flex;
      grid-gap: 10px;
      gap: 10px;
      flex-direction: column;
      width: 100%; }
  .ProfessionalCard__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    grid-gap: 45px;
    gap: 45px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalCard__wrapper {
        grid-gap: 0;
        gap: 0;
        flex-direction: column; } }
  .ProfessionalCard__button {
    width: 300px;
    min-height: 48px;
    margin-top: 10px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalCard__button {
        margin: 25px 0 20px 0;
        width: calc(100% - 40px); } }
  .ProfessionalCard__information {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 2.5 1;
    width: 100%; }
    .ProfessionalCard__information__priceWrapper {
      display: flex;
      align-items: center;
      margin-top: 10px; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__information__priceWrapper {
          flex-direction: column;
          align-items: flex-start; } }
    .ProfessionalCard__information__priceDurationContainer {
      display: flex;
      flex-direction: row;
      align-items: baseline; }
    .ProfessionalCard__information__skillsContainer {
      display: flex;
      flex-wrap: wrap;
      font-size: 15px; }
      .ProfessionalCard__information__skillsContainer__item {
        margin-right: 15px;
        margin-bottom: 5px; }
        .ProfessionalCard__information__skillsContainer__item--active {
          color: #1F35B5;
          font-weight: bold;
          order: -1; }
          .ProfessionalCard__information__skillsContainer__item--active::after {
            content: '✔\fe0e';
            margin-left: 4px; }
    .ProfessionalCard__information__educationsContainer__item {
      margin-bottom: 15px; }
    .ProfessionalCard__information__tabContainer {
      margin-top: 20px;
      overflow: hidden;
      width: 100%;
      -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent); }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__information__tabContainer {
          width: 100%;
          padding: 0 20px 0 20px; } }
      .ProfessionalCard__information__tabContainer__panelContainer {
        height: 112px;
        max-width: 503px; }
      .ProfessionalCard__information__tabContainer__labelContainer {
        display: flex;
        width: 60%; }
      .ProfessionalCard__information__tabContainer__bio {
        line-height: 22px !important;
        overflow: hidden; }
    .ProfessionalCard__information__name {
      text-transform: capitalize;
      line-height: 25px !important; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__information__name {
          font-size: 18px !important;
          line-height: 18px !important; } }
    .ProfessionalCard__information__doc {
      margin-top: 3px !important; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__information__doc {
          font-size: 12px !important; } }
    .ProfessionalCard__information__playvideo, .ProfessionalCard__information__reviews {
      display: flex;
      align-items: center;
      margin-left: 15px;
      grid-gap: 5px;
      gap: 5px;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__information__playvideo, .ProfessionalCard__information__reviews {
          margin: 14px 0 0 0;
          padding: 0; } }
      .ProfessionalCard__information__playvideo:hover, .ProfessionalCard__information__reviews:hover {
        opacity: 0.7; }
      .ProfessionalCard__information__playvideo__text, .ProfessionalCard__information__reviews__text {
        color: #1F35B5 !important; }
    .ProfessionalCard__information__wrapper {
      display: flex;
      flex-direction: row; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__information__wrapper {
          padding: 20px 20px 0 20px; } }
    .ProfessionalCard__information__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1.5rem;
      width: auto; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__information__content {
          width: 60%; } }
  .ProfessionalCard__availabilities {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 300px;
    height: 220px;
    margin-top: 10px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalCard__availabilities {
        margin-top: 0;
        height: auto;
        width: 100%; } }
    .ProfessionalCard__availabilities__status {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 170px;
      width: 300px; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__availabilities__status {
          min-height: 35px;
          width: 100%; } }
    .ProfessionalCard__availabilities__slots {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 34px);
      overflow-y: scroll;
      width: 300px;
      grid-gap: 10px;
      padding-right: 10px;
      overflow-x: hidden;
      height: 170px; }
      .ProfessionalCard__availabilities__slots::-webkit-scrollbar {
        border-radius: 10px;
        height: 10px;
        width: 5px;
        background-color: #F5F5F5; }
      .ProfessionalCard__availabilities__slots::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 10px; }
      .ProfessionalCard__availabilities__slots::-webkit-scrollbar-track {
        border-radius: 10px; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__availabilities__slots {
          display: flex;
          flex-direction: row;
          align-items: center;
          overflow-x: auto;
          white-space: nowrap;
          padding: 0 20px;
          width: 100%;
          height: auto;
          scrollbar-width: none;
          -ms-overflow-style: none; }
          .ProfessionalCard__availabilities__slots::-webkit-scrollbar {
            display: none; }
          .ProfessionalCard__availabilities__slots::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 0; } }
      .ProfessionalCard__availabilities__slots li {
        border: 1px solid #c1c1c1;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 64px;
        height: 35px; }
    .ProfessionalCard__availabilities__controlsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 25px; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalCard__availabilities__controlsContainer {
          padding: 20px 20px 0 20px;
          border-top: 1px solid #eee;
          margin-top: 20px; } }
      .ProfessionalCard__availabilities__controlsContainer__controls {
        display: flex;
        flex-direction: row;
        grid-gap: 20px;
        gap: 20px;
        justify-content: space-between; }
        .ProfessionalCard__availabilities__controlsContainer__controls button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background: none;
          padding: 0; }
          .ProfessionalCard__availabilities__controlsContainer__controls button:disabled {
            opacity: 0.2;
            cursor: not-allowed; }
      .ProfessionalCard__availabilities__controlsContainer__selectedDayContainer {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%; }
        .ProfessionalCard__availabilities__controlsContainer__selectedDayContainer__wrapper {
          display: flex;
          align-items: baseline; }
        @media (max-width: 350px) {
          .ProfessionalCard__availabilities__controlsContainer__selectedDayContainer {
            flex-direction: column;
            align-items: flex-start; } }
        .ProfessionalCard__availabilities__controlsContainer__selectedDayContainer__selectedShortDay {
          display: inline-block;
          text-transform: uppercase; }
          @media (min-width: 0px) and (max-width: 1096px) {
            .ProfessionalCard__availabilities__controlsContainer__selectedDayContainer__selectedShortDay {
              font-size: 15px !important; } }
        @media (min-width: 0px) and (max-width: 1096px) {
          .ProfessionalCard__availabilities__controlsContainer__selectedDayContainer__selectedLongDay {
            font-size: 15px !important; } }
        .ProfessionalCard__availabilities__controlsContainer__selectedDayContainer__selectedDay {
          display: inline-block; }
          @media (min-width: 0px) and (max-width: 1096px) {
            .ProfessionalCard__availabilities__controlsContainer__selectedDayContainer__selectedDay {
              font-size: 15px !important; } }

.ProfessionalsNetwork {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background: #1F35B5;
  height: 60px;
  width: 311px;
  border-radius: 50px;
  padding: 14px 25px; }
  .ProfessionalsNetwork__Text {
    color: #fff !important; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalsNetwork {
      width: 100%;
      border-radius: 5px;
      background: #fff;
      margin-top: 20px;
      box-shadow: 0px 1px 3px #00000029; }
      .ProfessionalsNetwork__Text {
        color: #4B4B4B !important; } }

.ProfessionalChip {
  border-radius: 0.5rem;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 100px;
  background-color: white;
  min-width: 100%; }
  .ProfessionalChip__info {
    display: inline-block;
    flex: 1 1;
    padding-left: 15px;
    width: calc(100% - 50px); }
    .ProfessionalChip__info p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #4B4B4B; }
  .ProfessionalChip__image {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center center;
    background-color: #CCCCCC;
    border-radius: 100%;
    margin-left: 10px; }
  .ProfessionalChip:hover {
    background-color: #ebebeb; }

.ProfileEducation {
  width: 100%; }
  .ProfileEducation--empty {
    margin-top: 1.4rem;
    font-size: 15px;
    font-family: "Ubuntu";
    color: #4b4b4b;
    font-style: italic; }

.profile-list-item {
  background-color: #ffffff;
  border-top: 1px solid #dedede; }
  .profile-list-item:first-child {
    border-top: none; }
  .profile-list-item--isOpen {
    background-color: #f5f5f5;
    border-top: 1px solid #dedede; }
  .profile-list-item--avatar {
    align-self: center;
    height: 5rem;
    width: 5rem; }
  .profile-list-item--info {
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin-left: 1.5rem; }
    .profile-list-item--info--status, .profile-list-item--info--status--active, .profile-list-item--info--status--inactive, .profile-list-item--info--status--uncomplete, .profile-list-item--info--id {
      margin-top: 0.7rem; }
    .profile-list-item--info--status, .profile-list-item--info--status--active, .profile-list-item--info--status--inactive, .profile-list-item--info--status--uncomplete {
      align-items: center;
      display: flex;
      margin-left: 1.5rem;
      width: auto; }
      .profile-list-item--info--status svg, .profile-list-item--info--status--active svg, .profile-list-item--info--status--inactive svg, .profile-list-item--info--status--uncomplete svg {
        margin-right: 0.5rem;
        width: 1.3rem; }
      .profile-list-item--info--status--active {
        color: #4ac326; }
        .profile-list-item--info--status--active svg {
          fill: #4ac326; }
      .profile-list-item--info--status--inactive {
        color: #eb384d; }
        .profile-list-item--info--status--inactive svg {
          fill: #eb384d; }
      .profile-list-item--info--status--uncomplete {
        color: #f9a435; }
        .profile-list-item--info--status--uncomplete svg {
          fill: #f9a435; }
  .profile-list-item--menu {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative; }
  .profile-list-item--float-menu {
    align-self: flex-start;
    display: block;
    position: absolute;
    left: -14.5rem;
    min-width: 17.8rem;
    top: 2.3rem;
    z-index: 1; }

.ProfileSpecialities {
  margin-top: 1.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .ProfileSpecialities--empty {
    font-size: 15px;
    font-family: "Ubuntu";
    color: #4b4b4b;
    font-style: italic; }
  .ProfileSpecialities__item {
    margin: 5px;
    margin-left: 0; }

.Questionnaire {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%; }
  @media (min-width: 1096px) {
    .Questionnaire {
      flex: 1 1;
      flex-direction: column;
      align-items: center; } }
  .Questionnaire__text {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 29.4rem;
    margin-bottom: 1rem; }
    @media (min-width: 1096px) {
      .Questionnaire__text {
        width: 59rem; } }
  .Questionnaire__numbers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    @media (min-width: 1096px) {
      .Questionnaire__numbers {
        display: flex;
        flex-direction: row;
        overflow: hidden; } }
    .Questionnaire__numbers::-webkit-scrollbar {
      display: none; }

.RadioButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start; }
  .RadioButton__icon {
    align-items: center;
    display: flex;
    margin-right: 5px; }
    .RadioButton__icon svg {
      fill: #1f35b5; }
  .RadioButton__text.disabled {
    color: #c4c4c4; }

.ResultNotFound {
  font-family: "Ubuntu";
  font-weight: 700;
  width: 100%; }

.RadioButtonFilter, .RadioButtonFilter--checked {
  align-items: center;
  border: 0.1rem dashed #c1c1c1;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  height: 4.8rem;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 0 1.5rem;
  width: 100%; }
  .RadioButtonFilter:first-of-type, .RadioButtonFilter--checked:first-of-type {
    margin-top: 0; }
  .RadioButtonFilter:last-of-type, .RadioButtonFilter--checked:last-of-type {
    margin-bottom: 0; }
  .RadioButtonFilter__Check {
    border: 0.1rem solid #c1c1c1;
    border-radius: 5rem;
    height: 2rem;
    width: 2rem; }
    .RadioButtonFilter__Check svg {
      display: none; }
  .RadioButtonFilter--checked {
    background-color: #22cbd3;
    border: 1px solid #22cbd3; }
    .RadioButtonFilter--checked .RadioButtonFilter__Check {
      border: 0; }
      .RadioButtonFilter--checked .RadioButtonFilter__Check svg {
        display: block; }

.RadioToggle {
  font-family: "Ubuntu";
  font-weight: 400;
  align-items: center;
  border: 1px solid #c3c3c3;
  border-radius: 4rem;
  color: #898989;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.1rem 0.7rem; }
  .RadioToggle.active {
    background-color: #1f35b5;
    border: 1px solid #1f35b5;
    color: #ffffff; }
  .RadioToggle__text {
    padding: 0 1rem; }
  .RadioToggle__icon {
    margin-top: 0.5rem; }

@media (min-width: 1096px) {
  .row-mansory {
    -webkit-columns: 50% !important;
            columns: 50% !important;
    -webkit-column-count: 2 !important;
            column-count: 2 !important;
    display: flow-root !important; } }

.SkillsSelection {
  margin-bottom: 4rem; }
  .SkillsSelection__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem; }
    .SkillsSelection__options .RadioToggle {
      flex-shrink: 0;
      margin-right: 0.5rem;
      margin-top: 1rem; }

.SlotCard {
  color: #4b4b4b;
  align-items: center;
  border: 0.1rem dashed #4b4b4b;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
  padding: 1.4rem 1.7rem;
  width: 100%; }
  .SlotCard__icon {
    cursor: pointer;
    height: 2.4rem; }

@keyframes Spinner {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

@-webkit-keyframes Spinner {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

.Spinner {
  position: relative; }

.Spinner div,
.Spinner div:after {
  position: absolute;
  width: 160px;
  height: 160px;
  border: 20px solid #1F35B5;
  border-top-color: transparent;
  border-radius: 50%; }

.Spinner .white,
.Spinner .white:after {
  position: absolute;
  width: 160px;
  height: 160px;
  border: 20px solid #FFFFFF;
  border-top-color: transparent;
  border-radius: 50%; }

.Spinner .black,
.Spinner .black:after {
  position: absolute;
  width: 160px;
  height: 160px;
  border: 20px solid #4B4B4B;
  border-top-color: transparent;
  border-radius: 50%; }

.Spinner .yellow-gray,
.Spinner .yellow-gray:after {
  position: absolute;
  width: 160px;
  height: 160px;
  border: 20px solid #BEBD73;
  border-top-color: transparent;
  border-radius: 50%; }

.Spinner div {
  -webkit-animation: Spinner 1s linear infinite;
  animation: Spinner 1s linear infinite;
  top: 100px;
  left: 100px; }

.Spinner div:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.Spinner {
  width: 42px !important;
  height: 42px !important;
  -webkit-transform: translate(-21px, -21px) scale(0.21) translate(21px, 21px);
  transform: translate(-21px, -21px) scale(0.21) translate(21px, 21px); }
  .Spinner__message {
    margin-top: 2rem; }

.StatusBox, .StatusBox--default, .StatusBox--cancelled, .StatusBox--scheduled, .StatusBox--attending, .StatusBox--attended {
  font-family: "Ubuntu";
  font-weight: 700;
  border: none;
  border-radius: 1rem;
  height: 10rem;
  text-align: center;
  width: 7.5rem;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0.6rem; }
  .StatusBox--cancelled {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #ebebeb; }
  .StatusBox--scheduled {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #4ac326; }
  .StatusBox--attending {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #f0bd35; }
  .StatusBox--attended {
    font-family: "Ubuntu";
    font-weight: 400;
    background-color: #ebebeb; }
  .StatusBox .footer, .StatusBox--default .footer, .StatusBox--cancelled .footer, .StatusBox--scheduled .footer, .StatusBox--attending .footer, .StatusBox--attended .footer {
    display: flex;
    flex-direction: column;
    letter-spacing: -0.1rem;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    .StatusBox .footer__icon, .StatusBox--default .footer__icon, .StatusBox--cancelled .footer__icon, .StatusBox--scheduled .footer__icon, .StatusBox--attending .footer__icon, .StatusBox--attended .footer__icon {
      display: flex;
      justify-content: space-around; }
    .StatusBox .footer__text, .StatusBox--default .footer__text, .StatusBox--cancelled .footer__text, .StatusBox--scheduled .footer__text, .StatusBox--attending .footer__text, .StatusBox--attended .footer__text {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      color: #ffffff;
      letter-spacing: -0.1rem;
      line-height: 1.3rem; }
  .StatusBox .header, .StatusBox--default .header, .StatusBox--cancelled .header, .StatusBox--scheduled .header, .StatusBox--attending .header, .StatusBox--attended .header {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column-reverse; }
    .StatusBox .header__time, .StatusBox--default .header__time, .StatusBox--cancelled .header__time, .StatusBox--scheduled .header__time, .StatusBox--attending .header__time, .StatusBox--attended .header__time {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -0.1rem;
      line-height: 1.3rem;
      padding-top: 0.9rem; }
    .StatusBox .header__date, .StatusBox--default .header__date, .StatusBox--cancelled .header__date, .StatusBox--scheduled .header__date, .StatusBox--attending .header__date, .StatusBox--attended .header__date {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -0.1rem;
      flex-direction: column; }

.SearchPlaceholder {
  display: flex;
  justify-content: center;
  align-self: center;
  align-content: center;
  flex-direction: column;
  justify-items: center;
  align-items: center; }
  .SearchPlaceholder__icon {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 1rem; }
  .SearchPlaceholder__title {
    display: flex;
    align-content: center;
    color: #898989;
    font-size: 1.5rem; }
  .SearchPlaceholder__text {
    display: flex;
    align-content: center;
    color: #898989;
    font-size: 1.2rem;
    font-style: italic; }

.StepperProgress {
  width: 100%; }
  .StepperProgress .Step {
    font-family: "Ubuntu";
    font-weight: 700;
    font-size: 1.5rem !important;
    border-bottom: 0.5rem solid #c4c4c4;
    text-align: left;
    width: 100%; }
    @media screen and (min-width: 1096px) {
      .StepperProgress .Step {
        font-size: 1.5rem !important; } }
    .StepperProgress .Step span {
      padding: 0 40%; }
    .StepperProgress .Step:before {
      bottom: -1.3rem;
      content: "\2022";
      left: 94%;
      position: relative; }
    .StepperProgress .Step-doing, .StepperProgress .Step-done {
      color: #1f35b5;
      border-bottom: 0.5rem solid #1f35b5; }

.SimplePersonItem {
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-bottom: 0.1rem solid #ebebeb;
  padding: 2rem 1rem;
  width: 100%; }
  .SimplePersonItem__PersonData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row no-wrap;
    width: 90%; }
    .SimplePersonItem__PersonData__image {
      display: flex; }
    .SimplePersonItem__PersonData__name {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 73%;
      flex-flow: row no-wrap;
      flex-direction: column; }
      @media (min-width: 1096px) {
        .SimplePersonItem__PersonData__name {
          width: 90%; } }
  .SimplePersonItem__Button {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    min-width: 7rem;
    width: 10%; }
    .SimplePersonItem__Button button {
      display: flex;
      align-items: center;
      justify-content: center; }
  .SimplePersonItem__separator {
    background-color: #e2e2e2;
    height: 0.1rem;
    margin: 1rem 0;
    width: 100%; }

.StickyIndex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  width: 100%;
  height: 3.5rem; }
  .StickyIndex__day {
    display: flex;
    justify-content: flex-start;
    width: 50%; }
  .StickyIndex__date {
    display: flex;
    justify-content: flex-end;
    width: 50%; }

.test {
  background-color: #000; }

.Tabs {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .Tabs__item, .Tabs__item-selected {
    border-bottom: 1px solid #acacac;
    cursor: pointer;
    color: #acacac;
    display: flex;
    justify-content: center;
    padding: 1.2rem 0 1.1rem;
    width: 100%; }
    .Tabs__item-selected {
      border-bottom: 2px solid #1f35b5;
      color: #1f35b5; }
      .Tabs__item-selected__quantity {
        color: #4b4b4b;
        padding-left: 3px; }
    .Tabs__item__quantity {
      padding-left: 3px; }

.Textarea__inputContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 24.8rem;
  border: 0.1rem solid #c1c1c1;
  border-radius: 0.5rem;
  color: #4b4b4b;
  flex-shrink: 0;
  font-family: "Ubuntu";
  font-size: 1.5rem;
  height: 100%;
  padding: 1.5rem;
  resize: none; }
  .Textarea__inputContent:focus {
    border-color: #1f35b5;
    color: #4b4b4b;
    opacity: 1;
    outline: none; }
  .Textarea__inputContent::-webkit-input-placeholder {
    color: #c4c4c4; }
  .Textarea__inputContent::placeholder {
    color: #c4c4c4; }
  .Textarea__inputContent:disabled {
    background-color: #f5f5f5;
    color: #acacac;
    border: 0.1rem solid #dedede; }
    .Textarea__inputContent:disabled::-webkit-input-placeholder {
      color: #acacac; }
    .Textarea__inputContent:disabled::placeholder {
      color: #acacac; }
  .Textarea__inputContent .inputContent textarea {
    resize: none; }
    .Textarea__inputContent .inputContent textarea::-webkit-resizer {
      display: none; }
    .Textarea__inputContent .inputContent textarea::-webkit-input-placeholder {
      color: #c4c4c4; }
    .Textarea__inputContent .inputContent textarea::placeholder {
      color: #c4c4c4; }

.TextToggleFilter, .TextToggleFilter:hover, .TextToggleFilter--active {
  align-content: space-between;
  align-items: center;
  border: dashed 1px #acacac;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 4.8rem;
  justify-content: space-between;
  padding: 2rem 2rem;
  width: 100%; }
  .TextToggleFilter:hover, .TextToggleFilter--active:hover, .TextToggleFilter--active {
    background-color: #22cbd3;
    border-color: #22cbd3; }
    .TextToggleFilter:hover p, .TextToggleFilter--active:hover p, .TextToggleFilter--active p {
      color: #ffffff;
      transition: 150ms; }
    .TextToggleFilter:hover path, .TextToggleFilter--active:hover path, .TextToggleFilter--active path {
      fill: #ffffff; }

.TextDetail {
  margin: 2rem 0; }
  .TextDetail * {
    display: inline; }

.TextInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem; }
  .TextInput .inputContent {
    display: flex;
    height: 100%;
    width: 100%; }
    .TextInput .inputContent.error input {
      border-color: #ff4949;
      opacity: 1;
      outline: none; }
    .TextInput .inputContent.error input:focus {
      border-color: #eb384d;
      opacity: 1;
      outline: none; }
    .TextInput .inputContent input {
      border: 0.1rem solid #c1c1c1;
      border-radius: 0.5rem;
      color: #4b4b4b;
      flex-shrink: 0;
      font-family: "Ubuntu";
      font-size: 1.5rem;
      height: 100%;
      padding: 1.5rem;
      width: 100%; }
      .TextInput .inputContent input::-webkit-input-placeholder {
        color: #c1c1c1; }
      .TextInput .inputContent input::placeholder {
        color: #c1c1c1; }
      .TextInput .inputContent input:disabled {
        background: #f5f5f5;
        color: #c1c1c1; }
      .TextInput .inputContent input:focus {
        border-color: #1f35b5;
        color: #4b4b4b;
        opacity: 1;
        outline: none; }
      .TextInput .inputContent input[name=password] {
        padding: 1.8rem 5rem 1.8rem 1.8rem; }
    .TextInput .inputContent span {
      align-content: center;
      align-items: center;
      display: flex;
      float: right;
      margin-left: -4rem;
      position: relative; }
  .TextInput-password {
    cursor: pointer; }
  .TextInput__Button {
    cursor: pointer; }
  .TextInput .messageError {
    color: #ff4949;
    font-size: 1.2rem;
    margin-left: 0; }
    .TextInput .messageError span {
      margin: 0; }
    .TextInput .messageError__topError {
      top: 0;
      position: absolute;
      color: #ff4949;
      font-size: 1.2rem;
      margin-left: 0; }
      .TextInput .messageError__topError span {
        margin: 0; }
  .TextInput input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset; }

.IntegerInput::-webkit-outer-spin-button,
.IntegerInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0; }

.TimestampTag {
  width: 100%; }
  .TimestampTag > svg {
    padding-top: 8px;
    margin-right: 8px; }
  .TimestampTag__timezone {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 15px;
    color: #898989; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .TimestampTag__timezone {
        flex-direction: column; } }
    .TimestampTag__timezone__icon {
      margin-right: 5px; }
    .TimestampTag__timezone__wrapper {
      display: flex;
      align-items: center; }

.TimestampTag--card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EBEBEB;
  border-radius: 8px; }

.toast, .toast-info, .toast-error, .toast-error-inverted, .toast-warn, .toast-primary, .toast-success {
  align-items: center;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0rem;
  text-align: start;
  width: 100%;
  padding: 2rem; }
  .toast-icon {
    color: #ffffff;
    height: 2.2rem;
    width: 2.2rem;
    text-align: center;
    min-width: 50px; }
  .toast-message {
    color: #ffffff;
    font-size: 1.6rem; }
    @media (min-width: 768px) {
      .toast-message {
        max-width: 100%; } }
  .toast-info {
    background: #d1ecf1;
    border: 1px solid #bee5eb; }
    .toast-info span {
      color: #0c5460; }
  .toast-error {
    background: #ef6b7b; }
    .toast-error span {
      color: #ffffff; }
  .toast-error-inverted {
    color: #ef6b7b; }
    .toast-error-inverted span {
      color: #ef6b7b; }
  .toast-warn {
    background: #fff3cd;
    border: 1px solid #ffeeba; }
    .toast-warn span {
      color: #856404; }
  .toast-primary {
    background: #d2d7f0;
    border: 1px solid #1f35b5; }
    .toast-primary span {
      color: #ffffff; }
  .toast-success {
    background: #4AC326;
    border: 1px solid #4AC326; }
    .toast-success span {
      color: #ffffff; }

.ToastGenericError {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFE6E6;
  width: 100%;
  height: 67px;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999999;
  padding: 0px 1.4rem;
  -webkit-animation: slide 0.5s ease;
          animation: slide 0.5s ease; }
  @media (min-width: 1096px) {
    .ToastGenericError {
      padding: 0px 30px; } }
  .ToastGenericError__BoxAlert {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(100% - 35px);
    padding-left: 0px; }
    @media (min-width: 1096px) {
      .ToastGenericError__BoxAlert {
        padding-left: 35px; } }
    .ToastGenericError__BoxAlert__Icon {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      margin-top: -10px; }
    .ToastGenericError__BoxAlert__Text {
      font-family: Ubuntu;
      font-size: 15px;
      line-height: 15px; }
  .ToastGenericError__BoxClose {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px; }
    .ToastGenericError__BoxClose__IconClose {
      cursor: pointer; }

@-webkit-keyframes slide {
  0% {
    position: fixed;
    top: -67px;
    left: 0; }
  100% {
    position: fixed;
    top: 0px;
    left: 0; } }

@keyframes slide {
  0% {
    position: fixed;
    top: -67px;
    left: 0; }
  100% {
    position: fixed;
    top: 0px;
    left: 0; } }

.ToggleButton, .ToggleButton--active {
  align-content: center;
  align-items: center;
  border: dashed 1px #acacac;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 0.6rem;
  width: 102px;
  margin: 4px;
  max-height: 90px; }
  .ToggleButton:hover, .ToggleButton--active:hover {
    opacity: 0.8; }
  .ToggleButton--active {
    background-color: #22cbd3;
    border-color: #22cbd3; }
    .ToggleButton--active p {
      color: #eb384d;
      transition: 250ms; }
  .ToggleButton__icon {
    display: flex;
    height: 3.75rem;
    width: 3.75rem;
    justify-content: center;
    justify-items: center;
    position: relative; }
    .ToggleButton__icon img {
      display: inline;
      height: 100%;
      margin: 0 auto;
      width: auto; }
  .ToggleButton__labelValue {
    font-size: 12px !important; }
  .ToggleButton .text, .ToggleButton--active .text {
    display: flex;
    line-height: 0.8;
    justify-content: center;
    justify-items: center;
    width: 100%;
    margin-top: 6px;
    letter-spacing: 0px;
    line-height: 1.3rem;
    font-family: "Ubuntu";
    font-weight: 700; }

.profile-list-items {
  background-color: transparent; }
  .profile-list-items--isOpen {
    background-color: transparent; }
  .profile-list-items--menu {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative; }
    .profile-list-items--menu svg {
      fill: #4b4b4b; }
  .profile-list-items--float-menu {
    align-self: flex-start;
    display: block;
    position: absolute;
    left: -135px;
    top: 2.4rem;
    z-index: 1; }

.typography {
  white-space: pre-line; }
  .typography.title {
    font-size: 2.1rem !important;
    font-family: "Ubuntu";
    font-weight: 500;
    line-height: 2.1rem; }
    @media screen and (min-width: 1096px) {
      .typography.title {
        font-size: 3rem !important; } }
  .typography.regular-title {
    font-size: 2.1rem !important;
    font-family: "Ubuntu";
    font-weight: 400; }
    @media screen and (min-width: 1096px) {
      .typography.regular-title {
        font-size: 3rem !important; } }
  .typography.subtitle {
    font-size: 1.8rem !important;
    font-family: "Ubuntu";
    font-weight: 500; }
    @media screen and (min-width: 1096px) {
      .typography.subtitle {
        font-size: 1.8rem !important; } }
  .typography.paragraph {
    font-size: 1.5rem !important;
    font-family: "Ubuntu";
    font-weight: 400;
    line-height: 1.2; }
    @media screen and (min-width: 1096px) {
      .typography.paragraph {
        font-size: 1.5rem !important; } }
  .typography.paragraph-medium {
    font-size: 1.5rem !important;
    font-family: "Ubuntu";
    font-weight: 500; }
    @media screen and (min-width: 1096px) {
      .typography.paragraph-medium {
        font-size: 1.5rem !important; } }
  .typography.heading1 {
    font-size: 2.4rem !important;
    font-family: "Ubuntu";
    font-weight: 500; }
    @media screen and (min-width: 1096px) {
      .typography.heading1 {
        font-size: 2.4rem !important; } }
  .typography.heading2 {
    font-size: 2.4rem !important;
    font-family: "Ubuntu";
    font-weight: 400;
    line-height: 1.1; }
    @media screen and (min-width: 1096px) {
      .typography.heading2 {
        font-size: 2.4rem !important; } }
  .typography.heading4 {
    font-size: 2.1rem !important;
    font-family: "Ubuntu";
    font-weight: 500; }
    @media screen and (min-width: 1096px) {
      .typography.heading4 {
        font-size: 2.1rem !important; } }
  .typography.heading5 {
    font-size: 1.8rem !important;
    font-family: "Ubuntu";
    font-weight: 1.8rem; }
    @media screen and (min-width: 1096px) {
      .typography.heading5 {
        font-size: 1.8rem !important; } }
  .typography.heading6 {
    font-size: 1.5rem !important;
    font-family: "Ubuntu";
    font-weight: 400; }
    @media screen and (min-width: 1096px) {
      .typography.heading6 {
        font-size: 1.5rem !important; } }
  .typography.subheading1 {
    font-size: 1.2rem !important;
    font-family: "Ubuntu";
    font-weight: 300; }
    @media screen and (min-width: 1096px) {
      .typography.subheading1 {
        font-size: 1.2rem !important; } }
  .typography.subheading2 {
    font-size: 1.2rem !important;
    font-family: "Ubuntu";
    font-weight: 400; }
    @media screen and (min-width: 1096px) {
      .typography.subheading2 {
        font-size: 1.2rem !important; } }
  .typography.menu-item {
    font-size: 1.8rem !important;
    font-family: "Ubuntu";
    font-weight: 500; }
    @media screen and (min-width: 1096px) {
      .typography.menu-item {
        font-size: 1.5rem !important; } }
  .typography.italic {
    font-style: italic; }
  .typography.bold {
    font-family: "Ubuntu";
    font-weight: 700; }
  .typography.uppercase {
    text-transform: uppercase; }
  .typography.underline {
    text-decoration: underline; }
  .typography.gutter-bottom {
    margin-bottom: 2.0rem; }
  .typography.left {
    text-align: left; }
  .typography.mcenter-dleft {
    text-align: center;
    width: 100%; }
    @media (min-width: 1096px) {
      .typography.mcenter-dleft {
        width: auto;
        text-align: left; } }
  .typography.mcenter-dright {
    text-align: center;
    width: 100%; }
    @media (min-width: 1096px) {
      .typography.mcenter-dright {
        width: auto;
        text-align: right; } }
  .typography.center {
    text-align: center; }
  .typography.right {
    text-align: right; }
  .typography.inherit {
    text-align: inherit; }
  .typography.justify {
    text-align: justify; }
  .typography.action-online {
    color: #4ac326; }
  .typography.orange {
    color: #F9A435; }
  .typography.color-title {
    color: #4b4b4b; }
  .typography.grey {
    color: #dedede; }
  .typography.grey-dark {
    color: #c4c4c4; }
  .typography.grey-darker {
    color: #acacac; }
  .typography.grey-light {
    color: #e2e2e2; }
  .typography.grey-ten {
    color: #898989; }
  .typography.grey-eleven {
    color: #4b4b4b; }
  .typography.primary {
    color: #1f35b5; }
  .typography.white {
    color: #ffffff; }
  .typography.black {
    color: #000000; }
  .typography.false {
    color: #ff4949; }
  .typography.red-lighter {
    color: #ff4949; }
  .typography.aqua {
    color: #03DAC6; }
  .typography.clickable {
    font-family: "Ubuntu";
    font-weight: 700;
    cursor: pointer;
    color: #1f35b5; }
    .typography.clickable:hover {
      opacity: 0.5; }

.PortalModal {
  display: flex;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 98;
  padding: '0 14px';
  overflow: auto;
  align-items: flex-start;
  justify-content: center; }
  @media (min-width: 1096px) {
    .PortalModal {
      padding: 0; } }
  .PortalModal-isFixed {
    display: flex;
    height: 100vh;
    place-content: center center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99; }
  .PortalModal-align-center {
    align-items: center; }
  .PortalModal__backdrop {
    -webkit-animation: openModal 0.5s ease;
            animation: openModal 0.5s ease;
    background-color: #dedede;
    height: 100%;
    opacity: 0.9;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0; }
  .PortalModal__container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    max-width: 548px;
    padding: 2rem 0 3rem;
    position: relative;
    width: 100%;
    z-index: 101; }
    @media (min-width: 1096px) {
      .PortalModal__container {
        height: calc(100vh - 60px);
        overflow-y: scroll;
        margin-top: 6rem; } }
    .PortalModal__container--scrollable {
      height: calc(90% - 5rem); }
    .PortalModal__container__close {
      align-self: flex-end;
      -webkit-animation: closeModal 0.5s ease;
              animation: closeModal 0.5s ease;
      cursor: pointer;
      display: flex;
      min-height: 1.6px;
      padding: 0 2rem 0;
      position: absolute;
      top: 14px;
      z-index: 500; }
    .PortalModal__container__content {
      padding: 1.4rem 1.4rem 0;
      width: 100%; }
      @media (min-width: 520px) {
        .PortalModal__container__content {
          padding: 1.4rem 9.4rem 0; } }
      .PortalModal__container__content--scrollable {
        padding: 1.4rem 0rem 0;
        width: 100%;
        height: 100%; }
        @media (min-width: 520px) {
          .PortalModal__container__content--scrollable {
            padding: 1.4rem 0rem 0; } }
    .PortalModal__container--scrollable {
      padding: 2rem 0 0rem; }

.CreditsPanel {
  width: 100%;
  min-height: 90px;
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .CreditsPanel--closed .PatientEligibility__content, .CreditsPanel--closed .PatientEligibility__footer {
    height: 0;
    overflow: hidden; }
  .CreditsPanel--closed .PatientEligibility__title {
    margin-bottom: 0; }
  .CreditsPanel--closed .PatientEligibility__footer {
    margin: 0;
    padding: 0 !important;
    border-top: initial; }
  .CreditsPanel__container {
    width: 1096px;
    height: 100%;
    padding: 25px;
    border-radius: 20px 20px 0 0;
    background-color: #FFFFB5;
    box-shadow: 0px 0px 7px #00000029;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .CreditsPanel__container {
        width: 100%;
        align-items: stretch; } }
  .CreditsPanel__top {
    width: 78px;
    height: 24px;
    background-color: #FFFFB5;
    border-radius: 20px 20px 0 0;
    position: relative;
    bottom: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .CreditsPanel__top::after {
      content: "";
      position: absolute;
      bottom: -3px;
      width: 85px;
      height: 31px;
      border-radius: 25px 25px 0 0;
      background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
      z-index: -1; }
  .CreditsPanel__icon {
    width: 24px;
    color: #CCCB87;
    margin-top: 5px; }
    .CreditsPanel__icon--up {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .CreditsPanel .PatientEligibility__content {
    display: flex;
    flex-direction: column;
    max-width: 520px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .CreditsPanel .PatientEligibility__content {
        max-width: 100%; } }
  @media (min-width: 1096px) {
    .CreditsPanel .PatientEligibility__info {
      flex-direction: row;
      justify-content: center; } }
  @media (min-width: 1096px) {
    .CreditsPanel .PatientEligibility__info p strong {
      padding: 0 4px; } }
  .CreditsPanel .PatientEligibility__info__credButton {
    max-width: 520px;
    margin: 0 auto; }
  @media (min-width: 1096px) {
    .CreditsPanel .PatientEligibility__textGroup {
      justify-content: center; } }
  @media (min-width: 1096px) {
    .CreditsPanel .PatientEligibility__textGroup:first-child {
      margin-right: 20px; } }
  .CreditsPanel .PatientEligibility__title {
    grid-gap: 12px;
    gap: 12px; }
  @media (min-width: 1096px) {
    .CreditsPanel .PatientEligibility__title,
    .CreditsPanel .PatientEligibility__info p, .CreditsPanel .PatientEligibility__message {
      display: flex;
      justify-content: center; }
      .CreditsPanel .PatientEligibility__title p,
      .CreditsPanel .PatientEligibility__info p p, .CreditsPanel .PatientEligibility__message p {
        max-width: 520px;
        text-align: left; } }
  @media (min-width: 768px) and (max-width: 1096px) {
    .CreditsPanel .PatientEligibility__title,
    .CreditsPanel .PatientEligibility__info p, .CreditsPanel .PatientEligibility__message {
      text-align: center; } }
  .CreditsPanel .PatientEligibility__title__groupName, .CreditsPanel .PatientEligibility__corporate--ineligible {
    color: #BEBD73; }
  .CreditsPanel .PatientEligibility__footer {
    max-width: 520px;
    width: 100%;
    padding: 20px 0; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .CreditsPanel .PatientEligibility__footer {
        max-width: 100%;
        margin: 15px 0 0;
        padding-bottom: 0; } }
    .CreditsPanel .PatientEligibility__footer__arrows {
      margin-right: -7px; }
  .CreditsPanel .PatientEligibility__message {
    justify-content: flex-start; }

.PatientEligibility {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .PatientEligibility__message {
    display: flex; }
  .PatientEligibility__title {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .PatientEligibility__title__groupName {
      color: #c1c1c1;
      font-weight: normal;
      margin-left: 6px; }
    .PatientEligibility__title__icon {
      cursor: pointer;
      display: flex; }
  .PatientEligibility__content {
    width: 100%; }
  .PatientEligibility__text--available {
    color: #4AC326;
    font-weight: bold; }
  .PatientEligibility__text--unavailable {
    color: #ff4949;
    font-weight: bold; }
  .PatientEligibility__text--inelegible-corporate {
    color: #c1c1c1;
    font-weight: bold;
    text-decoration: line-through; }
  .PatientEligibility__text--inelegible-enterprise {
    color: #ff4949;
    text-decoration: initial; }
  .PatientEligibility__text--expired-date-corporate {
    color: #ff4949;
    font-weight: initial;
    text-decoration: initial; }
  .PatientEligibility__text--expired {
    text-decoration: line-through; }
  .PatientEligibility__textGroup {
    display: flex;
    justify-content: space-between; }
  .PatientEligibility__info {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .PatientEligibility__info p {
        text-align: left; } }
    .PatientEligibility__info p:first-child {
      padding-right: 5px; }
    .PatientEligibility__info__credButton {
      margin-top: 20px;
      text-align: center; }
  .PatientEligibility__messageText {
    line-height: 16px !important; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .PatientEligibility__messageText {
        text-align: left; } }
  .PatientEligibility__corporate--eligible strong {
    color: #4AC326; }
  .PatientEligibility__corporate--ineligible {
    text-decoration: line-through;
    color: #c1c1c1; }
  .PatientEligibility__footer {
    padding: 20px;
    margin: 18px -20px -20px -20px;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
    border-radius: 0 0 5px 5px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .PatientEligibility__footer__wrapper__question {
      cursor: pointer; }
      .PatientEligibility__footer__wrapper__question:hover {
        opacity: 0.5; }
    .PatientEligibility__footer__arrows {
      align-self: baseline;
      margin-top: 4px;
      display: flex;
      grid-gap: 20px;
      gap: 20px; }
      .PatientEligibility__footer__arrows button {
        background: initial;
        border: initial;
        cursor: pointer;
        padding: 0; }
        .PatientEligibility__footer__arrows button:disabled {
          cursor: not-allowed;
          opacity: 0.2; }

.AdminScheduleContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }
  .AdminScheduleContainer__Content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }
    .AdminScheduleContainer__Content__Empty {
      padding: 60px 0px 50px; }
      .AdminScheduleContainer__Content__Empty__Icon {
        display: flex;
        justify-content: center;
        margin-bottom: 20px; }

.AgendaCalendarContainer {
  position: relative;
  padding-bottom: 5px; }
  .AgendaCalendarContainer--open .AgendaCalendarContainer__DayPicker {
    border-radius: 0.5rem;
    box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    max-height: initial;
    pointer-events: auto;
    border-radius: 5px;
    padding: 0 8px 8px 8px; }
  .AgendaCalendarContainer--open .DayPicker-NavBar {
    display: block !important; }
  .AgendaCalendarContainer__Open {
    display: flex;
    justify-content: flex-end;
    padding-right: 20%;
    align-items: flex-end;
    padding-top: 13px;
    width: 100%;
    cursor: pointer; }
  .AgendaCalendarContainer__Close {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .AgendaCalendarContainer__DayPicker {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 48px;
    overflow: hidden;
    width: 100%;
    pointer-events: none; }
    .AgendaCalendarContainer__DayPicker .DayPicker-NavBar {
      display: none; }
    .AgendaCalendarContainer__DayPicker .DayPicker-Weekdays,
    .AgendaCalendarContainer__DayPicker .DayPicker-Body {
      padding: 1rem; }

.CartList {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%; }
  .CartList__populated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }
    .CartList__populated__name {
      margin-bottom: 0.5rem; }
  .CartList__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }
    .CartList__empty__text {
      justify-content: center !important; }
    .CartList__empty__button {
      justify-content: flex-end !important;
      margin: 15px auto 0 !important;
      max-width: 18.5rem; }

.Modal, .PortalModal-align-center, .Modal-align-center {
  display: flex;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 98;
  padding: 0;
  overflow: auto;
  align-items: flex-start;
  justify-content: center; }
  .Modal-isFixed {
    display: flex;
    height: 100vh;
    place-content: center center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99; }
  .Modal-align-center {
    align-items: center; }
  .Modal__backdrop {
    -webkit-animation: openModal 0.5s ease;
            animation: openModal 0.5s ease;
    background-color: #dedede;
    height: 100%;
    opacity: 0.9;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0; }
  .Modal__container, .PortalModal__container--scrollable, .Modal__container--scrollable {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    max-width: 548px;
    padding: 2rem 0 3rem;
    position: relative;
    width: 100%;
    z-index: 101; }
    @media (min-width: 1096px) {
      .Modal__container, .PortalModal__container--scrollable, .Modal__container--scrollable {
        margin-top: 6rem; } }
    .Modal__container--scrollable {
      height: calc(90% - 5rem); }
    .Modal__container__close {
      align-self: flex-end;
      -webkit-animation: closeModal 0.5s ease;
              animation: closeModal 0.5s ease;
      cursor: pointer;
      display: flex;
      min-height: 1.6px;
      padding: 0 2rem 0;
      position: absolute;
      top: 14px;
      z-index: 500; }
    .Modal__container__content {
      padding: 1.4rem 1.4rem 0;
      width: 100%; }
      @media (min-width: 520px) {
        .Modal__container__content {
          padding: 1.4rem 9.4rem 0; } }
      .Modal__container__content--scrollable {
        padding: 1.4rem 0rem 0;
        width: 100%;
        height: 100%; }
        @media (min-width: 520px) {
          .Modal__container__content--scrollable {
            padding: 1.4rem 0rem 0; } }
    .Modal__container--scrollable {
      padding: 2rem 0 0rem; }

.ModalGeneric__Backdrop {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999 !important;
  background-color: rgba(196, 196, 196, 0.9);
  padding-top: 0px; }
  @media (min-width: 1096px) {
    .ModalGeneric__Backdrop {
      padding-top: 60px; } }
  .ModalGeneric__Backdrop .--large {
    max-width: 729px; }
    .ModalGeneric__Backdrop .--large .ModalGeneric__Modal__Topbar, .ModalGeneric__Backdrop .--large .ModalGeneric__Modal__Topbar--active, .ModalGeneric__Backdrop .--large .ModalGeneric__Modal__Downbar--active, .ModalGeneric__Backdrop .--large .ModalGeneric__Modal__Topbar--active {
      max-width: 729px; }
    @media (min-width: 1096px) {
      .ModalGeneric__Backdrop .--large .ModalGeneric__Modal__Container {
        padding: 0px 35px 22px; } }

.ModalGeneric__Modal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 536px;
  overflow-y: overlay !important;
  padding: 10px;
  position: relative;
  scroll-behavior: smooth;
  border-radius: 20px;
  max-height: calc(100% - 60px) !important;
  background: #fff; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ModalGeneric__Modal {
      height: calc(100% - 60px) !important;
      top: 60px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; } }
  .ModalGeneric__Modal__error::after {
    position: fixed;
    content: " ";
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: hidden;
    z-index: 600; }
  @media (min-width: 1096px) {
    .ModalGeneric__Modal {
      height: auto;
      max-height: 100%; } }
  .ModalGeneric__Modal__Title {
    text-align: center;
    width: 70%;
    margin-left: 15%;
    height: auto;
    padding: 6px 0px 20px 0px; }
    @media (min-width: 1096px) {
      .ModalGeneric__Modal__Title {
        width: 100%;
        margin-left: 0%; } }
    .ModalGeneric__Modal__Title__Subtitle {
      color: #898989;
      margin-top: 5px !important;
      text-align: center; }
  .ModalGeneric__Modal__CloseButton {
    position: fixed;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: white;
    right: initial;
    top: initial;
    z-index: 500;
    border-radius: 100px; }
    .ModalGeneric__Modal__CloseButton:hover {
      background: #F5F5F5; }
  .ModalGeneric__Modal__Topbar, .ModalGeneric__Modal__Topbar--active, .ModalGeneric__Modal__Downbar--active, .ModalGeneric__Modal__Downbar {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1000;
    width: 100%;
    max-width: 536px;
    height: 72px;
    padding: 18px;
    opacity: 1.0;
    background-color: transparent;
    box-shadow: none;
    margin: -18px auto 0 auto !important;
    left: 0;
    right: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px; }
    .ModalGeneric__Modal__Topbar--active, .ModalGeneric__Modal__Downbar--active {
      background-color: white;
      box-shadow: 0px 3px 6px #00000029;
      -webkit-box-shadow: 0px 3px 6px #00000029;
      -moz-box-shadow: 0px 3px 6px #00000029;
      -ms-box-shadow: 0px 3px 6px #00000029;
      -webkit-animation: fadeInAnimation 0.5s ease;
              animation: fadeInAnimation 0.5s ease;
      display: flex; }
      .ModalGeneric__Modal__Topbar--active__Title, .ModalGeneric__Modal__Downbar--active__Title {
        width: 63%;
        margin-left: 20%;
        text-align: center;
        line-height: 15px; }
  .ModalGeneric__Modal__Container {
    width: 100%;
    padding: 0px 0px 28px 0px; }
    @media (min-width: 1096px) {
      .ModalGeneric__Modal__Container {
        padding: 0px 82px 22px 82px; } }
    .ModalGeneric__Modal__Container__Group {
      margin: 20px 0; }
      .ModalGeneric__Modal__Container__Group__Item {
        margin-bottom: 10px; }
    .ModalGeneric__Modal__Container__Feedback {
      padding: 20px 0px;
      text-align: center; }
      .ModalGeneric__Modal__Container__Feedback__Icon {
        margin-bottom: 5px; }

@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0.0; }
  100% {
    opacity: 1.0; } }

@keyframes fadeInAnimation {
  0% {
    opacity: 0.0; }
  100% {
    opacity: 1.0; } }

.Container__warning {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem; }

.Container {
  margin-top: 0.8rem !important; }
  .Container__warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    margin-bottom: 2rem; }
  .Container_dropdown {
    width: 100%; }
  .Container_textbox {
    width: 100%; }

.ModalInvalidateRequest .Container {
  margin-top: 0.8rem;
  padding-top: 0; }
  .ModalInvalidateRequest .Container__warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    margin-bottom: 2rem; }
  .ModalInvalidateRequest .Container_dropdown {
    width: 100%; }
  .ModalInvalidateRequest .Container_textbox {
    width: 100%; }

.Container {
  width: 100%; }
  .Container_textbox {
    width: 100%;
    margin-top: 1.5rem; }

.EnableNewPatientsModal__UpdatedInfo {
  color: #898989;
  font-size: 12px;
  line-height: 15px;
  padding-top: 10px; }

.EditPlanModal__card-number-wrapper {
  width: 100%;
  margin-top: 10px; }

.EditPlanModal__wrapper-confirmation-data {
  width: 100%; }
  .EditPlanModal__wrapper-confirmation-data table {
    line-height: 1.1; }
    .EditPlanModal__wrapper-confirmation-data table + table {
      margin-top: 20px; }
    .EditPlanModal__wrapper-confirmation-data table th {
      text-align: left; }
    .EditPlanModal__wrapper-confirmation-data table tr > td {
      padding-right: 20px; }

.EditPlanModal__wrapper-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 30px; }
  .EditPlanModal__wrapper-buttons button {
    width: 100%; }
  .EditPlanModal__wrapper-buttons button:disabled {
    display: flex;
    justify-content: center;
    align-items: center; }
    .EditPlanModal__wrapper-buttons button:disabled > div {
      border-color: #fff;
      border-top-color: transparent;
      width: 40px;
      height: 40px; }

.EditPlanModal .Textarea {
  width: 100%;
  margin-top: 10px; }

.EditPlanModal .dropdown, .EditPlanModal .dropdown-error {
  width: 100%; }

.EditPlanModal .dropdown + .dropdown, .EditPlanModal .dropdown-error + .dropdown, .EditPlanModal .dropdown + .dropdown-error, .EditPlanModal .dropdown-error + .dropdown-error {
  margin-top: 10px; }

.editEmailAccount__data {
  margin-top: 20px; }

.editEmailAccount__actual-email {
  margin-top: 10px; }

.editEmailAccount__form-container {
  margin-top: 40px; }
  .editEmailAccount__form-container .TextInput {
    padding-top: 5px; }

.editEmailAccount__AlertSuccess {
  background: #FFFEB5;
  border-radius: 5px;
  margin-top: 30px;
  padding: 15px; }

.editEmailAccount__error {
  display: flex;
  align-items: center;
  margin-top: 2rem; }
  .editEmailAccount__error--msg {
    margin-left: 10px; }

.InactiveProfessionalModal .img-circular {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-size: cover;
  background-position: center; }

.InactiveProfessionalModal .__notification--success {
  flex-direction: column;
  margin-bottom: 30px; }

.InactiveProfessionalModal .Button.red-lighter:not(:disabled), .InactiveProfessionalModal .red-lighter.Button--default:not(:disabled), .InactiveProfessionalModal .red-lighter.Button--reverse:not(:disabled), .InactiveProfessionalModal .red-lighter.Button--reverse--grey:not(:disabled), .InactiveProfessionalModal .red-lighter.Button--reverse--grey--lighter:not(:disabled), .InactiveProfessionalModal .red-lighter.Button--reverse--grey--padded:not(:disabled), .InactiveProfessionalModal .red-lighter.Button--online:not(:disabled), .InactiveProfessionalModal .red-lighter.Button--false:not(:disabled) {
  background-color: #ff4949; }

.ActiveProfessionalModal .img-circular {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-size: cover;
  background-position: center; }

.ActiveProfessionalModal .__notification--success {
  flex-direction: column;
  margin-bottom: 30px; }

.Container {
  padding-top: 2rem; }
  .Container__data {
    width: 100%;
    margin-bottom: 20px; }
  .Container__dropdown {
    width: 100%; }
    .Container__dropdown .dropdown, .Container__dropdown .dropdown-error {
      margin-bottom: 20px !important; }

.FilterModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 45.4rem;
  position: relative;
  width: 100%; }
  .FilterModal__Content {
    width: 100%;
    padding: 0px;
    margin-top: 0px; }

.column {
  display: flex;
  flex-direction: column; }

.input {
  display: flex; }

.filtermodaltext {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1.5rem; }

.filtermodalcontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 100%; }
  .filtermodalcontent__toggleBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }

.cntbtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 32rem;
  max-width: 37rem;
  max-width: 59rem; }

.filterInput {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  margin: 0 auto; }

.FilterClear {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10px;
  margin-bottom: 15px; }

.ModalAdminSchedule__Content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%; }
  .ModalAdminSchedule__Content__DropdownWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; }
  .ModalAdminSchedule__Content__TreatmentNotStartedMessage {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px; }
  .ModalAdminSchedule__Content__LoadingContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px; }
    .ModalAdminSchedule__Content__LoadingContainer div {
      width: 30px;
      height: 30px; }

.ModalApproveValidity__Data__Fields {
  display: flex;
  width: 100%; }
  .ModalApproveValidity__Data__Fields--block {
    display: block;
    margin-top: 1rem; }
    .ModalApproveValidity__Data__Fields--block:last-child {
      margin-top: 2rem; }
    .ModalApproveValidity__Data__Fields--block p {
      margin-bottom: 3px; }
  .ModalApproveValidity__Data__Fields__Input {
    margin: 0.75rem;
    margin-bottom: 0;
    margin-top: 0;
    width: 100%; }
    .ModalApproveValidity__Data__Fields__Input:first-of-type {
      margin-left: 0; }
    .ModalApproveValidity__Data__Fields__Input:last-of-type {
      margin-right: 0; }

.ModalApproveValidity__Data__Review__BoxInfo {
  margin-bottom: 1.5rem; }

.ModalApproveValidity__Data .col {
  width: 100% !important; }

.Container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column; }
  .Container_textbox {
    width: 100%;
    margin-top: 1.5rem; }

.Container__List {
  width: 100%;
  height: calc(100% - 130px);
  margin-top: 2.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5%; }
  @media (min-width: 1096px) {
    .Container__List {
      padding: 0 20%; } }
  .Container__List__Empty {
    width: 100%;
    text-align: center;
    font-style: italic;
    padding-top: 20px; }
  .Container__List__LogCard {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    line-height: 14px; }
    .Container__List__LogCard__Time {
      padding-right: 20px; }
      .Container__List__LogCard__Time__Seconds {
        color: #929FEE; }

.Container__warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem;
  margin-bottom: 2rem; }

.Container__dropdown {
  width: 100%; }
  .Container__dropdown > div {
    max-width: 100%;
    margin-bottom: 10px; }
  .Container__dropdown .dropdown, .Container__dropdown .dropdown-error {
    margin-bottom: 10px !important; }

.Container__textbox {
  width: 100%; }

.Container__StepFile {
  width: 100%; }
  .Container__StepFile__Forms {
    margin: 2.5rem 0; }
    .Container__StepFile__Forms__Item--radio {
      display: flex;
      margin: 2rem 0; }
      .Container__StepFile__Forms__Item--radio__Item {
        margin: 0 1rem; }
        .Container__StepFile__Forms__Item--radio__Item:first-of-type {
          margin-left: 0; }
        .Container__StepFile__Forms__Item--radio__Item:last-of-type {
          margin-right: 0; }
    .Container__StepFile__Forms--image-wrapper {
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      position: relative;
      width: 100%; }
      .Container__StepFile__Forms--image-wrapper input[type=file] {
        cursor: pointer;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%; }
  .Container__StepFile__Error {
    align-items: flex-start;
    display: flex;
    margin-top: 20px; }
    .Container__StepFile__Error__Icon {
      margin-right: 1rem;
      margin-top: -1.5rem;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.ModalMenu {
  position: fixed;
  right: 24px;
  bottom: 80px;
  z-index: 105; }
  .ModalMenu__backdrop {
    position: relative;
    height: 100%;
    width: 100%; }
  .ModalMenu__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem; }
    .ModalMenu__item:hover .ModalMenu__item__background {
      background-color: #f5f5f5; }
    .ModalMenu__item:last-child {
      margin-bottom: 0; }
    .ModalMenu__item__background {
      border-radius: 0.5rem;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
      align-items: center;
      background-color: #ffffff;
      color: #4b4b4b;
      display: flex;
      padding: 0.6rem 1.4rem 0.9rem; }
    .ModalMenu__item__text {
      border-radius: 0.5rem;
      max-height: 3.2rem; }
    .ModalMenu__item__icon {
      border-radius: 50rem;
      margin-left: 1.7rem;
      padding: 0.8rem; }

.ModalFileBeneficiaries__WarningTag {
  background-color: #FFFEB5;
  color: #4B4B4B;
  font-size: 12px;
  line-height: 16px;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px; }

.ModalFileBeneficiaries--image-wrapper {
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .ModalFileBeneficiaries--image-wrapper .TextInput .inputContent span {
    margin-left: -3rem; }
  .ModalFileBeneficiaries--image-wrapper .TextInput .messageError {
    line-height: 14px;
    margin-top: 5px !important; }
  .ModalFileBeneficiaries--image-wrapper input[type=file] {
    cursor: pointer;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%; }

.ModalFileBeneficiaries__FeedbackContent {
  margin-top: 20px; }

.ModalFileBeneficiaries__removed__title {
  color: #ff4949;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 23px; }
  .ModalFileBeneficiaries__removed__title strong {
    font-size: 17px;
    font-eight: bold; }

.ModalFileBeneficiaries__removed__list {
  margin-bottom: 20px; }
  .ModalFileBeneficiaries__removed__list--beneficiary {
    color: #4b4b4b;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px; }
    .ModalFileBeneficiaries__removed__list--beneficiary span {
      font-weight: bold;
      text-transform: uppercase; }

.ModalFileBeneficiaries__removed__warning {
  color: #ff4949;
  font-size: 15px;
  margin-bottom: 20px; }
  .ModalFileBeneficiaries__removed__warning span {
    font-weight: bold; }

.ModalFileBeneficiaries__removed__buttons {
  justify-content: center;
  display: flex;
  flex-direction: column; }
  .ModalFileBeneficiaries__removed__buttons button {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .ModalFileBeneficiaries__removed__buttons button .SpinnerContainer {
      border-color: white;
      border-top-color: transparent;
      height: 36px;
      width: 36px; }

.ModalFileBeneficiaries__duplicate {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column; }
  .ModalFileBeneficiaries__duplicate__list {
    margin-top: 10px; }

.ModalUploadMedicalRequest--image-wrapper {
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .ModalUploadMedicalRequest--image-wrapper .TextInput .inputContent span {
    margin-left: -3rem; }
  .ModalUploadMedicalRequest--image-wrapper input[type=file] {
    cursor: pointer;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%; }

.ModalUploadMedicalRequest__Data {
  width: 100%; }
  .ModalUploadMedicalRequest__Data .col {
    width: 100% !important; }

.ContainerPatientDetail {
  width: 100%;
  padding-top: 0;
  margin-top: 0 !important; }
  .ContainerPatientDetail__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 34px;
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
    margin-bottom: 30px; }
    .ContainerPatientDetail__header__userData {
      margin-top: 15px;
      text-align: center; }
      .ContainerPatientDetail__header__userData__list {
        margin-top: 18px; }
        .ContainerPatientDetail__header__userData__list li {
          display: flex;
          align-items: center;
          justify-content: center; }
          .ContainerPatientDetail__header__userData__list li svg {
            margin-right: 10px;
            width: 20px; }
  .ContainerPatientDetail__planGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E2E2E2;
    margin-bottom: 30px; }
    .ContainerPatientDetail__planGroup:last-child {
      border: none;
      padding-bottom: 0; }
    .ContainerPatientDetail__planGroup.planGroupInfo__ineligible li:not(:last-child), .ContainerPatientDetail__planGroup.planGroupInfo__ineligibleUnlimited li:not(:last-child) {
      color: #c1c1c1;
      text-decoration: line-through; }
      .ContainerPatientDetail__planGroup.planGroupInfo__ineligible li:not(:last-child) p, .ContainerPatientDetail__planGroup.planGroupInfo__ineligibleUnlimited li:not(:last-child) p {
        color: #c1c1c1; }
    .ContainerPatientDetail__planGroup.planGroupInfo__expirationDate li:first-child p:nth-child(2) {
      text-decoration: line-through;
      font-weight: normal !important; }
    .ContainerPatientDetail__planGroup__list {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .ContainerPatientDetail__planGroup__list li {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px; }
        .ContainerPatientDetail__planGroup__list li .credits--eligible {
          color: #4AC326; }
        .ContainerPatientDetail__planGroup__list li .credits--unavailable {
          color: #ff4949; }
        .ContainerPatientDetail__planGroup__list li .validity--expirationDate {
          color: #ff4949; }
    .ContainerPatientDetail__planGroup__info.--waiting-validation > p {
      color: #4b4b4b;
      display: flex;
      align-items: center; }
      .ContainerPatientDetail__planGroup__info.--waiting-validation > p strong {
        margin-left: 4px; }
      .ContainerPatientDetail__planGroup__info.--waiting-validation > p svg {
        margin-right: 10px; }
    .ContainerPatientDetail__planGroup__info p {
      line-height: 15px;
      margin-top: 15px !important; }
    .ContainerPatientDetail__planGroup__info.corporateUnlimited p {
      color: #4AC326 !important; }
    .ContainerPatientDetail__planGroup.planGroupInfo__corporateUnlimited li:nth-child(1), .ContainerPatientDetail__planGroup.planGroupInfo__ineligibleUnlimited li:nth-child(1) {
      order: 2; }
    .ContainerPatientDetail__planGroup.planGroupInfo__corporateUnlimited li:nth-child(2), .ContainerPatientDetail__planGroup.planGroupInfo__ineligibleUnlimited li:nth-child(2) {
      order: 1; }
      .ContainerPatientDetail__planGroup.planGroupInfo__corporateUnlimited li:nth-child(2) p:last-child, .ContainerPatientDetail__planGroup.planGroupInfo__ineligibleUnlimited li:nth-child(2) p:last-child {
        color: #4AC326;
        font-weight: bold; }
    .ContainerPatientDetail__planGroup.planGroupInfo__corporateUnlimited li:nth-child(3), .ContainerPatientDetail__planGroup.planGroupInfo__ineligibleUnlimited li:nth-child(3) {
      order: 3; }
    .ContainerPatientDetail__planGroup.planGroupInfo__ineligibleUnlimited li:nth-child(2) p:last-child {
      color: #c1c1c1; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise li:last-child p:last-child {
      text-transform: uppercase;
      border-radius: 5px;
      color: #ffffff;
      padding: 3px 5px;
      font-size: 12px;
      font-weight: bold; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--waiting-validation li:last-child p:last-child {
      background: #f9a435; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--waiting-technical-evaluation li:last-child p:first-child {
      width: 60%; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--waiting-technical-evaluation li:last-child p:last-child {
      width: 40%;
      background: #f9a435; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--validated li:first-child p:last-child {
      color: #4AC326;
      font-weight: bold; }
      .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--validated li:first-child p:last-child.danger {
        color: #ff4949; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--validated li:last-child p:last-child {
      background: #4AC326; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--validated-without-medical-request li:last-child p:last-child {
      background: #4AC326; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--validated-without-medical-request li:first-child p:last-child {
      color: #4AC326; }
      .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--validated-without-medical-request li:first-child p:last-child.danger {
        color: #ff4949; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--finished li:last-child p:last-child {
      background: #4b4b4b; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--finished li:first-child p:last-child.danger {
      color: #ff4949; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--finished.dateExpiration li:first-child p:last-child {
      font-weight: normal;
      color: #4b4b4b;
      text-decoration: line-through; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--finished.dateExpiration li:nth-child(2) p:last-child {
      color: #ff4949; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--disapproved li:last-child p:last-child, .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--invalidated li:last-child p:last-child {
      background: #ff4949; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--not-validity li:first-child p:last-child {
      font-weight: normal; }
    .ContainerPatientDetail__planGroup.planGroupInfo__enterprise.--not-validity li:last-child p:last-child {
      font-weight: normal;
      color: #4b4b4b;
      padding: 0;
      font-size: 15px; }
  .ContainerPatientDetail__statusSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px; }
  .ContainerPatientDetail__planInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    grid-gap: 5px;
    gap: 5px; }
    .ContainerPatientDetail__planInfo__status {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      gap: 5px; }
  .ContainerPatientDetail__PatientTreatmentsEligibilityWrapper {
    width: 100%; }
  .ContainerPatientDetail__InactiveContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px 0; }
  .ContainerPatientDetail__PsiqTabWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px; }
  .ContainerPatientDetail__PsicTabWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; }
  .ContainerPatientDetail__Highlight__Green {
    color: #4AC326; }

.PatientDetailModal .Modal__container, .PatientDetailModal .PortalModal__container--scrollable, .PatientDetailModal .Modal__container--scrollable {
  border-radius: 30px; }

.ScheduleModal__warn {
  border-radius: 0.5rem;
  background-color: #ffffb5;
  margin-bottom: 2rem;
  padding: 15px; }

.ScheduleModal__error {
  display: flex;
  z-index: 999;
  margin: 10px 0;
  position: relative; }
  .ScheduleModal__error__text {
    margin-left: 15px; }
  .ScheduleModal__error__actions {
    z-index: 999;
    position: relative;
    margin: 10px 0 20px 0;
    display: flex;
    width: 100%;
    margin-left: 35px; }
    .ScheduleModal__error__actions__button {
      height: 35px !important;
      width: 100px !important;
      font-size: 12px !important;
      font-weight: 500 !important; }
    .ScheduleModal__error__actions__buttonRight {
      margin-left: 10px;
      height: 35px !important;
      width: 100px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      border-color: #4b4b4b !important;
      color: #4b4b4b !important; }
      .ScheduleModal__error__actions__buttonRight:hover {
        background-color: !important; }

.ScheduleModal__overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 400; }

.ScheduleModal__tabs {
  margin-bottom: 2.25rem;
  z-index: 1002; }

.ScheduleModal__single__time {
  padding: 0 40px; }

.ScheduleModal__single__error p {
  padding-left: 40px; }

.ScheduleModal__weekday, .ScheduleModal__single {
  padding: 2rem 1rem; }
  .ScheduleModal__weekday__title, .ScheduleModal__single__title {
    display: flex;
    flex-direction: column; }
  .ScheduleModal__weekday__time, .ScheduleModal__single__time {
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
    align-items: flex-start; }
  .ScheduleModal__weekday__input, .ScheduleModal__single__input {
    max-width: 90px; }
  .ScheduleModal__weekday__button, .ScheduleModal__single__button {
    flex-grow: 1; }
    .ScheduleModal__weekday__button button, .ScheduleModal__single__button button {
      width: 100%; }
  .ScheduleModal__weekday__date, .ScheduleModal__single__date {
    margin-top: 15px;
    width: 100%; }
  .ScheduleModal__weekday__child, .ScheduleModal__single__child {
    display: flex;
    align-items: center;
    padding: 0 0.5rem; }
    .ScheduleModal__weekday__child:first-child, .ScheduleModal__single__child:first-child {
      padding-left: 0; }
    .ScheduleModal__weekday__child:last-child, .ScheduleModal__single__child:last-child {
      padding-right: 0; }
    .ScheduleModal__weekday__child .TextInput, .ScheduleModal__single__child .TextInput {
      margin-bottom: 0 !important; }
      .ScheduleModal__weekday__child .TextInput .messageError, .ScheduleModal__single__child .TextInput .messageError {
        height: 0; }
      .ScheduleModal__weekday__child .TextInput input, .ScheduleModal__single__child .TextInput input {
        text-align: center;
        padding: 0.5rem; }
        .ScheduleModal__weekday__child .TextInput input:disabled, .ScheduleModal__single__child .TextInput input:disabled {
          border-color: initial; }
    .ScheduleModal__weekday__child p, .ScheduleModal__single__child p {
      height: 48px;
      line-height: 48px !important; }
    .ScheduleModal__weekday__child span, .ScheduleModal__single__child span {
      padding: 0 5px; }
  .ScheduleModal__weekday__slots, .ScheduleModal__single__slots {
    display: flex;
    flex-wrap: wrap;
    margin: 1.6rem 0; }
    .ScheduleModal__weekday__slots__slot, .ScheduleModal__single__slots__slot {
      padding-right: 5px;
      padding-bottom: 5px;
      width: 50%; }
      .ScheduleModal__weekday__slots__slot__error, .ScheduleModal__single__slots__slot__error {
        position: relative;
        z-index: 999; }
  .ScheduleModal__weekday__hourError, .ScheduleModal__single__hourError {
    font-size: 1.2rem;
    color: #ff4949;
    padding-top: 10px; }

.PlanSelection {
  width: 100%; }
  .PlanSelection__Selection__Plans {
    margin: 10px 0 10px; }

.RescheduleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 45.4rem;
  position: relative;
  width: 100%;
  /** Selected hours range section */ }
  .RescheduleContainer__calendar {
    margin-bottom: 20px;
    position: relative; }
    .RescheduleContainer__calendar .SpinnerContainer {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      z-index: 1; }
    .RescheduleContainer__calendar .DayPicker-Day--outside::after {
      content: none !important; }
    .RescheduleContainer__calendar .DayPicker-Day--schedule {
      position: relative; }
      .RescheduleContainer__calendar .DayPicker-Day--schedule::before {
        content: '' !important;
        position: absolute;
        left: 0;
        right: 0;
        height: 28px;
        width: 28px;
        background: #dedede url("/static/check.svg") no-repeat center center;
        border-radius: 28px;
        left: calc(50% - 14px);
        top: calc(50% - 14px);
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
  .RescheduleContainer__header p {
    line-height: 1.8rem; }
  .RescheduleContainer__header span {
    font-weight: 700; }
  .RescheduleContainer__reservation_text {
    margin: 20px 0; }
  .RescheduleContainer__hours {
    margin: 0 auto;
    max-width: 38.4rem;
    padding: 2.642rem 0 0 0;
    position: relative;
    width: 100%; }
    .RescheduleContainer__hours__selected {
      align-items: center;
      display: flex;
      height: 4.8rem;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 0 4%; }
      .RescheduleContainer__hours__selected p {
        color: #22cbd3;
        flex-shrink: 0;
        font-weight: 700;
        margin-right: 0.5rem; }
      .RescheduleContainer__hours__selected .dropdown, .RescheduleContainer__hours__selected .dropdown-error {
        flex-shrink: 2;
        margin-bottom: 0; }
        .RescheduleContainer__hours__selected .dropdown--placeholder, .RescheduleContainer__hours__selected .dropdown--placeholder-selected {
          flex-shrink: 2;
          min-width: 12rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
      .RescheduleContainer__hours__selected__clear {
        cursor: pointer;
        flex-shrink: 0;
        height: 3.5rem;
        width: 3.5rem; }

.ScheduleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 45.4rem;
  position: relative;
  width: 100%; }
  .ScheduleContainer__calendar {
    margin-bottom: 20px;
    position: relative; }
    .ScheduleContainer__calendar .SpinnerContainer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center; }
    .ScheduleContainer__calendar .DayPicker-Day--outside::after {
      content: none !important; }
    .ScheduleContainer__calendar .DayPicker-Day--schedule::before {
      content: '';
      left: 0;
      right: 0;
      height: 28px;
      width: 28px;
      background: #dedede url("/static/check.svg") no-repeat center center;
      border-radius: 28px;
      left: calc(50% - 14px);
      top: calc(50% - 14px);
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
  .ScheduleContainer__hours {
    margin-bottom: 10px;
    position: relative;
    width: 100%; }
    .ScheduleContainer__hours__selected {
      align-items: center;
      border: .5px solid #fff;
      display: flex;
      height: auto;
      justify-content: center;
      margin-bottom: 10px;
      border-radius: 10px;
      -webkit-animation-name: transitionAn;
              animation-name: transitionAn;
      -webkit-animation-duration: 0.5s;
              animation-duration: 0.5s;
      -webkit-animation-timing-function: ease;
              animation-timing-function: ease; }

@-webkit-keyframes transitionAn {
  from {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px); }
  to {
    background-color: #ffffff;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes transitionAn {
  from {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px); }
  to {
    background-color: #ffffff;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }
      .ScheduleContainer__hours__selected p {
        color: #22cbd3;
        font-weight: 700;
        min-width: 0px; }
      .ScheduleContainer__hours__selected .dropdown, .ScheduleContainer__hours__selected .dropdown-error {
        margin: 0 10px 0 20px;
        min-width: auto;
        flex-grow: 1; }
      .ScheduleContainer__hours__selected__clear {
        cursor: pointer;
        flex-shrink: 0;
        height: 3.5rem;
        width: 3.5rem; }
  .ScheduleContainer__Error {
    align-items: flex-start;
    display: flex;
    margin: 20px 0px; }
    .ScheduleContainer__Error__Icon {
      margin-right: 10px;
      margin-top: -5px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .ScheduleContainer__Reservation {
    border-radius: 10px;
    background-color: #e7ffe5;
    padding: 15px;
    display: flex;
    justify-content: center;
    margin: 0 0 1.5rem 0; }
    .ScheduleContainer__Reservation__Exceptions {
      margin-top: 10px; }
    .ScheduleContainer__Reservation__container {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .ScheduleContainer__Reservation__footer {
      margin-top: 10px !important; }
  .ScheduleContainer__ReservationAdmin {
    min-height: 155px;
    border-radius: 10px;
    background-color: #e7ffe5;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem; }
    .ScheduleContainer__ReservationAdmin__footer {
      margin-top: 10px !important; }
  .ScheduleContainer .TimestampTag {
    margin: 20px 0 10px 0; }
  .ScheduleContainer__PsychiatryMessage {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 20px; }
    .ScheduleContainer__PsychiatryMessage svg {
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content; }

.TimerContainer, .TimerContainer--negative {
  color: #1f35b5;
  font-family: "Ubuntu";
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  width: 4rem; }
  .TimerContainer--negative {
    color: #ff4949; }

.CheckoutPlans {
  min-height: calc(100vh - 12rem) !important;
  width: 100%; }
  .CheckoutPlans__header {
    text-align: center;
    width: 100%; }
  .CheckoutPlans__content {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%; }
    .CheckoutPlans__content .FirstTime {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      max-width: 53.8rem;
      width: 100%; }
      @media (min-width: 1096px) {
        .CheckoutPlans__content .FirstTime {
          margin-top: 3rem; } }
      .CheckoutPlans__content .FirstTime .Card, .CheckoutPlans__content .FirstTime .Card--default, .CheckoutPlans__content .FirstTime .Card--darkenBackground, .CheckoutPlans__content .FirstTime .Card--yellowBackground, .CheckoutPlans__content .FirstTime .Card--scrollable {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2.5rem 1.5rem 3rem;
        white-space: pre-wrap; }
        .CheckoutPlans__content .FirstTime .Card__buttons {
          align-items: center;
          direction: row;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          max-width: 27.1rem;
          width: 100%; }
          .CheckoutPlans__content .FirstTime .Card__buttons .Button, .CheckoutPlans__content .FirstTime .Card__buttons .Button--default, .CheckoutPlans__content .FirstTime .Card__buttons .Button--reverse, .CheckoutPlans__content .FirstTime .Card__buttons .Button--reverse--grey, .CheckoutPlans__content .FirstTime .Card__buttons .Button--reverse--grey--lighter, .CheckoutPlans__content .FirstTime .Card__buttons .Button--reverse--grey--padded, .CheckoutPlans__content .FirstTime .Card__buttons .Button--online, .CheckoutPlans__content .FirstTime .Card__buttons .Button--false {
            margin: 0;
            width: 12.8rem; }
    .CheckoutPlans__content .MedicalRequest {
      align-items: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      max-width: 35.2rem;
      padding-top: 1rem;
      width: 100%; }
      @media (min-width: 1096px) {
        .CheckoutPlans__content .MedicalRequest {
          padding-top: 0; } }
      .CheckoutPlans__content .MedicalRequest--image-wrapper {
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: 100%; }
        .CheckoutPlans__content .MedicalRequest--image-wrapper .TextInput .inputContent span {
          margin-left: -3rem; }
        .CheckoutPlans__content .MedicalRequest--image-wrapper input[type=file] {
          cursor: pointer;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          width: 100%; }
      .CheckoutPlans__content .MedicalRequest .Button, .CheckoutPlans__content .MedicalRequest .Button--default, .CheckoutPlans__content .MedicalRequest .Button--reverse, .CheckoutPlans__content .MedicalRequest .Button--reverse--grey, .CheckoutPlans__content .MedicalRequest .Button--reverse--grey--lighter, .CheckoutPlans__content .MedicalRequest .Button--reverse--grey--padded, .CheckoutPlans__content .MedicalRequest .Button--online, .CheckoutPlans__content .MedicalRequest .Button--false {
        margin: 0; }
    .CheckoutPlans__content .Details {
      max-width: 91rem;
      width: 100%; }
      .CheckoutPlans__content .Details .Button, .CheckoutPlans__content .Details .Button--default, .CheckoutPlans__content .Details .Button--reverse, .CheckoutPlans__content .Details .Button--reverse--grey, .CheckoutPlans__content .Details .Button--reverse--grey--lighter, .CheckoutPlans__content .Details .Button--reverse--grey--padded, .CheckoutPlans__content .Details .Button--online, .CheckoutPlans__content .Details .Button--false {
        margin: 0 0 2rem; }
      .CheckoutPlans__content .Details .CartList__populated__name {
        margin: 1rem 0; }
    .CheckoutPlans__content .Confirmation {
      align-items: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin: 4.8rem 0 0;
      max-width: 35.2rem;
      text-align: center;
      white-space: pre-wrap;
      width: 100%; }
      .CheckoutPlans__content .Confirmation span {
        font-weight: 700; }
    .CheckoutPlans__content .YellowBox {
      align-items: center;
      background-color: #ffffb5;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      padding: 1.1rem 0.1rem 1.4rem;
      width: 100%;
      white-space: pre-wrap; }
      .CheckoutPlans__content .YellowBox p {
        line-height: 1.4; }
    .CheckoutPlans__content .Button, .CheckoutPlans__content .Button--default, .CheckoutPlans__content .Button--reverse, .CheckoutPlans__content .Button--reverse--grey, .CheckoutPlans__content .Button--reverse--grey--lighter, .CheckoutPlans__content .Button--reverse--grey--padded, .CheckoutPlans__content .Button--online, .CheckoutPlans__content .Button--false {
      margin: 1rem 0 0; }

.Dashboard {
  min-height: calc(100vh - 6rem); }
  .Dashboard__Menu {
    margin-top: 40px; }
  .Dashboard__Content {
    margin-top: 20px; }
    @media (min-width: 1096px) {
      .Dashboard__Content {
        margin-top: 40px;
        padding-left: 30px; } }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Dashboard__Content {
        padding-bottom: 90px; } }
  .Dashboard__Header {
    align-items: center;
    margin-bottom: 20px !important;
    min-height: 48px;
    position: relative; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Dashboard__Header {
        justify-content: center !important;
        position: static; } }
  .Dashboard-filter {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%; }
    @media (min-width: 1096px) {
      .Dashboard-filter {
        max-width: 33.7rem; } }
    .Dashboard-filter .filter-input {
      width: 100%; }
      @media (min-width: 1096px) {
        .Dashboard-filter .filter-input {
          width: 34rem; } }
    .Dashboard-filter .filter-btn {
      cursor: pointer;
      height: 6rem;
      margin-left: 1rem;
      width: 3.5rem; }

.editUserPaci__email {
  position: relative; }

.editUserPaci__edit-icon {
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translate(-16px, 50%);
          transform: translate(-16px, 50%);
  cursor: pointer;
  color: #1F35B5;
  fontWeight: bold; }

.editUserPaci__phone-number {
  margin-top: 10px; }

.editUserPaci__edit-button {
  border: 0;
  background: initial;
  color: #1F35B5;
  font-weight: bold; }

.dashboard-person-list {
  align-content: center;
  justify-content: center;
  width: 100%; }
  .dashboard-person-list--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 37.3rem;
    color: #4b4b4b; }
    .dashboard-person-list--empty.animation-shake {
      -webkit-animation: shake 0.1s;
              animation: shake 0.1s;
      -webkit-animation-iteration-count: 3;
              animation-iteration-count: 3; }
  .dashboard-person-list--icon {
    fill: #4b4b4b;
    max-height: 6rem;
    max-width: 6rem; }

.onScroll {
  position: fixed;
  top: 0;
  width: 100%; }

.MyConsultations {
  width: 100%; }

.AppointmentsList__heading {
  margin-bottom: 1.5rem; }

.PaymentList__heading {
  margin-bottom: 1.5rem; }

.AppointmentsList {
  margin-bottom: 10.5rem; }
  .AppointmentsList__topMessage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 15px; }
  .AppointmentsList__message {
    max-width: 188px;
    text-align: right;
    line-height: 16px;
    word-break: break-word; }
  .AppointmentsList__icon {
    margin: 0 10px; }
  .AppointmentsList__list-empty {
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: center; }
    .AppointmentsList__list-empty .ListPlaceholder {
      margin: 0 auto;
      width: 100%; }
      .AppointmentsList__list-empty .ListPlaceholder__description {
        max-width: 14rem; }
        @media (min-width: 1096px) {
          .AppointmentsList__list-empty .ListPlaceholder__description {
            max-width: 100%; } }
        .AppointmentsList__list-empty .ListPlaceholder__description p {
          line-height: 1.2; }
    .AppointmentsList__list-empty .btn--test {
      margin: 0 auto;
      margin-top: 1rem;
      width: 7.261rem; }
      @media (min-width: 1096px) {
        .AppointmentsList__list-empty .btn--test {
          margin-top: 2rem; } }
      .AppointmentsList__list-empty .btn--test button {
        font-size: 1.2rem;
        height: 3.5rem; }
  .AppointmentsList__footer--test-device {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1rem; }
    .AppointmentsList__footer--test-device p {
      margin-right: 1.5rem; }
    .AppointmentsList__footer--test-device .btn--test {
      margin: 0;
      margin-top: 1rem;
      width: 7.261rem; }
      .AppointmentsList__footer--test-device .btn--test button {
        font-size: 1.2rem;
        height: 3.5rem;
        margin: 0; }

.report {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px; }
  .report--text {
    display: flex;
    justify-content: center;
    align-items: center; }
  .report--input {
    width: 87%;
    margin-bottom: 0; }
    .report--input .TextInput {
      margin-bottom: 0; }
  .report--button {
    margin-top: 20px; }

.Permissions__title {
  margin-bottom: 1.4rem; }

.Plans {
  margin-bottom: 10.5rem; }
  .Plans__List {
    margin: 3rem 0 2.5rem; }
  @media (min-width: 1096px) {
    .Plans__Add {
      max-width: 50%; } }
  .Plans__Add__Label {
    margin-bottom: 1rem; }
  .Plans__Add__Error {
    align-items: center;
    display: flex;
    margin: 2.5rem 0;
    width: 100%; }
    .Plans__Add__Error__Icon {
      align-self: flex-start;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .Plans__Add__Error__Description {
      margin-left: 1rem; }

.MedicalRequest__List {
  width: 100%; }
  .MedicalRequest__List__CardMedicalRequest {
    width: 100%; }
    .MedicalRequest__List__CardMedicalRequest:last-of-type {
      margin-bottom: 2rem; }

.LinkPlanModal {
  overflow-y: inherit !important; }

.DisableNewPatientsModal {
  max-width: 870px !important; }
  .DisableNewPatientsModal .ModalGeneric__Modal__Button {
    max-width: 332px;
    margin: 0 auto; }
  .DisableNewPatientsModal .ModalGeneric__Modal__Container {
    padding-left: 60px;
    padding-right: 60px; }
  .DisableNewPatientsModal .ModalGeneric__Modal__Topbar, .DisableNewPatientsModal .ModalGeneric__Modal__Topbar--active, .DisableNewPatientsModal .ModalGeneric__Modal__Downbar--active {
    max-width: 870px; }

@-webkit-keyframes shake {
  0% {
    margin-left: 0; }
  25% {
    margin-left: 5px; }
  50% {
    margin-left: 0; }
  75% {
    margin-left: -5px; }
  100% {
    margin-left: 0; } }

@keyframes shake {
  0% {
    margin-left: 0; }
  25% {
    margin-left: 5px; }
  50% {
    margin-left: 0; }
  75% {
    margin-left: -5px; }
  100% {
    margin-left: 0; } }

.RegisterBlock__BiographyData, .RegisterBlock__DocumentsData, .RegisterBlock__EducationData, .RegisterBlock__MediaData, .RegisterBlock__ProductsData, .RegisterBlock__SkillsData {
  margin-top: 3rem; }

.RegisterBlock__EducationData {
  display: flex; }
  .RegisterBlock__EducationData--Education {
    display: flex;
    margin-top: 3rem !important; }
    .RegisterBlock__EducationData--Education--EducationList {
      border-top: 0.1rem solid #f5f5f5;
      padding-bottom: 2rem !important;
      padding-top: 2rem !important; }
      .RegisterBlock__EducationData--Education--EducationList--data {
        width: 90%; }
      .RegisterBlock__EducationData--Education--EducationList--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem; }
  .RegisterBlock__EducationData__year {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1.5rem; }
    .RegisterBlock__EducationData__year--field {
      display: flex;
      width: 50%; }
      @media (min-width: 1096px) {
        .RegisterBlock__EducationData__year--field {
          width: 10rem; } }
    .RegisterBlock__EducationData__year--start {
      display: flex;
      justify-content: space-between;
      align-items: center !important;
      margin-right: 2rem; }
      @media (min-width: 1096px) {
        .RegisterBlock__EducationData__year--start {
          margin-right: 3.4rem; } }
      .RegisterBlock__EducationData__year--start--text {
        display: flex;
        justify-content: flex-start;
        margin-right: 2rem;
        padding-bottom: 1rem; }
    .RegisterBlock__EducationData__year--end {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .RegisterBlock__EducationData__year--end--text {
        display: flex;
        justify-content: flex-start;
        margin-right: 2rem;
        padding-bottom: 1rem; }

.RegisterBlock__MediaData--image-wrapper {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .RegisterBlock__MediaData--image-wrapper .TextInput .inputContent span {
    margin-left: -3rem; }
  .RegisterBlock__MediaData--image-wrapper input[type=file] {
    cursor: pointer;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%; }

.RegisterBlock__SkillsData__title {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%; }
  @media (min-width: 1096px) {
    .RegisterBlock__SkillsData__title {
      justify-content: flex-start; } }
  .RegisterBlock__SkillsData__title h2 {
    max-width: 40% !important; }
  .RegisterBlock__SkillsData__title__toggle-btn {
    cursor: pointer;
    height: 3.5rem; }

.RegisterBlock__SkillsData .RegisterSkillsModal {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 0;
  max-width: 100%;
  opacity: 0;
  overflow: hidden;
  padding: 0; }
  .RegisterBlock__SkillsData .RegisterSkillsModal__header {
    margin-bottom: 2.5rem; }
  .RegisterBlock__SkillsData .RegisterSkillsModal .Button, .RegisterBlock__SkillsData .RegisterSkillsModal .Button--default, .RegisterBlock__SkillsData .RegisterSkillsModal .Button--reverse, .RegisterBlock__SkillsData .RegisterSkillsModal .Button--reverse--grey, .RegisterBlock__SkillsData .RegisterSkillsModal .Button--reverse--grey--lighter, .RegisterBlock__SkillsData .RegisterSkillsModal .Button--reverse--grey--padded, .RegisterBlock__SkillsData .RegisterSkillsModal .Button--online, .RegisterBlock__SkillsData .RegisterSkillsModal .Button--false {
    max-width: 33.2rem; }
  .RegisterBlock__SkillsData .RegisterSkillsModal--show {
    height: auto;
    max-width: 100%;
    opacity: 1;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out; }
    .RegisterBlock__SkillsData .RegisterSkillsModal--show .SkillsSelection {
      margin-bottom: 4rem;
      max-width: 100%; }
      .RegisterBlock__SkillsData .RegisterSkillsModal--show .SkillsSelection__options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1rem;
        max-width: 100%; }
        @media (min-width: 1096px) {
          .RegisterBlock__SkillsData .RegisterSkillsModal--show .SkillsSelection__options {
            justify-content: flex-start; } }
        .RegisterBlock__SkillsData .RegisterSkillsModal--show .SkillsSelection__options .RadioToggle {
          flex-shrink: 1;
          margin-right: 0.5rem;
          margin-top: 1rem;
          max-width: 100%; }
          .RegisterBlock__SkillsData .RegisterSkillsModal--show .SkillsSelection__options .RadioToggle__text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; }

.RegisterBlock__SkillsData--btn {
  text-align: center; }
  @media (min-width: 1096px) {
    .RegisterBlock__SkillsData--btn {
      text-align: left; } }
  .RegisterBlock__SkillsData--btn button {
    font-size: 1.2rem;
    height: 3.5rem;
    margin: 0;
    max-width: 18rem;
    padding: 0; }

.RegisterBlock__ProductsData__SelectPlansModal {
  max-width: 969px;
  position: relative;
  padding: 0;
  height: 100vh; }
  .RegisterBlock__ProductsData__SelectPlansModal__Title {
    background: #fff;
    position: fixed;
    display: flex;
    align-items: center;
    padding: 30px 0 40px 40px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    top: 60px;
    width: 950px; }
  .RegisterBlock__ProductsData__SelectPlansModal__Container {
    display: flex;
    flex-direction: column;
    margin-top: 30px; }
    .RegisterBlock__ProductsData__SelectPlansModal__Container__ModalContentWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 0 0 40px;
      grid-gap: 50px;
      gap: 50px;
      margin-top: 30px; }
    .RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 500px;
      max-width: 300px;
      padding-right: 20px;
      position: fixed; }
      .RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__SidebarTitle {
        font-size: 10px !important;
        color: #898989 !important; }
      .RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__TierSelecionWrapper {
        margin-top: 35px;
        display: flex;
        flex-direction: column; }
        .RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__TierSelecionWrapper svg {
          fill: #4B4B4B; }
        .RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__TierSelecionWrapper__TierSelectionSection {
          border-bottom: 1px solid #E2E2E2;
          padding: 15px 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          grid-gap: 10px;
          gap: 10px; }
          .RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__TierSelecionWrapper__TierSelectionSection:first-of-type {
            padding-top: 10px; }
          .RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__TierSelecionWrapper__TierSelectionSection:last-of-type {
            border: none; }
      .RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__FiltersWrapper {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px; }
    .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent {
      overflow-y: scroll;
      overflow-x: hidden;
      flex: 2 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 40px 70px 300px; }
      .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlansSection {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #E2E2E2;
        padding: 30px 0;
        width: 100%; }
        .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlansSection:nth-child(1) {
          border-top: 1px solid #E2E2E2; }
        .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlansSection__GroupedPlansHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 20px; }
          .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlansSection__GroupedPlansHeader > button {
            height: auto; }
        .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlansSection__GroupedPlans {
          display: flex;
          flex-wrap: wrap;
          grid-gap: 10px;
          gap: 10px; }
      .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlanSelection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px 10px 20px;
        background: #FFFFFF00 0% 0% no-repeat padding-box;
        border: 1px solid #C3C3C3;
        border-radius: 40px;
        grid-gap: 15px;
        gap: 15px;
        cursor: pointer; }
        .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlanSelection--active {
          background: #E8EAF8 0% 0% no-repeat padding-box;
          border: 1px solid #1F35B5; }
        .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlanSelection__TextWrapper {
          color: #898989; }
          .RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlanSelection__TextWrapper--active {
            color: #1F35B5; }
  .RegisterBlock__ProductsData__SelectPlansModal__ModalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    width: 969px;
    height: 70px;
    box-shadow: 0px -5px 20px #00000029;
    padding: 0 40px;
    position: fixed;
    bottom: 60px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px; }
    .RegisterBlock__ProductsData__SelectPlansModal__ModalFooter__ActionsWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 40px;
      gap: 40px; }
      .RegisterBlock__ProductsData__SelectPlansModal__ModalFooter__ActionsWrapper > button {
        width: 182px; }
  .RegisterBlock__ProductsData__SelectPlansModal > .ModalGeneric__Modal__Container {
    padding: 0; }
  .RegisterBlock__ProductsData__SelectPlansModal > .ModalGeneric__Modal__CloseButton {
    margin: 18px 18px 0 0; }

.RegisterBlock__ProductsData__PlanContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  grid-gap: 20px;
  gap: 20px; }

.RegisterBlock__ProductsData__AddPlansContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #F5F5F5;
  border-radius: 5px;
  padding: 30px 0;
  flex: 1 1;
  grid-gap: 13px;
  gap: 13px;
  height: 185px; }
  .RegisterBlock__ProductsData__AddPlansContainer__AddPlansButton {
    margin-top: 6px;
    width: 230px; }

.RegisterBlock__ProductsData__ParticularPlanContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: #F5F5F5;
  padding: 30px 0;
  border-radius: 5px;
  flex: 1 1;
  height: 185px; }
  .RegisterBlock__ProductsData__ParticularPlanContainer__InputPrice {
    width: 230px;
    margin: 20px 0 0 0; }
  .RegisterBlock__ProductsData__ParticularPlanContainer__RadioButtonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 15px; }

.RegisterBlock__ProductsData__product {
  display: flex;
  border-bottom: 1px solid #E2E2E2;
  padding: 30px 0 !important; }
  .RegisterBlock__ProductsData__product:nth-child(1) {
    border-top: 1px solid #E2E2E2; }
  .RegisterBlock__ProductsData__product__heading {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; }
    @media (min-width: 1096px) {
      .RegisterBlock__ProductsData__product__heading {
        flex-direction: row; } }
    .RegisterBlock__ProductsData__product__heading__fixed, .RegisterBlock__ProductsData__product__heading__price {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%; }
      @media (min-width: 1096px) {
        .RegisterBlock__ProductsData__product__heading__fixed, .RegisterBlock__ProductsData__product__heading__price {
          width: 45%; } }
    .RegisterBlock__ProductsData__product__heading__fixed__title {
      line-height: 1.1; }
    .RegisterBlock__ProductsData__product__heading__price .TextInput {
      max-width: 17.5rem !important; }
      @media (min-width: 1096px) {
        .RegisterBlock__ProductsData__product__heading__price .TextInput {
          margin-left: 1rem; } }
    @media (min-width: 1096px) {
      .RegisterBlock__ProductsData__product__heading__price {
        justify-content: flex-end;
        margin-right: 1rem; } }
    .RegisterBlock__ProductsData__product__heading__plans {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .RegisterBlock__ProductsData__product__heading__plans__title {
        display: block;
        width: 100%;
        margin-bottom: 1rem; }
      .RegisterBlock__ProductsData__product__heading__plans .RadioToggle {
        margin-right: 10px;
        margin-top: 10px; }

.ProfessionalHome {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .ProfessionalHome__Card {
    margin-bottom: 20px;
    max-width: 100% !important; }
    .ProfessionalHome__Card__ReviewNotification {
      color: #fff;
      position: absolute;
      background: #ff2929;
      border-radius: 15px;
      text-align: center;
      border: 4px solid #fff;
      right: 0;
      top: 0;
      padding: 2px 7px;
      -webkit-animation: pulse 2.5s infinite;
              animation: pulse 2.5s infinite; }
  .ProfessionalHome__Schedules {
    margin: 20px 0; }
  .ProfessionalHome__ScheduleItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px; }
    .ProfessionalHome__ScheduleItem:last-child {
      margin-bottom: 0; }
    .ProfessionalHome__ScheduleItem__Grid {
      display: flex;
      align-items: center;
      margin-top: 5px; }
      .ProfessionalHome__ScheduleItem__Grid p {
        margin-left: 5px; }
  .ProfessionalHome__EmptySchedules {
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #C1C1C1; }

@-webkit-keyframes pulse {
  0% {
    border: 4px solid #ff2929;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7); }
  70% {
    border: 4px solid rgba(255, 82, 82, 0);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0); } }

@keyframes pulse {
  0% {
    border: 4px solid #ff2929;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7); }
  70% {
    border: 4px solid rgba(255, 82, 82, 0);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0); } }

.ProfessionalAgenda__Header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px 20px 0; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalAgenda__Header {
      margin-top: 5px;
      padding: 0 4px 20px; } }
  .ProfessionalAgenda__Header__CustomWrapper {
    padding: 15px 0 20px 0 !important;
    box-shadow: 0 4px 2px -2px #00000029; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalAgenda__Header__CustomWrapper {
        padding: 15px 14px !important; } }
  .ProfessionalAgenda__Header__Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%; }
  .ProfessionalAgenda__Header__ClearFilters {
    margin-top: 20px; }
    .ProfessionalAgenda__Header__ClearFilters__Text {
      text-transform: uppercase !important;
      font-size: 12px !important; }
  .ProfessionalAgenda__Header__Title {
    font-size: 30px !important; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalAgenda__Header__Title {
        font-size: 21px !important;
        font-weight: 500;
        margin-bottom: 20px; } }
  .ProfessionalAgenda__Header__Filters {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalAgenda__Header__Filters {
        width: 100%; } }
    .ProfessionalAgenda__Header__Filters__Button {
      background: none;
      border: 0;
      height: 35px;
      margin: 0;
      padding: 0; }
      .ProfessionalAgenda__Header__Filters__Button svg {
        margin: 0;
        padding: 0; }
    .ProfessionalAgenda__Header__Filters__Input {
      width: 250px !important; }
      .ProfessionalAgenda__Header__Filters__Input__Custom {
        margin: 0 !important; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .ProfessionalAgenda__Header__Filters__Input {
          width: 100% !important; } }
  .ProfessionalAgenda__Header--floating {
    position: fixed;
    top: 0; }

.ProfessionalAgenda__Title {
  display: flex; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalAgenda__Title {
      margin-top: 10px;
      justify-content: center; } }
  @media (min-width: 1096px) {
    .ProfessionalAgenda__Title {
      border-right: 2px solid #c4c4c4; } }
  .ProfessionalAgenda__Title__Avatar {
    margin-right: 10px; }
  .ProfessionalAgenda__Title__Text {
    padding-top: 2px;
    padding-left: 5px; }
  .ProfessionalAgenda__Title p {
    line-height: 15px; }

@media (min-width: 0px) and (max-width: 1096px) {
  .ProfessionalAgenda__Calendar {
    margin-top: 20px; } }

.ProfessionalAgenda__Calendar__float {
  position: absolute;
  width: 100%;
  margin-top: -7.3rem;
  z-index: 205; }
  @media (min-width: 1096px) {
    .ProfessionalAgenda__Calendar__float {
      position: absolute;
      width: 100%;
      margin-top: -7.3rem;
      margin-left: -17.4rem;
      z-index: 205; } }

.ProfessionalAgenda__Calendar__link {
  color: initial;
  cursor: pointer; }

.ProfessionalAgenda__DateSelector__date {
  align-items: center;
  display: flex;
  height: 6rem;
  flex-grow: 1;
  justify-content: space-between; }

.ProfessionalAgenda__DateSelector__dateButton {
  cursor: pointer; }

.ProfessionalAgenda__DateSelector__dateValue {
  margin-bottom: 0.5rem; }

.ProfessionalAgenda__appointmentList {
  height: 100%; }
  .ProfessionalAgenda__appointmentList__text {
    margin-bottom: 1rem; }
  .ProfessionalAgenda__appointmentList__button {
    width: 16.7rem; }
  .ProfessionalAgenda__appointmentList__Slots {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .ProfessionalAgenda__appointmentList__Schedules {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .ProfessionalAgenda__appointmentList__Schedules__item {
      margin: 1rem 0; }

@media (min-width: 0px) and (max-width: 1096px) {
  .ProfessionalAgenda__List__Row {
    margin: 0 -10px !important;
    width: auto !important; } }

.ProfessionalAgenda__List__EmptyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #898989; }
  .ProfessionalAgenda__List__EmptyWrapper svg {
    margin: 164px 0 10px 0; }

.ProfessionalAgenda__List__Item__ActionButton {
  background: none;
  border: none;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; }

.ProfessionalAgenda__List__Item__Ripple {
  padding: 14px 0 14px 15px; }
  .ProfessionalAgenda__List__Item__Ripple__DateDisplayer {
    background-color: #898989 !important; }

.ProfessionalAgenda__List__Item__Badge {
  margin: 0 40px 18px 0;
  display: flex;
  justify-content: flex-end; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalAgenda__List__Item__Badge {
      margin: 15px 0;
      justify-content: flex-start; } }

.ProfessionalAgenda__List__Item__ContentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 15px 14px 0; }

.ProfessionalAgenda__List__Item__SlotData {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding-left: 15px; }
  .ProfessionalAgenda__List__Item__SlotData__RecurrentRange {
    line-height: 16px !important; }
  .ProfessionalAgenda__List__Item__SlotData__Separator {
    color: #bebebe; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalAgenda__List__Item__SlotData {
      flex-direction: column;
      grid-gap: 5px;
      gap: 5px;
      align-items: flex-start; } }

.ProfessionalAgenda__List__Item__Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85px;
  width: 100%; }
  .ProfessionalAgenda__List__Item__Content__MedicalReturn {
    justify-content: normal; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalAgenda__List__Item__Content__MedicalReturn {
        height: 100%; } }

.ProfessionalAgenda__List__Item__PlanData {
  display: flex; }
  .ProfessionalAgenda__List__Item__PlanData__Reservation {
    margin-left: 10px !important; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalAgenda__List__Item__PlanData__Reservation {
        margin: 0 0 10px 0 !important; } }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalAgenda__List__Item__PlanData {
      flex-direction: column-reverse; } }
  .ProfessionalAgenda__List__Item__PlanData__Wrapper {
    display: flex;
    grid-gap: 10px;
    gap: 10px; }

.ProfessionalAgenda__List__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  max-height: 35px; }

.ProfessionalAgenda__List__SkeletonWrapper__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F5F5F5;
  height: 35px;
  padding: 10px; }

.ProfessionalAgenda__List__SkeletonWrapper__Item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  border-top: 1px solid #E2E2E2; }
  .ProfessionalAgenda__List__SkeletonWrapper__Item__Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0; }

.ProfessionalAgenda__RemoveSlotModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .ProfessionalAgenda__RemoveSlotModal__Title, .ProfessionalAgenda__RemoveSlotModal__SuccessTitle {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center; }
  .ProfessionalAgenda__RemoveSlotModal__SuccessTitle {
    margin: 10px 0 25px 0; }
  .ProfessionalAgenda__RemoveSlotModal__SlotData {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px; }
  .ProfessionalAgenda__RemoveSlotModal__ConfirmationMessage {
    display: flex;
    color: #FF4949;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    margin: 30px 0 20px 0; }
  .ProfessionalAgenda__RemoveSlotModal__Button {
    width: 100%; }

.ProfessionalAgenda__FiltersModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 332px;
  position: relative; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalAgenda__FiltersModal {
      width: 100%; } }
  .ProfessionalAgenda__FiltersModal__Header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .ProfessionalAgenda__FiltersModal__InputWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 15px;
    gap: 15px;
    margin: 10px 0; }
  .ProfessionalAgenda__FiltersModal__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; }
    .ProfessionalAgenda__FiltersModal__Content__FiltersWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 15px; }
  .ProfessionalAgenda__FiltersModal__ClearFilter {
    display: flex;
    background: none;
    border: none;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 30px 0 27px 0; }
  .ProfessionalAgenda__FiltersModal__Button {
    width: 100%; }

@media (min-width: 0px) and (max-width: 1096px) {
  .ProfessionalAgenda .FloatActionButtonDropdown__Button {
    padding: 0; } }

@media (min-width: 0px) and (max-width: 1096px) {
  .ProfessionalAgenda .FloatActionButtonDropdown__Icon {
    margin-right: 0; } }

@media (min-width: 0px) and (max-width: 1096px) {
  .ProfessionalAgenda .FloatActionButtonDropdown__Title {
    display: none; } }

.PatientHome {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .PatientHome__Card {
    margin-bottom: 20px;
    max-width: 100% !important; }
  .PatientHome__NextScheduleCard {
    margin: 20px 0; }
    .PatientHome__NextScheduleCard > div {
      align-items: inherit; }
  .PatientHome__NextScheduleEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    fill: #c1c1c1;
    color: #c1c1c1;
    margin-top: 60px;
    margin-bottom: 50px; }
  .PatientHome__EmptyReservation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    color: #898989;
    overflow: auto; }
    .PatientHome__EmptyReservation__title {
      margin: 10px 0 !important; }
    .PatientHome__EmptyReservation__subtitle {
      max-width: 224px;
      margin-bottom: 20px !important;
      text-align: center; }
  .PatientHome__InfoDialog__icon {
    margin-top: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .PatientHome__InfoDialog__title {
    margin-bottom: 20px !important; }
  .PatientHome__InfoDialog__content {
    margin-bottom: 20px !important; }
  .PatientHome__InfoDialog__paper {
    margin: 10px 0 20px 0;
    align-self: center;
    max-width: 100% !important; }
  .PatientHome__Reservation {
    display: flex;
    margin-top: 20px;
    z-index: 1; }
    .PatientHome__Reservation__exceptions {
      justify-content: flex-start !important;
      margin: 20px 0; }
    .PatientHome__Reservation__avatar {
      display: flex;
      justify-content: center;
      margin-right: 15px; }
    .PatientHome__Reservation__schedule {
      flex: 1 1; }
    .PatientHome__Reservation__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .PatientHome__Reservation__footer {
      background-color: #E7FFE5;
      display: flex;
      align-items: center;
      border-radius: 0 0 5px 5px;
      justify-content: center;
      padding: 17px 15px 20px 20px;
      z-index: 2;
      margin: 15px -20px -20px -20px; }
    .PatientHome__Reservation__footerIcon {
      margin-top: 5px;
      margin-right: 10px; }
    .PatientHome__Reservation__scheduleProfessional {
      margin-top: 10px; }
  .PatientHome__CancelModal__warning {
    text-align: center;
    max-width: 100%; }
  .PatientHome__CancelModal__reason {
    margin-top: 30px;
    display: flex;
    flex-direction: column; }
  .PatientHome__CancelModal__textbox {
    margin-top: 10px;
    margin-bottom: 20px; }
  .PatientHome__CancelModal__required {
    color: #898989 !important; }
  .PatientHome__CancelModal__button {
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%; }
  .PatientHome__CancelModal__spinner {
    display: flex;
    justify-content: center;
    height: 50vh;
    padding-top: calc(25vh - 42px); }
  .PatientHome__CancelModal__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center; }
    .PatientHome__CancelModal__success__subTitle {
      margin-top: 5px !important; }
    .PatientHome__CancelModal__success__warning {
      margin-top: 30px;
      padding: 20px !important; }
  .PatientHome__CancelModal__successIcon {
    margin-bottom: 10px; }
  .PatientHome__CancelModal__successButton {
    width: 332px;
    margin-top: 30px; }
  .PatientHome__CancelModal__professionalWarning {
    margin-bottom: 20px;
    justify-content: center;
    max-width: 100% !important; }
  .PatientHome__FabItem {
    max-width: 60%;
    cursor: pointer; }
    @media (min-width: 1096px) {
      .PatientHome__FabItem {
        max-width: initial;
        width: 250px; } }
  .PatientHome .PatientEligibility__title {
    text-align: left; }
  .PatientHome .PatientEligibility__info {
    justify-content: flex-start; }
  .PatientHome .PatientEligibility__info p, .PatientHome .PatientEligibility__message {
    text-align: left; }

.ProfessionalReservations__spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 82px; }

.ProfessionalReservations__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 82px;
  padding: 0 20%; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalReservations__empty {
      padding: 0 20px; } }

.ProfessionalReservations__emptyTitle {
  margin: 10px 0 20px 0 !important; }

.ProfessionalReservations__emptyText {
  color: #898989 !important;
  text-align: center;
  margin-bottom: 20px !important; }

.ProfessionalReservations__dayHeader {
  width: 100%;
  height: 35px;
  background-color: #FFFFB5;
  display: flex;
  align-items: center;
  padding-left: 15px; }

.ProfessionalReservations__listItem {
  display: flex;
  padding: 15px;
  border-top: 1px solid #E2E2E2; }

.ProfessionalReservations__grow {
  flex-grow: 1; }

.ProfessionalReservations__displayer {
  justify-content: center !important;
  align-items: center !important;
  margin-right: 15px;
  height: auto !important; }

.ProfessionalReservations__listItemPlan {
  margin-top: 40px;
  display: flex; }

.ProfessionalReservations__listItemPlanName {
  margin-right: 10px !important; }

.ProfessionalReservations__listItemActions {
  display: flex;
  align-items: center;
  justify-content: center; }

.ProfessionalReservations__listItem--hideBorderTop {
  border-top: none; }

.Homepage {
  background-color: #f5f5f5;
  position: relavite; }
  .Homepage__Header {
    background: linear-gradient(to right, #1F35B5, #556EFF 100%);
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 2; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Homepage__Header {
        height: 54px; } }
    .Homepage__Header__Info {
      width: 100%;
      max-width: 910px;
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      justify-content: space-between; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .Homepage__Header__Info {
          justify-content: center; } }
  .Homepage__FiltersWrapper {
    position: relative;
    z-index: 2; }
  .Homepage__Title__Text {
    color: #fff !important;
    font-size: 30px !important; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Homepage__Title__Text {
        margin: 20px 0;
        text-align: center;
        font-size: 21px !important; } }
  .Homepage__bottom {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Homepage__bottomMessage {
    max-width: 400px;
    cursor: pointer; }
  @media (min-width: 1096px) {
    .Homepage__oneHalfColumn.five.col {
      width: 41.65%; } }
  .Homepage__timestampContainer {
    margin-top: 20px; }
  .Homepage .AlertIcon {
    margin-top: 1.5rem; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Homepage .AlertIcon {
        display: none; } }
  .Homepage__professionalsLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%; }
  .Homepage__ApproachesModal, .Homepage__ReviewsModal {
    max-width: 729px !important; }
    .Homepage__ApproachesModal__Container, .Homepage__ReviewsModal__Container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 30px; }
    .Homepage__ApproachesModal__Sidebar, .Homepage__ReviewsModal__Sidebar {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 170px; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .Homepage__ApproachesModal__Sidebar, .Homepage__ReviewsModal__Sidebar {
          display: none; } }
    .Homepage__ApproachesModal__DescriptionContainer, .Homepage__ReviewsModal__DescriptionContainer {
      max-width: 350px;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #c1c1c1;
      padding-left: 40px; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .Homepage__ApproachesModal__DescriptionContainer, .Homepage__ReviewsModal__DescriptionContainer {
          border: none;
          padding-left: 0; } }
    .Homepage__ApproachesModal__ApproachesList, .Homepage__ReviewsModal__ApproachesList {
      display: flex;
      flex-direction: column;
      margin-top: 10px; }
      .Homepage__ApproachesModal__ApproachesList__Button, .Homepage__ReviewsModal__ApproachesList__Button {
        font-weight: bold;
        text-transform: capitalize !important;
        padding: 0;
        height: 30px !important; }
  .Homepage__ReviewsModal {
    height: 100% !important; }
  .Homepage__ReviewsModal__Container {
    margin-top: 20px; }
  .Homepage__ReviewsModal__Sidebar {
    max-width: 150px;
    top: 160px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Homepage__ReviewsModal__Sidebar {
        top: initial;
        max-width: initial;
        display: flex;
        position: relative;
        align-items: center; } }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Homepage__ReviewsModal__Sidebar__ProfessionalDetail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        grid-gap: 15px;
        gap: 15px;
        padding-bottom: 10px; } }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Homepage__ReviewsModal__Sidebar__ProfessionalDetail__Texts {
        max-width: 170px; } }
  .Homepage__ReviewsModal__DescriptionContainer {
    border-left: initial;
    padding-left: 0;
    max-width: initial;
    width: 100%; }
    @media (min-width: 1096px) {
      .Homepage__ReviewsModal__DescriptionContainer {
        max-width: 370px; } }
    .Homepage__ReviewsModal__DescriptionContainer__Select {
      max-width: initial !important; }
      .Homepage__ReviewsModal__DescriptionContainer__Select > div {
        margin-top: 0; }
    .Homepage__ReviewsModal__DescriptionContainer__EmptyReviews {
      padding-top: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      grid-gap: 20px;
      gap: 20px; }
  .Homepage__ApproachesModalContainer, .Homepage__ReviewsModalContainer {
    padding: 0px 50px 22px 50px !important; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Homepage__ApproachesModalContainer, .Homepage__ReviewsModalContainer {
        padding: 0 12px 22px 12px !important; } }
  .Homepage__ApproachesModalTopbar, .Homepage__ReviewsModalTopbar {
    max-width: 729px !important; }
  .Homepage__ApproachesModalTitle, .Homepage__ReviewsModalTitle {
    width: 80% !important;
    margin-left: 10% !important; }
  .Homepage__VideoOverlay {
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999999;
    height: 100%;
    position: fixed;
    bottom: 0;
    width: 100%; }
    .Homepage__VideoOverlay__Wrapper {
      width: 100%;
      max-width: 700px; }
    .Homepage__VideoOverlay__TextWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;
      grid-gap: 5px;
      gap: 5px; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .Homepage__VideoOverlay__TextWrapper {
          align-items: flex-start;
          grid-gap: 0;
          gap: 0;
          padding-left: 20px; } }
    .Homepage__VideoOverlay__CloseButton {
      position: absolute;
      right: 0;
      background: none;
      border: none;
      transition: all 0.2s;
      padding: 0; }
      .Homepage__VideoOverlay__CloseButton:hover {
        opacity: 0.7; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .Homepage__VideoOverlay__CloseButton {
          margin-right: 15px; } }
    .Homepage__VideoOverlay__Text {
      color: #fff !important;
      text-transform: capitalize; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .Homepage__VideoOverlay__Text {
          width: 80%;
          line-height: 24px !important; } }
    @media (min-width: 0px) and (max-width: 1096px) {
      .Homepage__VideoOverlay__ProfessionalName {
        font-size: 18px !important; } }
    .Homepage__VideoOverlay__VideoWrapper {
      height: 393px;
      width: 100%; }
      @media (min-width: 0px) and (max-width: 1096px) {
        .Homepage__VideoOverlay__VideoWrapper {
          height: 240px; } }

.MyConsultations {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; }

.header {
  display: flex;
  justify-content: space-between; }

.Response {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 12rem) !important; }
  @media (min-width: 1096px) {
    .Response {
      width: 86%;
      margin: 0 auto; } }
  .Response__container {
    width: 100%;
    margin-top: 1.6rem; }
    @media (min-width: 1096px) {
      .Response__container {
        width: 89%;
        margin: 0 auto; } }

.ResponseTextBold {
  font-weight: 700; }

.CustomError {
  width: 100%;
  text-align: center;
  padding: 50px 0px 70px 0px; }
  @media (min-width: 1096px) {
    .CustomError {
      padding: 120px 0px 130px 0px; } }
  .CustomError__Img {
    width: 90%;
    max-width: 382.56px;
    margin-bottom: 20px;
    height: auto; }
  .CustomError__Subtitle {
    width: 255px;
    display: inline-block;
    margin-top: 10px; }
    @media (min-width: 1096px) {
      .CustomError__Subtitle {
        width: 288px; } }
  .CustomError__ButtonBox {
    margin-top: 20px; }
    .CustomError__ButtonBox__Button {
      font-size: 12px;
      font-weight: 400;
      padding: 10px;
      width: 189px;
      height: auto; }
  .CustomError__Footer {
    margin-top: 0px; }

.Processing,
.Cart,
.Checkout {
  width: 100%;
  min-height: calc(100vh - 12rem) !important; }

.Processing {
  align-items: center;
  display: flex;
  justify-content: center; }

.Cart__item-list {
  margin-bottom: 2rem; }

.Checkout__payment-methods, .Checkout__payment-method {
  margin-bottom: 2rem; }

.PaymentContainer__payment-method {
  margin-top: 2rem !important; }

.PaymentContainer__registered-card-form, .PaymentContainer__new-card-form {
  margin-top: 2rem !important; }

.Response {
  width: 100%;
  min-height: calc(100vh - 12rem) !important; }
  .Response__icon-error {
    fill: #eb384d !important; }
  .Response__icon-sucess {
    fill: #4AC326 !important; }

.PlanResponse {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 5.2rem); }

@media (min-width: 1096px) {
  .ProfessionalProfile {
    padding-top: 30px !important; } }

.ProfessionalProfile__loading {
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center; }

@media (min-width: 1096px) {
  .ProfessionalProfile__Info {
    padding-right: 30px; } }

.ProfessionalProfile__Schedule {
  background-color: #FFF;
  box-shadow: 0px 1px 7px #00000033;
  border-radius: 5px;
  padding: 30px;
  background-color: #fff; }
  .ProfessionalProfile__Schedule .LineBreaker {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px); }

.ProfessionalProfile__Stamp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 30px; }
  @media (min-width: 0px) and (max-width: 1096px) {
    .ProfessionalProfile__Stamp {
      flex-direction: column;
      align-items: flex-start; } }
  .ProfessionalProfile__Stamp img {
    margin: 0 20px 0 0;
    height: 30px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalProfile__Stamp img {
        margin: 0 0 15px 0; } }

.ProfessionalProfile__video {
  height: 0;
  margin-bottom: 30px;
  max-height: 382px;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%; }
  .ProfessionalProfile__video iframe, .ProfessionalProfile__video video {
    position: absolute;
    width: 100%;
    height: 100%; }
  .ProfessionalProfile__video__layer {
    align-items: center;
    background: #000000;
    bottom: 0;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
    .ProfessionalProfile__video__layer__button {
      margin-bottom: 20px; }
    .ProfessionalProfile__video__layer__typography {
      color: #ffffff !important; }

.ProfessionalProfile__Reviews {
  text-align: center; }
  .ProfessionalProfile__Reviews__Header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalProfile__Reviews__Header {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 25px;
        gap: 25px; } }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalProfile__Reviews__Header__Select {
        max-width: initial !important; } }
    .ProfessionalProfile__Reviews__Header__Select > div {
      margin-top: 0; }
  .ProfessionalProfile__Reviews__Button {
    padding-top: 30px; }
  .ProfessionalProfile__Reviews__EmptyReviews {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0 80px;
    grid-gap: 20px;
    gap: 20px; }
    @media (min-width: 0px) and (max-width: 1096px) {
      .ProfessionalProfile__Reviews__EmptyReviews {
        flex-direction: column; } }

.Register__Header {
  margin: 40px 0 30px !important; }
  @media (min-width: 1096px) {
    .Register__Header {
      margin: 80px 0 0 !important; } }

.Register__Link {
  font-weight: bold;
  color: #1f35b5;
  cursor: pointer; }
  .Register__Link:hover {
    color: #8E99D9; }

.Register__InfoGroup {
  width: 100%; }
  .Register__InfoGroup__Head {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .Register__InfoGroup__Head__Image {
      align-items: center;
      border-radius: 0.5rem;
      box-shadow: 0rem 0rem 0.6rem rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      justify-self: self-start;
      overflow: hidden;
      width: auto;
      min-width: 115px;
      height: auto;
      min-height: 62px; }
      .Register__InfoGroup__Head__Image img {
        width: 100%;
        height: auto; }
    .Register__InfoGroup__Head__Text {
      width: 100%;
      text-align: left;
      padding-left: 15px; }
  .Register__InfoGroup__Forms {
    margin: 20px 0 20px 0; }
    .Register__InfoGroup__Forms__Item {
      margin-bottom: 10px; }
      .Register__InfoGroup__Forms__Item:nth-child(4) {
        margin-bottom: 5px; }
      .Register__InfoGroup__Forms__Item.inputMedicalRequest {
        width: 100%; }
      .Register__InfoGroup__Forms__Item--radio {
        display: flex;
        margin: 2rem 0; }
        .Register__InfoGroup__Forms__Item--radio__Item {
          margin: 0 1rem; }
          .Register__InfoGroup__Forms__Item--radio__Item:first-of-type {
            margin-left: 0; }
          .Register__InfoGroup__Forms__Item--radio__Item:last-of-type {
            margin-right: 0; }
      .Register__InfoGroup__Forms__Item .dropdown, .Register__InfoGroup__Forms__Item .dropdown-error {
        margin-bottom: 0.5rem; }
      .Register__InfoGroup__Forms__Item__HelperText {
        line-height: 15px !important;
        font-size: 12px;
        font-style: italic; }
        .Register__InfoGroup__Forms__Item__HelperText.helperTextMedicalRequest {
          text-align: left; }
    .Register__InfoGroup__Forms--image-wrapper {
      cursor: pointer;
      overflow: hidden;
      position: relative;
      width: 100%; }
      .Register__InfoGroup__Forms--image-wrapper input[type=file] {
        cursor: pointer;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%; }
  .Register__InfoGroup__Error {
    align-items: flex-start;
    display: flex;
    margin: 20px 0px; }
    .Register__InfoGroup__Error__Icon {
      margin-right: 10px;
      margin-top: -5px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.Register__Professionals {
  margin-top: 30px; }
  .Register__Professionals > div {
    content: "' '";
    height: 1px;
    width: 100%;
    background-color: #e2e2e2;
    margin-bottom: 30px; }
  .Register__Professionals__Text {
    font-size: 12px;
    color: #898989;
    text-align: center; }

.Register__no-medical-order {
  margin-bottom: 20px; }

.Register__boxMedicalRequest {
  padding-bottom: 85px !important; }
  .Register__boxMedicalRequest--authorization {
    background: #FFFEB5;
    border-radius: 5px;
    line-height: 1.8rem !important;
    padding: 20px; }
  .Register__boxMedicalRequest--info {
    max-width: 33rem;
    font-size: 2.4rem;
    line-height: 2.6rem; }
  .Register__boxMedicalRequest--iconButton {
    -webkit-animation: refresh 1s linear infinite;
    animation: refresh 1s linear infinite;
    position: absolute;
    right: calc(50% - 110px);
    top: 10px; }
  .Register__boxMedicalRequest--alignItems {
    position: relative; }
  .Register__boxMedicalRequest .TextInput {
    margin-bottom: 5px; }

.GroupPlanSelection {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%; }
  .GroupPlanSelection__Title {
    margin-bottom: 11px; }
  .GroupPlanSelection__Selectors {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 22rem; }
    .GroupPlanSelection__Selectors .FilterToggleBox {
      width: 100% !important; }
  .GroupPlanSelection__Dropdown {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .GroupPlanSelection__Dropdown .dropdown, .GroupPlanSelection__Dropdown .dropdown-error {
      margin-bottom: 10px;
      width: 100%; }
  .GroupPlanSelection__Button {
    width: 100%; }

.Modal .Modal__container__content, .PortalModal-align-center .Modal__container__content, .Modal-align-center .Modal__container__content {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .Modal .Modal__container__content > p, .PortalModal-align-center .Modal__container__content > p, .Modal-align-center .Modal__container__content > p {
    margin-bottom: 15px; }

@-webkit-keyframes refresh {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes refresh {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.Login__Header {
  margin: 40px 0 30px !important; }
  @media (min-width: 1096px) {
    .Login__Header {
      margin: 80px 0 30px !important; } }

.Login__Content__Column {
  width: 50%; }

.Login__Content__Link:hover {
  color: #8E99D9; }

.ResendPin-message-pin {
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1.5rem; }
  .ResendPin-message-pin--title {
    align-items: center;
    display: flex;
    justify-content: flex-start; }
    .ResendPin-message-pin--title .message-title {
      color: #4ac326;
      margin-left: 1rem; }
  .ResendPin-message-pin p {
    margin-left: 3rem; }

.messageError {
  margin-top: 1rem; }

button {
  margin: 0; }

.Survey {
  width: 98%;
  margin: 0 auto;
  min-height: calc(100vh -12vh) !important; }
  .Survey__Button {
    width: 33.2rem;
    margin: 30px auto; }
    @media (min-width: 1096px) {
      .Survey__Button {
        width: 59.5rem; } }
    .Survey__Button button {
      width: 100%;
      font-weight: bold; }
  .Survey__body {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .Survey__subheading {
    margin: 0.5rem auto 2rem auto;
    color: #acacac !important; }
    @media (min-width: 1096px) {
      .Survey__subheading {
        width: 51rem; } }
  .Survey__link {
    font-weight: 700; }
  .Survey__message {
    margin-top: 30px; }
  .Survey__loading {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .Survey__loading div {
      width: 33px;
      height: 33px; }

.DayPicker, .DayPicker-wrapper, .DayPicker-Month {
  font-family: "Ubuntu";
  font-size: 1.5rem;
  font-weight: 400;
  width: 100%;
  padding: 10px 0;
  outline: none; }
  .DayPicker-wrapper, .DayPicker-Month {
    margin: 0;
    padding: 0; }
  .DayPicker-Day {
    border: .5px solid #fff;
    border-radius: 0;
    height: 4.2rem;
    outline: none;
    padding: 10px 0;
    width: 4.2rem; }
    .DayPicker-Day--today:not(.DayPicker-Day--disabled) {
      color: #1f35b5; }
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: rgba(31, 53, 181, 0.2) !important; }
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
      background-color: #1f35b5 !important;
      color: #fff !important;
      font-weight: 700; }
    .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
      color: #dedede;
      position: relative; }
      .DayPicker-Day--disabled:not(.DayPicker-Day--outside)::before {
        content: '';
        display: block;
        height: 70%;
        width: 1px;
        background-color: #dedede;
        position: absolute;
        top: 15%;
        left: 50%;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        -webkit-animation: line-through 300ms forwards;
                animation: line-through 300ms forwards; }
    .DayPicker-Day--schedule.DayPicker-Day--disabled {
      position: relative; }
      .DayPicker-Day--schedule.DayPicker-Day--disabled::after {
        content: '';
        left: 0;
        right: 0;
        height: 28px;
        width: 28px;
        background: #dedede url("/static/check.svg") no-repeat center center;
        border-radius: 28px;
        left: calc(50% - 14px);
        top: calc(50% - 14px);
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        position: absolute; }
  .DayPicker-Caption {
    margin-bottom: 0.5rem;
    padding: 0; }
    .DayPicker-Caption div {
      align-items: center;
      display: flex;
      font-weight: 700;
      height: 4.2rem;
      justify-content: center;
      text-transform: uppercase; }
  .DayPicker-NavButton {
    background-size: 100%;
    height: 4.2rem;
    margin-top: 0;
    width: 4.2rem;
    top: 0; }
    .DayPicker-NavButton:focus {
      outline: none; }
    .DayPicker-NavButton--prev {
      background-image: url("/static/icon-navigate-before.svg");
      left: 0rem;
      margin-right: 0; }
    .DayPicker-NavButton--next {
      background-image: url("/static/icon-navigate-next.svg");
      right: 0rem; }
  .DayPicker-Weekday {
    color: #1f35b5;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0;
    padding-bottom: 1.057rem; }

html {
  scroll-behavior: smooth; }

body {
  font-family: "Ubuntu";
  font-weight: 400;
  background-color: #ffffff; }
  body .App {
    height: 100%;
    overflow-x: hidden;
    width: 100%; }

h1 {
  font-family: "Ubuntu";
  font-weight: 400;
  color: #4b4b4b;
  font-size: 3rem;
  line-height: 2.1rem;
  margin: 0;
  text-align: left; }

p,
a {
  margin: 0;
  text-align: left; }

a {
  color: #1f35b5; }
  a:hover, a:active {
    color: #4b4b4b; }

form {
  width: 100%; }

.messageError {
  color: #eb384d;
  font-size: 1.5rem;
  margin-left: 0;
  text-align: left;
  width: 100%; }
  .messageError span {
    margin: 0; }

.grecaptcha-badge {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  opacity: 0; }

.grecaptcha-badge:hover {
  width: 256px !important; }

.title {
  font-size: 1.5rem !important;
  font-family: "Ubuntu";
  font-weight: 700; }
  @media screen and (min-width: 1096px) {
    .title {
      font-size: 1.5rem !important; } }

.btn-teste {
  border-top-left-radius: 1.5em 50%;
  border-bottom-left-radius: 1.5em 50%;
  border-top-right-radius: 1.5em 50%;
  border-bottom-right-radius: 1.5em 50%;
  border-radius: 0.5rem;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
  background-color: #f8c947;
  max-width: 10%;
  padding: 0.25rem; }

.div-test {
  background-color: #eb384d;
  height: 50px; }

/** General **/
.cursor-pointer {
  cursor: pointer !important; }

.overflow-hidden {
  overflow: hidden; }

.hasMarginBottom {
  margin-bottom: 2rem !important; }

.hasLeftMarginMobile {
  margin-left: 1.5rem !important; }
  @media (min-width: 1096px) {
    .hasLeftMarginMobile {
      margin-left: 0 !important; } }

/** Third Party Components **/
.pincode-input-container {
  display: flex !important;
  width: 100% !important; }
  .pincode-input-container .pincode-input-text {
    border: none !important;
    border-bottom: 0.1rem solid !important;
    color: #4b4b4b !important;
    font-family: "Ubuntu" !important;
    font-size: 1.5rem !important;
    margin: 0.5rem !important;
    outline: none !important;
    padding: 1.8rem !important;
    width: 100% !important; }

.react-phone-number-input {
  height: 4.8rem !important; }

.react-phone-number-input__icon {
  border: none !important; }

.react-phone-number-input__icon svg {
  height: 15px;
  width: 20px; }

.react-phone-number-input__input {
  background: transparent !important;
  border: none !important;
  color: #4b4b4b; }

.react-phone-number-input__row {
  align-items: center;
  border: 0.1rem solid #e2e2e2;
  border-radius: 0.5rem;
  display: flex;
  padding: 1.5rem 1rem; }
  .react-phone-number-input__row input::-webkit-input-placeholder {
    color: #c4c4c4; }
  .react-phone-number-input__row input::placeholder {
    color: #c4c4c4; }

.react-phone-number-input__error {
  margin-left: 0 !important; }

/** Custom Classes for Card Component **/
.Card-filterHeader {
  padding: 1rem !important;
  border-radius: 0 !important; }
  @media (min-width: 1096px) {
    .Card-filterHeader {
      border-radius: 0.5rem !important; } }

