.ProfessionalCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 392px;
  padding: 30px !important;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  @include mobile-media {
    padding: 0 !important;
    height: auto !important;
    max-width: calc(100vw - 14px) !important; }
  &__loading {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 43px;
    gap: 30px;
    height: 358px;
    @include mobile-media {
      padding: 20px 0 !important;
      flex-direction: column;
      gap: 20px; } }
  &__sectionLoading {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    @include mobile-media {
      border-top: 1px solid #eee;
      padding: 20px 0 0 20px; }
    &__info {
      flex: 1.5;
      @include mobile-media {
        padding: 0 20px;
        width: 100%; } } }
  &__availabilitiesLoading {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 35px);
    margin: 25px 0;
    grid-gap: 10px;
    overflow-x: hidden;
    @include mobile-media {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;
      white-space: nowrap;
      padding: 0 20px 0 0;
      width: 100%;
      height: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none; }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 0; } } }
  &__submitLoadingButton {
    @include mobile-media {
      padding-right: 20px; } }
  &__informationWrapperLoading {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 15px;
    &__data {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 55%; } }
  &__tabLoading {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    width: 70%;
    @include mobile-media {
      width: 100%; } }
  &__descriptionLoading {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 30px;
    &__wrapper {
      display: flex;
      gap: 10px;
      flex-direction: column;
      width: 100%; } }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 45px;
    @include mobile-media {
      gap: 0;
      flex-direction: column; } }
  &__button {
    width: 300px;
    min-height: 48px;
    margin-top: 10px;
    @include mobile-media {
      margin: 25px 0 20px 0;
      width: calc(100% - 40px); } }
  &__information {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 2.5;
    width: 100%;
    &__priceWrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;
      @include mobile-media {
        flex-direction: column;
        align-items: flex-start; } }
    &__priceDurationContainer {
      display: flex;
      flex-direction: row;
      align-items: baseline; }
    &__skillsContainer {
      display: flex;
      flex-wrap: wrap;
      font-size: 15px;
      &__item {
        margin-right: 15px;
        margin-bottom: 5px;
        &--active {
          color: #1F35B5;
          font-weight: bold;
          order: -1;
          &::after {
            content: '✔\fe0e';
            margin-left: 4px; } } } }
    &__educationsContainer {
      &__item {
        margin-bottom: 15px; } }
    &__tabContainer {
      margin-top: 20px;
      overflow: hidden;
      width: 100%;
      -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
      @include mobile-media {
        width: 100%;
        padding: 0 20px 0 20px; }
      &__panelContainer {
        height: 112px;
        max-width: 503px; }
      &__labelContainer {
        display: flex;
        width: 60%; }
      &__bio {
        line-height: 22px !important;
        overflow: hidden; } }
    &__name {
      text-transform: capitalize;
      line-height: 25px !important;
      @include mobile-media {
        font-size: 18px !important;
        line-height: 18px !important; } }
    &__doc {
      margin-top: 3px !important;
      @include mobile-media {
        font-size: 12px !important; } }
    &__playvideo, &__reviews {
      display: flex;
      align-items: center;
      margin-left: 15px;
      gap: 5px;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      @include mobile-media {
        margin: 14px 0 0 0;
        padding: 0; }
      &:hover {
        opacity: 0.7; }
      &__text {
        color: #1F35B5 !important; } }
    &__wrapper {
      display: flex;
      flex-direction: row;
      @include mobile-media {
        padding: 20px 20px 0 20px; } }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1.5rem;
      width: auto;
      @include mobile-media {
        width: 60%; } } }
  &__availabilities {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 300px;
    height: 220px;
    margin-top: 10px;
    @include mobile-media {
      margin-top: 0;
      height: auto;
      width: 100%; }
    &__status {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 170px;
      width: 300px;
      @include mobile-media {
        min-height: 35px;
        width: 100%; } }

    &__slots {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 34px);
      overflow-y: scroll;
      width: 300px;
      grid-gap: 10px;
      padding-right: 10px;
      overflow-x: hidden;
      height: 170px;
      &::-webkit-scrollbar {
        border-radius: 10px;
        height: 10px;
        width: 5px;
        background-color: #F5F5F5; }
      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 10px; }
      &::-webkit-scrollbar-track {
        border-radius: 10px; }
      @include mobile-media {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: auto;
        white-space: nowrap;
        padding: 0 20px;
        width: 100%;
        height: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none; }
        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 0; } }
      li {
        border: 1px solid #c1c1c1;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 64px;
        height: 35px; } }
    &__controlsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 25px;
      @include mobile-media {
        padding: 20px 20px 0 20px;
        border-top: 1px solid #eee;
        margin-top: 20px; }
      &__controls {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background: none;
          padding: 0;
          &:disabled {
            opacity: 0.2;
            cursor: not-allowed; } } }
      &__selectedDayContainer {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        &__wrapper {
          display: flex;
          align-items: baseline; }
        @media (max-width: 350px) {
          flex-direction: column;
          align-items: flex-start; }
        &__selectedShortDay {
          display: inline-block;
          text-transform: uppercase;
          @include mobile-media {
            font-size: 15px !important; } }
        &__selectedLongDay {
          @include mobile-media {
            font-size: 15px !important; } }
        &__selectedDay {
          display: inline-block;
          @include mobile-media {
            font-size: 15px !important; } } } } } }
