.editEmailAccount {
  &__data {
    margin-top: 20px; }
  &__actual-email {
    margin-top: 10px; }
  &__form-container {
    margin-top: 40px;
    .TextInput {
      padding-top: 5px; } }
  &__AlertSuccess {
    background: #FFFEB5;
    border-radius: 5px;
    margin-top: 30px;
    padding: 15px; }
  &__error {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    &--msg {
      margin-left: 10px; } } }
