.MenuUserInfo {

  @include desktop-media {
    margin-bottom: 25px; }

  &__name {
    @include ubuntu($font-bold);
    font-size: $font-d;
    margin-top: 0.4rem; }

  &__email {
    @include ubuntu($font-regular);
    color: $white;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.5;
    @include desktop-media {
      color: #898989; } } }
