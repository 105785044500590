.PlanGroupsList {
  &__header {
    width: 100%; }

  &__filters {
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto;
    @media (max-width: 1096px) {
      width: 100%;
      display: flex;
      justify-content: center; } }

  &__inputText {
    width: 220px;
    @media (max-width: 1096px) {
      width: 100%; } }

  &__headerButton {
    margin-bottom: 10px;
    margin-left: 17px;

    button {
      display: flex;
      gap: 12px; }

    @media (max-width: 1096px) {
      display: none; } }

  &__buttonLabel {
    display: flex;
    align-items: center;
    padding: 0 5px;

    > svg {
      margin-right: 10px; } }

  &__CompaniesContainer {
    width: 100%;
    background-color: #F5F5F5;
    height: 35px;
    display: flex;
    align-items: center; }

  &__Companies {
    margin-left: 14px;
    color: #2d2d2d;
    font-size: 15px;
    font-weight: normal; } }
