.Header {
  background: linear-gradient(to right, #1F35B5, #556EFF 100%);
  display: flex;
  justify-content: center;
  max-height: 6rem;
  padding: 20px !important;
  width: 100%;
  z-index: 1;

  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: $desktop;
    width: 100%;

    .header--logo {
      cursor: pointer;
      margin-top: 0.4rem;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 10px; } }

  &--center {
    width: 68%;
    display: flex;
    justify-content: space-between;

    &--item {
      p {
        &:hover {
          font-weight: $font-bold;
          text-decoration: underline; } } } }

  &--succinct {
    @extend .Header;
    background: $grey-one;
    border-bottom: 0.1rem solid $grey-six; }

  &--nav {
    align-items: flex-start;
    display: flex;
    justify-content: center;

    &--text, &--avatar {
      color: $white;
      height: 100%; }

    &--avatar {
      margin-left: 1rem;
      margin-top: 5px;
      height: 30px; } }

  &--protector {
    width: 100%;
    height: 0;
    padding-bottom: 6rem; } }

@include desktop-media {
  .Header {
    position: relative;
    &--nav {
      align-items: center;
      display: flex; }
    &--protector {
      display: none; } } }
