.AgendaCalendarContainer {
  position: relative;
  padding-bottom: 5px;

  &--open {
    .AgendaCalendarContainer__DayPicker {
      @include card(1);
      background-color: $white;
      max-height: initial;
      pointer-events: auto;
      border-radius: 5px;
      padding: 0 8px 8px 8px; }

    .DayPicker-NavBar {
      display: block !important; } }

  &__Open {
    display: flex;
    justify-content: flex-end;
    padding-right: 20%;
    align-items: flex-end;
    padding-top: 13px;
    width: 100%;
    cursor: pointer; }

  &__Close {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }

  &__DayPicker {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 48px;
    overflow: hidden;
    width: 100%;
    pointer-events: none;

    .DayPicker-NavBar {
      display: none; }

    .DayPicker-Weekdays,
    .DayPicker-Body {
      padding: 1rem; } } }
