.ModalFileBeneficiaries {

    &__WarningTag {
        background-color: #FFFEB5;
        color: #4B4B4B;
        font-size: 12px;
        line-height: 16px;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 20px; }

    &--image-wrapper {
        cursor: pointer;
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;

        .TextInput {
            .inputContent {
                span {
                    margin-left: -3rem; } }

            .messageError {
                line-height: 14px;
                margin-top: 5px !important; } }

        input[type=file] {
            cursor: pointer;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%; } }

    &__FeedbackContent {
        margin-top: 20px; }

    &__removed {
        &__title {
            color: $red-lighter;
            font-size: 15px;
            font-weight: normal;
            margin-bottom: 23px;
            strong {
                font-size: 17px;
                font-eight: bold; } }
        &__list {
            margin-bottom: 20px;
            &--beneficiary {
                color: $color-text;
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 10px;
                span {
                    font-weight: bold;
                    text-transform: uppercase; } } }
        &__warning {
            color: $red-lighter;
            font-size: 15px;
            margin-bottom: 20px;
            span {
                font-weight: bold; } }
        &__buttons {
            justify-content: center;
            display: flex;
            flex-direction: column;
            button {
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .SpinnerContainer {
                    border-color: white;
                    border-top-color: transparent;
                    height: 36px;
                    width: 36px; } } } }

    &__duplicate {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        &__list {
            margin-top: 10px; } } }
