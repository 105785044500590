.ProfileSpecialities {
  margin-top: 1.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &--empty {
    font-size: 15px;
    font-family: "Ubuntu";
    color: #4b4b4b;
    font-style: italic; }

  &__item {
    margin: 5px;
    margin-left: 0; } }
