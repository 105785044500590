.ChartCard {
  width: 100%;
  padding: 20px;
  font-family: "Ubuntu";
  background: #ffffff;
  box-shadow: 0 1px 3px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--spaced {
    margin-right: 20px;
    @media (max-width: 1096px) {
      margin-bottom: 20px; } }

  &__title {
    width: 100%;
    font-size: 18px;
    color: #4B4B4B;
    margin-bottom: 25px;

    &--correction {
      margin-bottom: -25px; } }

  &__chart {
    margin-bottom: 30px;

    &--solo {
      margin: 30px 0; } }

  &__labels {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 36px;
    margin-bottom: 25px;
    @media (max-width: 1096px) {
      width: auto; } }

  &__link {
    color: #1F35B5;
    cursor: pointer; }

  &__bottom {
    width: 100%; }

  &__text {
    line-height: 12px;
    font-size: 12px;
    color: #4B4B4B;
    margin-bottom: 5px;

    &--sub {
      font-style: italic;
      color: #ACACAC; } }

  &__doughnut__container {
    margin-top: 40px; } }
