.Accordion {
  width: 100%;

  &__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &__text {
      max-width: 92%; } }

  &__body {
    margin-top: 2rem; } }
