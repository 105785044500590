.SkillsSelection {
  margin-bottom: 4rem;

  &__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;

    .RadioToggle {
      flex-shrink: 0;
      margin-right: 0.5rem;
      margin-top: 1rem; } } }
