.Container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &_textbox {
    width: 100%;
    margin-top: 1.5rem; } }

.Container__List {
  width: 100%;
  height: calc(100% - 130px);
  margin-top: 2.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5%;

  @include desktop-media {
    padding: 0 20%; }

  &__Empty {
    width: 100%;
    text-align: center;
    font-style: italic;
    padding-top: 20px; }

  &__LogCard {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    line-height: 14px;

    &__Time {
      padding-right: 20px;

      &__Seconds {
        color: #929FEE; } } } }
