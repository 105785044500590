.PaymentDetails {
  display:flex {}
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2rem solid $grey-one;
  padding: 0 2rem 2rem 2rem;
  width: 100%;
  @include desktop-media {
   padding: 0; }
  &__data {
    display: flex;
    flex-direction: column; }
  &__content {
    display: flex;
    flex-direction: column;
    @include desktop-media {
      flex-direction: row;
      justify-content: space-between; } }


  &__name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 2rem;
    margin-top: 3rem;
    @include desktop-media {
      align-items: flex-start; } }
  &__paymentStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    margin-top: 2rem;
    &__typography {
      margin-right: 1rem; }
    @include desktop-media {
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 1rem; }

 }    // flex-direction: column
  &__paymentFooter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2rem;
    &__tags {
      @include desktop-media;
      margin-right: 1.5rem; } }

  &__buttons {
    display: flex;
    margin: 0 auto;
    width: 70%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include desktop-media {
      flex-direction: row;
      margin: 0;
      width: 60%;
      button {
        margin-left: 1rem; } } } }




