.PlanDetail {
  background-color: #f5f5f5;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 5px;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__resume {
      width: 100%;

      p {
        line-height: 15px; } }

    &__button {
      display: flex;
      justify-content: flex-end;

      &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 7px;
        cursor: pointer;

        p {
          color: #1f35b5; } } } }

  &__details {
    &--close {
      display: none; }

    &--open {
      margin-top: 20px; }

    &__item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0; }

      p {
        line-height: 15px; } } } }
