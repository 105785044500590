.ResendPin {
  &-message-pin {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1.5rem;

    &--title {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .message-title {
        color: $action-online;
        margin-left: 1rem; } }

    p {
      margin-left: 3rem; } } }

.messageError {
  margin-top: 1rem; }

button {
  margin: 0; }
