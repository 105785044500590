.NpsNumber {
    display: flex;
    border: 1px solid $white;
    justify-content: center;
    border-radius: $radius-circle;
    width: 5.5rem;
    height: 8rem;
    flex-direction: column;
    justify-items: center;
    .NpsNumber__circle {
        display: flex;
        width: 4.5rem;
        height: 4.5rem;
        justify-content: center;
        align-items: center;
        background-color: $red;
        border-radius: $radius-circle;
        margin-bottom: 0.3rem;
        margin-left: 0.4rem;
        cursor: pointer;
        &--0 {
            @extend .NpsNumber__circle;
            background-color: #EB2D2D; }
        &--1 {
            @extend .NpsNumber__circle;
            background-color: #FF4949; }
        &--2 {
            @extend .NpsNumber__circle;
            background-color: #FF6549; }
        &--3 {
            @extend .NpsNumber__circle;
            background-color: #FF7349; }
        &--4 {
            @extend .NpsNumber__circle;
            background-color: #FF9649; }
        &--5 {
            @extend .NpsNumber__circle;
            background-color: #FFB249; }
        &--6 {
            @extend .NpsNumber__circle;
            background-color: #FFCE49; }
        &--7 {
            @extend .NpsNumber__circle;
            background-color: #C5DB1B; }
        &--8 {
            @extend .NpsNumber__circle;
            background-color: #47DB1B; }
        &--9 {
            @extend .NpsNumber__circle;
            background-color: #3AAD17; }
        &--10 {
            @extend .NpsNumber__circle;
            background-color: #268909; }

        &:hover {
          opacity: 0.5; } }
    &__icon {
        display: flex;
        margin-left: 1rem;
        margin-top: 0.1rem; }

    &.active {
        display: flex;
        border: 1px solid #4B4B4B;
        border-radius: $radius-circle;
        width: 5.5rem;
        height: 8rem; } }

