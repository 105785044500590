.ProfessionalListItem {
  display: flex;
  justify-content: space-between;
  border-top: 0.2rem solid $grey-two;
  width: 100%;
  padding: 2rem 0 2rem 0;
  &__Avatar {
    display: flex;
    justify-content: center;
    align-items: center; }
  &--icon {
    display: flex;
    align-items: center; }
  &__Content {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    justify-content: flex-start;
    align-items: flex-start;
    letter-spacing: -0.1rem;
    line-height: 1.8rem;
    width: 100%;
    @include desktop-media {
      justify-content: center;
      align-items: flex-start;
      flex: 2;
      line-height: 1.8rem;
      margin-left: 2rem; }
    &__name {
      display: flex;
      flex-flow: wrap;
      font-weight: bold;

      flex-direction: column;

      margin-bottom: 10px; }

    &__info {
      display: flex;
      flex-direction: column;
      @include desktop-media {
        flex-direction: row;
        margin-top: 0.2rem; }
      &__document {
        display: flex;
        color: #4B4B4B;
        @include desktop-media {
          margin-right: 15px; }
        &--text {
          display: flex;
          margin-right: 0.5rem; } }
      &__id {
        display: flex;
        flex-direction: column;
        flex-basis: 8rem;
        @include desktop-media;
        flex-direction: column; }
      &__status {
        display: flex;
        flex-basis: 22rem; } } }


  &--footer {
    display: flex;
    flex-direction: row;
    width: 19rem;
    @include desktop-media {
     flex-grow: 6; } } }

.statusIcon {
  display: flex;
  flex-direction: row;
  color: $action-online;
  flex-basis: 23rem;

  &--active {
    @extend .statusIcon;
    @include ubuntu($font-regular);
    color: $action-online; }

  &--inactive {
    @extend .statusIcon;
    @include ubuntu($font-regular);
    color: $red; }

  &--incomplete {
    @extend .statusIcon;
    @include ubuntu($font-regular);
    color: $status-pending; } }

