.Container {
  margin-top: 0.8rem !important;
  &__warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    margin-bottom: 2rem; }
  &_dropdown {
    width: 100%; }
  &_textbox {
    width: 100%; } }
