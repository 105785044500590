.TimestampTag {
  width: 100%;
  // margin-top: 20px

  > svg {
    padding-top: 8px;
    margin-right: 8px; }

  &__timezone {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 15px;
    color: $grey-ten;
    @include mobile-media {
      flex-direction: column; }
    &__icon {
      margin-right: 5px; }
    &__wrapper {
      display: flex;
      align-items: center; } } }

.TimestampTag--card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EBEBEB;
  border-radius: 8px; }
