.FloatActionButtonDesktop {
  align-items: center;
  display: flex;
  margin-top: -6rem;

  &__button {
    @include card(3);
    align-items: center;
    border: none;
    border-radius: 10rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 5.6rem;
    justify-content: center;
    margin-top: 4rem;
    padding: 1.5rem 2rem;
    min-width: 5.6rem;
    transition: 0.1s;

    &:focus {
      outline: none; }

    &--primary {
      @extend .FloatActionButtonDesktop__button;
      background-color: $color-primary;
      color: $white;

      &:hover {
        background-color: darken($color-primary, 10%); } } }

  &__icon {
    margin-right: 1rem;
    height: 2.4rem; }

  &__info {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    max-width: 16rem;

    &__icon {
      margin-right: -2.5rem;
      width: 4.9rem; }
    &__text {
      width: 11.6rem; } } }
