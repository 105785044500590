.menu-dashboard-item {
  cursor: pointer;
  margin-bottom: 20px !important;

  &--icon {
    margin-right: 10px;
    height: 24px; }
  &--badge {
    margin-left: 8px; }

  &:hover {
    .menu-dashboard-item {
      &--icon {
        color: $primary-darker;
        fill: $primary-darker; }
      &--text {
        color: $primary-darker; } } } }
