.PaymentFooter {
  min-height: 60px;
  width: 100%;

  &__container {
    border-top: 0.1rem solid $grey-six;
    height: 100%; }

  &__row {
    flex-direction: column !important;
    height: 100%;
    padding: 2rem 0 1.7rem !important; }

  &__payment-methods {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2.1rem;
    margin-right: 0;
    width: auto;
    p {
      text-align: center; }

    &__text {
      margin-right: 0;
      width: auto; }

    &__cards {
      height: 2.2rem;
      width: auto; } }

  &__copyright {
    p {
      text-align: center !important; } } }


@include desktop-media {
  .PaymentFooter {
    height: 6rem;

    &__payment-methods {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 0;
      margin-right: 1.25rem;
      width: calc(50% - 1.25rem);
      // padding-left: 2rem
      // padding-right: 2rem

      &__text {
        margin-right: 1.5rem;
        width: auto; }

      &__cards {
        height: 2.2rem;
        width: auto; } }

    &__row {
      flex-direction: row !important; }

    &__copyright {
      display: flex;
      justify-content: flex-start;
      margin-left: 1.25rem;
      margin-top: 0;
      width: calc(50% - 1.25rem); } } }
