.MyPatients {
  width: 100%;
  height: 100%;
  // margin-bottom: 10.5rem

  &__top {
    width: 100%;
    position: relative; }

  &__title {
    margin-bottom: 1rem;
    @media (max-width: 1096px) {
      margin-bottom: 20px; } }

  &__searchField {
    width: 332px;
    display: flex;
    align-items: center;
    @media (max-width: 1096px) {
      width: 100%;
      padding: 0 14px; } }

  &__group {
    max-width: 244px;
    word-wrap: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }

  &__icon {
    color: #898989;
    margin-bottom: 17px; }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;

    &--centered {
      align-items: center;
      justify-content: center; } }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: auto; } }
