.DayPickerInput {
  width: 100%;

  input {
    background-color: $white;
    background-image: url('/static/icon-calendar.svg');
    background-position: 96% 50%;
    background-repeat: no-repeat;
    background-size: 2.3rem;
    border: 0.1rem solid $grey;
    border-radius: $radius-s;
    color: $color-text;
    cursor: pointer;
    font-family: $font-ubuntu;
    font-size: $font-s;
    height: 100%;
    padding: 1.5rem;
    text-align: left;
    width: 100%; }

  input::placeholder {
    color: $color-text;
    opacity: 1; }

  &-Overlay {
    min-height: 35rem;
    text-align: center;
    width: 100%; } }
