.Tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__item {
    border-bottom: 1px solid $grey-eight;
    cursor: pointer;
    color: $grey-eight;
    display: flex;
    justify-content: center;
    padding: 1.2rem 0 1.1rem;
    width: 100%;

    &-selected {
      @extend .Tabs__item;
      border-bottom: 2px solid $color-primary;
      color: $color-primary;

      &__quantity {
        color: $grey-eleven;
        padding-left: 3px; } }

    &__quantity {
      padding-left: 3px; } } }
