.AdminScheduleContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__Content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__Empty {
      padding: 60px 0px 50px;

      &__Icon {
        display: flex;
        justify-content: center;
        margin-bottom: 20px; } } } }
