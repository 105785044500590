.StatusBox {
  @include ubuntu($font-bold);
  border: none;
  border-radius: $radius-m;
  height: 10rem;
  text-align: center;
  width: 7.5rem;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0.6rem;

  &--default {
    @extend .StatusBox; }

  &--cancelled {
    @extend .StatusBox;
    @include ubuntu($font-regular);
    background-color: $grey-two; }

  &--scheduled {
    @extend .StatusBox;
    @include ubuntu($font-regular);
    background-color: $action-online; }

  &--attending {
    @extend .StatusBox;
    @include ubuntu($font-regular);
    background-color: #f0bd35; }
  &--attended {
    @extend .StatusBox;
    @include ubuntu($font-regular);
    background-color: $grey-two; }


  .footer {
    display: flex;
    flex-direction: column;
    letter-spacing: -0.1rem;
    align-items: center;
    justify-content: space-evenly;


    flex-wrap: wrap;
    &__icon {
      display: flex;
      justify-content: space-around; }

    &__text {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      color: $white;
      letter-spacing: -0.1rem;
      line-height: 1.3rem; } }


  .header {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column-reverse;

    &__time {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -0.1rem;
      line-height: 1.3rem;
      padding-top: 0.9rem; }

    &__date {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -0.1rem;
      flex-direction: column; } } }
