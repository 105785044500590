.Button {
  @include ubuntu($font-bold);
  border: none;
  border-radius: $radius-s;
  background-color: $color-primary;
  color: $white;
  font-size: $font-s;
  padding: 15px 20px 16px;
  text-align: center;
  transition: 0.4s;
  width: 100%;

  span {
    @include ubuntu($font-regular);
    padding-left: 1rem; }

  &:focus {
    outline: none; }

  &:hover,
  &:active {
    background-color: $primary-darker; }

  &:disabled {
    background-color: $grey-two;
    border: none;
    color: $grey-seven; }

  &--default {
    @extend .Button; }

  &--reverse {
    @extend .Button;
    @include ubuntu($font-bold);
    background-color: $white;
    border: 0.1rem solid $color-primary;
    color: $color-primary;
    padding: 15px 20px 14px;

    &:hover {
      background-color: lighten($grey-four, 8%); }

    &--grey {
      @extend .Button--reverse;
      background-color: $white;
      border: 0.1rem solid $grey-eleven;
      color: $grey-eleven;
      &--lighter {
        @extend .Button--reverse--grey;
        border: 0.1rem solid $grey-six;
        color: $grey-six; }

      &--padded {
        @extend .Button--reverse--grey;
        padding: 1rem 1.7rem 1.2rem;
        height: auto; } } }

  &--online {
    @extend .Button;
    background-color: $action-online;
    color: $white;

    &:hover {
      background-color: darken($action-online, 10%); } }

  &--false {
    @extend .Button;
    background-color: $red;
    color: $white;

    &:hover:not(:disabled) {
      background-color: darken($red, 20%); } } }
