$photo-sizes: 50px;

.PatientCard {
  width: 100%;
  display: grid;
  grid-template-columns: $photo-sizes auto;
  column-gap: 15px;
  background-color: #FFFFFF;
  padding: 25px 14px;

  &__photo {
    width: $photo-sizes;
    height: $photo-sizes;
    border-radius: 50%;
    background-color: #C4C4C4;
    color: #EBEBEB; }

  &__row {
    display: flex;
    align-items: center;
    @media (max-width: 1096px) {
      align-items: flex-start;
      flex-direction: column; }

    &--spaced {
      margin-bottom: 10px; }

    &--column {
      @media (max-width: 1096px) {
        flex-direction: row !important; } } }

  &__text {
    font-size: 15px;
    line-height: 15px;
    color: #898989;
    font-family: "Ubuntu";
    margin-right: 15px;
    margin-bottom: 2px;
    white-space: normal;
    &:last-child {
      margin: 0; }

    &--name {
      color: #4B4B4B;
      font-weight: bold; }

    &--appointments {
      font-weight: bold;
      color: #4AC326; }

    &--normal {
      color: #4B4B4B; } } }
