.FloatmenuCard {
  background-color: $white;
  border-radius: $radius-s;
  box-shadow: 0.3rem 0.3rem 1rem $grey, 0.3rem 0.3rem 1rem $grey;
  width: 185px;
  overflow: hidden;

  &__item {
    padding: 1rem 1.5rem;

    &:hover {
      background-color: lighten($color-primary, 45%);
      color: $color-primary;
      cursor: pointer; } } }
