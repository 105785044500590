.ToastGenericError {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFE6E6;
  width: 100%;
  height: 67px;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999999;
  padding: 0px 1.4rem;
  animation: slide 0.5s ease;
  @include desktop-media {
    padding: 0px 30px; }

  &__BoxAlert {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(100% - 35px);
    padding-left: 0px;
    @include desktop-media {
      padding-left: 35px; }

    &__Icon {
      transform: rotate(180deg);
      margin-top: -10px; }

    &__Text {
      font-family: Ubuntu;
      font-size: 15px;
      line-height: 15px; } }

  &__BoxClose {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px;

    &__IconClose {
      cursor: pointer; } } }

@keyframes slide {
  0% {
    position: fixed;
    top: -67px;
    left: 0; }

  100% {
    position: fixed;
    top: 0px;
    left: 0; } }
