.DateFilter {
  margin-top: 11px;

  &__group {
    display: flex;
    align-items: center; }

  &__text {
    font-size: 15px;
    color: #898989;
    margin: 0 10px 20px; }

  &__errorMessage {
    font-size: 1.2rem;
    color: #eb384d;
    text-align: center;
    margin-top: -1rem;

    &--hidden {
      visibility: hidden; } } }
