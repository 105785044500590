.GeneralViewCard {
  width: 100%;
  max-height: 113px;
  padding: 10px 0 15px;
  background: #ffffff;
  box-shadow: 0 1px 3px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--spaced {
    margin-right: 20px; }

  &__text {
    width: 70%;
    margin: 0;
    font-size: 12px;
    font-family: "Ubuntu";
    color: #4B4B4B;
    text-align: center;
    line-height: 1;
    word-break: break-word;

    &--value {
      font-size: 35px;
      font-weight: bold;
      color: #1F35B5;
      margin-bottom: 5px; }

    &--chips {
      width: auto;
      padding: 3px 5px;
      color: #1F35B5;
      background: #EBEEFD;
      border-radius: 3px;
      margin-top: 10px; } } }
