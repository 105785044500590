.Response {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 12rem) !important;
  @include desktop-media {
   width: 86%;
   margin: 0 auto; }
  &__container {
    width: 100%;
    margin-top: 1.6rem;
    @include desktop-media {
     width: 89%;
     margin: 0 auto; } } }


.ResponseTextBold {
  font-weight:  $font-bold; }
