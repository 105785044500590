.CreditsPanel {
    width: 100%;
    min-height: 90px;
    position: fixed;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--closed {
        .PatientEligibility__content, .PatientEligibility__footer {
            height: 0;
            overflow: hidden; }

        .PatientEligibility__title {
            margin-bottom: 0; }

        .PatientEligibility__footer {
            margin: 0;
            padding: 0 !important;
            border-top: initial; } }

    &__container {
        width: 1096px;
        height: 100%;
        padding: 25px;
        border-radius: 20px 20px 0 0;
        background-color: #FFFFB5;
        box-shadow: 0px 0px 7px #00000029;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        @include mobile-media {
            width: 100%;
            align-items: stretch; } }

    &__top {
        width: 78px;
        height: 24px;
        background-color: #FFFFB5;
        border-radius: 20px 20px 0 0;
        position: relative;
        bottom: -5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            bottom: -3px;
            width: 85px;
            height: 31px;
            border-radius: 25px 25px 0 0;
            background: radial-gradient(rgba(#00000029, 0.1), rgba(#00000029, 0));
            z-index: -1; } }

    &__icon {
        width: 24px;
        color: #CCCB87;
        margin-top: 5px;

        &--up {
            transform: rotate(180deg); } }

    .PatientEligibility {
        &__content {
            display: flex;
            flex-direction: column;
            max-width: 520px;
            @include mobile-media {
                max-width: 100%; } }
        &__info {
            @include desktop-media {
                flex-direction: row;
                justify-content: center; }

            p strong {
                @include desktop-media {
                    padding: 0 4px; } }

            &__credButton {
                max-width: 520px;
                margin: 0 auto; } }

        &__textGroup {
            @include desktop-media {
                justify-content: center; }

            &:first-child {
                @include desktop-media {
                    margin-right: 20px; } } }

        &__title {
            gap: 12px; }

        &__title,
        &__info p,
        &__message {
            @include desktop-media {
                display: flex;
                justify-content: center;

                p {
                    max-width: 520px;
                    text-align: left; } }

            @include tablet-media {
                text-align: center; } }

        &__title__groupName,
        &__corporate--ineligible {
            color: #BEBD73; }

        &__footer {
            max-width: 520px;
            width: 100%;
            padding: 20px 0;

            @include mobile-media {
                max-width: 100%;
                margin: 15px 0 0;
                padding-bottom: 0; }

            &__arrows {
                margin-right: - 7px; } }

        &__message {
            justify-content: flex-start; } } }
