.RadioButtonFilter {
    align-items: center;
    border: 0.1rem dashed $grey-seven;
    border-radius: $radius-s;
    cursor: pointer;
    display: flex;
    height: 4.8rem;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 0 1.5rem;
    width: 100%;

    &:first-of-type {
        margin-top: 0; }
    &:last-of-type {
        margin-bottom: 0; }

    &__Check {
        border: 0.1rem solid $grey-seven;
        border-radius: 5rem;
        height: 2rem;
        width: 2rem;

        svg {
            display: none; } }

    &--checked {
        @extend .RadioButtonFilter;
        background-color: $color-secondary;
        border: 1px solid $color-secondary;

        .RadioButtonFilter__Check {
            border: 0;

            svg {
                display: block; } } } }

