.BodyContent {
    align-content: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    &__Container {
        height: 100%;
        width: 30rem;

        @include desktop-media {
          width: 108.7rem; } } }
