.FloatActionButtonDropdown {
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 98;
  width: auto;
  justify-content: space-between;

  @include desktop-media {
    position: relative; }

  &__SpinnerContainer {
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center; }
  &__Spinner {
    height: 34px !important;
    width: 34px !important;
    border-width: 4px !important;
    border-color: #fff !important;
    border-top-color: transparent !important; }
  &__Modal {
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    overflow: auto;
    align-items: flex-start;
    justify-content: center;
    place-content: center center;
    left: 0;
    z-index: 99;
    &--open {
      display: flex;
      visibility: visible; }
    &__Backdrop {
      background-color: $grey;
      height: 100%;
      opacity: 0.9;
      position: fixed;
      width: 100%;
      z-index: 100;
      top: 0;
      left: 0; }
    &__Wrapper {
      position: relative;
      height: 100%;
      width: 100%; }
    &__OptionsList {
      position: fixed;
      z-index: 105;
      max-width: 60%;
      @include mobile-media {
        width: 100%;
        max-width: 100%; }
      &__Option {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-bottom: 1rem;
        justify-content: flex-end;
        &:hover {
          .FloatActionButtonDropdown__Modal__OptionsList__Option__Background {
            background-color: $grey-one; } }

        &:last-child {
          margin-bottom: 0; }

        &--right {
          @include mobile-media {
            padding-left: 30%; } }

        &--left {
          @include mobile-media {
            padding-right: 30%;
            justify-content: flex-start; } }

        &__Background {
          @include card(1);
          align-items: center;
          background-color: $white;
          color: $grey-eleven;
          display: flex;
          padding: 0.6rem 1.4rem 0.9rem; }

        &__Text {
          margin: 0 8px;
          padding: 8px 16px; }

        &__Icon {
          border-radius: 30px;
          padding: 8px;
          margin: 0 3px; } } } }
  &--open {
    z-index: 300; }
  &--left {
    @include mobile-media {
      bottom: 20px;
      left: 20px;
      right: auto;
      top: auto; } }
  &--right {
    @include mobile-media {
      bottom: 20px;
      right: 20px;
      left: auto;
      top: auto; } }
  &__Button {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: center;
    padding: 0 2rem;
    transition: 0.1s;
    background-color: $color-primary;
    color: $white;
    min-width: 48px;

    @include desktop-media {
      border-radius: 5px; }

    @include mobile-media {
      @include card(1);
      border-radius: 48px !important;
      padding: 0;
      height: 56px;
      width: 56px;
      box-shadow: 0px 6px 10px #0000004D; }

    &:hover {
      background-color: darken($color-primary, 10%); }

    &:focus {
      outline: none; }

    &--open {
      padding: 0;
      border-radius: 48px; } }
  &__Icon {
    margin-right: 1rem;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    @include mobile-media {
      margin-right: 0; }

    &--open {
      margin-right: 0; } } }
