.HeaderButton {
  @include ubuntu($font-medium);
  border: 0.1rem solid $white;
  border-radius: $radius-s;
  background-color: $white;
  color: $color-primary;
  font-size: $font-s;
  margin-left: 0.8rem;
  min-height: 3.6rem;
  padding: 1rem 1.6rem;
  text-transform: uppercase;
  transition: 0.4s;

  &:focus {
    outline: none; }

  &:hover {
    background-color: $primary-darker;
    border-color: $primary-darker;
    color: #fff; }

  &:disabled {
    @extend .HeaderButton;
    background-color: $grey;
    border-color: $grey;
    color: $grey-eight; }

  &--default {
    @extend .HeaderButton; }

  &--secondary {
    @extend .HeaderButton;
    background-color: $color-primary;
    border: 0.1rem solid $white;
    color: $white; }

  &--outlined {
    @extend .HeaderButton;
    background: transparent;
    color: #fff; }

  &--mobile {
    @extend .HeaderButton;
    background-color: $color-primary;
    border-color: $color-primary;
    color: $white;
    padding: 0; }

  &--text {
    @extend .HeaderButton;
    background: none;
    border: none;
    padding: 0; } }
