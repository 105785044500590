.CartPlansCredit {
  background-color: $yellow-light;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 2rem;
  position: relative;
  width: 100%;
  z-index: 1;

  &__credit--header {
    width: 80%; }

  &__credit--info {
    color: $action-online;
    width: 20%; } }
