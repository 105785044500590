.CustomError {
    width: 100%;
    text-align: center;
    padding: 50px 0px 70px 0px;
    @include desktop-media {
        padding: 120px 0px 130px 0px; }

    &__Img {
        width: 90%;
        max-width: 382.56px;
        margin-bottom: 20px;
        height: auto; }

    &__Subtitle {
        width: 255px;
        display: inline-block;
        margin-top: 10px;
        @include desktop-media {
            width: 288px; } }

    &__ButtonBox {
        margin-top: 20px;

        &__Button {
                font-size: 12px;
                font-weight: 400;
                padding: 10px;
                width: 189px;
                height: auto; } }

    &__Footer {
        margin-top: 0px; } }
