@keyframes Spinner {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		transform: translate(-50%, -50%) rotate(0deg); }

	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
		transform: translate(-50%, -50%) rotate(360deg); } }


@-webkit-keyframes Spinner {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		transform: translate(-50%, -50%) rotate(0deg); }

	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
		transform: translate(-50%, -50%) rotate(360deg); } }


.Spinner {
	position: relative; }

.Spinner div,
.Spinner div:after {
	position: absolute;
	width: 160px;
	height: 160px;
	border: 20px solid #1F35B5;
	border-top-color: transparent;
	border-radius: 50%; }

.Spinner .white,
.Spinner .white:after {
	position: absolute;
	width: 160px;
	height: 160px;
	border: 20px solid #FFFFFF;
	border-top-color: transparent;
	border-radius: 50%; }

.Spinner .black,
.Spinner .black:after {
	position: absolute;
	width: 160px;
	height: 160px;
	border: 20px solid #4B4B4B;
	border-top-color: transparent;
	border-radius: 50%; }

.Spinner .yellow-gray,
.Spinner .yellow-gray:after {
	position: absolute;
	width: 160px;
	height: 160px;
	border: 20px solid #BEBD73;
	border-top-color: transparent;
	border-radius: 50%; }

.Spinner div {
	-webkit-animation: Spinner 1s linear infinite;
	animation: Spinner 1s linear infinite;
	top: 100px;
	left: 100px; }

.Spinner div:after {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg); }

.Spinner {
	width: 42px !important;
	height: 42px !important;
	-webkit-transform: translate(-21px, -21px) scale(0.21) translate(21px, 21px);
	transform: translate(-21px, -21px) scale(0.21) translate(21px, 21px);
	&__message {
		margin-top: 2rem; } }
