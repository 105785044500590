.Container {
    &__warning {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0rem;
        margin-bottom: 2rem; }
    &__dropdown {
        width: 100%;

        & > div {
          max-width: 100%;
          margin-bottom: 10px; }

        .dropdown {
            margin-bottom: 10px !important; } }

    &__textbox {
        width: 100%; }
    &__StepFile {
        width: 100%;

        &__Forms {
            margin: 2.5rem 0;

            &__Item {
                &--radio {
                    display: flex;
                    margin: 2rem 0;

                    &__Item {
                        margin: 0 1rem;
                        &:first-of-type {
                            margin-left: 0; }
                        &:last-of-type {
                            margin-right: 0; } } } }

            &--image-wrapper {
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
                position: relative;
                width: 100%;

                input[type=file] {
                    cursor: pointer;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    width: 100%; } } }

        &__Error {
            align-items: flex-start;
            display: flex;
            margin-top: 20px;

            &__Icon {
                margin-right: 1rem;
                margin-top: -1.5rem;
                transform: rotate(180deg); } } } }
