.Homepage {
    background-color: #f5f5f5;
    position: relavite;

    &__Header {
        background: linear-gradient(to right, #1F35B5, #556EFF 100%);
        // background: red
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index: 2;
        @include mobile-media {
            height: 54px; }
        &__Info {
            width: 100%;
            max-width: 910px;
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;
            justify-content: space-between;
            @include mobile-media {
                justify-content: center; } } }
    &__FiltersWrapper {
        position: relative;
        z-index: 2; }
    &__Title {
        &__Text {
            color: #fff !important;
            font-size: 30px !important;
            @include mobile-media {
                margin: 20px 0;
                text-align: center;
                font-size: 21px !important; } } }
    &__bottom {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }

    &__bottomMessage {
        max-width: 400px;
        cursor: pointer; }

    &__oneHalfColumn.five.col {
        @include desktop-media {
            width: 41.65%; } }

    &__timestampContainer {
        margin-top: 20px; }

    .AlertIcon {
        margin-top: 1.5rem;

        @include mobile-media {
            display: none; } }
    &__professionalsLoading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%; }
    &__ApproachesModal, &__ReviewsModal {
        max-width: 729px !important;
        &__Container {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 30px; }
        &__Sidebar {
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 170px;
            @include mobile-media {
                display: none; } }
        &__DescriptionContainer {
            max-width: 350px;
            display: flex;
            flex-direction: column;
            border-left: 1px solid $grey-seven;
            padding-left: 40px;
            @include mobile-media {
                border: none;
                padding-left: 0; } }
        &__ApproachesList {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            &__Button {
                font-weight: bold;
                text-transform: capitalize !important;
                padding: 0;
                height: 30px !important; } } }

    &__ReviewsModal {
        height: 100% !important; }

    &__ReviewsModal__Container {
        margin-top: 20px; }

    &__ReviewsModal__Sidebar {
        max-width: 150px;
        top: 160px;
        @include mobile-media {
            top: initial;
            max-width: initial;
            display: flex;
            position: relative;
            align-items: center; }

        &__ProfessionalDetail {
            @include mobile-media {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                gap: 15px;
                padding-bottom: 10px; }

            &__Texts {
                @include mobile-media {
                    max-width: 170px; } } } }

    &__ReviewsModal__DescriptionContainer {
        border-left: initial;
        padding-left: 0;
        max-width: initial;
        width: 100%;

        @include desktop-media {
            max-width: 370px; }

        &__Select {
            max-width: initial !important;

            > div {
                margin-top: 0; } }

        &__EmptyReviews {
            padding-top: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 20px; } }

    &__ApproachesModalContainer, &__ReviewsModalContainer {
        padding: 0px 50px 22px 50px !important;
        @include mobile-media {
            padding: 0 12px 22px 12px !important; } }
    &__ApproachesModalTopbar, &__ReviewsModalTopbar {
        max-width: 729px !important; }
    &__ApproachesModalTitle, &__ReviewsModalTitle {
        width: 80% !important;
        margin-left: 10% !important; }
    &__VideoOverlay {
        background: rgba(0,0,0,0.85);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999999999999;
        height: 100%;
        position: fixed;
        bottom: 0;
        width: 100%;
        &__Wrapper {
            width: 100%;
            max-width: 700px; }
        &__TextWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;
            gap: 5px;
            @include mobile-media {
                align-items: flex-start;
                gap: 0;
                padding-left: 20px; } }
        &__CloseButton {
            position: absolute;
            right: 0;
            background: none;
            border: none;
            transition: all 0.2s;
            padding: 0;
            &:hover {
                opacity: 0.7; }
            @include mobile-media {
                margin-right: 15px; } }
        &__Text {
            color: #fff !important;
            text-transform: capitalize;
            @include mobile-media {
                width: 80%;
                line-height: 24px !important; } }
        &__ProfessionalName {
            @include mobile-media {
                font-size: 18px !important; } }
        &__VideoWrapper {
            height: 393px;
            width: 100%;
            @include mobile-media {
                height: 240px; } } } }
