.PatientEligibility {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__message {
    display: flex; }

  &__title {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__groupName {
      color: $grey-seven;
      font-weight: normal;
      margin-left: 6px; }

    &__icon {
      cursor: pointer;
      display: flex; } }

  &__content {
    width: 100%; }

  &__text {
    &--available {
      color: #4AC326;
      font-weight: bold; }

    &--unavailable {
      color: $red-lighter;
      font-weight: bold; }

    &--inelegible-corporate {
      color: $grey-seven;
      font-weight: bold;
      text-decoration: line-through; }

    &--inelegible-enterprise {
      color: $red-lighter;
      text-decoration: initial; }

    &--expired-date-corporate {
      color: $red-lighter;
      font-weight: initial;
      text-decoration: initial; }

    &--expired {
      text-decoration: line-through; } }


  &__textGroup {
    display: flex;
    justify-content: space-between; }

  &__info {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    p {
      @include mobile-media {
        text-align: left; }

      &:first-child {
        padding-right: 5px; } }

    &__credButton {
      margin-top: 20px;
      text-align: center; } }

  &__messageText {
    line-height: 16px !important;
    @include mobile-media {
      text-align: left; } }

  &__corporate {
    &--eligible strong {
      color: $green; }

    &--ineligible {
      text-decoration: line-through;
      color: $grey-seven; } }

  &__footer {
    padding: 20px;
    margin: 18px -20px -20px -20px;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
    border-radius: 0 0 5px 5px;
    padding-right: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__wrapper {
      &__question {
        cursor: pointer;

        &:hover {
          opacity: 0.5; } } }

    &__arrows {
      align-self: baseline;
      margin-top: 4px;
      display: flex;
      gap: 20px;

      button {
        background: initial;
        border: initial;
        cursor: pointer;
        padding: 0;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.2; } } } } }


