.GeneralViewFilters {
  margin-bottom: 20px;

  &__sectionTitle {
    font-size: 15px;
    color: #4B4B4B;
    font-weight: bold;
    font-family: "Ubuntu";
    margin-top: 20px;
    margin-bottom: 17px; }

  &__radio {
    margin-bottom: 15px; }

  &__dateFilter {
    display: flex;
    align-items: center;
    margin-top: 11px; }

  &__text {
    font-size: 15px;
    color: #898989;
    margin: 0 10px 10px; }

  &__errorMessage {
    font-size: 1.2rem;
    color: #eb384d;
    text-align: center; } }
