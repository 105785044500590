.InputList {

    &__Input {
        border: 0.1rem solid #c1c1c1;
        border-radius: 0.5rem;
        color: #4b4b4b;
        flex-shrink: 0;
        font-family: inherit;
        font-size: 1.5rem;
        height: auto;
        padding: 1.5rem 40px 1.5rem 1.5rem;
        width: 100%;

        &:hover,
        &:active,
        &:focus {
            outline: none;
            border: 0.1rem solid #1f35b5; }

        &::placeholder {
            color: #C1C1C1; } }

    &__Button {
        position: absolute;
        margin-left: -40px;
        margin-top: 10px;
        cursor: pointer; }

    &__List {
        width: 100%;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: 0.2rem 0.2rem 0.5rem #dedede, -0.1rem -0.1rem 0.1rem #dedede;
        height: auto;
        max-height: 175px;
        overflow-y: scroll;
        position: absolute;
        display: none;

        &--show {
            @extend .InputList__List;
            display: block; }

        &__Item {
            width: 100%;
            padding: 15px;
            height: 50px;

            &:hover {
                color: #1F35B5;
                background-color: #E8EAF8;
                cursor: pointer;
                transition: 0.2s; } } } }
