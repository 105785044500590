.RadioToggle {
  @include ubuntu($font-regular);
  align-items: center;
  border: 1px solid #c3c3c3;
  border-radius: 4rem;
  color: #898989;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.1rem 0.7rem;

  &.active {
    background-color: $color-primary;
    border:  1px solid $color-primary;
    color: $white; }

  &__text {
    padding: 0 1rem; }

  &__icon {
    margin-top: 0.5rem; } }
