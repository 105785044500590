@keyframes openMenu {
  0% {
    right: -30rem; }
  100% {
    right: 0rem; } }

@keyframes closeMenu {
  0% {
    right: 0rem; }
  100% {
    right: -30rem; } }

.main-menu--container {
  animation: openMenu;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  background-color: $white;
  box-shadow: -0.2rem 0.1rem 1rem $color-title;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  width: 27.8rem;
  z-index: 500;

  &-undefined {
    @extend .main-menu--container;
    right: -30rem;
    animation: none; }

  &-closed {
    @extend .main-menu--container;
    animation: closeMenu;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in; } }

.main-menu--header, .main-menu {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  position: relative;
  width: 27.8rem; }

.main-menu {
  background-color: $color-primary;
  display: flex;
  padding-bottom: 0.5rem;
  height: 100vh;
  width: 27.8rem;
  z-index: 100;

  &--header {
    background-color: $color-primary;
    color: $white;
    display: block;
    height: auto;
    padding-bottom: 2rem;
    padding-top: 2rem; }

  &--separator {
    display: block !important;
    background-color: $white;
    height: 2px;
    width: 100%; }

  &--top {
    align-items: center;
    color: $white;
    display: flex;
    height: 3.5rem;
    justify-content: flex-end;
    margin: 1.3rem 1.4rem 0 0;
    width: 3.5rem;

    &-row {
      right: 0;
      padding-right: 1.4rem !important;
      position: absolute !important;
      width: 3.8rem !important;
      z-index: 101; } }

  &--body {
    display: block !important;
    &--text {
      @include font-size($text-sizes);

      color: $white !important;
      &:hover {
        color: $grey-four !important; } } }

  &--user {
    display: block !important;
    &--text {
      @include font-size($default-sizes);

      color: $white !important;
      &:hover {
        color: $grey-four !important; } } }

 }  // LOGGED
.main-menu.isLogged {
  background-color: $white;

  .main-menu {
    height: fit-content;
    &--separator {
      background-color: $grey-four; }

    &--body {
      display: block !important;
      &--text {
        color: $color-text !important;
        &:hover {
          color: $grey-eight !important; } } } } }

.black-screen {
  background-color: #00000088;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 99; }
