.RemoveUnavailabilityModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__topInfo {
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__toptext {
    max-width: 212px;
    margin-bottom: 20px; }

  &__toast {
    margin: 20px 0 30px;
    display: grid;
    grid-template-columns: 22px auto;
    grid-column-gap: 10px;

    > svg {
      width: 22px;
      height: 22px;
      margin-right: 10px; } }

  &__text {
    font-size: 15px;
    text-align: center;

    &--toast {
      color: #ff4949;
      line-height: 1;
      text-align: left; } } }
