.SuccessModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__top {
    max-width: 264px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > svg {
      margin-bottom: 10px; } } }
