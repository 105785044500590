.ProfessionalProfile {
  @include desktop-media {
    padding-top: 30px !important; }

  &__loading {
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center; }

  &__Info {
    @include desktop-media {
      padding-right: 30px; } }

  &__Schedule {
    background-color: #FFF;
    box-shadow: 0px 1px 7px #00000033;
    border-radius: 5px;
    padding: 30px;
    background-color: #fff;

    .LineBreaker {
      margin-left: -30px;
      margin-right: -30px;
      width: calc(100% + 60px); } }

  &__Stamp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 30px;

    @include mobile-media {
      flex-direction: column;
      align-items: flex-start; }

    img {
      margin: 0 20px 0 0;
      height: 30px;

      @include mobile-media {
        margin: 0 0 15px 0; } } }
  &__video {
    height: 0;
    margin-bottom: 30px;
    max-height: 382px;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    iframe, video {
      position: absolute;
      width: 100%;
      height: 100%; }
    &__layer {
      align-items: center;
      background: $black;
      bottom: 0;
      color: $white;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      &__button {
        margin-bottom: 20px; }
      &__typography {
        color: #ffffff !important; } } }
  &__Reviews {
    text-align: center;

    &__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mobile-media {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px; }
      &__Select {
        @include mobile-media {
          max-width: initial !important; }
        > div {
          margin-top: 0; } } }

    &__Button {
      padding-top: 30px; }

    &__EmptyReviews {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 70px 0 80px;
      gap: 20px;
      @include mobile-media {
        flex-direction: column; } } } }
