.DayPicker {
  font-family: $font-ubuntu;
  font-size: $font-s;
  font-weight: $font-regular;
  width: 100%;
  padding: 10px 0;
  outline: none;

  &-wrapper,
  &-Month {
    @extend .DayPicker;
    margin: 0;
    padding: 0; }

  &-Day {
    border: .5px solid #fff;
    border-radius: 0;
    height: 4.2rem;
    outline: none;
    padding: 10px 0;
    width: 4.2rem;

    &--today:not(&--disabled) {
      color: $color-primary; }

    &:not(&--disabled):not(&--outside):hover {
      background-color: transparentize($color-primary, 0.8) !important; }

    &--selected:not(&--outside) {
      background-color: $color-primary !important;
      color: #fff !important;
      font-weight: $font-bold; }

    &--disabled:not(&--outside) {
      color: $grey;
      position: relative;

      &::before {
        content: '';
        display: block;
        height: 70%;
        width: 1px;
        background-color: $grey;
        position: absolute;
        top: 15%;
        left: 50%;
        transform: rotate(45deg);
        animation: line-through 300ms forwards; } }

    &--schedule#{&}--disabled {
      position: relative;

      &::after {
        content: '';
        left: 0;
        right: 0;
        height: 28px;
        width: 28px;
        background: $grey url('/static/check.svg') no-repeat center center;
        border-radius: 28px;
        left: calc(50% - 14px);
        top: calc(50% - 14px);
        transform: rotate(0deg);
        position: absolute; } } }

  &-Caption {
    margin-bottom: 0.5rem;
    padding: 0;

    div {
      align-items: center;
      display: flex;
      font-weight: $font-bold;
      height: 4.2rem;
      justify-content: center;
      text-transform: uppercase; } }

  &-NavButton {
    background-size: 100%;
    height: 4.2rem;
    margin-top: 0;
    width: 4.2rem;
    top: 0;

    &:focus {
      outline: none; }

    &--prev {
      background-image: url('/static/icon-navigate-before.svg');
      left: 0rem;
      margin-right: 0; }

    &--next {
      background-image: url('/static/icon-navigate-next.svg');
      right: 0rem; } }

  &-Weekday {
    color: $color-primary;
    font-size: $font-s;
    font-weight: $font-bold;
    padding: 0;
    padding-bottom: 1.057rem; } }
