// Shadows

@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(0.12 0.16 0.19 0.25 0.3, $depth));

  @return 0 $primary-offset $blur $color; }

@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(0.24 0.23 0.23 0.22 0.22, $depth));

  @return 0 $primary-offset $blur $color; }
