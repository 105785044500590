.ContainerPatientDetail {
  width: 100%;
  padding-top: 0;
  margin-top: 0 !important;
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 34px;
    border-bottom: 1px solid $grey-four;
    width: 100%;
    margin-bottom: 30px;
    &__userData {
      margin-top: 15px;
      text-align: center;
      &__list {
        margin-top: 18px;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin-right: 10px;
            width: 20px; } } } } }

  &__planGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E2E2E2;
    margin-bottom: 30px;
    &:last-child {
      border: none;
      padding-bottom: 0; }
    &.planGroupInfo__ineligible, &.planGroupInfo__ineligibleUnlimited {
      li {
        &:not(:last-child) {
          color: $grey-seven;
          text-decoration: line-through;
          p {
            color: $grey-seven; } } } }
    &.planGroupInfo__expirationDate {
      li:first-child p:nth-child(2) {
        text-decoration: line-through;
        font-weight: normal !important; } }
    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;
        .credits--eligible {
          color: $green; }
        .credits--unavailable {
          color: $red-lighter; }
        .validity--expirationDate {
          color: $red-lighter; } } }

    &__info {
      &.--waiting-validation {
        & > p {
          color: $grey-eleven;
          display: flex;
          align-items: center;
          strong {
            margin-left: 4px; }
          svg {
            margin-right: 10px; } } }
      p {
        line-height: 15px;
        margin-top: 15px !important; } }

    &__info.corporateUnlimited p {
      color: $green !important; }
    &.planGroupInfo__corporateUnlimited, &.planGroupInfo__ineligibleUnlimited {
      li {
        &:nth-child(1) {
          order: 2; }
        &:nth-child(2) {
          order: 1;
          p:last-child {
            color: $green;
            font-weight: bold; } }
        &:nth-child(3) {
          order: 3; } } }
    &.planGroupInfo__ineligibleUnlimited {
      li {
        &:nth-child(2) {
          p:last-child {
            color: $grey-seven; } } } }
    &.planGroupInfo__enterprise {
      li:last-child p:last-child {
        text-transform: uppercase;
        border-radius: 5px;
        color: $white;
        padding: 3px 5px;
        font-size: 12px;
        font-weight: bold; } }

    &.planGroupInfo__enterprise {
        &.--waiting-validation {
          li:last-child p:last-child {
            background: $status-pending; } }

        &.--waiting-technical-evaluation {
          li:last-child {
            p:first-child {
              width: 60%; }
            p:last-child {
              width: 40%;
              background: $status-pending; } } }

        &.--validated {
          li:first-child p:last-child {
            color: $green;
            font-weight: bold;
            &.danger {
              color: $red-lighter; } }
          li:last-child p:last-child {
            background: $green; } }

        &.--validated-without-medical-request {
          li:last-child p:last-child {
            background: $green; }
          li:first-child p:last-child {
            color: $green;
            &.danger {
              color: $red-lighter; } } }

        &.--finished {
          li:last-child p:last-child {
            background: $grey-eleven; }
          li:first-child p:last-child {
            &.danger {
              color: $red-lighter; } }
          &.dateExpiration {
            li:first-child p:last-child {
              font-weight: normal;
              color: $grey-eleven;
              text-decoration: line-through; }
            li:nth-child(2) p:last-child {
              color: $red-lighter; } } }

        &.--disapproved, &.--invalidated {
          li:last-child p:last-child {
            background: $red-lighter; } }

        &.--not-validity {
          li:first-child p:last-child {
            font-weight: normal; }
          li:last-child p:last-child {
            font-weight: normal;
            color: $grey-eleven;
            padding: 0;
            font-size: 15px; } } } }

  &__statusSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px; }

  &__planInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    gap: 5px;
    &__status {
      display: flex;
      align-items: center;
      gap: 5px; } }


  &__PatientTreatmentsEligibilityWrapper {
    width: 100%; }

  &__InactiveContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px 0; }

  &__PsiqTabWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px; }
  &__PsicTabWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; }

  &__Highlight {
    &__Green {
      color: $green; } } }
.PatientDetailModal {
  .Modal__container {
    border-radius: 30px; } }
