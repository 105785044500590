.Login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__Title {
    text-align: center;
    margin-bottom: 20px !important; }
  &__Form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &__Error {
      margin-bottom: 10px; } }
  &__SubmitButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  &__Spinner {
    height: 24px !important;
    width: 24px !important;
    border-width: 4px !important;
    border-color: #fff !important;
    border-top-color: transparent !important; } }
