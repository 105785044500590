.AgendaListItem {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: $white;
  padding: 1rem 1.7rem 1rem 1rem;
  border-radius: $radius-m !important;
  @include card(1);

  &--default {
    @extend .AgendaListItem; }
  &--cancelled {
    @extend .AgendaListItem;
    @include ubuntu($font-regular);
    background-color: $grey-two;
    box-shadow: none; }
  &--scheduled {
    @extend .AgendaListItem;
    @include ubuntu($font-regular); }


  &--attending {
    @extend .AgendaListItem;
    @include ubuntu($font-regular); }
  &--attended {
    @extend .AgendaListItem;
    @include ubuntu($font-regular);
    background-color: $grey-two;
    box-shadow: none; }

  &--not-attended {
    @extend .AgendaListItem;
    @include ubuntu($font-regular);
    background-color: $grey-two;
    box-shadow: none; }
  &--personal {
    @extend .AgendaListItem;
    @include ubuntu($font-regular); }
  &__TimeBox {
    display: flex;
    margin-right: 1.4rem; }
  &__Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 10rem;
    &__extra-info {
      display: flex;
      flex-direction: column;
      @include desktop-media {
        display: flex;
        margin-top: 1rem;
        line-height: 1.8rem; } }
    &__info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      &__patient .paragraph {
          font-size: 5rem !important; }
      @include desktop-media {
        display: flex;
        line-height: 1.8rem; } }
    &__message {
      display: flex;
      justify-content: flex-start;
      flex-direction: row; }
    &__Status {
      display: flex;
      justify-content: flex-start;
      flex-direction: row; }
    &__data {
      display: flex;
      flex-direction: row;
      &__plan-full-name {
        margin-left: 2rem; } } }


  &__menuButton {
    cursor: pointer;
    display: flex;
    flex: 2;
    align-items: flex-end;
    justify-content: flex-end; }

  &__emptyCard {
      padding: 15px;
      border: 1px dashed #000;
      border-radius: 5px;
      background-color: #e3e3e3; } }
