.RescheduleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 45.4rem;
  position: relative;
  width: 100%;

  &__calendar {
    margin-bottom: 20px;
    position: relative;

    .SpinnerContainer {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparentize(white, 0.2);
      z-index: 1; }

    .DayPicker-Day--outside::after {
      content: none !important; }

    .DayPicker-Day--schedule {
      position: relative;
      &::before {
        content: '' !important;
        position: absolute;
        left: 0;
        right: 0;
        height: 28px;
        width: 28px;
        background: $grey url('/static/check.svg') no-repeat center center;
        border-radius: 28px;
        left: calc(50% - 14px);
        top: calc(50% - 14px);
        transform: rotate(0deg); } } }

  &__header {
    p {
      line-height: 1.8rem; }
    span {
      font-weight: $font-bold; } }

  &__reservation_text {
    margin: 20px 0; }

  /** Selected hours range section */
  &__hours {
    margin: 0 auto;
    max-width: 38.4rem;
    padding: 2.642rem 0 0 0;
    position: relative;
    width: 100%;

    &__selected {
      align-items: center;
      display: flex;
      height: 4.8rem;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 0 4%;

      p {
        color: $color-secondary;
        flex-shrink: 0;
        font-weight: $font-bold;
        margin-right: 0.5rem; }

      .dropdown {
        flex-shrink: 2;
        margin-bottom: 0;
        &--placeholder,
        &--placeholder-selected {
          flex-shrink: 2;
          min-width: 12rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; } }

      &__clear {
        cursor: pointer;
        flex-shrink: 0;
        height: 3.5rem;
        width: 3.5rem; } } } }
