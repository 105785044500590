.col {
  width: 100%;
  box-sizing: border-box; }

.right-offset {
  margin-right: 10px !important; }

@include desktop-media {
  .col {
    &:first-child {
      margin-left: 0; } }

  .one.col {
    @include columns(1); }

  .two.col {
    @include columns(2); }

  .three.col {
    @include columns(3); }

  .four.col {
    @include columns(4); }

  .five.col {
    @include columns(5); }

  .six.col {
    @include columns(6); }

  .seven.col {
    @include columns(7); }

  .eight.col {
    @include columns(8); }

  .nine.col {
    @include columns(9); }

  .ten.col {
    @include columns(10); }

  .eleven.col {
    @include columns(11); }

  .twelve.col {
    @include columns(12); }

  .mansory-col {
    break-inside: avoid-region !important; } }
