$main-text: 21px;
$label-text: 12px;

.ProfessionalData {
  font-family: "Ubuntu";

  &--spaced {
    margin-right: 30px; }

  &__content {
    font-size: $main-text;
    font-weight: 500;
    color: #4B4B4B;
    display: flex;
    align-items: center;
    @media (max-width: 1096px) {
      justify-content: center; } }

  &__label {
    font-size: $label-text;
    color: #ACACAC;
    line-height: $label-text;
    margin-top: 2px;
    @media (max-width: 1096px) {
      text-align: center; } } }
