$circle-sizes: 15px;

.ChartLabel {
  display: flex;
  margin-bottom: 15px;
  font-family: "Ubuntu";

  &__group {
    display: flex;
    flex-direction: column; }

  &__color {
    width: $circle-sizes;
    height: $circle-sizes;
    border-radius: 50%;
    margin-right: 10px; }

  &__text {
    font-size: 15px;
    color: #4B4B4B;
    line-height: 15px;

    &--highlight {
      width: fit-content;
      padding: 3px 5px;
      font-size: 12px;
      margin-top: 4px;
      border-radius: 3px; } } }
