.ModalMenu {
  position: fixed;
  right: 24px;
  bottom: 80px;
  z-index: 105;

  &__backdrop {
    position: relative;
    height: 100%;
    width: 100%; }

  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;

    &:hover {
      .ModalMenu__item__background {
        background-color: $grey-one; } }

    &:last-child {
      margin-bottom: 0; }

    &__background {
      @include card(2);
      align-items: center;
      background-color: $white;
      color: $grey-eleven;
      display: flex;
      padding: 0.6rem 1.4rem 0.9rem; }

    &__text {
      border-radius: $radius-s;
      max-height: 3.2rem; }

    &__icon {
      border-radius: 50rem;
      margin-left: 1.7rem;
      padding: 0.8rem; } } }
