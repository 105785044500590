.ConsultationCard {
  background-color: $white;
  border-color: $grey-ten;
  border-radius: $radius-m !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 13rem;
  padding: 1rem;
  @include card(1);
  @include desktop-media {
    height: 13rem; }

  &__proDocument {
    @include desktop-media {
      display: flex; } }


  &--cardColor {
    @extend .ConsultationCard;
    background-color: $yellow-light; }

  &__Cardcenter {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 1.5rem;
    margin-right: 2rem;
    @include desktop-media {
      flex-direction: row;
      justify-content: space-around; }

    .Cardcenter {
      &__Psycologist {
        display: flex;
        flex-direction: column;
        line-height: 1.6rem;
        text-align: left;
        width: 14.5rem;
        height: 3.3rem;
        @include desktop-media {
          flex-direction: row;
          align-items: center;
          width: 20.5rem;
          height: auto;
          text-align: center;
          flex: 2; }

        .avatar {
          width: 40px;
          height: 40px;
          display: none;
          @include desktop-media {
            width: 65px;
            height: 65px;
            display: block; } }

        .pinfo {
          @include desktop-media {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            padding: 2rem; } }

        &__Name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden; } } }

    .info {
      margin-top: 2rem;
      @include desktop-media {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 4rem;
        padding-right: 1rem; }

      &__plan {
        @include mobile-media {
          margin-top: 2rem;
          margin-right: 15px;
          line-height: 1rem; }
        @include desktop-media {
          margin-right: 30px; } }

      &__id {
        @include mobile-media {
          line-height: 2rem;
          margin-right: 0.8rem; }
        @include desktop-media {
          margin-right: 30px; } }

      &__price {
        @include mobile-media {
          line-height: 0.8rem; }
        span {
          margin-left: 0.08rem; } } } }

  &__Icon {
    align-items: center;
    display: flex;
    justify-content: center; }

  &__NoIcon {
    margin-right: 24px; }

  &__Notification {
    align-items: center;
    display: flex;
    justify-content: center; } }
