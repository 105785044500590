.InactiveProfessionalModal {

    .img-circular {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-size: cover;
      background-position: center; }

    .__notification--success {
      flex-direction: column;
      margin-bottom: 30px; }

    .Button.red-lighter:not(:disabled) {
      background-color: $red-lighter; } }
