.SignInBarBackdrop {
  display: flex;
  align-items: flex-end;
  background: rgba(196, 196, 196, 0.9);
  z-index: 10;
  height: 100%;
  position: fixed;
  bottom: 0;
  width: 100%; }
.SignInBar {
  &__Collapsed {
    background: #fff;
    height: 76px;
    box-shadow: 0px 1px 20px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
    @include mobile-media {
      background: none;
      box-shadow: none; } }
  &__Expanded {
    height: 550px;
    gap: 90px;
    background: #fff;
    box-shadow: 0px 1px 20px #00000029;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include mobile-media {
      height: 350px;
      gap: 0;
      flex-direction: column-reverse; } }
  &__CloseButton {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    margin: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  &__LoginData {
    display: flex;
    align-items: center;
    @include mobile-media {
      padding: 0 60px;
      flex-direction: column;
      margin: 20px 0; }
    &__Label {
      text-align: center; } }
  &__ButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile-media {
      margin-top: 20px; } }
  &__SignInButton {
    height: 36px !important;
    width: 96px;
    padding: 9px 17px;
    font-size: 15px !important;
    margin-left: 17px; }
  &__SignUpButton {
    height: 36px !important;
    width: 135px;
    padding: 9px 17px;
    font-size: 15px !important;
    margin-left: 10px;
    &__Expanded {
      width: 163px;
      margin-bottom: 30px; } }
  &__LoginWrapper {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  &__LinkWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  &__DividerWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin: 30px 0;
    &__Divider {
      width: 100%;
      height: 1px;
      background: #C1C1C1; } }
  &__Link {
    color: #1F35B5 !important;
    cursor: pointer;
    font-weight: 500 !important; } }
