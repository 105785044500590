.ScheduleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 45.4rem;
  position: relative;
  width: 100%;

  &__calendar {
    margin-bottom: 20px;
    position: relative;

    .SpinnerContainer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparentize(white, 0.2);
      z-index: 1;

      display: flex;
      align-items: center;
      justify-content: center; }

    .DayPicker-Day--outside::after {
      content: none !important; }

    .DayPicker-Day--schedule {
      &::before {
        content: '';
        left: 0;
        right: 0;
        height: 28px;
        width: 28px;
        background: $grey url('/static/check.svg') no-repeat center center;
        border-radius: 28px;
        left: calc(50% - 14px);
        top: calc(50% - 14px);
        transform: rotate(0deg); } } }


  &__hours {
    margin-bottom: 10px;
    position: relative;
    width: 100%;


    &__selected {
      align-items: center;
      border: .5px solid #fff;
      display: flex;
      height: auto;
      justify-content: center;
      margin-bottom: 10px;
      border-radius: 10px;
      animation-name: transitionAn;
      animation-duration: 0.5s;
      animation-timing-function: ease;

      @keyframes transitionAn {
        from {
          transform: translateX(-30px); }

        to {
          background-color: $white;
          transform: translateX(0px); } }

      p {
        color: $color-secondary;
        font-weight: $font-bold;
        min-width: 0px; }


      .dropdown {
        margin: 0 10px 0 20px;
        min-width: auto;
        flex-grow: 1; }

      &__clear {
        cursor: pointer;
        flex-shrink: 0;
        height: 3.5rem;
        width: 3.5rem; } } }

  &__Error {
    align-items: flex-start;
    display: flex;
    margin: 20px 0px;

    &__Icon {
        margin-right: 10px;
        margin-top: -5px;
        transform: rotate(180deg); } }

  &__Reservation {
    border-radius: 10px;
    background-color: #e7ffe5;
    padding: 15px;
    display: flex;
    justify-content: center;
    margin: 0 0 1.5rem 0;

    &__Exceptions {
      margin-top: 10px; }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center; }

    &__footer {
      margin-top: 10px !important; } }

  &__ReservationAdmin {
    min-height: 155px;
    border-radius: 10px;
    background-color: #e7ffe5;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;

    &__footer {
      margin-top: 10px !important; } }

  .TimestampTag {
    margin: 20px 0 10px 0; }

  &__PsychiatryMessage {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;

    svg {
      min-width: fit-content; } } }
