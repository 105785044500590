.CartSummary {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    width: 100%; }

  &__items {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 100%; }

  &__totals {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    width: 100%;

    &__header {
      &__value {
        display: flex;
        justify-content: space-between;
        width: 100%; } }

    &__final {
      &__value {
        display: flex;
        justify-content: space-between;
        width: 100%; } }

    &__separator {
      background-color: $grey-six;
      height: 0.2rem;
      margin: 1rem 0;
      width: 100%; } } }
