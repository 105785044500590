.FilterModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 45.4rem;
  position: relative;
  width: 100%;

  &__Content {
    width: 100%;
    padding: 0px;
    margin-top: 0px; } }

.column {
  display: flex;
  flex-direction: column; }

.input {
  display: flex;
 }  // margin-right: 2rem
.filtermodaltext {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1.5rem; }
.filtermodalcontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  &__toggleBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; } }
.cntbtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 32rem;
  max-width: 37rem;
  @include desktop-media;
  max-width: 59rem; }
.filterInput {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  @include desktop-media;
  margin: 0 auto; }

.FilterClear {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10px;
  margin-bottom: 15px; }
