.NpsTextArea {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  flex-wrap: wrap;

  @include desktop-media {
    flex: 1;
    flex-direction: column;
    align-items: center; }

  &__text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 29rem;

    @include desktop-media {
      width: 52.4rem; } }

  &__textarea {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    @include desktop-media {
      width: 59rem; } }

  &__subtext {
    max-width: 557px;

    p {
      text-align: center; } }

  &__text ~ &__subtext {
    margin-top: 0.5rem; }

  &__text ~ &__textarea {
    margin-top: 2rem; } }
