.container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  width: 100%;

  &.view {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }

  &--mobile-full {
    @extend .container;
    padding: 0; }

  &--min-height {
    @extend .container;
    min-height: calc(100vh - 450px); } }

@include desktop-media {
  .container {
    padding: 0;
    width: $desktop; } }
