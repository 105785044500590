.TextToggleFilter {
  align-content: space-between;
  align-items: center;
  border: dashed 1px $grey-eight;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 4.8rem;
  justify-content: space-between;
  padding: 2rem 2rem;
  width: 100%;
  &:hover,
  &--active {
    @extend .TextToggleFilter;
    background-color: $color-secondary;
    border-color: $color-secondary;
    p {
      color: $white;
      transition: 150ms; }
    path {
      fill: $white; } } }
