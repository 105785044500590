.ProfUnavailabilityModal {
  color: #4B4B4B;

  &__title {
    width: 100%;
    text-align: center;
    font-size: 21px; }

  &__text {
    text-align: center;
    margin-bottom: 10px !important;

    &--toast {
      margin-bottom: 20px;
      color: #ff4949;
      line-height: 1;
      text-align: left; } }

  &__alertText {
    padding: 13px 15px !important;
    font-size: 12px !important;
    line-height: 14px !important;
    border-radius: 5px;
    background-color: #FFFFB5; }

  &__inputItem {
    margin-top: 20px; }

  &__subInput {
    margin-top: -10px;
    font-size: 12px;
    color: #898989; }

  &__doubleInput {
    margin-top: 10px;

    &--shortSpaced {
      margin-top: 20px; } }

  &__toast {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    padding: 0 20px;

    > svg {
      width: 22px;
      height: 22px;
      margin-right: 10px; } }

  &__button {
    margin-top: 20px; } }

.TimestampTag {
  margin: 20px 0; }
