.SimplePersonItem {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 0.1rem solid $grey-two;
    padding: 2rem 1rem;
    width: 100%;
    &__PersonData {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row no-wrap;
        width: 90%;
        &__image {
            display: flex; }

        &__name {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 73%;
            flex-flow: row no-wrap;
            flex-direction: column;
            @include desktop-media {
             width: 90%; } } }
    &__Button {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        min-width: 7rem;
        width: 10%;

        button {
            display: flex;
            align-items: center;
            justify-content: center; } }

    &__separator {
      background-color: $grey-four;
      height: 0.1rem;
      margin: 1rem 0;
      width: 100%; } }
