.Processing,
.Cart,
.Checkout {
  width: 100%;
  min-height: calc(100vh - 12rem) !important; }

.Processing {
  align-items: center;
  display: flex;
  justify-content: center; }

.Cart {
  &__item-list {
    margin-bottom: 2rem; } }

.Checkout {
  &__payment-methods, &__payment-method {
    margin-bottom: 2rem; } }

.PaymentContainer {
  &__payment-method {
    margin-top: 2rem !important; }

  &__registered-card-form, &__new-card-form {
    margin-top: 2rem !important; } }

.Response {
  width: 100%;
  min-height: calc(100vh - 12rem) !important;

  &__icon {
    &-error {
      fill: $red !important; }

    &-sucess {
      fill: $green !important; } } }

.PlanResponse {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 5.2rem); }
