html {
  scroll-behavior: smooth; }

body {
  @include ubuntu($font-regular);
  background-color: $white;

  .App {
    height: 100%;
    overflow-x: hidden;
    width: 100%; } }

h1 {
  @include ubuntu($font-regular);
  color: $color-title;
  font-size: $font-xl;
  line-height: 2.1rem;
  margin: 0;
  text-align: left; }

p,
a {
  margin: 0;
  text-align: left; }

a {
  color: $color-primary;

  &:hover,
  &:active {
    color: $color-text; } }

form {
  width: 100%; }

.messageError {
  color: $red;
  font-size: $font-s;
  margin-left: 0;
  text-align: left;
  width: 100%;
  span {
    margin: 0; } }

.grecaptcha-badge {
    transform: scale(0.7);
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
    opacity: 0; }

.grecaptcha-badge:hover {
    width: 256px !important; }
