/* Fonts */
@mixin font-size($sizes) {
  @each $fs-breakpoint, $fs-size in $sizes {
    @if $fs-breakpoint == null {
      font-size: $fs-size !important; }
    @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-size !important; } } } }

@mixin ubuntu($weight: $font-regular) {
  font-family: $font-ubuntu;
  font-weight: $weight; }

/* Borders */
@mixin complex-border($v-radius, $h-radius) {
  border-top-left-radius: $v-radius $h-radius;
  border-bottom-left-radius: $v-radius $h-radius;
  border-top-right-radius: $v-radius $h-radius;
  border-bottom-right-radius: $v-radius $h-radius; }

/* Media Query */
@mixin modal-media {
  @media (min-width: $modal) {
    @content; } }

@mixin desktop-media {
  @media (min-width: $desktop) {
    @content; } }

@mixin mobile-media {
  @media (min-width: $mobile) and (max-width: $desktop) {
    @content; } }

@mixin tablet-media {
  @media (min-width: $tablet) and (max-width: $desktop) {
    @content; } }

/* Shadows */
@mixin card($depth, $radius: true) {
  @if $radius {
    border-radius: $radius-s; }

  @if $depth < 1 {
    box-shadow: none; }
  @else if $depth > 5 {
    @warn 'Invalid $depth `#{$depth}` for mixin `card`.'; }
  @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth); } }

/* Grids and Columns */
@mixin columns($ac, $tc: 12, $gp: $gutter) {
  // * $cw - column width
  // * $tc - total columns
  // * $ac - actual column (how many columns)
  // * $oc - one column (width)
  // * $tw - total width

  $tw: 100%;
  $oc: $tw / $tc;
  $cw: $oc * $ac;

  // * $gp - gutter percentual (relative to 1 column width)
  // * $gt - gutter total

  $gt: $oc * $gp / $tw;

  // * $fc - final column (width)

  $fc: $cw - $gt;

  // * $gd - gutter distributtion

  $gd: $gt / $tc * $ac;

  // * $rc - redistributted collumn

  $rc: $fc + $gd;

  width: $rc;

  &.left-offset {
    margin-left: $gt; }

  &.right-offset {
    margin-right: $gt; }

  &:first-child {
    margin-left: 0;

    &:last-child {
      width: $cw; } } }

/* Colors */
@mixin svg-colors($color) {
  svg {
    fill: $color !important;
    path {
      fill: $color !important; }
    circle {
      stroke: $color !important; } } }

@mixin hover-opacity {
  &:hover {
      transition: 0.2s;
      opacity: 0.4; } }
