/* colors */
$color-primary: #1f35b5;
$primary-darker: #172888;
$primary-lighter: #d2d7f0; // adicionar ao mouseover

$color-secondary: #22cbd3;
// $color-secondary: #03dac6
$secondary-darker: #1a989e;
$secondary-lighter: #d3f5f6;

$status-online: #55d86b;
$action-online: #4ac326; // btn fale agora
$status-pending: #f9a435;

$white: #ffffff;

$black: #000000;

$grey-one: #f5f5f5;
$grey-two: #ebebeb;
$grey-three: #e0e0e0;
$grey-four: #e2e2e2;
$grey: #dedede;
$grey-five: #dedede;
$grey-six: #c4c4c4;
$grey-seven: #c1c1c1;
$grey-eight: #acacac;
$grey-nine: #9f9f9f;
$grey-ten: #898989;
$grey-eleven: #4b4b4b;
$grey-twelve: #2d2d2d;
$grey-thirteen: #0000004D;

$green: #4AC326;
$aqua: #03DAC6;
$pink: #eb0679;
$pink-lighter: #ef6b7b;

$red: #eb384d;
$red-lighter: #ff4949;

$yellow-light: #ffffb5;
$yellow: #f8c947;
$yellow-dark: #cdcd85;

$orange: #F9A435;

$color-text: #4b4b4b;
$color-title: #4b4b4b;


/* viewports */
$mobile: 0px;
$modal: 520px;
$desktop: 1096px;
$tablet: 768px;

/* grid
 * $gutter is relative to 1 column width */
$gutter: 20%;

/* Font Size */
$font-xs: 1.2rem;
$font-s: 1.5rem;
$font-d: 1.8rem;
$font-m: 2.1rem;
$font-l: 2.4rem;
$font-xl: 3.0rem;

$small-sizes: (null: $font-xs, $desktop: $font-xs);
$medium-sizes: (null: $font-m, $desktop: $font-m);
$subtitle-sizes: (null: $font-d, $desktop: $font-d);
$text-sizes: (null: $font-s, $desktop: $font-s);
$default-sizes: (null: $font-d, $desktop: $font-d);
$title-sizes: (null: $font-m, $desktop: $font-xl); // only for h1
$heading-sizes: (null: $font-l, $desktop: $font-l);
$menu-sizes: (null: $font-d, $desktop: $font-s);

$font-ubuntu: 'Ubuntu';
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

$font-spacing-small: 0.1rem;
$font-spacing-medium: 0.2rem;
$font-spacing-large: 0.4rem;

/* Spacing */
$size-s: 1.2rem;
$size-m: 1.4rem;
$size-l: 1.6rem;
$size-xl: 1.8rem;

/* Radius */
$radius-s: 0.5rem;
$radius-m: 1rem;
$radius-l: 1.5rem;
$radius-circle: 2.5rem;
