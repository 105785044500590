.Avatar {
  display: block;
  height: 100%;
  height: 9.2rem;
  width: 9.2rem;
  position: relative;
  width: 100%;

  &__image {
    background-image: url('/static/avatar.png');
    background-size: cover;
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    justify-items: center;
    overflow: hidden;
    position: relative;
    width: auto;

    img {
      display: inline;
      height: 100%;
      margin: 0 auto;
      width: auto; } }

  &__status {
    border: 3px solid $white;
    border-radius: 50%;
    display: block;
    height: 25px;
    position: absolute;
    right: -3px;
    top: -3px;
    width: 25px;
    z-index: 1;

    &--busy {
      @extend .Avatar__status;
      background-color: red; }

    &--offline {
      display: none; }

    &--online {
      @extend .Avatar__status;
      background-color: $status-online; } } }
