.ModalApproveValidity {
    &__Data {
        &__Fields {
            display: flex;
            width: 100%;

            &--block {
                display: block;
                margin-top: 1rem;

                &:last-child {
                    margin-top: 2rem; }

                p {
                    margin-bottom: 3px; } }

            &__Input {
                margin: 0.75rem;
                margin-bottom: 0;
                margin-top: 0;
                width: 100%;

                &:first-of-type {
                    margin-left: 0; }
                &:last-of-type {
                    margin-right: 0; } } }

        &__Review {

            &__BoxInfo {
                margin-bottom: 1.5rem; } }

        .col {
            width: 100% !important; } } }
