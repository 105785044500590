.PatientsList {
  width: 100%;
  height: 100%;

  &__top {
    background: #F5F5F5;
    padding: 8px 14px;
    font-size: 15px;
    color: #2D2D2D;

    &--fixed {
      width: 100%;
      max-width: 787px;
      position: fixed;
      z-index: 2; } }

  &__list {
    width: 100%;
    list-style: none;
    padding: 0; }

  &__item {
    border-bottom: 2px solid #F5F5F5;

    &:last-child {
      border: none; } } }
