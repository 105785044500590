.Breadcrumb {
  @include ubuntu;
  margin: 2.0rem 0 0;
  width: 100%;

  &__item {
    color: $grey-eight;
    cursor: pointer;
    @include ubuntu($font-regular);
    font-size: $font-xs;
    text-transform: uppercase;
    letter-spacing: $font-spacing-small;

    &::before {
      color: $grey-eight;
      content: '>';
      margin: 0 0.3rem 0.2rem; }

    &:first-child {
      &::before {
        content: '';
        margin: 0; } }

    &:last-child {
      @include ubuntu($font-bold);

      &::before {
        @include ubuntu($font-regular); } }

    &:hover {
      color: $grey-six;
      &::before {
        color: $grey-six; } } } }
