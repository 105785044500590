.Questionnaire {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 100%;
    @include desktop-media {
        flex: 1;
        flex-direction: column;
        align-items: center; }

    &__text {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 29.4rem;
        margin-bottom: 1rem;
        @include desktop-media {
            width: 59rem; } }

    &__numbers {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        @include desktop-media {
            display: flex;
            flex-direction: row;
            overflow: hidden; }

        &::-webkit-scrollbar {
            display: none; } } }
