.app {
  background: #FFFFFF;

  &--gradient {
    background: linear-gradient(#F5F5F5 10%, #FFFFFF 20%);
    @media (max-width: 1096px) {
      background: #FFFFFF; } }

  &--grey {
    background: #F5F5F5; } }

a:hover {
  color: #8E99D9; }
