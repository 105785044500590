.TextInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  .inputContent {
    display: flex;
    height: 100%;
    width: 100%;
    &.error {
      input {
        border-color: $red-lighter;
        opacity: 1;
        outline: none; }
      input {
        &:focus {
          border-color: $red;
          opacity: 1;
          outline: none; } } }

    input {
      border: 0.1rem solid $grey-seven;
      border-radius: $radius-s;
      color: $color-text;
      flex-shrink: 0;
      font-family: $font-ubuntu;
      font-size: $font-s;
      height: 100%;
      padding: 1.5rem;
      width: 100%;

      &::placeholder {
        color: $grey-seven; }

      &:disabled {
        background: $grey-one;
        color: $grey-seven; }

      &:focus {
        border-color: $color-primary;
        color:  $color-text;
        opacity: 1;
        outline: none; }

      &[name=password] {
        padding: 1.8rem 5rem 1.8rem 1.8rem; } }

    span {
      align-content: center;
      align-items: center;
      display: flex;
      float: right;
      margin-left: -4rem;
      position: relative; } }

  &-password {
    cursor: pointer; }

  &__Button {
    cursor: pointer; }

  .messageError {
    color: $red-lighter;
    font-size: $font-xs;
    margin-left: 0;
    span {
        margin: 0; }

    &__topError {
      top: 0;
      position: absolute;
      color: $red-lighter;
      font-size: $font-xs;
      margin-left: 0;
      span {
          margin: 0; } } }

  // .moderateMargin
  //   margin: .5rem 0 !important

  input:-webkit-autofill {  // Remover o fundo amarelo do input no AutoComplete
    -webkit-box-shadow: 0 0 0px 1000px white inset; } }
