.StepperProgress {
  width: 100%;

  .Step {
    @include ubuntu($font-bold);
    @include font-size($text-sizes);
    border-bottom: 0.5rem solid $grey-six;
    text-align: left;
    width: 100%;

    span {
      padding: 0 40%; }

    &:before {
      bottom: -1.3rem;
      content: "\2022";
      left: 94%;
      position: relative; }

    &-doing,
    &-done {
      color: $color-primary;
      border-bottom: 0.5rem solid $color-primary; } } }
